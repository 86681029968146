import { CourseProgressType, ICourseProgress } from '@models/IClassroom'
import { IRates, LangEnum } from '@models/IProfile'
import i18next from 'i18next'
export const IS_DEV_MODE = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
export const PlatformLink = 'https://escuela-clase.web.app'
export const HelpWhatsAppLink = 'https://wa.me/+79832953970'
export const HelpTelegramLink = 'https://t.me/escuela_esp'
export const ManagerPhone = '+7 (495) 487-07-97'
export const InstagramLink = 'https://www.instagram.com/escuela.pro'
export const VkLink = 'https://vk.com/escuelapro'
export const FacebookLink = 'https://www.facebook.com/esp.escuela/'
export const MobileAppLink = 'https://escuela.pro/mobile'
export const AndroidAppLink = 'https://play.google.com/store/apps/details?id=com.escuela.pro'
export const AppleAppLink = 'https://apps.apple.com/us/app/id1524291158'
export const BlogLink = 'https://escuela.pro/rss-feed-961193842695.xml'
export const BlogLinkEnglishAndSpanish = 'https://escuela.pro/rss-feed-285206784881.xml'
export const TelegramNetworkingBot = 'https://t.me/Escuela_Networking_Bot'

export const AudioNotesHintLink =
  'https://escuela.pro/uchim-yazik-cherez-zametki-sekreti-amerikanskogo-poliglota'
export const ExtentionLink =
  'https://chrome.google.com/webstore/detail/iabgfffiiihohefjadcgcbkahjokjcfn'
export const TelegramShareLink = (promoCode: string) =>
  `https://telegram.me/share/url?url=https://escuela.pro&text=Вам будут вручены два бесплатных урока. Просто воспользуйтесь этим промокодом: ${promoCode}`
export const HowLearnWordsBlogPost =
  'https://escuela.pro/blog/tpost/1dufd4lamv-kak-zapominat-vse-inostrannie-slova-koto?utm_source=platform'

export const CURRENCY_EXCHANGE_API_ENDPOINT =
  'https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/eur.json'

export const ourContactsLink = 'https://escuela-clase.web.app/contacts'
export const offerLink = 'https://drive.google.com/file/d/1g8cmGXBSAopEyVzi50ET9F954todawk4/view'
export const policyLink = 'https://escuela.su/policy'
export const rubOfferLink = 'https://drive.google.com/file/d/1W5bHrfvi2boi0BXVj4osRq2wbhZLPA49/view'
export const rubAgreementLink =
  'https://drive.google.com/file/d/1mZgGJPu6z8goSY0g6c6StTgLrWrsrNAB/view'
export const rubPolicyLink = 'https://escuela.pro/policy'

export const NAVIGATOR = {
  main: {
    path: '/',
    name: 'Главная',
  },
  support: {
    path: 'https://forms.yandex.ru/u/5e00f76973c71f5b162a53a1/',
    name: 'Support',
  },

  insertionLesson: {
    path: ' https://escuela-lessons.web.app/teacher',
    name: 'Отметить урок',
  },

  // auth
  register: {
    path: '/register',
    name: 'Регистрация',
  },
  afterRegister: {
    path: '/registration-successful',
    name: 'успешная регистрация',
  },
  login: {
    path: '/login',
    name: 'Вход в кабинет',
  },
  restore: {
    path: '/restore',
    name: 'Восстановление доступа',
  },

  logout: {
    path: '/personal/logout',
    name: 'Logout',
  },

  // cabinet
  personal: {
    path: '/personal',
    name: '',
  },
  manager_register: {
    path: '/manager/register',
    name: 'Создать пользователя',
  },
  manager_accounting: {
    path: '/manager/accounting',
    name: 'Бухгалтерия',
  },
  manager_students: {
    path: '/manager/students',
    name: 'Список учеников',
  },
  manager_students_possible: {
    path: '/manager/students-possible',
    name: 'Потенциальные ученики',
  },
  manager_factory: {
    path: '/manager/exercise-factory',
    name: 'Interactive exercises Factory',
  },
  teacher_classes: {
    path: '/teacher/classes',
    name: 'My classes',
  },
  teacher_courses: {
    path: '/teacher/classes/courses',
    name: 'Доступные курсы',
  },
  teacher_calendar: {
    path: '/teacher',
    name: 'Timetable',
  },
  teacher_library: {
    path: '/teacher/library',
    name: 'Library',
  },
  teacher_homework: {
    path: '/teacher/homework',
    name: 'Homeworks',
  },
  teacher_lesson_history: {
    path: '/teacher/lesson/history',
    name: 'History of lessons',
  },
  teacher_profile: {
    path: '/teacher/profile',
    name: 'Личный профиль',
  },
  teacher_students: {
    path: '/teacher/students',
    name: 'Students',
  },
  teacher_test_review: {
    path: '/teacher/tests/:testId/:pageId?',
    name: 'Teacher Tests',
  },
  teacher_exercise_factory: {
    path: '/teacher/exercise-factory',
    name: 'Interactive exercises Factory',
  },
  manager_profile: {
    path: '/manager/profile',
    name: 'Личный профиль',
  },
  manager_teachers: {
    path: '/manager',
    name: 'Список преподавателей ',
  },
  manager_search_teacher: {
    path: '/manager/search/teacher',
    name: 'Поиск преподавателей',
  },
  manager_constructor: {
    path: '/courses',
    name: 'Конструктор занятий',
  },
  teacher: {
    path: '/teacher',
    name: 'Личный кабинет',
  },
  teacher_courseroom: {
    path: '/teacher/courseroom',
    name: '',
  },

  teacher_classroom: {
    path: '/teacher/classroom',
    name: '',
  },

  teacher_chat: {
    path: '/teacher/chat',
    name: 'Chat',
  },
  manager_search_user: {
    path: '/manager/search/user',
    name: 'Поиск пользователей',
  },
  manager_statistics: {
    path: '/manager/statistics',
    name: 'Статистика',
  },
  //student
  student: {
    path: '/student',
    name: 'Personal',
  },
  student_profile: {
    path: '/student/profile',
    name: 'Main',
    page_title: 'Main',
  },
  student_classes: {
    path: '/student/classes',
    name: 'MyClasses',
    page_title: 'MyClasses',
  },
  student_tests: {
    path: '/student/tests',
    name: 'MyTests',
    page_title: 'MyTests',
  },
  student_courseroom: {
    path: '/student/courseroom',
    name: '',
  },
  auto_teacher_selection: {
    path: '/auto/teacher/selection',
    name: '',
  },
  homework: {
    path: '/student/homework',
    name: 'Homework',
  },
  dictionary: {
    path: '/student/dict',
    name: 'Dictionary',
  },
  leaderShip: {
    path: '/student/leadership',
    name: 'Leadership.table',
  },
  notes: {
    path: '/student/notes',
    name: 'LessonNotes',
  },
  buy: {
    path: '/student/buy',
    name: 'BuyLessons',
  },
  mini_courses: {
    path: '/student/minicourses',
    name: 'MiniCourses.Practikomos',
  },
  tests: {
    path: '/student/tests',
    name: 'Tests',
  },
  test: {
    path: '/student/tests/:testId/:pageId?',
    name: 'Tests',
  },

  chat: {
    path: '/student/chat',
    name: 'Chat.Message',
  },

  // lesson preview
  student_lesson: {
    path: '/student/course/:courseId/lesson/:lessonId/page/:pageId',
    name: 'MyClasses',
    page_title: 'MyClasses',
  },
  teacher_lesson: {
    path: '/teacher/course/:courseId/lesson/:lessonId/page/:pageId',
    name: 'Мои уроки',
    page_title: 'Доступные классы',
  },

  //errors
  forbidden: {
    path: '/error/forbidden',
    name: 'NoAccess',
  },

  //teachers info
  teachersInfo: {
    path: '/teachersInfo',
    name: 'Teachers info',
    page_title: 'Информация об учителях',
  },

  detailsTeacherInfo: {
    path: '/teachersInfo/:teacherId',
    name: 'Teacher details info',
    page_title: 'подробная информация об учителе',
  },
}
export const LOADER_FACTS = {
  es: [
    'В заимствованных словах буква H читается:  <span>hámster, hobby, hokey, hacker</span>',
    'Испанский самый быстрый по произношению язык в мире, после японского.',
    'Эта фраза одинакова, если читать ее слева на право и справа на лево <span>«Anita lava la tina»</span>',
    'В Мексике при приветствии говорят: <span>¿qué onda?</span>, а в Аргентине <span>¿cómo andás?</span> ',
    'В Латинской Америке машину <span>“el coche”</span>, называют <span>“el carro”</span>',
    '23 апреля - день испанского языка. В этот день был похоронен великий испанский писатель Мигель Сервантес ',
    'Перво название испанского языка было <span>“latín hispánico”</span> - “испаноязычная латынь”',
    '<span>El español es el segundo idioma más hablado del mundo por nativos, tras el chino mandarín.</span>',
    '<span>El "Quijote" es la obra más destacada de la literatura en español y uno de los libros más traducidos.</span>',
    '<span>España es el país con más bares por habitante en toda Europa.</span>',
    '<span>El español incorpora palabras de muchas lenguas, incluyendo el árabe, con más de 4,000 préstamos lingüísticos.</span>',
  ],
}

export const API_PATHS = {
  personal: 'personal',
  users: 'users',
  info: 'info',
  classrooms: 'classrooms',
  library: 'library',
  students: 'students',
  homeworks: 'homeworks',
  managers: 'managers',
  promotions: 'promotions',
  payments: 'payments',
  promoCodes: 'promoCodes',
  packages: 'packages',
  config: 'config',
  requisites: 'requisites',
  feedback: 'feedback',
  fbgoal: 'fbgoal',
  passedLessons: 'passedLessons',
  cards: 'cards',
  courses: 'courses',
  publishedCourses: 'publishedCourses',
  activeCourses: 'activeCourses',
  chats: 'chats',
  exercises: 'exercises',
  salesManagers: 'salesManagers',
  tests: 'tests',
  individualPlans: 'individualPlans',
  records: 'records',
}

export const ERROR_LOAD_FILE = 'Toast.FailedToLoadFile'

export const ERROR_TYPE_FILE = {
  image: 'FormatImage',
  video: 'видео',
  audio: 'аудио',
}

export const SKETCHPAD_COLORS = [
  '#9B51E0',
  '#336A93',
  '#2D9CDB',
  '#219653',
  '#FFD657',
  '#FF922D',
  '#F43E3E',
  '#FFFFFF',
  '#272727',
]

export enum CLASSROOM_BLOCK_TYPES {
  BOARD = 'board',
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
  DOCS = 'docs',
  FACTORY = 'factory',
  SUBTOPICS = 'subtopics',
  QUESTIONS = 'questions',
  TEXT_GAME = 'textGame',
  DIALOGUE_WITH_EXERCISE = 'dialogueWithExercise',
  VOCABULARY = 'vocabulary',
  QUESTIONS_VOCABULARY = 'questionsVocabulary',
  INTERACTIVE_EXERCISES = 'interacttiveExercises',
}
export const PROJECT_FILES_TYPES = {
  image: {
    title: 'Classroom.AddPicture',
    type: CLASSROOM_BLOCK_TYPES.IMAGE,
    name: 'Classroom.Picture',
    link_name: 'Google',
    size: 5,
    message: 'Toast.AddedSuccessfully',
  },
  board: {
    title: 'Classroom.AddBoard',
    type: CLASSROOM_BLOCK_TYPES.BOARD,
    name: 'Classroom.Board',
    message: 'Toast.AddedSuccessfully',
  },
  video: {
    title: 'Classroom.AddVideo',
    type: CLASSROOM_BLOCK_TYPES.VIDEO,
    name: 'Classroom.Video',
    link_name: 'YouTube',
    size: 40,
    message: 'Toast.AddedSuccessfully',
  },
  audio: {
    title: 'Classroom.AddAudio',
    type: CLASSROOM_BLOCK_TYPES.AUDIO,
    name: 'Classroom.Audio',
    link_name: 'SoundCloud',
    size: 20,
    message: 'Toast.AddedSuccessfully',
  },
  docs: {
    title: 'Classroom.AddFile',
    type: CLASSROOM_BLOCK_TYPES.DOCS,
    name: 'Classroom.Document',
    link_name: 'Google Docs',
    size: 200,
    message: 'Toast.AddedSuccessfully',
  },
  factory: {
    title: 'Classroom.AddFactory',
    type: CLASSROOM_BLOCK_TYPES.FACTORY,
    name: 'Classroom.Factory',
    link_name: 'Factory',
    size: 5,
    message: 'Toast.AddedSuccessfully',
  },
  aiBlock: {
    title: 'Classroom.AddAIBlock',
    type:
      CLASSROOM_BLOCK_TYPES.SUBTOPICS ||
      CLASSROOM_BLOCK_TYPES.QUESTIONS ||
      CLASSROOM_BLOCK_TYPES.TEXT_GAME ||
      CLASSROOM_BLOCK_TYPES.DIALOGUE_WITH_EXERCISE ||
      CLASSROOM_BLOCK_TYPES.VOCABULARY ||
      CLASSROOM_BLOCK_TYPES.QUESTIONS_VOCABULARY ||
      CLASSROOM_BLOCK_TYPES.INTERACTIVE_EXERCISES,
    name: 'Classroom.AIBlock',
    link_name: 'AIBlock',
    size: 5,
    message: 'Toast.AddedSuccessfully',
  },
}

export const CALENDAR_ITEMS_SLIDER_WIDTH = 2156

export const CALENDAR_ITEM_WIDTH_WITH_PADDING = 98

export const CLASSES_GRADIENTS = [
  '178.33deg, #FF852D 7.05%, #FF7E78 53.8%, #FF79B1 89.58%',
  '178.33deg, #336A93 7.05%, #3EC5AC 89.58%',
  '178.33deg, #1CAE56 7.05%, #C6D238 89.58%',
  '178.33deg, #F75A6D 7.05%, #7A1195 89.58%',
  '178.33deg, #37C3FF 7.05%, #C6F28D 89.58%',
]

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  paymentFnLink: process.env.REACT_APP_FIREBASE_PAYMENT_FUNCTION,
  paySelectionFnLink: process.env.REACT_APP_FIREBASE_PAY_SELECTION_FUNCTION,
  payOneFnLink: process.env.REACT_APP_FIREBASE_ONE_PAY_FUNCTION,
  paySelectionMinicourseFnLink: process.env.REACT_APP_FIREBASE_PAY_SELECTION_MINI_COURSE_FUNCTION,
  payOneFnLinkMinicourseFnLink: process.env.REACT_APP_FIREBASE_ONE_PAY_MINI_COURSE_FUNCTION,

  paymentMinicourseFnLink: process.env.REACT_APP_FIREBASE_PAYMENT_MINI_COURSE_FUNCTION,
  stripePaymentFnLink: process.env.REACT_APP_FIREBASE_STRIPE_PAYMENT_FUNCTION,
  stripePaymentMinicourseFnLink: process.env.REACT_APP_FIREBASE_STRIPE_PAYMENT_MINI_COURSE_FUNCTION,
  writePassedLessonFnLink: process.env.REACT_APP_FIREBASE_WRITE_PASSED_LESSON_FUNCTION,
  calendarFnLink: process.env.REACT_APP_FIREBASE_CALENDAR_FUNCTION,
  proxyImageFnLink: process.env.REACT_APP_FIREBASE_PROXY_IMAGE_FUNCTION,
  getAISuggestionFnLink: process.env.REACT_APP_FIREBASE_GET_AI_SUGGESTION_FUNCTION,
  audioPronounceFnLink: process.env.REACT_APP_FIREBASE_AUDIO_PRONOUNCE_FUNCTION,
  notesDetectFnLink: process.env.REACT_APP_FIREBASE_NOTES_DETECT_FUNCTION,
  translateFnLink: process.env.REACT_APP_FIREBASE_TRANSLATE_FUNCTION,
  generateAITextGameFnLink: process.env.REACT_APP_GENERATE_AI_TEXT_GAME_FUNCTION,
  generateAIFiveSubtopicsFnLink: process.env.REACT_APP_GENERATE_AI_FIVE_SUBTOPICS_FUNCTION,
  generateAITenPhrasesVocabularyFnLink: process.env.REACT_APP_GENERATE_AI_TEN_PHRASES_FUNCTION,
  generateAIQuestionsWithVocabularyWordsFnLink:
    process.env.REACT_APP_GENERATE_AI_QUESTIONS_WITH_VOCABULARY_WORDS_FUNCTION,
  generateAIInteractiveExercisesFnLink:
    process.env.REACT_APP_GENERATE_AI_INTERACTIVE_EXERCISES_FUNCTION,
  generateAIDialogueWithExercisesFnLink:
    process.env.REACT_APP_GENERATE_AI_DIOLOGUE_WITH_EXERCISES_FUNCTION,
  generateAIEightQuestionsFnLink: process.env.REACT_APP_GENERATE_AI_EIGHT_QUESTIONS_FUNCTION,
  generateGeminiTextLink: process.env.REACT_APP_GENERATE_TEXT_VIA_GEMINI_AI_FUNCTION,
  generateOpenAITextLink: process.env.REACT_APP_GENERATE_TEXT_VIA_OPEN_AI_FUNCTION,
  deleteUserFnLink: process.env.REACT_APP_FIREBASE_DELETE_USER_FUNCTION,
  askAIHelperLink: process.env.REACT_APP_ASK_AI_HELPER_FUNCTION,
  generateAudioLink: process.env.REACT_APP_GENERATE_AUDIO,
  matchTopicWithInterest: process.env.REACT_APP_MATCH_TOPIC_WITH_INTEREST,
}

export const TypeOfLangs = {
  es: {
    label: 'Type of Spanish',
    items: [
      { value: 'spanishOfSpain', label: 'LangVariant.1' },
      { value: 'latinAmericanSpanish', label: 'LangVariant.2' },
    ],
  },
  pt: {
    label: 'Type of Portuguese',
    items: [
      { value: 'portugal', label: 'LangVariant.1' },
      { value: 'brasil', label: 'LangVariant.2' },
    ],
  },
}
export const APP_NAME = {
  es: 'Escuela',
  pt: 'PortLang',
}
export const EssayCheck = {
  es: 'Spanish',
  pt: 'Portuguese',
}

export const APP_CONFIG = {
  appName: APP_NAME[process.env.REACT_APP_LANG] || 'Escuela',
  lang: process.env.REACT_APP_LANG || LangEnum.es,
  typeOfLangs: TypeOfLangs[process.env.REACT_APP_LANG || LangEnum.es],
  isEscuela: (process.env.REACT_APP_LANG || LangEnum.es) === LangEnum.es,
  essayAILang: EssayCheck[process.env.REACT_APP_LANG] || 'Spanish',
  loaderFacts: LOADER_FACTS[process.env.REACT_APP_LANG || LangEnum.es],
}

export const GOOGLE_AUTH = {
  clientID: process.env.REACT_APP_AUTH_CLIENT_ID,
  clientSecret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
  tokenUrl: 'https://accounts.google.com/o/oauth2/token',
}

export const LOGIN_CODES = {
  email: {
    invalid: 'auth/invalid-email',
    disabled: 'auth/user-disabled',
  },
  password: {
    wrong: 'auth/wrong-password',
    many_request: 'auth/too-many-requests',
  },
  other: {
    not_found: 'auth/user-not-found',
    account_deactivated: 'auth/account-deactivated',
  },
}

export const REGISTER_CODES = {
  email: {
    invalid: 'auth/invalid-email',
    already_in_use: 'auth/email-already-in-use',
  },

  password: {
    weak_password: 'auth/weak-password',
  },
  other: {
    not_allowed: 'auth/operation-not-allowed',
    student_not_found: 'auth/student-not-found',
    manager_not_found: 'auth/manager-not-found',
    sales_manager_not_found: 'auth/sales-manager-not-found',
    teacher_not_found: 'auth/teacher-not-found',
  },
}

export const RESTORE_CODES = {
  email: {
    invalid: 'auth/invalid-email',
    already_in_use: 'auth/email-already-in-use',
  },
  mobile: {
    missing_android_pkg_name: 'auth/missing-android-pkg-name',
    mission_ios_bundle_id: 'auth/missing-ios-bundle-id',
  },
  url: {
    missing: 'auth/missing-continue-uri',
    invalid: 'auth/invalid-continue-uri',
    unauthorized: 'auth/unauthorized-continue-uri',
  },
  other: {
    not_found: 'auth/user-not-found',
  },
}

export const videoPlayerConfig = {
  youtube: {
    playerVars: { showinfo: 1 },
  },
}

export const CLASS_NAME_ALREADY_EXISTS = 'Такое имя класса уже существует.'
export const NOTES_TEXT = 'Only you can see this'

export enum ROLES {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
  MANAGER = 'MANAGER',
  METHODIST = 'METHODIST',
  MANAGER_ASSISTANT = 'MANAGER_ASSISTANT',
  SALES_MANAGER = 'SALES_MANAGER',
}

export const TEACHING_EXPERIENCE = [
  { label: '1 год', value: 1 },
  { label: '2 года', value: 2 },
  { label: '3 года', value: 3 },
  { label: '4 года', value: 4 },
  { label: '5 лет', value: 5 },
  { label: '6 лет', value: 6 },
  { label: '7 лет', value: 7 },
  { label: '8 лет', value: 8 },
  { label: '9 лет', value: 9 },
  { label: '10 лет', value: 10 },
  { label: '11 лет', value: 11 },
  { label: '12 лет', value: 12 },
  { label: '13 лет', value: 13 },
  { label: '14 лет', value: 14 },
  { label: '15 лет', value: 15 },
  { label: 'более 15 лет', value: 16 },
]

export const Gender_Options = [
  {
    label: 'жен.',
    value: 'F',
  },
  {
    label: 'муж.',
    value: 'M',
  },
]

export type SelectType = {
  label: string | number
  value: string | number
}
export const WEEKS = [
  { label: 'Monday', value: 0 },
  { label: 'Tuesday', value: 1 },
  { label: 'Wednesday', value: 2 },
  { label: 'Thursday', value: 3 },
  { label: 'Friday', value: 4 },
  { label: 'Saturday', value: 5 },
  { label: 'Sunday', value: 6 },
]

export const BUSINESS_HOURS = [
  {
    daysOfWeek: [],
    startTime: '00:00',
    endTime: '01:00',
  },
  {
    daysOfWeek: [],
    startTime: '01:00',
    endTime: '02:00',
  },
  {
    daysOfWeek: [],
    startTime: '02:00',
    endTime: '03:00',
  },
  {
    daysOfWeek: [],
    startTime: '03:00',
    endTime: '04:00',
  },

  {
    daysOfWeek: [],
    startTime: '04:00',
    endTime: '05:00',
  },
  {
    daysOfWeek: [],
    startTime: '05:00',
    endTime: '06:00',
  },
  {
    daysOfWeek: [],
    startTime: '06:00',
    endTime: '07:00',
  },
  {
    daysOfWeek: [],
    startTime: '07:00',
    endTime: '08:00',
  },
  {
    daysOfWeek: [],
    startTime: '08:00',
    endTime: '09:00',
  },
  {
    daysOfWeek: [],
    startTime: '09:00',
    endTime: '10:00',
  },
  {
    daysOfWeek: [],
    startTime: '10:00',
    endTime: '11:00',
  },
  {
    daysOfWeek: [],
    startTime: '11:00',
    endTime: '12:00',
  },
  {
    daysOfWeek: [],
    startTime: '12:00',
    endTime: '13:00',
  },
  {
    daysOfWeek: [],
    startTime: '13:00',
    endTime: '14:00',
  },
  {
    daysOfWeek: [],
    startTime: '14:00',
    endTime: '15:00',
  },
  {
    daysOfWeek: [],
    startTime: '15:00',
    endTime: '16:00',
  },
  {
    daysOfWeek: [],
    startTime: '16:00',
    endTime: '17:00',
  },
  {
    daysOfWeek: [],
    startTime: '17:00',
    endTime: '18:00',
  },
  {
    daysOfWeek: [],
    startTime: '18:00',
    endTime: '19:00',
  },
  {
    daysOfWeek: [],
    startTime: '19:00',
    endTime: '20:00',
  },
  {
    daysOfWeek: [],
    startTime: '20:00',
    endTime: '21:00',
  },
  {
    daysOfWeek: [],
    startTime: '21:00',
    endTime: '22:00',
  },
  {
    daysOfWeek: [],
    startTime: '22:00',
    endTime: '23:00',
  },
  {
    daysOfWeek: [],
    startTime: '23:00',
    endTime: '24:00',
  },
]

export const getBusinessHours = () => {
  return [
    ...BUSINESS_HOURS.map(item => {
      return { ...item, daysOfWeek: [...item.daysOfWeek] }
    }),
  ]
}

export enum CURRENCIES {
  RUB = 'RUB',
  EUR = 'EUR',
  USD = 'USD',
  KZT = 'KZT',
}

export const getRoleTitle = (role: ROLES) => {
  switch (role) {
    case ROLES.STUDENT:
      return i18next.t('IamStudent')
    case ROLES.MANAGER:
      return i18next.t('IamManager')
    case ROLES.TEACHER:
      return i18next.t('IamTeacher')

    default:
      return null
  }
}

export const getCurrencyTitle = (currency: CURRENCIES) => {
  switch (currency) {
    case CURRENCIES.RUB:
      return '₽ (RUB)'
    case CURRENCIES.EUR:
      return '€ (EUR)'
    case CURRENCIES.USD:
      return '$ (USD)'
    case CURRENCIES.KZT:
      return '₸ (KZT)'
  }
}

export enum STUDENT_RATES {
  child = 'child',
  student = 'student',
  couple = 'couple',
}
export enum TEACHER_RATES {
  BEGINNER = 'BEGINNER',
  BEGINNER_TWO = 'BEGINNER_TWO',
  EXPERIENCED = 'EXPERIENCED',
  EXPERIENCED_TWO = 'EXPERIENCED_TWO',
  NATIVE_ONE = 'NATIVE_ONE',
  NATIVE_TWO = 'NATIVE_TWO',
}
export const CURRENCY_CODES_MAP = {
  RUB: 643,
  EUR: 978,
  USD: 840,
}

export const CURRENCY_SYMBOL_MAP = {
  643: '₽',
  978: '€',
  840: '$',
}

export enum CURRENCY_CODES {
  rubl = 643,
  euro = 978,
  usd = 840,
}
export const STUDENT_RATES_INFO = {
  child: {
    title: 'Child',
    rate: 0,
  },
  student: {
    title: 'Student',
    rate: 0,
  },
  couple: {
    title: 'Couple',
    rate: 0,
  },
}

export const TEACHER_RATES_INFO = {
  beginner: {
    title: 'A1',
    rate: 375,
    currency: CURRENCY_CODES.rubl,
  },
  beginner_two: {
    title: 'A2',
    rate: 445,
    currency: CURRENCY_CODES.rubl,
  },
  experienced: {
    title: 'B1',
    rate: 500,
    currency: CURRENCY_CODES.rubl,
  },
  experienced_two: {
    title: 'B2',
    rate: 625,
    currency: CURRENCY_CODES.rubl,
  },
  native_one: {
    title: 'C1',
    rate: 690,
    currency: CURRENCY_CODES.rubl,
  },
  native_two: {
    title: 'C2',
    rate: 750,
    currency: CURRENCY_CODES.rubl,
  },
}

export const getStudentRatesInfo = (rate: STUDENT_RATES) => {
  switch (rate) {
    case STUDENT_RATES.child:
      return STUDENT_RATES_INFO.child
    case STUDENT_RATES.couple:
      return STUDENT_RATES_INFO.couple
    case STUDENT_RATES.student:
      return STUDENT_RATES_INFO.student

    default:
      return STUDENT_RATES_INFO.student
  }
}

export const getRateLesson = (studentRate: STUDENT_RATES, rate: number) => {
  switch (studentRate) {
    case STUDENT_RATES.child:
      return (rate / 100) * 75
    case STUDENT_RATES.couple:
      return rate * 1.5
    default:
      return rate
  }
}

export const getTeacherRatesInfo = (
  rate: TEACHER_RATES,
  passedLessonCount?: number,
  rates?: IRates[]
) => {
  if (!rates || rates?.length <= 0) {
    switch (rate) {
      case TEACHER_RATES.BEGINNER:
        return TEACHER_RATES_INFO.beginner
      case TEACHER_RATES.BEGINNER_TWO:
        return TEACHER_RATES_INFO.beginner_two
      case TEACHER_RATES.EXPERIENCED:
        return TEACHER_RATES_INFO.experienced
      case TEACHER_RATES.EXPERIENCED_TWO:
        return TEACHER_RATES_INFO.experienced_two
      case TEACHER_RATES.NATIVE_ONE:
        return TEACHER_RATES_INFO.native_one
      case TEACHER_RATES.NATIVE_TWO:
        return TEACHER_RATES_INFO.native_two
      default:
        return TEACHER_RATES_INFO.beginner
    }
  } else {
    rates.sort((a, b) => a.after - b.after)

    let applicableRate = rates[0]

    rates.forEach(rate => {
      if (passedLessonCount >= rate.after) {
        applicableRate = rate
      }
    })
    return applicableRate
  }
}

export enum FILE_LOAD_TYPES {
  LINK = 'link',
  LOCAL = 'local',
  LIB = 'lib',
}

export enum CALENDAR_EVENT_TYPES {
  ONCE = 'once',
  TRIAL = 'trial',
  REGULAR = 'regular',
  RESCHEDULE = 'reschedule',
  BOOKED = 'booked',
}

export const EXTERNAL_STUDENT = 'Внешний ученик'

export enum ORDER {
  ASC = 'asc',
  DESC = 'desc',
}
export enum AUTH_STATUS {
  ON = 'logged on',
  OUT = 'logged out',
  UNKNOWN = 'unknown',
}

export const DBConfig = {
  name: 'EscuelaDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'files',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'blob', keypath: 'blob', options: { unique: false } },
      ],
    },
  ],
}

export const TZ_MSK = 'Europe/Moscow'

export const PLAYER_STATE = {
  playing: 'playing',
  paused: 'paused',
  unstarted: 'unstarted',
  buffering: 'buffering',
}

export enum LEVELS {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
}

export enum GRADIES {
  one = 'one',
  two = 'two',
  three = 'three',
  four = 'four',
  five = 'five',
}

export const GRADIES_PERCENT = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
}
export const LEVELS_PERCENT = {
  A1: 0,
  A2: 1,
  B1: 2,
  B2: 3,
  C1: 4,
  C2: 5,
}

export enum STUDENT_MODAL_NAME {
  BONUS = 'BONUS',
  INVITE_FRIEND = 'INVITE_FRIEND',
  CHANGE_EVENT_DATE = 'CHANGE_EVENT_DATE',
  MOBILE_CONTENT = 'MOBILE_CONTENT',
}

export enum STUDENT_NOTIFICATION_NAME {
  FIRST_LESSON = 'FIRST_LESSON',
  REMIND_LESSON = 'REMIND_LESSON',
  ONE_LESSON_REMAIN = 'ONE_LESSON_REMAIN',
}

export const ATTITUDE_TO_HOMEWORK_OPTIONS = ['GreatIDo', 'IDontLike', 'DoesntMatter']
export const MAIN_GOALS = [
  'ToStudy',
  'ToTravel',
  'GetAJob',
  'MoveToSpanishSpeakingCountry',
  'PassTheExam',
  'EnjoyALearningLanguage',
]

export const GOALS = [
  {
    id: 1,
    title: 'Goals.ToStudy',
    active: false,
  },
  {
    id: 2,
    title: 'Goals.ToTravel',
    active: false,
  },
  {
    id: 3,
    title: 'Goals.ToWork',
    active: false,
  },
  {
    id: 4,
    title: 'Goals.ToMove',
    active: false,
  },
  {
    id: 5,
    title: 'Goals.ForExam',
    active: false,
  },
  {
    id: 6,
    title: 'Goals.JustLike',
    active: false,
  },
]

export const INTERESTS = [
  { id: 'family', name: 'Interests.Family' },
  { id: 'sport', name: 'Interests.Sport' },
  { id: 'nature', name: 'Interests.Nature' },
  { id: 'art', name: 'Interests.Art' },
  { id: 'food', name: 'Interests.Food' },
  { id: 'travel', name: 'Interests.Travel' },
  { id: 'movie', name: 'Interests.Movies' },
  { id: 'history', name: 'Interests.History' },
  { id: 'games', name: 'Interests.Game' },
  { id: 'music', name: 'Interests.Music' },
  { id: 'photo', name: 'Interests.Photo' },
  { id: 'health', name: 'Interests.Yoga' },
  { id: 'literature', name: 'Interests.Literature' },
  { id: 'tech', name: 'Interests.Technology' },
  { id: 'business', name: 'Interests.Business' },
  { id: 'policy', name: 'Interests.Politics' },
  { id: 'shopping', name: 'Interests.Fashion' },
  { id: 'desktops', name: 'Interests.Desktops' },
  { id: 'psychology', name: 'Interests.Psychology' },
  { id: 'astrology', name: 'Interests.Astrology' },
  { id: 'tikTok', name: 'Interests.TikTok' },
  { id: 'blogging', name: 'Interests.Blogging' },
  // { id: 'children', name: 'Interests.Children' },
  { id: 'knitting', name: 'Interests.Knitting' },
  { id: 'dancing', name: 'Interests.Dancing' },
  { id: 'animals', name: 'Interests.Animals' },
  { id: 'house', name: 'Interests.House' },
  { id: 'culture', name: 'Interests.Culture' },
  { id: 'entertainment', name: 'Interests.Entertainment' },
  { id: 'philosophy', name: 'Interests.Philosophy' },
]

export const PACKAGES_DESC = {
  russian: {
    title: 'PackagesRussianTitle',
    desc: 'PackagesRussianDescription',
  },
  family: {
    title: 'PackagesFamilyTitle',
    desc: 'PackagesFamilyDescription',
  },
  hispanic: {
    title: 'PackagesIspanicTitle',
    desc: 'PackagesIspanicDescription',
  },
  individual: {
    title: 'PackagesIndividualTitle',
    desc: '',
  },
}
export const EscuelaPackages = [
  {
    type: 'russian',
    courses: [
      { lessons: 4, price: 3840 },
      { lessons: 8, price: 7360 },
      { lessons: 16, price: 13440 },
      { lessons: 32, price: 22080 },
    ],
  },
  {
    type: 'family',
    courses: [
      { lessons: 1, price: 3920 },
      { lessons: 8, price: 7520 },
      { lessons: 16, price: 13760 },
      { lessons: 32, price: 26240 },
    ],
  },
  {
    type: 'hispanic',
    courses: [
      { lessons: 2, price: 9960 },
      { lessons: 8, price: 18880 },
      { lessons: 16, price: 36480 },
      { lessons: 32, price: 63360 },
    ],
  },
]
export const PortLangPackages = [
  {
    type: 'individual',
    courses: [
      { lessons: 4, price: 81 },
      { lessons: 8, price: 144 },
      { lessons: 16, price: 272 },
      { lessons: 32, price: 454 },
    ],
  },
  {
    type: 'family',
    courses: [
      { lessons: 4, price: 120 },
      { lessons: 8, price: 216 },
      { lessons: 16, price: 388 },
      { lessons: 32, price: 684 },
    ],
  },
]

export const DEFAULT_PACKAGES = APP_CONFIG.lang === LangEnum.pt ? PortLangPackages : EscuelaPackages

export const LocalStorageConsts = {
  lessonData: 'lessonData',
  isEndLesson: 'isEndLesson',
}

export const timeZonesList = [
  'Pacific/Midway',
  'Pacific/Tahiti',
  'America/Nome',
  'America/Vancouver',
  'America/Denver',
  'America/Guatemala',
  'America/Detroit',
  'America/Santiago',
  'America/Santiago',
  'America/Bahia',
  'America/Noronha',
  'Atlantic/Azores',
  'Europe/London',
  'Europe/Madrid',
  'Europe/Paris',
  'Europe/Riga',
  'Europe/Moscow',
  'Europe/Saratov',
  'Indian/Maldives',
  'Asia/Almaty',
  'Asia/Tomsk',
  'Asia/Singapore',
  'Asia/Tokyo',
  'Pacific/Saipan',
  'Pacific/Pohnpei',
  'Pacific/Wallis',
  'Pacific/Fiji',
]

export const TranslateSpanish = {
  n: 'ñ',
  a: 'á',
  e: 'é',
  i: 'í',
  o: 'ó',
  u: ['ú', 'ü'],
  N: 'Ñ',
  A: 'Á',
  E: 'É',
  I: 'Í',
  O: 'Ó',
  U: 'Ú',
  '?': '¿',
  '!': '¡',
}

export const InitialProgress: ICourseProgress[] = [
  {
    type: CourseProgressType.native,
    lessons: [],
  },
  {
    type: CourseProgressType.probation,
    lessons: [],
  },
  {
    type: CourseProgressType.music,
    lessons: [],
  },
  {
    type: CourseProgressType.grammar,
    lessons: [],
  },
]
export const correctionTextColor = {
  add: '#1CB219',
  remove: '#BBBBBB',
  lineThrough: '#7E92AC',
}

export const studentMenuStatus = [
  {
    name: 'Personal',
    status: 1,
    type: 'Old',
    path: NAVIGATOR['student'].path,
  },
  {
    name: 'MyClasses',
    status: 1,
    type: 'Old',
    path: NAVIGATOR['student_classes'].path,
  },
  {
    name: 'Homework',
    status: 1,
    type: 'Old',
    path: NAVIGATOR['homework'].path,
  },
  {
    name: 'Chat.Message',
    status: 1,
    type: 'Old',
    path: NAVIGATOR['chat'].path,
  },
  {
    name: 'Leadership.table',
    status: 0,
    type: 'New',
    path: NAVIGATOR['leaderShip'].path,
  },
  {
    name: 'Dictionary',
    status: 1,
    type: 'Old',
    path: NAVIGATOR['dictionary'].path,
  },
  {
    name: 'LessonNotes',
    status: 1,
    type: 'Old',
    path: NAVIGATOR['notes'].path,
  },
  {
    name: 'MiniCourses.Practikomos',
    status: 0,
    type: 'New',
    path: NAVIGATOR['mini_courses'].path,
  },
  {
    name: 'Tests',
    status: 0,
    type: 'New',
    path: NAVIGATOR['tests'].path,
  },
  {
    name: 'MyTests',
    status: 0,
    type: 'New',
    path: NAVIGATOR['student_tests'].path,
  },
]

export const COURSES_STATUS = {
  MODERATION: 'moderation',
  DIVERTED: 'diverted',
  PUBLISHED: 'published',
  CHANGED: 'changed',
  CHANGE_DIVERTED: 'change_rejected',
}
export const levelOptions = [
  { label: 'A1', value: 'A1' },
  { label: 'A2', value: 'A2' },
  { label: 'B1', value: 'B1' },
  { label: 'B2', value: 'B2' },
  { label: 'C1', value: 'C1' },
  { label: 'C2', value: 'C2' },
]
export const countryOptions = [
  { label: 'Argentina', value: 'argentina' },
  { label: 'Bolivia', value: 'bolivia' },
  { label: 'Chile', value: 'chile' },
  { label: 'Colombia', value: 'colombia' },
  { label: 'Costa Rica', value: 'costa_rica' },
  { label: 'Cuba', value: 'cuba' },
  { label: 'Dominican Republic', value: 'dominican_republic' },
  { label: 'Ecuador', value: 'ecuador' },
  { label: 'El Salvador', value: 'el_salvador' },
  { label: 'Equatorial Guinea', value: 'equatorial_guinea' },
  { label: 'Guatemala', value: 'guatemala' },
  { label: 'Honduras', value: 'honduras' },
  { label: 'Mexico', value: 'mexico' },
  { label: 'Nicaragua', value: 'nicaragua' },
  { label: 'Panama', value: 'panama' },
  { label: 'Paraguay', value: 'paraguay' },
  { label: 'Peru', value: 'peru' },
  { label: 'Spain', value: 'spain' },
  { label: 'Uruguay', value: 'uruguay' },
  { label: 'Venezuela', value: 'venezuela' },
  { label: 'Puerto Rico', value: 'puerto_rico' },
]

export const grammaticalRuleOptions = [
  { label: 'Adjectives with SER and ESTAR', value: '1' },
  { label: 'Adverbios de frecuencia', value: '2' },

  {
    label: 'Cuando el género cambia el significado de las palabras',
    value: '4',
  },
  { label: 'Indefinidos afirmativos y negativos', value: '5' },
  { label: 'Pronombres posesivos', value: '6' },
  { label: 'Diferencia entre ser y estar', value: '28' },
  { label: 'Preposiciones Por y Para', value: '29' },
  { label: 'Verbo GUSTAR', value: '30' },
  { label: 'IR A + INFINITIVO', value: '31' },
  { label: 'Pronombres demostrativos', value: '32' },
  { label: 'Estilo indirecto', value: '33' },

  {
    label: 'Imperativo afirmativo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '8.1',
      },
      {
        label: 'de los verbos reflexivos',
        value: '8.2',
      },
      {
        label: 'de los verbos con el cambio vocálico',
        value: '8.3',
      },
      {
        label: 'de los verbos irregulares',
        value: '8.4',
      },
    ],
  },
  {
    label: 'Imperativo negativo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '9.1',
      },
      {
        label: 'de los verbos reflexivos',
        value: '9.2',
      },
      {
        label: 'de los verbos con el cambio vocálico',
        value: '9.3',
      },
      {
        label: 'de los verbos irregulares',
        value: '9.4',
      },
    ],
  },
  {
    label: 'Imperativo negativo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '10.1',
      },
      {
        label: 'de los verbos reflexivos',
        value: '10.2',
      },
      {
        label: 'de los verbos con el cambio vocálico',
        value: '10.3',
      },
      {
        label: 'de los verbos irregulares',
        value: '10.4',
      },
    ],
  },
  {
    label: 'Presente de indicativo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '11.1',
      },
      {
        label: 'de los verbos con el cambio vocálico',
        value: '11.2',
      },
      {
        label: 'de los verbos irregulares',
        value: '11.3',
      },
      {
        label: 'de los verbos reflexivos',
        value: '11.4',
      },
    ],
  },
  {
    label: 'Pretérito indefinido de indicativo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '12.1',
      },
      {
        label: 'de los verbos irregulares',
        value: '12.2',
      },
      {
        label: 'de los verbos con el cambio vocálico',
        value: '12.3',
      },
      {
        label: 'de los verbos reflexivos',
        value: '12.4',
      },
    ],
  },
  {
    label: 'Pretérito imperfecto de indicativo ',
    options: [
      {
        label: 'de los verbos regulares',
        value: '13.1',
      },
      {
        label: 'de los verbos irregulares',
        value: '13.2',
      },
      {
        label: 'de los verbos con el cambio vocálico',
        value: '13.3',
      },
      {
        label: 'de los verbos reflexivos',
        value: '13.4',
      },
    ],
  },
  {
    label: 'Pretérito perfecto de indicativo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '14.1',
      },
      {
        label: 'de los verbos irregulares',
        value: '14.2',
      },
    ],
  },
  {
    label: 'Pretérito pluscuamperfecto de indicativo',
    options: [
      {
        label: 'de los verbos regulares ',
        value: '15.1',
      },
      {
        label: 'de los verbos irregulares',
        value: '15.2',
      },
      {
        label: 'de los verbos reflexivos',
        value: '15.3',
      },
    ],
  },
  {
    label: 'Futuro simple ',
    options: [
      {
        label: 'de los verbos regulares',
        value: '16.1',
      },
      {
        label: 'de los verbos irregulares',
        value: '16.2',
      },
      {
        label: 'de los verbos reflexivos',
        value: '16.3',
      },
    ],
  },
  {
    label: 'Futuro compuesto  ',
    options: [
      {
        label: 'de los verbos regulares',
        value: '17.1',
      },
      {
        label: 'de los verbos irregulares',
        value: '17.2',
      },
      {
        label: 'de los verbos reflexivos',
        value: '17.3',
      },
    ],
  },
  {
    label: 'Presente de subjuntivo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '18.1',
      },
      {
        label: 'de los verbos irregulares',
        value: '18.2',
      },
    ],
  },
  {
    label: 'Pretérito imperfecto de subjuntivo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '19.1',
      },
      {
        label: 'de los verbos y regulares',
        value: '19.2',
      },
    ],
  },
  {
    label: 'Pretérito perfecto de subjuntivo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '20.1',
      },
      {
        label: 'de los verbos y regulares',
        value: '20.2',
      },
    ],
  },
  {
    label: 'Pretérito pluscuamperfecto de subjuntivo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '21.1',
      },
      {
        label: 'de los verbos y regulares',
        value: '21.2',
      },
    ],
  },
  {
    label: 'Condicional simple de indicativo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '22.1',
      },
      {
        label: 'de los verbos irregulares',
        value: '22.2',
      },
    ],
  },
  {
    label: 'Condicional compuesto de indicativo',
    options: [
      {
        label: 'de los verbos regulares',
        value: '23.1',
      },
      {
        label: 'de los verbos irregulares',
        value: '23.2',
      },
    ],
  },
  {
    label: ' Oraciones condicionales',
    options: [
      {
        label: 'Oraciones condicionales tipo I',
        value: '34.1',
      },
      {
        label: 'Oraciones condicionales tipo II',
        value: '34.2',
      },
      {
        label: 'Oraciones condicionales tipo III',
        value: '34.3',
      },
      {
        label: 'Oraciones condicionales mixtas',
        value: '34.4',
      },
    ],
  },

  {
    label: 'Perífrasis verbales',
    options: [],
  },
  {
    label: 'Sustantivos',
    options: [
      {
        label: 'Género de los sustantivos',
        value: '24.1',
      },
      {
        label: 'Número de los sustantivos',
        value: '24.2',
      },
    ],
  },
  {
    label: 'Adjetivos',
    options: [
      {
        label: 'Género de los adjetivos',
        value: '25.1',
      },
      {
        label: 'Número de los adjetivos',
        value: '25.2',
      },
      {
        label: 'Grado comparativo',
        value: '25.3',
      },
      {
        label: 'Superlativo relativo',
        value: '25.4',
      },
      {
        label: 'Superlativo absoluto',
        value: '25.5',
      },
    ],
  },
  {
    label: 'Pronombres y determinantes',
    options: [
      {
        label: 'Personales',
        value: '26.1',
      },
      {
        label: 'Posesivos',
        value: '26.2',
      },
      {
        label: 'Reflexivos',
        value: '26.3',
      },
      {
        label: 'Relativos',
        value: '26.4',
      },
      {
        label: 'Interrogativos',
        value: '26.5',
      },
      {
        label: 'Demostrativos',
        value: '26.6',
      },
      {
        label: 'Indefinidos',
        value: '26.7',
      },
      {
        label: 'Objeto directo',
        value: '26.8',
      },
      {
        label: 'Objeto indirecto',
        value: '26.9',
      },
    ],
  },
  {
    label: 'Articulos',
    options: [
      {
        label: 'Articulos determinados',
        value: '27.1',
      },
      {
        label: 'Articulos indeterminados',
        value: '27.2',
      },
      {
        label: 'Articulo neutro "lo"',
        value: '27.3',
      },
    ],
  },
  {
    label: 'Preposiciones',
    options: [],
  },
  {
    label: 'Adverbios',
    options: [],
  },
]

export const PROMPTS = [
  {
    title: 'Da retroalimentación al estudiante para resumir la lección',
    desc: 'Celebra el esfuerzo, no solo los resultados.',
  },
  {
    title: 'Da retroalimentación al estudiante para resumir la lección',
    desc:
      'Para que la retroalimentación sea informativa en lugar de controladora, elogia el trabajo que ha realizado el estudiante.',
  },
  {
    title: 'Da retroalimentación al estudiante para resumir la lección',
    desc:
      "Si no sabes por qué elogiar a un estudiante, usa la frase: 'He notado': 'He notado que has estado completando diligentemente tus asignaciones durante dos semanas ahora'.",
  },
  {
    title: 'Da retroalimentación al estudiante para resumir la lección',
    desc:
      'Puedes pedir al estudiante que reflexione por sí mismo/a. Por ejemplo, pídele que nombre 3 cosas que haya aprendido en la lección.',
  },
  {
    title: 'Da retroalimentación al estudiante para resumir la lección',
    desc:
      "Puedes pedir al estudiante que reflexione por sí mismo/a. Por ejemplo, pregúntale, '¿Qué tareas te resultaron fáciles y cuáles difíciles?'",
  },
]

export const grammaticalRuleOptionsWithChilds = () => {
  const options = []
  for (const option of grammaticalRuleOptions) {
    if (option.options?.length > 0) {
      for (const child of option.options) {
        options.push(child)
      }
    } else {
      options.push(option)
    }
  }
  return options
}

export const CURRRENCY_SYMBOL = {
  RUB: '₽',
  EUR: '€',
  USD: '$',
  KZT: '₸',
}
