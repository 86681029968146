import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { rem } from '../../styles/fuctions'

export const DashboardContainer = styled.div<{
  student?: boolean
  isTeacherLessonPage: boolean
  open: boolean
}>`
  flex-grow: 1;

  display: grid;
  grid-template-areas: ${({ student, isTeacherLessonPage }) =>
    isTeacherLessonPage
      ? student
        ? "'main'"
        : "'nav main'"
      : student
      ? "'main main' 'main main'"
      : "'nav header' 'nav main'"};

  @media (min-width: 576px) {
    grid-template-columns: ${({ isTeacherLessonPage, student, open }) =>
      isTeacherLessonPage && student ? '1fr' : open ? '286px 1fr' : '70px 1fr'};
    grid-template-rows: ${({ isTeacherLessonPage }) => (isTeacherLessonPage ? '1fr' : '55px 1fr')};
  }

  @media (max-width: 576px) {
    grid-template-columns: ${({ open }) => (open ? `${rem(286)} 1fr` : `${rem(70)} 1fr`)};
    grid-template-rows: ${({ open }) => (open ? `${rem(286)} 1fr` : `${rem(70)} 1fr`)};
  }
`

export const NavBar = styled.nav<{
  open: boolean
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  background-color: ${props => props.theme.colors.bgNav};
  grid-area: nav;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  z-index: 99;
  transition: width 0.2s;
  .drawer-btn {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 9999;
    right: ${props => (props.open ? '0px' : '-10px')};
    cursor: pointer;

    ${props => {
      return (
        props.open &&
        css`
          rotate: 180deg;
        `
      )
    }}
  }

  @media (min-width: 576px) {
    width: ${({ open }) => (open ? `286px` : `70px`)};
  }

  @media (max-width: 576px) {
    width: ${({ open }) => (open ? `${rem(286)} ` : `${rem(70)} `)};
  }
`

export const NavFace = styled.div<{ open: boolean }>`
  position: relative;

  margin: ${props => (props.open ? '15px 0px 15px' : '25px auto')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 56px;
  height: 56px;
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    right: 0;
    background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='white'/%3E%3Cpath d='M4 10.3336V12H5.66644L10.5813 7.08513L8.91487 5.41869L4 10.3336ZM11.87 5.79642C12.0433 5.62311 12.0433 5.34315 11.87 5.16984L10.8302 4.12998C10.6569 3.95667 10.3769 3.95667 10.2036 4.12998L9.39036 4.9432L11.0568 6.60964L11.87 5.79642Z' fill='%231B3B77'/%3E%3C/svg%3E%0A")
      center no-repeat;

    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
`
export const NavFaceContainer = styled(NavLink)<{ open: boolean; active?: boolean }>`
  display: flex;

  ${props => {
    return (
      props.open &&
      css`
        justify-content: flex-start;
        align-items: center;
        margin-left: 23px;
        text-decoration: none;
        position: relative;
      `
    )
  }}
  cursor: pointer;
  .menu-text {
    display: block;
    position: relative;
    align-items: center;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    z-index: 10;
    margin-left: 15px;

    opacity: ${props => (props.active ? 1 : 0.5)};
    &:hover {
      .menu-text {
        display: flex;
        color: ${props => (props.open ? props.theme.colors.orange : '#fff')};
      }
    }
    ${props => {
      return (
        !props.open &&
        css`
          display: none;
          align-items: center;
          position: absolute;
          background: RGBA(65, 71, 82, 0.8);
          height: 32px;
          border-radius: 8px;
          text-align: center;
          font-size: 14px;
          color: #fff;
          padding: 0 15px;
          z-index: 10;
          left: 60px;
          opacity: 1;
        `
      )
    }}
  }
`

export const NavMenuLink = styled(NavLink)<{
  active?: boolean
  isSearchUser?: boolean
  open: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;

  ${props => {
    return (
      props.open &&
      css`
        justify-content: flex-start;
        margin-left: 23px;
      `
    )
  }};
  @media (min-width: 576px) {
    width: ${props => (props.open ? `100%` : `56px`)};
    height: 56px;
    > svg {
      width: 30px;
    }
  }
  @media (max-width: 576px) {
    width: ${({ open }) => (open ? `100%` : `${rem(56)}`)};
    height: ${rem(56)};
    > svg {
      width: ${rem(30)};
    }
  }
  &:hover {
    .menu-text {
      display: flex;
      color: ${props => (props.open ? props.theme.colors.orange : '#fff')};
    }
    > svg {
      g {
        opacity: 1;
      }

      path {
        fill: ${props => !props.isSearchUser && props.theme.colors.orange};
        stroke: ${props => props.isSearchUser && props.theme.colors.orange};
      }
    }
  }
  > svg {
    g {
      opacity: ${props => (props.active ? 1 : 0.5)};
    }
  }

  .menu-text {
    display: block;
    position: relative;
    align-items: center;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    z-index: 10;
    margin-left: 15px;
    white-space: nowrap;
    opacity: ${props => (props.active ? 1 : 0.5)};

    ${props => {
      return (
        !props.open &&
        css`
          display: none;
          align-items: center;
          position: absolute;
          background: RGBA(65, 71, 82, 0.8);
          height: 32px;
          border-radius: 8px;
          text-align: center;
          font-size: 14px;
          color: #ffffff;
          padding: 0 15px;
          z-index: 10;
          left: 60px;
          opacity: 1;
        `
      )
    }}
  }
  .homework-notification {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    word-wrap: wrap;
    text-align: left;
    background-color: #ff922d;
    color: #fff;
    font-size: 14px;
    font-family: Open Sans;
    border-radius: 10px;
    width: 200px;
    height: 81px;
    left: 85px;
    padding: 20px;
    box-shadow: -4px 4px 6px 0px rgba(0, 0, 0, 0.25);
    z-index: 10;
    .left-arrow {
      position: absolute;
      left: -25px;
      top: 26px;
    }
    span {
      white-space: normal;
    }
  }
  .nav-item-notification {
    border-radius: 40px;
    background-color: #ff922d;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      g {
        opacity: 1;
      }
    }
  }
`

export const NavMenuContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
`

export const Header = styled.header<{ isTeacherSearchPage?: boolean; open: boolean }>`
  background-color: ${props => props.theme.colors.bgHeader};
  align-items: center;
  padding: 0 20px 0 20px;
  color: ${props => props.theme.textColors.mainContent};
  font-weight: bold;
  grid-area: header;
  position: fixed;
  z-index: 99;
  left: ${({ open }) => (open ? `286px` : `70px`)};
  right: 0;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    font-size: 12px !important;
    height: 70px;
    padding: 0 5px;
    left: ${({ open }) => (open ? `286px` : `56px`)};
    width: ${({ open }) => (open ? `calc(100% - 56px)` : `calc(100% - 56px)`)};
    box-sizing: border-box;
  }
`

export const DashboardMain = styled.main<{
  isChatPage: boolean
  isTeacherSearchPage?: boolean
  isCalendarPage?: boolean
}>`
  grid-area: main;
  display: flex;
  flex-direction: column;
  padding: ${props => (props.isChatPage ? '0px' : '15px')};
  overflow: ${props => (props.isChatPage ? 'hidden' : 'visible')};
  background-color: ${props => props.theme.colors.bgDashboard};
  padding-top: ${props => props.isCalendarPage && '0px'};
  @media (max-width: 991px) {
    overflow-x: ${props => (props.isTeacherSearchPage ? 'auto' : 'visible')};
    padding: 5px;
    padding-top: ${props => props.isCalendarPage && '0px'};
  }
`

export const HeaderMenuContainer = styled.div`
  display: flex;
`

export const HeaderMenuItem = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 767px) {
    display: none;
  }
`
export const SyncBtn = styled.div`
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #7e92ac;
  cursor: pointer;
  img {
    transition: transform 0.7s ease-in-out;
  }
  &:hover {
    img {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 767px) {
    span {
      font-size: 12px;
    }
  }
`
export const HeaderMenuLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: relative;
  @media (max-width: 767px) {
    .linkItem {
      flex-direction: column !important;
      gap: 2px;
    }
    .headerSearchText {
      font-size: 12px !important;
    }
  }
`

export const GoBackContainer = styled.div`
  display: flex;
  cursor: pointer;

  img {
    margin-right: 20px;
  }
`

export const GoBackTitle = styled.div`
  color: ${props => props.theme.textColors.mainContent};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.01em;
`
export const ConnectionError = styled.div<{ isOnline: boolean }>`
  background: rgb(21, 86, 207);
  z-index: 555;
  color: white;
  text-align: center;
  position: fixed;
  bottom: ${props => (props.isOnline ? '-50px' : 0)};
  width: 100%;
  padding: 5px 0px;
  transition: all 0.6s;
`
export const UncheckeddHomeworkContainer = styled.div`
  width: 498px;
  display: flex;

  flex-direction: column;
  align-items: center;
  position: relative;
  svg {
    position: absolute;
    right: 16px;
    top: 16px;
  }
`

export const UncheckeddHomeworkTitle = styled.div`
  color: #414752;
  text-align: center;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: 40px 60px 34px 60px;
`

export const UncheckedHomeworkDesc = styled.div`
  color: #414752;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.18px;
  margin: 0px 60px 10px 60px;
`

export const UnCheckedHomeworkClose = styled.button`
  color: #414752;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  width: fit-content;
  margin-top: 20px;
  margin-bottom: 30px;
`

export const UnCheckedHomeworkButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  background: #ff922d;
  border-radius: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Color_menu_dashb */

  color: #f3f5f9;
  width: fit-content;
  margin-top: 40px;
`
