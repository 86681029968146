import { IStudentProfile } from '@models/IProfile'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import * as Styled from './personal-info.styles'
import { ATTITUDE_TO_HOMEWORK_OPTIONS, getStudentRatesInfo, GRADIES } from '@utils/constants'
import { firebaseTimestamp, individualPlansRef, studentsRef } from '@configs/firebase'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { parseBirthDate } from '@utils/helperst'
import StartImg from '../../Icons/start.svg'
import StudentButton from '@components/Student/Button'
import { theme } from '@styles/Theme'
import Worksheet from './Worksheet'
import LevelInfo from './EditComponents/LevelInfo'
import InterestsInfo from './EditComponents/InterestsInfo'
import CustomAccordion from './EditComponents/CustomAccordion'
import DeadlineInfo from './EditComponents/DeadlineInfo'
import MainGoalInfo from './EditComponents/MainGoalInfo'
import PersonalInfo from './EditComponents/PersonalInfo'
import AttitudeToHomeworkInfo from './EditComponents/AttitudeToHomeworkInfo'
import FrequencyOfClasssesInfo from './EditComponents/FrequencyOfClasssesInfo'
import IntermediateGoalInfo from './EditComponents/IntermediateGoalInfo'
import PrioritiesInfo from './EditComponents/PrioritiesInfo'
import { createIndividualPlanForStudent } from '@servise/webhookAPI'
import Spinner from 'react-spinkit'
import { GenerationText, WorkSheetContainer } from './Worksheet/worksheet.styles'
import IndividualPlanModal from '@components/Student/IndividualPlan/IndividualPlanModal'

type EditType =
  | 'level'
  | 'interests'
  | 'intermediateGoals'
  | 'deadline'
  | 'attitudeToHomework'
  | 'name'
  | 'intermediateDeadline'
  | null

const PersonalData = (props: {
  studentData: IStudentProfile
  handleChange: (data: IStudentProfile) => void
}) => {
  const { t } = useTranslation()
  const [student, setStudent] = useState<IStudentProfile>({
    ...props.studentData,
  })
  const [workSheetIsStart, setWorkSheetIsStart] = useState(false)
  const [editType, setEditType] = useState<EditType>(null)
  const [trialLesson, setTrialLesson] = useState<boolean>(false)
  const [wasChanged, setWasChanged] = useState(false)
  const [individualPlan, setIndividualPlan] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [years, setYears] = useState<number | null>(() => {
    if (props.studentData.birthDate) {
      return moment().diff(
        moment(parseBirthDate(props.studentData.birthDate), 'dd.mm.yyyy'),
        'years'
      )
    }
    return null
  })

  useEffect(() => {
    setStudent({ ...props.studentData })
    if (!props.studentData.classFrequency) {
      setTrialLesson(true)
    } else {
      setTrialLesson(false)
    }
  }, [props.studentData])

  const handleClickEdit = useCallback(
    async (type: EditType) => {
      setEditType(null)
      let updateDate: any = {}
      if (type === editType) {
        if (type === 'level') {
          if (
            student.level !== props.studentData.level ||
            student.levelGrade !== props.studentData.levelGrade
          ) {
            updateDate = {
              level: student.level,
              levelGrade: student.levelGrade,
              levelLastUpdate: firebaseTimestamp.now(),
            }
            setStudent(prev => ({
              ...prev,
              levelLastUpdate: updateDate.levelLastUpdate,
            }))
          }
        } else if (type === 'name') {
          updateDate = {
            name: student.name,
            lastName: student.lastName,
          }
        } else {
          if (type === 'intermediateDeadline') {
            type = 'intermediateGoals'
          }
          updateDate = { [type]: student[type] }
        }

        props.handleChange({ ...student, ...updateDate })
        await studentsRef.doc(student.id).update({ ...updateDate })
        setWasChanged(true)
      } else {
        setEditType(type)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [student, props.studentData, editType]
  )
  const rate = useMemo(() => {
    return getStudentRatesInfo(student.rate)
  }, [student.rate])

  const onCloseWorksheet = (data?: IStudentProfile) => {
    if (data) {
      setStudent(data)
      props.handleChange(data)
    }

    setTrialLesson(false)
    setWorkSheetIsStart(false)
  }

  const onChangeData = ({ name, value }: { name: string; value: any }) => {
    setStudent(prev => ({ ...prev, [name]: value }))
  }

  const onChangeName = (data: string) => {
    const normalizedData = data.replace(/\s+/g, ' ').trim()
    const delimiterIndex = normalizedData.search(/\b(и|\/)\b/)

    const firstName =
      delimiterIndex !== -1
        ? normalizedData.substring(0, delimiterIndex).trim()
        : normalizedData
            .split(' ')
            .slice(0, -1)
            .join(' ')
            .trim()

    const lastName =
      delimiterIndex !== -1
        ? normalizedData.substring(delimiterIndex + 1).trim()
        : normalizedData.split(' ').pop() || ''

    setStudent(prev => ({
      ...prev,
      name: firstName,
      lastName: lastName,
    }))
  }
  const handleOpenPlan = async () => {
    try {
      const planSnap = await individualPlansRef.doc(student.id).get()
      if (planSnap.exists) {
        const plan = planSnap.data()
        setIndividualPlan(plan)
      } else {
        setIsLoading(true)
        await createIndividualPlanForStudent(student.level, student.id, 70)
        setTimeout(async () => {
          await individualPlansRef
            .doc(student.id)
            .get()
            .then(snap => {
              setIndividualPlan(snap.data())
            })
          setIsLoading(false)
        }, 7000)
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Styled.PersonalDataContainter overflowHidden={'intermediateGoals' === editType}>
      <Styled.StudentCardHeader>Student's info</Styled.StudentCardHeader>
      {workSheetIsStart ? (
        <Worksheet student={student} onClose={onCloseWorksheet} />
      ) : isLoading ? (
        <WorkSheetContainer>
          <GenerationText>Генерируем план занятий...</GenerationText>
          <div style={{ alignSelf: 'center' }}>
            <Spinner
              style={{
                display: 'flex',
                transform: 'scale(0.6)',
              }}
              name="line-spin-fade-loader"
              fadeIn="none"
              color="orange"
            />
          </div>
        </WorkSheetContainer>
      ) : individualPlan ? (
        <Styled.Div className="personal-content">
          <IndividualPlanModal
            styles={{ marginTop: 20, marginBottom: 20 }}
            individualPlan={individualPlan}
            teacherView
            handleTeacherClose={() => {
              setIsLoading(false)
              setIndividualPlan(null)
            }}
          />
        </Styled.Div>
      ) : (
        <Styled.Div className="personal-content">
          <PersonalInfo
            editType={editType}
            handleClickEdit={handleClickEdit}
            onChange={onChangeName}
            rate={rate}
            years={years}
            student={student}
          />
          {trialLesson ? (
            <Styled.WorksheetStartInfo>
              <Styled.WorksheetStartTitle>Student Questionnaire</Styled.WorksheetStartTitle>
              <Styled.WorksheetStartDesc>{t('WorksheetDesc')}</Styled.WorksheetStartDesc>
              <div style={{ width: 'fit-content' }}>
                <StudentButton
                  onClick={() => setWorkSheetIsStart(true)}
                  text={t('Balance.Start')}
                  bgColor={theme.colors.button}
                  img={StartImg}
                />
              </div>
            </Styled.WorksheetStartInfo>
          ) : (
            <>
              <LevelInfo
                level={student.level}
                levelGrade={student.levelGrade || GRADIES.one}
                levelLastUpdate={student.levelLastUpdate}
                editType={editType}
                handleClickEdit={handleClickEdit}
                onChange={onChangeData}
              />
              {student?.intermediateGoals?.length > 0 ? (
                <IntermediateGoalInfo
                  goal={student.goal}
                  goals={student?.intermediateGoals}
                  editType={editType}
                  handleClickEdit={handleClickEdit}
                  onChange={onChangeData}
                />
              ) : (
                <>
                  <MainGoalInfo
                    goal={student.goal}
                    editType={editType}
                    handleClickEdit={handleClickEdit}
                    onChange={onChangeData}
                  />

                  <DeadlineInfo
                    deadline={student.deadline}
                    editType={editType}
                    handleClickEdit={handleClickEdit}
                    onChange={onChangeData}
                  />
                </>
              )}

              <FrequencyOfClasssesInfo
                classFrequency={student?.classFrequency ? student.classFrequency : [0, 0]}
                editType={editType}
                handleClickEdit={handleClickEdit}
                onChange={onChangeData}
              />
              <AttitudeToHomeworkInfo
                attitudeToHomework={student.attitudeToHomework || ATTITUDE_TO_HOMEWORK_OPTIONS[0]}
                editType={editType}
                handleClickEdit={handleClickEdit}
                onChange={onChangeData}
              />
              <PrioritiesInfo
                priorities={
                  student.priorities || {
                    reading: 'notImportant',
                  }
                }
                editType={editType}
                handleClickEdit={handleClickEdit}
                onChange={onChangeData}
              />
              {student?.intermediateGoals?.length > 0 && (
                <CustomAccordion header={'Main goal and deadline'} hover={!editType}>
                  <MainGoalInfo
                    goal={student.goal}
                    editType={editType}
                    handleClickEdit={handleClickEdit}
                    onChange={onChangeData}
                  />
                  <DeadlineInfo
                    deadline={student.deadline}
                    editType={editType}
                    handleClickEdit={handleClickEdit}
                    onChange={onChangeData}
                  />
                </CustomAccordion>
              )}

              <Styled.ShowPlanButton onClick={handleOpenPlan}>
                Open the training plan
              </Styled.ShowPlanButton>

              {wasChanged && (
                <Styled.Advice>
                  Советуем студенту сгенерировать новый план обучения, так как в анкету были внесены
                  изменения.
                </Styled.Advice>
              )}
              <InterestsInfo
                interests={student.interests || []}
                editType={editType}
                handleClickEdit={handleClickEdit}
                onChange={onChangeData}
              />
            </>
          )}
        </Styled.Div>
      )}
    </Styled.PersonalDataContainter>
  )
}

export default PersonalData
