import * as React from 'react'

const CheckedIcon = ({
  color = '#1556CF',
  withBorder = false,
}: {
  color?: string
  withBorder?: boolean
}) => {
  if (withBorder) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="18" height="18" rx="3" stroke="#D5D5D5" stroke-width="2" />
        <path
          d="M8.5 12.6326L5.99622 10.1288L5.875 10.0076L5.75378 10.1288L4.87878 11.0038L4.75756 11.125L4.87878 11.2462L8.37878 14.7462L8.5 14.8674L8.62122 14.7462L16.1212 7.24622L16.2424 7.125L16.1212 7.00378L15.2462 6.12878L15.125 6.00756L15.0038 6.12878L8.5 12.6326Z"
          fill="#D5D5D5"
          stroke="#D5D5D5"
          stroke-width="0.342857"
        />
      </svg>
    )
  }
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" fill={color} />
      <path
        d="M6.5002 9.85761L4.52141 7.87883L4.4002 7.75761L4.27898 7.87883L3.57898 8.57883L3.45776 8.70005L3.57898 8.82127L6.37898 11.6213L6.5002 11.7425L6.62141 11.6213L12.6214 5.62127L12.7426 5.50005L12.6214 5.37883L11.9214 4.67883L11.8002 4.55761L11.679 4.67883L6.5002 9.85761Z"
        fill="white"
        stroke="white"
        strokeWidth="0.342857"
      />
    </svg>
  )
}

export default CheckedIcon
