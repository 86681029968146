import { TextArea } from '@pages/teacher/Classroom/classroom.styles'
import { STUDENT_RATES } from '@utils/constants'
import styled, { css } from 'styled-components'

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f3f5f9;
  border-radius: 20px;
  position: absolute;
  right: -20px;
  left: -20px;
  top: -16px;
  z-index: 3;
`

export const EditMainContainer = styled(EditContainer)`
  padding: 10px 14px;
  bottom: 20px;
  left: 15px;
  right: 15px;
  top: auto;
  .edit-interests-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
    gap: 16px;
  }
`

export const WorksheetStartTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #1b3b77;
`
export const WorksheetStartDesc = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #90a4bd;
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 238px;
`

export const WorksheetStartInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 46px 0px 46px;
`

export const PersonalDataContainter = styled.div<{ overflowHidden: boolean }>`
  width: 410px;
  display: flex;
  flex-direction: column;
  position: relative;
  .personal-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: #ffffff;
    border-radius: 20px;

    overflow-y: ${props => (props.overflowHidden ? 'hidden' : 'auto')};
    ::-webkit-scrollbar {
      width: 4px;
      margin: 22px 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${props => props.theme.colors.scrollTrack};
      border-radius: 4px;
      margin: 22px 0px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.colors.scrollThumb};
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.colors.scrollThumb};
      border-radius: 4px;
    }
  }
  .personal-info {
    display: flex;
    margin: 15px 30px 0px 20px;
    z-index: 3;
  }
  .personal-name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #1b3b77;
    display: flex;
    margin-left: -20px;
    margin-right: 20px;
    flex-direction: column;
    padding: 5px 0 18px;
    z-index: 2;
  }
  .personal-years {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #414752;
    margin-right: 10px;
    z-index: 2;
  }
  .personal-data {
    z-index: 2;
    margin-left: -20px;
    margin-right: 20px;
  }
  .balance-count {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #414752;
    margin-right: 5px;
    z-index: 2;
  }
`

export const LevelInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  z-index: 2;
  .level-info-container {
    display: flex;
    align-items: center;
  }
  .level-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #1b3b77;
  }
  .level-time {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #c2cfe0;
    margin-left: 8px;
  }

  .level-grade {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;

    /* Text_dark */

    color: #414752;
  }
`
export const AccordionContainer = styled.div<{ hover: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    background: ${props => (props.hover ? '#f3f5f9' : '')};
  }
`

export const AccordionContent = styled.div`
  margin-bottom: 10px;
`

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 30px;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const PersonalInfoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 20px 30px 0px 30px;
  .hover-block {
    position: absolute;
    display: none;
    background: #f3f5f9;
    border-radius: 20px;
    right: -20px;
    left: -20px;
    top: -16px;
    bottom: -16px;
    z-index: 1;
  }

  .edit-btn {
    display: none;
  }
  &:hover {
    .hover-block {
      display: block;
    }
    .edit-btn {
      display: block;
    }
  }
`
export const Name = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
`
export const PersonalItemCard = styled.div`
  border: 1px #4284ff solid;
  color: #4284ff;
  border-radius: 4px;
  border-radius: 4px;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  z-index: 2;
`

export const PersonalInfoLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #1b3b77;
  width: 154px;
  z-index: 2;
`

export const InterestsContianer = styled.div`
  height: 190px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  z-index: 2;
  margin-top: 14px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollTrack};
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 4px;
  }
`

export const PersonalRate = styled.div<{ rate?: STUDENT_RATES }>`
  border-radius: 4px;
  height: 18px;
  padding: 4px 6px;
  width: 30%;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  color: #ffffff;
  text-transform: lowercase;
  background: ${props =>
    props.rate === STUDENT_RATES.child
      ? '#FF922D'
      : props.rate === STUDENT_RATES.couple
      ? '#BB6BD9'
      : '#7E92AC'};
`

export const LevelContainer = styled.div<{ show: boolean }>`
  display: flex;
  height: auto;
  position: relative;
  .show {
    display: none;
  }

  &:hover {
    background: ${props => (props.show ? '#f3f5f9' : 'none')};
    border-radius: 20px;
    .show {
      display: block;
    }
  }
`

export const InterestsContainer = styled.div<{ show: boolean }>`
  width: 350px;
  height: 222px;
  padding: 10px 5px 10px 15px;
  position: relative;
  margin-top: 10px;
  z-index: 2;
  .show {
    display: none;
  }
  &:hover {
    background: ${props => (props.show ? '#f3f5f9' : 'none')};
    border-radius: 7px;
    .show {
      display: block;
    }
  }
  ::-webkit-scrollbar {
    width: 4px;
    margin: 22px 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollTrack};
    border-radius: 4px;
    margin: 22px 0px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 4px;
  }
`

export const AddButton = styled.button`
  width: 40px;
  height: 40px;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  color: #c2cfe0;
  background: #f3f5f9;
  border-radius: 4px;
`

export const GoalsContainer = styled.div<{ show: boolean }>`
  width: 202px;
  height: 112px;
  padding: 10px 5px 10px 15px;
  position: relative;
  .show {
    display: none;
  }

  &:hover {
    background: ${props => (props.show ? '#f3f5f9' : 'none')};
    border-radius: 7px;
    .show {
      display: block;
    }
  }
`

export const CardsContainer = styled.div`
  display: flex;

  flex-wrap: wrap;
  overflow: hidden;
  align-content: flex-start;

  ::-webkit-scrollbar {
    width: 5px;
    height: 100%;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 2px;
  }
  ${InterestsContainer}:hover & {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #c2cfe0 #f3f5f9;
    ::-webkit-scrollbar-thumb {
      background: #c2cfe0;
    }
  }
  ${GoalsContainer}:hover & {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #c2cfe0 #f3f5f9;
    ::-webkit-scrollbar-thumb {
      background: #c2cfe0;
    }
  }
`

export const LevelCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83px;
  height: 40px;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.01em;
  background: #c2cfe0;
  border-radius: 4px;
  color: #ffffff;
`

export const GoalsCard = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0px 15px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  background: #c2cfe0;
  border-radius: 4px;
  color: #ffffff;
  margin-bottom: 10px;
`
export const StudentCardHeader = styled.p<{ child?: boolean }>`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #1b3b77;
  margin-bottom: ${props => (props.child ? '10px' : '15px')};
`

export const ToCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`

export const Div = styled.div``

export const LevelProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background: white;
  position: relative;
  border-radius: 50%;
  z-index: 3;
`

export const LevelProgeessCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 42px;
  height: 42px;

  background: #f3f5f9;
  border-radius: 50%;
`

export const LevelProgressTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* Headline dark */

  color: #4d6084;
`

export const InterestCard = styled(PersonalItemCard)<{ active: boolean }>`
  height: fit-content;
  color: #fff;
  border: none;
  background: ${props => (!props.active ? '#C2CFE0' : '#f3f5f9')};
  > img {
    margin-right: 10px;
  }
`

export const ShowPlanButton = styled.button`
  padding: 12px 24px;
  margin: 20px 30px 0;
  background-color: #4284ff;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  letter-spacing: 0.01em;
  text-align: center;
  border-radius: 40px;
  color: #fff;
  width: 224px;
`
export const RegeneratePlanButton = styled.button`
  background-color: #4284ff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Advice = styled.div`
  color: #7e92ac;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  margin: 20px 30px 0;
`
