import React, { useEffect, useMemo, useState } from 'react'

import styled from 'styled-components'

import { IProfile, IStudentProfile } from '@models/IProfile'
import * as Styled from '@pages/student/AutoTeacherSelection/ResultTeachers/result-teachers.styles'
import { APP_CONFIG } from '@utils/constants'
import { FILTER_AGES, GENDERS } from '@pages/student/AutoTeacherSelection'
import Dropdown from '@pages/student/AutoTeacherSelection/ResultTeachers/Dropdown'
import { usersRef } from '@configs/firebase'
import SingleTeacherInfo from './SingleTeacherInfo'
import { Loader, StudentButton } from '@components/index'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@store/index'
import { getStudentProfile } from '@servise/profileAPI'
import { authRef } from '@configs/firebase'
import { calculateAge } from '@utils/helperst'
import { useTranslation } from 'react-i18next'

export const Wrapper = styled.div`
  margin: 0 auto;
  height: 100%;
`
const TeacherResultTitle = styled(Styled.TeacherResultTitle)`
  padding-top: 60px;
`

const TeachersNotFoundLabel = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;

  color: #414752;
`
export const DecorStripe = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #e1ecff;
`
const SearchContainer = styled(Styled.SearchContainer)`
  justify-content: center;
`

const TeachersInfo = () => {
  const { t } = useTranslation()
  const [teachers, setTeachers] = useState<IProfile[]>([])
  const [countTeachers, setCountTeachers] = useState(20)
  const [showIsMoreBtn, setShowIsMoreBtn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const profileData = useSelector((state: RootState) => state.profile.profile as IStudentProfile)
  const [filterData, setFilterData] = useState(() => ({
    gender: 'ANY',
    langVariant: 'ANY',
    age: 'any',
  }))

  const [isNotFound, setIsNotFound] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    setIsLoading(true)

    dispatch(getStudentProfile(authRef.currentUser?.uid))
    usersRef
      .where('role', '==', 'TEACHER')
      .get()
      .then(snapshot => {
        const data = []
        snapshot.docs
          .filter(doc => doc.data().deactivated !== true || doc.data().deactivated === undefined)
          .forEach(doc => {
            data.push({ ...doc.data(), id: doc.id })
          })

        setTeachers([...data])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [countTeachers])

  const onChange = ({ name, value }: { name: string; value: any }) => {
    setFilterData(prev => ({ ...prev, [name]: value }))
  }
  const handleShowMoreButton = () => {
    setCountTeachers(prev => prev + 20)
  }
  const filteredTeachers = useMemo(() => {
    let filterTeachers = teachers.filter(teacher => {
      if (filterData.gender !== 'ANY' && teacher.gender !== filterData.gender) {
        return false
      }
      if (filterData.langVariant !== 'ANY' && teacher.typeOfLang !== filterData.langVariant) {
        return false
      }

      return true
    })
    if (profileData) {
      const SIX_MONTHS_AGO = new Date()
      SIX_MONTHS_AGO.setMonth(SIX_MONTHS_AGO.getMonth() - 6)

      filterTeachers = filterTeachers.filter(teacher => {
        const hasRecentPayments = profileData?.payments?.some(
          payment => payment.date?.toDate() > SIX_MONTHS_AGO
        )

        return (
          !teacher?.isEnrolmentClosed ||
          (hasRecentPayments && profileData?.teachers.includes(teacher.id))
        )
      })
    } else {
      filterTeachers = filterTeachers.filter(teacher => !teacher?.isEnrolmentClosed)
    }
    if (filterData.age !== 'ANY') {
      filterTeachers = filterTeachers.filter(teacher => {
        if (!teacher.birthDate) return true
        const age = calculateAge(teacher.birthDate)

        if (filterData.age === 'greaterThan30') {
          return age > 30
        } else if (filterData.age === 'between30And40') {
          return age >= 30 && age <= 40
        } else if (filterData.age === 'lessThan40') {
          return age < 40
        }
        return true
      })
    }
    return filterTeachers
  }, [teachers, filterData, profileData])

  useEffect(() => {
    setShowIsMoreBtn(countTeachers <= filteredTeachers.length && teachers.length > 0)
  }, [teachers, filteredTeachers])

  useEffect(() => {
    const observer = new MutationObserver(_mutations => {
      const renderedTeachers = document.querySelectorAll('.single-teacher-info')
      setIsNotFound(renderedTeachers.length === 0)
    })

    const targetNode = document.getElementById('teachers-container')
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true })
    }

    return () => observer.disconnect()
  }, [])

  if (isLoading) return <Loader />
  return (
    <Wrapper>
      <Styled.TeacherResultContainer isTeacherInfo>
        <TeacherResultTitle>{t('AutoTeacher.TeachersResultTitle')}</TeacherResultTitle>
        <SearchContainer>
          <DecorStripe />

          <Dropdown
            title={t('Gender')}
            selected={filterData.gender}
            items={GENDERS}
            onChange={value => onChange({ name: 'gender', value })}
            width={116}
          />
          <Dropdown
            title={t('Age')}
            selected={filterData.age}
            items={FILTER_AGES}
            onChange={value => onChange({ name: 'age', value })}
            width={106}
          />
          <Dropdown
            title={t('AutoTeacher.Accent')}
            selected={filterData.langVariant}
            items={[...APP_CONFIG.typeOfLangs?.items, { label: 'LangVariant.ANY', value: 'ANY' }]}
            onChange={value => onChange({ name: 'langVariant', value })}
            width={116}
          />
        </SearchContainer>
        <Styled.TeachersList style={{ paddingBottom: '20px' }} id="teachers-container">
          {filteredTeachers.slice(countTeachers - 20, countTeachers).map(teacher => (
            <SingleTeacherInfo teacher={teacher} key={teacher.email} />
          ))}
          {isNotFound && <TeachersNotFoundLabel>{t('AutoTeacher.NotFound')}</TeachersNotFoundLabel>}

          {showIsMoreBtn && (
            <StudentButton
              text={t('ShowMore')}
              onClick={handleShowMoreButton}
              bgColor="#fff"
              textColor="#4284FF"
              styles={{ border: '2px solid #4284FF', margin: '40px auto 60px auto' }}
            />
          )}
        </Styled.TeachersList>
      </Styled.TeacherResultContainer>
    </Wrapper>
  )
}

export default TeachersInfo
