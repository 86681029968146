import { Dispatch } from 'redux'
import { toast } from 'react-toastify'

import { RootState } from '@store'
import { HomeworkActionTypes } from '@store/homework/types'
import {
  failedFetchingHomeWorks,
  removeHomework,
  setHomeworksAction,
  setStudentCountHomeWorks,
  setTeacherCountHomeWorks,
  startFetchingHomeWorks,
  successFetchingHomeWorks,
  updateHomeworkAction,
} from '@store/homework/actions'
import { authRef, fbgoalRef, feedbackRef, firebaseTimestamp, studentsRef } from '@configs/firebase'

import {
  HomeworkStatuses,
  IHomework,
  ILessonHomework,
  IClassroom,
  IProfile,
  IStudentProfile,
  ILessonHomeworkAnswers,
  ILesson,
} from '@models'

import { fetchStudentProfileByUid, fetchTeacherProfileByUid } from './profileAPI'
import i18next from 'i18next'
import { logger } from '@utils/ConsoleLogger'
import axios from 'axios'
import { APP_CONFIG, FIREBASE_CONFIG } from '@utils/constants'
import { sendNotifyBadRate } from './webhookAPI'

export const getHomeWorks = (isStudent: boolean, studentId?: string) => {
  return async (dispatch: Dispatch<HomeworkActionTypes>, getState) => {
    dispatch(startFetchingHomeWorks())
    const uid: string = authRef.currentUser.uid
    const state: RootState = getState()
    let homeworks: (ILessonHomework | IHomework)[] = []

    if (isStudent) {
      dispatch(setStudentCountHomeWorks(0))

      const teachers = (state.profile.profile as IStudentProfile).teachers || []

      const lessonHomeworksSnap = await studentsRef
        .doc(uid)
        .collection('homeworks')
        .get()
      homeworks.push(
        ...lessonHomeworksSnap.docs.map(
          l =>
            ({
              ...l.data(),
              id: l.id,
              isLessonHomework: true,
            } as ILessonHomework)
        )
      )

      for (const t of teachers) {
        const tHm = await studentsRef
          .doc(uid)
          .collection(t)
          .get()

        homeworks.push(...tHm.docs.map(doc => ({ ...doc.data(), id: doc.id } as IHomework)))
      }
      const homeWorksCount = homeworks.reduce((prev, curr) => {
        if (!curr.isHomework && HomeworkStatuses.NOT_READY.toString() === curr.status) {
          return (prev += 1)
        }
        return prev
      }, 0)
      dispatch(setStudentCountHomeWorks(homeWorksCount))
    } else {
      const students = (state.profile.profile as IProfile).students

      if (!studentId) {
        try {
          await Promise.all(
            students.map(async studentId => {
              const tHm = await studentsRef
                .doc(studentId)
                .collection(uid)
                .where('status', 'in', [HomeworkStatuses.SENT, HomeworkStatuses.CHECKED_AI])
                .get()

              homeworks.push(
                ...tHm.docs.map(
                  doc =>
                    ({
                      ...doc.data(),
                      id: doc.id,
                      studentId,
                    } as IHomework)
                )
              )
            })
          )
          homeworks = homeworks.sort((a, b) => b.createdAt?.toMillis() - a.createdAt?.toMillis())
        } catch (e) {}
      } else {
        try {
          const tHm = await studentsRef
            .doc(studentId)
            .collection(uid)
            .orderBy('createdAt', 'desc')
            .get()

          const lessonHomeworksSnap = await studentsRef
            .doc(studentId)
            .collection('homeworks')

            .get()
          homeworks.push(
            ...lessonHomeworksSnap.docs
              .filter(doc => doc.data().createdAt)
              .sort((a, b) => {
                return b.data().createdAt.toMillis() - a.data().createdAt.toMillis()
              })
              .map(
                l =>
                  (({
                    ...l.data(),
                    id: l.id,
                    studentId,
                    isLessonHomework: true,
                  } as unknown) as ILessonHomework)
              )
          )

          tHm.docs.map(doc => {
            return homeworks.push({
              ...doc.data(),
              id: doc.id,
            } as IHomework)
          })
        } catch (e) {
          logger.error('error', e)
        }
      }
    }

    const sentArray = []
    const notReadyArray = []
    const checkedArray = []
    let prevHomeworks = state.homework.homeworks

    homeworks.map(homework => {
      prevHomeworks = prevHomeworks.filter(prevHomework => prevHomework.id !== homework.id)

      if (
        homework.status === HomeworkStatuses.SENT ||
        homework.status === HomeworkStatuses.CHECKED_AI
      )
        sentArray.push(homework)
      else if (homework.status === HomeworkStatuses.NOT_READY) notReadyArray.push(homework)
      else checkedArray.push(homework)
    })

    dispatch(
      setHomeworksAction([...sentArray, ...notReadyArray, ...checkedArray, ...prevHomeworks])
    )
  }
}

export const getTeacherHomeWorksCount = () => {
  return async (dispatch: Dispatch<HomeworkActionTypes>, getState) => {
    const uid: string = authRef.currentUser.uid
    const state = getState()
    let homeWorksCount = 0
    const students = (state.profile.profile as IProfile).students || []

    await Promise.all(
      students.map(async s => {
        const tHm = await studentsRef
          .doc(s)
          .collection(uid)
          .where('status', 'in', [HomeworkStatuses.SENT, HomeworkStatuses.CHECKED_AI])

          .get()

        homeWorksCount += tHm.size
      })
    )

    dispatch(setTeacherCountHomeWorks(homeWorksCount))
  }
}

export const removeCheckedHomeWork = (
  studentId,
  teacherId,
  homeworkId,
  isLesson = false,
  isMade = false
) => {
  return async dispatch => {
    dispatch(startFetchingHomeWorks())
    const studentUid = studentId || authRef.currentUser.uid
    try {
      if (isLesson) {
        await studentsRef
          .doc(studentUid)
          .collection('homeworks')
          .doc(homeworkId)
          .delete()
      } else {
        await studentsRef
          .doc(studentUid)
          .collection(teacherId)
          .doc(homeworkId)
          .delete()
      }

      await dispatch(removeHomework(homeworkId))
      await dispatch(getHomeWorks(true))
      toast(i18next.t('Homework.RemoveSuccess'), {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-message-success',
      })
      dispatch(successFetchingHomeWorks())
    } catch (e) {
      logger.error(e)
      toast(i18next.t('Homework.RemoveFailed'), {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-message-failed',
      })
      dispatch(failedFetchingHomeWorks('Не удалось сохранить домашнее задание'))
    }
  }
}

export const saveHomeWork = (
  data: {
    description?: string
    isWriting?: boolean
    isHomework?: boolean
    isAudio?: boolean
    writing?: string
    audioURL?: string
    status?: string
    comment?: string
    teacherId?: string
    studentId?: string
    courseStudentId?: string
    lessonName?: string
    homeworkId?: string
    teacherWriting?: string
    suggestionByAI?: string
    checkAt?: any
    createdAt?: any
    isToastClosed?: boolean
  },
  homeWorkUid: string | null = null,
  isStudent: boolean = true
) => {
  return async (dispatch, getState: () => RootState) => {
    const {
      courseStudentId,
      lessonName,
      homeworkId,
      teacherId,
      studentId: studentUuid,
      ...homeWorkData
    } = data
    dispatch(startFetchingHomeWorks())
    const state: RootState = getState()
    const classData: IClassroom = state.classroom.classroomData
    const studentId = courseStudentId ?? (classData.student_uid || studentUuid)
    const studentProfile: IStudentProfile = await fetchStudentProfileByUid(studentId)
    const teacherProfile: IProfile = await fetchTeacherProfileByUid(
      teacherId || authRef.currentUser.uid
    )

    if (!homeWorkUid) {
      const newHomeWork = {
        className: lessonName ?? classData.name,
        status: homeWorkData.status || HomeworkStatuses.NOT_READY.toString(),
        studentAvatar: studentProfile?.avatar ? studentProfile.avatar : '',
        studentName: studentProfile ? `${studentProfile.name} ${studentProfile.lastName}` : '',
        studentId: courseStudentId ?? (classData.student_uid || studentId),
        teacherAvatar: teacherProfile?.avatar || '',
        teacherName: `${teacherProfile.name} ${teacherProfile.lastName || ''}`,
        teacherId: teacherId || authRef.currentUser.uid,
        createdAt: firebaseTimestamp.now(),
        isNew: true,

        ...homeWorkData,
      }

      try {
        await studentsRef
          .doc(courseStudentId ?? (studentId || classData.student_uid))
          .collection(teacherId || authRef.currentUser.uid)
          .add(newHomeWork)
        toast('Домашнее задание успешно добавлено', {
          autoClose: 2000,
          position: 'bottom-center',
          closeButton: false,
          hideProgressBar: true,
          className: 'message-success',
        })
        dispatch(successFetchingHomeWorks())
      } catch (e) {
        logger.error(e)
        dispatch(failedFetchingHomeWorks('Не удалось добавить домашнее задание'))
      }
    } else {
      try {
        const studentHomework = studentsRef
          .doc(isStudent ? authRef.currentUser.uid : studentId)
          .collection(isStudent ? teacherId : authRef.currentUser.uid)
          .doc(homeWorkUid)

        const doc = await studentHomework.get()

        if (doc.exists) {
          await studentHomework.update(homeWorkData)
        } else {
          await studentHomework.set(homeWorkData)
        }

        if (homeworkId) {
          const currentHomework = await studentsRef
            .doc(isStudent ? authRef.currentUser.uid : studentId)
            .collection('homeworks')
            .doc(homeworkId)
            .get()
          if (currentHomework.exists) {
            const tempPages = currentHomework.data().pages.map(page => {
              if (page.pageId === homeWorkUid) {
                return {
                  ...page,
                  teacherWriting: data.teacherWriting,
                  status: data.status,
                }
              } else {
                return page
              }
            })
            const tempHomeWork = {
              ...currentHomework.data(),
              pages: [...tempPages],
            }

            await studentsRef
              .doc(isStudent ? authRef.currentUser.uid : studentId)
              .collection('homeworks')
              .doc(homeworkId)
              .update(tempHomeWork)
          }
        }

        let successMessage = ''
        if (isStudent) {
          if ((homeWorkData.status as HomeworkStatuses) === HomeworkStatuses.SENT) {
            successMessage = 'HomeworkSubmitted'
          } else if ((homeWorkData.status as HomeworkStatuses) === HomeworkStatuses.CHECKED_AI) {
            successMessage = 'HomeworkCheckedByAI'
          } else {
            successMessage = 'HomeworkSuccessfullySaved'
          }
        } else {
          successMessage =
            (homeWorkData.status as HomeworkStatuses) === HomeworkStatuses.CHECKED
              ? 'Домашнее задание проверено'
              : 'Домашнее задание сохранено'
        }
        toast(i18next.t(successMessage), {
          autoClose: 2000,
          position: 'bottom-center',
          closeButton: false,
          hideProgressBar: true,
          className: 'student-message-success',
        })
        dispatch(updateHomeworkAction({ id: homeWorkUid, ...homeWorkData }))
        // todo: in the future, remove getHomeWorks after each save at all!!!
        dispatch(getHomeWorks(isStudent))
        dispatch(successFetchingHomeWorks())
        if (!isStudent) {
          dispatch(getTeacherHomeWorksCount())
        }
      } catch (e) {
        logger.error(e)
        toast('Не удалось сохранить домашнее задание', {
          autoClose: 2000,
          position: 'bottom-center',
          closeButton: false,
          hideProgressBar: true,
          className: 'student-message-failed',
        })
        dispatch(failedFetchingHomeWorks('Не удалось сохранить домашнее задание'))
      }
    }
  }
}

export const getHomeWorkFromLesson = async ({
  lessonId,
  studentId,
}: {
  lessonId: string
  studentId: string
}) => {
  const lessonRef = await studentsRef
    .doc(studentId)
    .collection('homeworks')
    .doc(lessonId)
    .get()
  return lessonRef.exists ? lessonRef.data() : null
}

export const saveLesssonHomeWork = (lessonHomeWork, pageId, isEssay: boolean = false) => {
  const currentPage = lessonHomeWork.pages.find(page => page.id === parseInt(pageId))
  return async dispatch => {
    dispatch(startFetchingHomeWorks())

    try {
      const homeworkExists = await studentsRef
        .doc(lessonHomeWork.studentId)
        .collection(currentPage.teacherId || lessonHomeWork.teacherId)
        .doc(currentPage.pageId)
        .get()
      await studentsRef
        .doc(lessonHomeWork.studentId)
        .collection('homeworks')
        .doc(lessonHomeWork.id)
        .update(lessonHomeWork)
      homeworkExists.exists
        ? await studentsRef
            .doc(lessonHomeWork.studentId)
            .collection(currentPage.teacherId)
            .doc(currentPage.pageId)
            .update(currentPage)
        : await studentsRef
            .doc(lessonHomeWork.studentId)
            .collection(currentPage.teacherId)
            .doc(currentPage.pageId)
            .set(currentPage)

      let successMessage =
        (currentPage.status as HomeworkStatuses) === HomeworkStatuses.CHECKED_AI
          ? 'Домашнее задание проверено'
          : 'Домашнее задание сохранено'
      if (!isEssay) {
        toast(i18next.t(successMessage), {
          autoClose: 2000,
          position: 'bottom-center',
          closeButton: false,
          hideProgressBar: true,
          className: 'student-message-success',
        })
      }

      dispatch(updateHomeworkAction({ id: lessonHomeWork.id, ...lessonHomeWork }))
      dispatch(successFetchingHomeWorks())
      return currentPage
    } catch (e) {
      toast('Не удалось сохранить домашнее задание', {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-message-failed',
      })
      logger.error(e)
      dispatch(failedFetchingHomeWorks('Не удалось добавить домашнее задание'))
    }
  }
}

export const saveHomeworkPages = async (homeLesson: any, studentId: string) => {
  const teacherId = homeLesson?.teacherId || authRef.currentUser.uid
  const prevData = await getHomeWorkFromLesson({
    lessonId: homeLesson.id,
    studentId,
  })
  const studentProfile: IStudentProfile = await fetchStudentProfileByUid(studentId)
  const teacherProfile: IProfile = await fetchTeacherProfileByUid(teacherId)

  const newPage = homeLesson.pages.map(page => {
    const pageState = page.pageState.find(page => page.type === 'essay')
    if (!pageState) {
      return page
    }
    let tempListOfWord = pageState?.content.listOfWords ?? ['']
    let tempRandomWord = pageState?.content.randomWord ?? ['']
    let words = [...tempListOfWord, ...tempRandomWord].filter(word => word !== '')
    const description = `<b>${pageState?.content.heading ?? ''}</b></br></br>${pageState?.content
      .condition ?? ''}</br></br><b>Используй следующие слова в сочинении:</b></br>${words.join(
      ', '
    )}</br></br><span class='minWord'>Минимальное количество слов: <b>${pageState?.content
      .minWords ?? 0}</b></span>`

    const collection_id = studentsRef
      .doc(studentId)
      .collection(teacherId)
      .doc().id

    return {
      ...page,
      pageId: collection_id,
      className: page.name,
      description: description,
      studentAvatar: studentProfile?.avatar ? studentProfile.avatar : '',
      studentName: studentProfile ? `${studentProfile.name} ${studentProfile.lastName}` : '',
      teacherAvatar: teacherProfile.avatar || '',
      teacherName: `${teacherProfile.name} ${teacherProfile.lastName || ''}`,
      homeworkId: homeLesson.id,
      studentId: studentProfile.id,
      teacherId: authRef.currentUser.uid,
      createdAt: firebaseTimestamp.now(),
      isWriting: true,
    }
  })
  const data: ILesson = {
    ...homeLesson,
    teacherId,
    createdAt: firebaseTimestamp.now(),
    status: HomeworkStatuses.NOT_READY,
    answers: {
      pageStates: homeLesson.pages.reduce((acc, { id }) => {
        const prevPageState = prevData?.answers?.pageStates?.[`pageId-${id}`]
        return {
          ...acc,
          ...(prevPageState && { [`pageId-${id}`]: prevPageState }),
        }
      }, {}),
      progress: homeLesson.pages.reduce(
        (acc, { id }) => ({
          ...acc,
          [`pageId-${id}`]: prevData?.answers?.progress?.[`pageId-${id}`] || false,
        }),
        {}
      ),
    },
    pages: [...newPage],
  }
  try {
    await studentsRef
      .doc(studentId)
      .collection('homeworks')
      .doc(homeLesson.id)
      .set(data)
    toast('Домашнее задание успешно добавлено', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'message-success',
    })
  } catch (e) {
    console.error(e)
  }
}

export const saveMiniCoursesPages = async (homeLesson: any, studentId: string) => {
  const prevData = await getHomeWorkFromLesson({
    lessonId: homeLesson.id,
    studentId,
  })

  const data: ILessonHomework = {
    ...homeLesson,
    createdAt: firebaseTimestamp.now(),
    status: HomeworkStatuses.NOT_READY,
    answers: {
      pageStates: homeLesson.pages.reduce((acc, { id }) => {
        const prevPageState = prevData?.answers?.pageStates?.[`pageId-${id}`]
        return {
          ...acc,
          ...(prevPageState && { [`pageId-${id}`]: prevPageState }),
        }
      }, {}),
      progress: homeLesson.pages.reduce(
        (acc, { id }) => ({
          ...acc,
          [`pageId-${id}`]: prevData?.answers?.progress?.[`pageId-${id}`] || false,
        }),
        {}
      ),
    },
  }

  try {
    await studentsRef
      .doc(studentId)
      .collection('homeworks')
      .doc(homeLesson.id)
      .set(data)
    toast('Домашнее задание успешно добавлено', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'message-success',
    })
  } catch (e) {
    console.error(e)
  }
}

export const saveHomeworkAnswers = async (
  answers: ILessonHomeworkAnswers,
  status: string,
  homeworkId: string
) => {
  try {
    await studentsRef
      .doc(authRef.currentUser.uid)
      .collection('homeworks')
      .doc(homeworkId)
      .update({ answers, status })
  } catch (e) {
    console.error(e)
  }
}

export const saveFeedBack = async (
  rating: any,
  studentInfo: {
    studentName: string
    studentEmail: string
    studentLastName: string
    tutorId: string
  }
) => {
  try {
    await feedbackRef.doc().set({
      ...rating,
      student: authRef.currentUser.uid,
      date: firebaseTimestamp.now(),
    })

    if (
      (rating.programScore <= 3 && rating.programScore !== 0) ||
      (rating.videoScore <= 3 && rating.videoScore !== 0) ||
      (rating.teacherScore <= 3 && rating.teacherScore !== 0)
    ) {
      const tutor = await fetchTeacherProfileByUid(studentInfo.tutorId)

      await sendNotifyBadRate({
        teacher: rating.teacherScore,
        program: rating.programScore,
        video: rating.videoScore,
        name: studentInfo.studentName,
        lastName: studentInfo.studentLastName,
        email: studentInfo.studentEmail,
        tutorTelegram: tutor?.telegram || '',
      })
    }
  } catch (e) {
    console.error(e)
  }
}

export const saveLessonFeedBack = async (rating: any) => {
  try {
    await fbgoalRef.doc(rating.lessonId).set({
      ...rating,
      student: authRef.currentUser.uid,
      date: firebaseTimestamp.now(),
    })
  } catch (e) {
    console.error(e)
  }
}

export const getLessonFeedBack = async (studentId: string, lessonId: string) => {
  try {
    const snapshot = await fbgoalRef
      .where('student', '==', studentId)
      .where('lessonId', '==', lessonId)
      .get()
    if (snapshot.docs.length > 0) {
      return true
    } else {
      return false
    }
  } catch (e) {
    console.error(e)
  }
}

export const getChatGptSuggestion = (Text: string) => {
  return axios
    .post(FIREBASE_CONFIG.getAISuggestionFnLink, {
      Text,
      lang: APP_CONFIG.essayAILang,
    })
    .then(res => res)
    .catch(error => logger.error(error))
}

export const getGeminiStories = (prompt: string) => {
  return axios
    .post(FIREBASE_CONFIG.generateGeminiTextLink, {
      prompt,
    })
    .then(res => res)
    .catch(error => logger.error(error))
}

export const getOpenAIStories = (prompt: string) => {
  return axios
    .post(FIREBASE_CONFIG.generateOpenAITextLink, {
      prompt,
    })
    .then(res => res)
    .catch(error => logger.error(error))
}

export const getAiHelperAnswer = (lang: string, messages: any) => {
  return axios
    .post(FIREBASE_CONFIG.askAIHelperLink, {
      lang,
      messages,
    })
    .then(res => res)
    .catch(error => {
      logger.error(error)
      throw error
    })
}

export const getStudentHomeWorkdFinished = async (studentId: string, teachers: string[]) => {
  let finishedHomeWorksCount = 0
  for (const t of teachers) {
    const tHm = await studentsRef
      .doc(studentId)
      .collection(t)
      .where('status', 'in', [
        HomeworkStatuses.DONE.toString(),
        HomeworkStatuses.CHECKED.toString(),
      ])
      .get()

    finishedHomeWorksCount += tHm.size
  }
  return finishedHomeWorksCount
}
