import CloseIconWithCircle from '@components/Icons/closeIconWithCircle'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Styled from './auto-teacher-selection.styles'
import FilterTeachers from './FilterTeachers'
import ResultTeachers from './ResultTeachers'
import ChoosedTeachers from './SelectedEvents'
import { IBusinessHours, IProfile, IStudentProfile } from '@models/IProfile'
import { AUTH_STATUS, BUSINESS_HOURS, CALENDAR_EVENT_TYPES, LEVELS } from '@utils/constants'
import { getAutoTeachersSelection, getStudentProfile } from '@servise/profileAPI'
import { IEvent } from '@models/ICalendar'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import MainModal from '@components/Modal'
import { Loader, TeacherButton } from '@components/index'
import { addEvent } from '@servise/calendarAPI'
import { useDispatch, useSelector } from 'react-redux'
import { authRef, studentsRef, usersRef } from '@configs/firebase'
import { RootState } from '@store/index'
import firebase from 'firebase/app'
import { createStyles, withStyles, Theme } from '@material-ui/core/styles'
import { Tooltip } from '@pages/student/student.styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { getAvailableBusinessHours } from './ResultTeachers/TeacherInfoCard'
import { sendBookedCalendarEvent } from '@servise/webhookAPI'
import moment from 'moment-timezone'
import SkipBtn from '@components/Icons/SkipBtn'
import { chatAPI } from '@servise/chatAPI'
import { IMessage, MessageType } from '@models/IMessage'
import { firebaseTimestamp } from '@configs/firebase'
import { calendarUtil } from '@utils/calendarUtil'
import { calculateAge } from '@utils/helperst'

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      width: '100%',
    },
    colorPrimary: {
      backgroundColor: '#F3F5F9',
    },
    bar: {
      backgroundColor: '#E1ECFF',
    },
  })
)(LinearProgress)

export const GENDERS = [
  { label: 'Gender.M', value: 'M' },
  { label: 'Gender.F', value: 'F' },
  { label: 'Gender.ANY', value: 'ANY' },
]
export const FILTER_AGES = [
  { label: '>30', value: 'greaterThan30' },
  { label: '30-40', value: 'between30And40' },
  { label: '<40', value: 'lessThan40' },
  { label: 'Gender.ANY', value: 'any' },
]
export const COUNTRIES = [
  { label: 'Country.Hispanic', value: 'hispanic' },
  { label: 'Country.ANY', value: 'ANY' },
]

export interface IAutoTeacherSelectionFilterData {
  step: number
  withWho: 'one' | 'multiple'
  gender: string
  country: string
  age: string
  langVariant: string
  studentBusinessHours: IBusinessHours[]
  selectedEvents: { teacher: IProfile; event: IEvent }[]
  isSkip: boolean
}
const AutoTeacherSelection = () => {
  const [filterData, setFilterData] = useState<IAutoTeacherSelectionFilterData>(() => ({
    step: 1,
    withWho: 'one',
    gender: 'ANY',
    country: 'ANY',
    langVariant: 'ANY',
    age: 'any',
    studentBusinessHours: BUSINESS_HOURS,
    selectedEvents: [],
    isSkip: false,
  }))
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const states = useSelector((state: RootState) => ({
    authStatus: state.authStatus,
    profileData: state.profile.profile as IStudentProfile,
  }))
  const [isClose, setIsClose] = useState(false)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [teachers, setTeachers] = useState<IProfile[]>([])
  const onChangeFilterData = ({ name, value }: { name: string; value: any }) => {
    setFilterData(prev => ({
      ...prev,
      [name]: value,
      isSkip: name === 'studentBusinessHours' ? false : filterData.isSkip,
    }))
  }
  useEffect(() => {
    if (filterData.step >= 4) {
      setIsLoading(true)
      getAutoTeachersSelection()
        .then(teachers => {
          setTeachers(teachers)
        })
        .finally(() => setIsLoading(false))
    }
  }, [filterData.step])

  useEffect(() => {
    if (states.profileData?.timeZone) {
      moment.tz.setDefault(states.profileData.timeZone)
    }
  }, [states.profileData])

  const handleClose = () => {
    history.push('/student')
  }
  const isTrialLesson = useMemo(() => {
    return (
      states?.profileData?.payments?.filter(
        item => item.package > 0 || (item.amount === 0 && item.package === 0)
      ).length === 0
    )
  }, [states])
  const handleSave = useCallback(async () => {
    if (isSending) return
    setIsSending(true)
    await Promise.all(
      filterData.selectedEvents.map(async (event, index) => {
        if (!event.teacher?.students?.includes(event.event.teacherId)) {
          await usersRef.doc(event.event.teacherId).update({
            students: firebase.firestore.FieldValue.arrayUnion(event.event.studentId),
          })
        }
        if (!states?.profileData.teachers?.includes(event.event.teacherId)) {
          await studentsRef.doc(event.event.studentId).update({
            teachers: firebase.firestore.FieldValue.arrayUnion(event.event.teacherId),
          })
        }
        const calendarEvent = event.event
        if (isTrialLesson && index === 0) {
          calendarEvent.type = CALENDAR_EVENT_TYPES.TRIAL
          calendarEvent.rrule.count = 1
        }

        await dispatch(addEvent(calendarEvent))

        const dateTimeSlot = calendarUtil
          .convertToMSK(calendarEvent.rrule.dtstart)
          .format('YYYY-MM-DD HH:mm')
        const type =
          isTrialLesson && index === 0 ? 'TRIAL' : event.teacher.isNative ? 'NATIVE' : 'SIMPLE'

        sendBookedCalendarEvent({
          emailStudent: states.profileData.email,
          emailTeacher: event.teacher.email,
          nameStudent: `${states.profileData.name} ${states.profileData.lastName}`,
          nameTeacher: `${event.teacher.name} ${event.teacher.lastName}`,
          type,
          dateTimeSlot,
          isEmail: event.teacher.isEmailOn !== undefined ? event.teacher.isEmailOn : true,
          isTg: event.teacher.isTgOn !== undefined ? event.teacher.isTgOn : false,
          locale: states.profileData.lang,
        })

        const data: { id: string; message: IMessage } = {
          id: `${calendarEvent.studentId}${calendarEvent.teacherId}`,
          message: {
            id: calendarEvent.studentId,
            m: '',
            newEvent: calendarEvent.rrule.dtstart as string,
            date: firebaseTimestamp.now().toMillis(),
            type: MessageType.scheduleBooking,
            eventType: calendarEvent.type,
          },
        }
        await chatAPI.sendMessage(data)

        await chatAPI.addUnreadMessage({
          userId: calendarEvent.teacherId,
          isTeacher: false,
          addUserId: calendarEvent.studentId,
        })

        await chatAPI.addUnreadMessage({
          userId: calendarEvent.studentId,
          isTeacher: true,
          addUserId: calendarEvent.teacherId,
        })
      })
    )
    setIsClose(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterData.selectedEvents, isTrialLesson, states, isSending])
  useEffect(() => {
    if (states.authStatus === AUTH_STATUS.ON && !states.profileData) {
      dispatch(getStudentProfile(authRef?.currentUser?.uid))
    }
  }, [dispatch, states])

  const isNative = useMemo(() => {
    const nativePayments = states.profileData?.payments?.filter(payment => payment.amount > 1) || []
    return nativePayments.length > 0
      ? nativePayments[nativePayments.length - 1].type === 'hispanic'
      : false
  }, [states.profileData])

  const studentBusinessHours = useMemo(() => {
    if (!filterData.isSkip) return filterData.studentBusinessHours
    return filterData.studentBusinessHours.map(item => ({
      ...item,
      daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
    }))
  }, [filterData.isSkip, filterData.studentBusinessHours])

  const filteredTeachers = useMemo(() => {
    if (!states.profileData) return []
    const SIX_MONTHS_AGO = new Date()
    SIX_MONTHS_AGO.setMonth(SIX_MONTHS_AGO.getMonth() - 6)

    let filterTeachers = teachers.filter(
      teacher =>
        !teacher.deactivated && teacher.businessHours?.some(item => item.daysOfWeek.length > 0)
    )

    filterTeachers = isTrialLesson
      ? filterTeachers.filter(teacher => teacher.isTrialLessonsEnabled)
      : filterTeachers.filter(teacher => {
          const hasRecentPayments = states.profileData?.payments?.some(
            payment => payment.date?.toDate() > SIX_MONTHS_AGO
          )

          return (
            !teacher?.isEnrolmentClosed ||
            (hasRecentPayments && states.profileData?.teachers.includes(teacher.id))
          )
        })

    const hasHispanic = states.profileData?.payments?.some(payment => payment.type === 'hispanic')
    const hasRussian = states.profileData?.payments?.some(payment => payment.type === 'russian')

    if (!hasHispanic || !hasRussian) {
      filterTeachers = filterTeachers.filter(teacher => teacher.isNative === isNative)
    }

    if (filterData.withWho === 'multiple') {
      filterTeachers = filterTeachers.filter(teacher => teacher.a0Course)
    }

    if (filterData.gender !== 'ANY') {
      filterTeachers = filterTeachers.filter(teacher => teacher.gender === filterData.gender)
    }

    if (filterData.langVariant !== 'ANY') {
      filterTeachers = filterTeachers.filter(
        teacher => teacher.typeOfLang === filterData.langVariant
      )
    }
    if (filterData.age !== 'any') {
      filterTeachers = filterTeachers.filter(teacher => {
        if (!teacher.birthDate) return true
        const age = calculateAge(teacher.birthDate)
        if (filterData.age === 'greaterThan30') {
          return age > 30
        } else if (filterData.age === 'between30And40') {
          return age >= 30 && age <= 40
        } else if (filterData.age === 'lessThan40') {
          return age < 40
        }
        return true
      })
    }
    filterTeachers = filterTeachers.filter(teacher =>
      teacher.levelsOfStudents?.includes(states.profileData.level as LEVELS)
    )
    if (!filterData.isSkip) {
      filterTeachers = filterTeachers.filter(
        teacher =>
          getAvailableBusinessHours(
            studentBusinessHours,
            teacher.businessHours,
            teacher.timeZone,
            states.profileData.timeZone
          ).length > 0
      )
    }

    return filterTeachers
  }, [
    teachers,
    isNative,
    filterData.withWho,
    filterData.isSkip,
    filterData.gender,
    filterData.langVariant,
    filterData.age,
    studentBusinessHours,
    isTrialLesson,
    states.profileData,
  ])

  useEffect(() => {
    onChangeFilterData({ name: 'selectedEvents', value: [] })
  }, [filterData.withWho])
  const handleSkipFilters = () => {
    setFilterData(prev => ({ ...prev, isSkip: true, step: 4 }))
  }

  const progress = useMemo(() => {
    const currentStep = filterData.selectedEvents.length > 0 ? filterData.step + 1 : filterData.step
    return (currentStep * 100) / 5
  }, [filterData.step, filterData.selectedEvents])

  return !states.profileData || isLoading ? (
    <Loader />
  ) : (
    <Styled.PageContainer>
      <BorderLinearProgress variant="determinate" value={progress} />
      <Styled.TeacherSelectionContainer selected={filterData.selectedEvents.length > 0}>
        <Styled.TeachersSelectionMain selected={filterData.selectedEvents.length > 0}>
          <Styled.Actions>
            <Tooltip
              title={
                <Styled.TooltipContainer>
                  {t('AutoTeacher.TooltipAllTeachers')}
                </Styled.TooltipContainer>
              }
            >
              <Styled.AllTeachersBtn onClick={handleSkipFilters}>
                {t('AutoTeacher.AllTeachers')}
                <SkipBtn />
              </Styled.AllTeachersBtn>
            </Tooltip>
            <Styled.CloseBtn onClick={() => setIsClose(true)}>
              {t('Close')} <CloseIconWithCircle />
            </Styled.CloseBtn>
          </Styled.Actions>

          {filterData.step < 4 ? (
            <FilterTeachers filterData={filterData} onChange={onChangeFilterData} />
          ) : (
            <ResultTeachers
              filterData={filterData}
              onChange={onChangeFilterData}
              teachers={filteredTeachers}
            />
          )}
        </Styled.TeachersSelectionMain>
        {filterData.selectedEvents.length > 0 && (
          <ChoosedTeachers
            filterData={filterData}
            onChange={onChangeFilterData}
            onSave={handleSave}
            isNative={isNative}
          />
        )}
      </Styled.TeacherSelectionContainer>
      <MainModal
        open={isClose}
        maxWidth={'560px'}
        children={() => {
          return isSending ? (
            <Styled.CloseModalContainer isSent>
              <Styled.CloseModalTitle>
                {t('AutoTeacher.ScheduleSentForConfirmationTitle')}?
              </Styled.CloseModalTitle>

              <Styled.CloseModalDesc>
                {t('AutoTeacher.ScheduleSentForConfirmationText')}?
              </Styled.CloseModalDesc>
              <TeacherButton
                whiteTheme
                bgWhite
                style={{ marginBottom: 40 }}
                children={t('Schedule.RescheduleOk')}
                onClick={() => {
                  history.push('/student')
                }}
              />
            </Styled.CloseModalContainer>
          ) : filterData.selectedEvents.length ? (
            <Styled.CloseModalContainer>
              <Styled.CloseModalTitle>{t('AutoTeacherCloseModalTitle')}?</Styled.CloseModalTitle>
              <TeacherButton
                whiteTheme
                bgWhite
                children={t('Save')}
                loading={isSending}
                onClick={handleSave}
              />
              <Styled.CloseModalCancel onClick={handleClose}>
                {t('Schedule.NotCancel')}
              </Styled.CloseModalCancel>
            </Styled.CloseModalContainer>
          ) : (
            <Styled.CloseModalContainer>
              <Styled.CloseModalTitle>
                {t('AutoTeacherCloseModalTitleSecond')}
              </Styled.CloseModalTitle>
              <TeacherButton whiteTheme bgWhite children={t('Logout')} onClick={handleClose} />
              <Styled.CloseModalCancel onClick={() => setIsClose(false)}>
                {t('Cancel')}
              </Styled.CloseModalCancel>
            </Styled.CloseModalContainer>
          )
        }}
        handleClose={() => {
          setIsClose(false)
          if (isSending) {
            history.push('/student')
          }
        }}
      />
    </Styled.PageContainer>
  )
}

export default AutoTeacherSelection
