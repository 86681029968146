import React from 'react'
import { IconButton } from '@material-ui/core'
import EditIcon from '../../../Icons/EditIcon'
import CheckMarkIcon from '../../../Icons/CheckMark'
import { useTranslation } from 'react-i18next'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css'
import moment from 'moment'
import * as Styled from '../personal-info.styles'
import firebase from 'firebase/app'
import { firebaseTimestamp } from '@configs/firebase'

export default function({
  deadline,
  onChange,
  editType,
  handleClickEdit,
}: {
  editType?: string
  handleClickEdit: (data: string) => void
  deadline: firebase.firestore.Timestamp
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()
  return (
    <>
      <Styled.PersonalInfoItem>
        <Styled.PersonalInfoLabel>{t('Deadline')}</Styled.PersonalInfoLabel>
        {deadline?.seconds && (
          <Styled.PersonalItemCard>
            {moment(deadline?.seconds ? deadline.toDate() : '')
              .locale('en')
              .format('DD-MM-YYYY')}
          </Styled.PersonalItemCard>
        )}
        {!editType && <div className="hover-block" />}

        {editType !== 'deadline' && (
          <IconButton className="edit-btn" size="small" onClick={() => handleClickEdit('deadline')}>
            {editType === 'deadline' ? <CheckMarkIcon /> : <EditIcon />}
          </IconButton>
        )}
      </Styled.PersonalInfoItem>

      {editType === 'deadline' && (
        <Styled.EditContainer
          style={{
            padding: '16px 20px',
            top: 60,
            right: 16,
            left: 16,
            bottom: 20,
          }}
        >
          <Styled.PersonalInfoLabel
            style={{
              width: 'auto',
              fontWeight: 700,
              fontSize: 16,
              marginBottom: 24,
            }}
          >
            Deadline
          </Styled.PersonalInfoLabel>
          <IconButton
            size="small"
            className="edit-btn"
            style={{ display: 'block', top: 10, right: 10 }}
            onClick={() => handleClickEdit('deadline')}
          >
            <CheckMarkIcon />
          </IconButton>
          <span
            style={{
              marginBottom: 14,
              color: '#414752',
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            By what date should the goal be met?
          </span>
          <Calendar
            date={deadline?.seconds ? deadline.toDate() : null}
            onChange={date =>
              onChange({
                name: 'deadline',
                value: firebaseTimestamp.fromDate(date),
              })
            }
          />
          <div
            style={{
              marginTop: 10,
              color: '#7E92AC',
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            The frequency of the lessons will be determined according to the selected deadline and
            goal. If the learning pace is more important, you can select it first and the deadline
            date will be determined automatically.
          </div>
        </Styled.EditContainer>
      )}
    </>
  )
}
