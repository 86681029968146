import { browserName, browserVersion } from 'react-device-detect'
import { APP_CONFIG, IS_DEV_MODE } from './../utils/constants'
import { ROLES } from '@utils/constants'
import { capitalize } from '@utils/helperst'
import { logger } from '@utils/ConsoleLogger'
import { LangEnum } from '@models/IProfile'
import { toast } from 'react-toastify'
import { INotes } from '@models/INotes'
import { storageRef } from '@configs/firebase'

const { isEscuela } = APP_CONFIG

export const sendMessageRegister = async (data: {
  name: string
  lastName: string
  email: string
  password: string
  role: string
  uid: string
  isSelf: boolean
  phone: string
  locale: string
}) => {
  try {
    if (isEscuela) {
      await fetch(
        `https://hook.integromat.com/vazp0nmpc9lbghcw05ro1sd97gzd7z93?email=${data.email}&pass=${
          data.password
        }&role=${data.role === ROLES.STUDENT ? 'student' : 'teacher'}&name=${capitalize(
          data.name
        )}&lastname=${capitalize(data.lastName)}&uid=${data.uid}&isSelf=${data.isSelf}&phone=${
          data.phone
        }&locale=${data.locale || 'ru'}`
      )
    }
  } catch (e) {
    logger.error(e)
  }
}

export const sendMessageTrialEvent = async (data: {
  date: string
  time: string
  name: string
  teacher: string
  email: string
  type: string
  locale: string
  student_chat_id: string
  teacher_chat_id: string
  telegram?: string
}) => {
  let url = `https://hook.eu1.make.com/a0926vy9x1xd7yjkcx7t2u1i9hop1qqn?date=${data.date}&time=${
    data.time
  }&name=${capitalize(data.name)}&teacher=${capitalize(data.teacher)}&email=${data.email}&type=${
    data.type
  }&locale=${data.locale || 'ru'}`

  if (data.student_chat_id) {
    url = url + `&student_chat_id=${data.student_chat_id}`
  }

  if (data.teacher_chat_id) {
    url = url + `&teacher_chat_id=${data.teacher_chat_id}`
  }
  if (data.telegram && data.telegram.length > 0) {
    let telegram = data.telegram
    if (telegram[0] !== '@') {
      telegram = '@' + telegram
    }
    url = url + `&telegram=${telegram}`
  }

  try {
    if (isEscuela) {
      await fetch(url)
    }
  } catch (e) {
    logger.error(e)
  }
}

export const sendMessageStartTrialLesson = async (data: {
  teacherName: string
  name: string
  class: string
  email: string
  locale: string
}) => {
  try {
    if (isEscuela) {
      await fetch(
        `https://hook.integromat.com/gfdkffxoxuhuhfa6cg7xx38o4lb0f2dv?email=${
          data.email
        }&name=${capitalize(data.name)}&class=${data.class}&teacherName=${capitalize(
          data.teacherName
        )}&locale=${data.locale || 'ru'}`
      )
    }
  } catch (e) {
    logger.error(e)
  }
}

export const sendMessageStartTrialCourseLesson = async (data: {
  teacherName: string
  name: string
  email: string
  lessonId: string
  courseId: string
  locale: string
}) => {
  try {
    if (isEscuela) {
      await fetch(
        `https://hook.integromat.com/gfdkffxoxuhuhfa6cg7xx38o4lb0f2dv?email=${
          data.email
        }&name=${capitalize(data.name)}&lesson=${data.lessonId}&course=${
          data.courseId
        }&teacherName=${capitalize(data.teacherName)}&locale=${data.locale || 'ru'}`
      )
    }
  } catch (e) {
    logger.error(e)
  }
}

export const sendCalendarNotify = async (data: {
  type: string
  time: string
  date: string
  studentName: string
  studentId: string
  teacherName: string
  teacherId: string
  p: string
  studentEmail: string
  teacherEmail: string
  locale: string
  student_chat_id: string
  teacher_chat_id: string
  telegram?: string
  studentCid?: string
}) => {
  let url = `https://hook.integromat.com/7zoorowvwshnwrwau5c2gvmn82if0ht7?type=${data.type}&time=${
    data.time
  }&date=${data.date}&
studentId=${data.studentId}&studentName=${data.studentName}&teacherId=${
    data.teacherId
  }&teacherName=${data.teacherName}&
p=${data.p}&student_email=${data.studentEmail}&teacher_email=${
    data.teacherEmail
  }&locale=${data.locale || 'ru'}`

  if (data.student_chat_id) {
    url = url + `&student_chat_id=${data.student_chat_id}`
  }

  if (data.teacher_chat_id) {
    url = url + `&teacher_chat_id=${data.teacher_chat_id}`
  }
  if (data.studentCid) {
    url = url + `&studentCid=${data.studentCid}`
  }

  if (data.telegram && data.telegram.length > 0) {
    let telegram = data.telegram
    if (telegram[0] !== '@') {
      telegram = '@' + telegram
    }
    url = url + `&telegram=${telegram}`
  }
  try {
    if (isEscuela) {
      await fetch(url)
    }
  } catch (e) {
    logger.error(e)
  }
}
export const sendMessageCountingLessons = async (data: {
  dateTime: string
  countLesson: number
  studentId: string
  teacherId: string
  name: string
  is16Lesson: boolean
  countOfLesson: number
  email: string
  locale: string
  tutorTelegram: string
  tutorName: string
  isNativeTeacher: boolean
  studentCid?: string
}) => {
  try {
    if (isEscuela) {
      await fetch(
        `https://hook.eu1.make.com/4ovtb7mwgsf4px49ut31w9u0l718o0wh?datetime=${
          data.dateTime
        }&countLesson=${data.countLesson}&studentid=${data.studentId}&teacherid=${
          data.teacherId
        }&is16Lesson=${data.is16Lesson}&name=${data.name}&test=${IS_DEV_MODE}&countOfLesson=${
          data.countOfLesson
        }&email=${data.email}&locale=${data.locale || 'ru'}&tutorTelegram=${
          data.tutorTelegram
        }&tutorName=${data.tutorName}${data.studentCid ? `&studentCid=${data.studentCid}` : ''}`
      )
    }
  } catch (e) {
    logger.error(e)
  }
}

export const sendTeacherSelection = async (data: {
  name: string
  phone: string
  email: string
  locale: LangEnum
  telegram?: string
  isStudent?: boolean
}) => {
  try {
    let url = `https://hook.eu1.make.com/ixiqh6u6a1liwjyom8ym8llisvv4j5iz?email=${
      data.email
    }&name=${capitalize(data.name)}&phone=${data.phone}&locale=${data.locale || 'ru'}&type=${
      data.isStudent ? 'regular' : 'trial'
    }`
    if (data.telegram) {
      url = url + `&tg=${data.telegram}`
    }
    if (isEscuela) {
      await fetch(url)
    }
  } catch (e) {
    logger.error(e)
  }
}

export const sendTeacherRescheduleLesson = async (data: {
  emailStudent: string
  emailTeacher: string
  nameTeacher: string
  dateTimeSlot: string
  student_chat_id: string
  teacher_chat_id: string
  student_uid: string
  teacher_uid: string
  isConfirm: boolean
  locale: string
  studentCid?: string
}) => {
  let url = `https://hook.eu1.make.com/ewrf87ewke3npb59e8d80jq41483ycfu?emailStudent=${
    data.emailStudent
  }&emailTeacher=${data.emailTeacher}&nameTeacher=${capitalize(data.nameTeacher)}&dateTimeSlot=${
    data.dateTimeSlot
  }&isConfirm=${data.isConfirm}&student_uid=${data.student_uid}&teacher_uid=${
    data.teacher_uid
  }&locale=${data.locale || 'ru'}`

  if (data.student_chat_id) {
    url = url + `&student_chat_id=${data.student_chat_id}`
  }

  if (data.teacher_chat_id) {
    url = url + `&teacher_chat_id=${data.teacher_chat_id}`
  }
  if (data.studentCid) {
    url = url + `&studentCid=${data.studentCid}`
  }
  try {
    if (isEscuela) {
      await fetch(url)
    }
  } catch (e) {
    logger.error(e)
  }
}

export const sendBookedCalendarEvent = async (data: {
  emailStudent: string
  emailTeacher: string
  nameTeacher: string
  dateTimeSlot: string
  nameStudent: string
  student_chat_id?: string
  teacher_chat_id?: string
  type: string
  isTg: boolean
  isEmail: boolean
  locale: string
}) => {
  try {
    let url = `https://hook.eu1.make.com/dfbucuohj114u2lbahpozfocasjdz12q?emailStudent=${
      data.emailStudent
    }&emailTeacher=${data.emailTeacher}&nameTeacher=${capitalize(data.nameTeacher)}&dateTimeSlot=${
      data.dateTimeSlot
    }
    &nameStudent=${capitalize(data.nameStudent?.trim())}
    &type=${data.type}&locale=${data.locale || 'ru'}&isEmail=${data.isEmail}&isTg=${data.isTg}`
    if (data.student_chat_id) {
      url = url + `&student_chat_id=${data.student_chat_id}`
    }

    if (data.teacher_chat_id) {
      url = url + `&teacher_chat_id=${data.teacher_chat_id}`
    }
    if (isEscuela) {
      await fetch(url)
    }
  } catch (e) {
    logger.error(e)
  }
}
export const sendConfirmLesson = async (data: {
  emailStudent: string
  emailTeacher: string
  nameTeacher: string
  dateTimeSlot: string
  student_chat_id: string
  teacher_chat_id: string
  locale: string
  isConfirm: boolean
}) => {
  try {
    let url = `https://hook.eu1.make.com/12sfafgylus2yu1eymlnxgdz0i8c1gk2?emailTeacher=${
      data.emailTeacher
    }&emailStudent=${data.emailStudent}&nameTeacher=${data.nameTeacher}&dateTimeSlot=${
      data.dateTimeSlot
    }&locale=${data.locale || 'ru'}&isConfirm=${data.isConfirm}`
    if (data.student_chat_id) {
      url = url + `&student_chat_id=${data.student_chat_id}`
    }
    if (data.teacher_chat_id) {
      url = url + `&teacher_chat_id=${data.teacher_chat_id}`
    }
    if (isEscuela) {
      await fetch(url)
    }
  } catch (e) {
    logger.error(e)
  }
}

export const sendSMSToStudent = async (data: { phNo: string; text: string; locale: string }) => {
  try {
    await fetch(
      `https://hook.eu1.make.com/k58d5mx9vgq0ys4ib33owxros4gcchux?number=${data.phNo}&text=${
        data.text
      }&locale=${data.locale || 'ru'}`
    )
  } catch (e) {
    logger.error(e)
  }
}

export const sendNotifyError = async (data: {
  text: string
  courseId: string
  lessonId: string
  pageId: string
  studentId: string
  desc?: string
  locale: string
}) => {
  try {
    let url = `https://hook.integromat.com/4vhru5nk1x29fi14bow5365oh1tfvlyz?text=${
      data.text
    }&courseid=${data.courseId}&lessonid=${data.lessonId}&page=${data.pageId}&studentid=${
      data.studentId
    }&locale=${data.locale || 'ru'}&browser=${`${browserName} ${browserVersion}`}`

    if (data.desc) {
      url = url + `&description=${data.desc}`
    }

    if (isEscuela) {
      await fetch(url)
    }
  } catch (e) {
    logger.error(e)
  }
}

export const sendNotifyBadRate = async (data: {
  teacher: string
  program: string
  video: string
  name: string
  lastName: string
  email: string
  tutorTelegram: string
}) => {
  try {
    const hookUrl = `https://hook.eu1.make.com/4vur3fyqcer0f4gjojskbtuikimreumd?teacherRate=${
      data.teacher
    }&programRate=${data.program}&videoRate=${data.video}&tutorTelegram=${data.tutorTelegram ||
      ''}&student=${data.name} ${data.lastName}&mail=${data.email}`
    await fetch(hookUrl)
  } catch (error) {
    logger.error(error)
  }
}

export const sendNotifyBadStory = async (data: {
  studentId: string
  comment: string
  ai: string
  email: string
}) => {
  try {
    const hookUrl = `https://hook.eu1.make.com/bctj16g8xuigu6g3q42n5gqvyujvuk2k?studentId=${data.studentId}&comment=${data.comment}&ai=${data.ai}&email=${data.email}`
    await fetch(hookUrl)
  } catch (error) {
    logger.error(error)
  }
}

export const createIndividualPlanForStudent = async (
  level: string,
  studentId: string,
  points: number
) => {
  try {
    const test = IS_DEV_MODE
    await fetch(
      `https://hook.eu1.make.com/0xp8boy7vwm9sxc2l8if5zgc8q8x0qiw?level=${level}&studentId=${studentId}&test=${test}&points=${points}`
    )
  } catch (error) {
    toast('Не удалось создать план, попробуйте еще раз', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
  }
}

export const sendDataToStudentTelegram = async (data: INotes, studentCid: string) => {
  const { notesText, audio, className } = data
  let url = `https://hook.eu1.make.com/u5o9acm6xnjxtzw3orjx6iajmxrettuk?studentCid=${studentCid}&noteName=${className}`
  if (notesText) {
    url += `&text=${notesText}`
  }
  if (audio) {
    const audioLink = await storageRef
      .ref('audio')
      .child(audio)
      .getDownloadURL()

    const encodedLink = encodeURIComponent(audioLink)
    url += `&audioLink=${encodedLink}`
  }
  try {
    await fetch(url)
  } catch (error) {
    logger.error(error)
  }
}
