import React, { useState, useEffect } from 'react'
import { Editor, EditorState, RichUtils, Modifier } from 'draft-js'
import 'draft-js/dist/Draft.css'
import Dropdown from 'rc-dropdown'
import Left from '@assets/images/blocks/Link/left-icon.svg'
import Center from '@assets/images/blocks/Link/centered-icon.svg'
import LeftActive from '@assets/images/blocks/Link/left-icon-active.svg'
import CenterActive from '@assets/images/blocks/Link/centered-icon-active.svg'
import Move from '@assets/images/blocks/move.svg'
import Copy from '@assets/images/blocks/copy.svg'
import Delete from '@assets/images/blocks/delete.svg'
import TextColor from '@assets/images/blocks/Editor/text-color.svg'
import TextColorActive from '@assets/images/blocks/Editor/text-color-active.svg'
import TextItalic from '@assets/images/blocks/Editor/text-italic.svg'
import TextItalicActive from '@assets/images/blocks/Editor/text-italic-active.svg'
import TextBoldActive from '@assets/images/blocks/Editor/text-bold-active.svg'
import Fzplus from '@assets/images/blocks/Editor/fzplus.svg'
import Fzminus from '@assets/images/blocks/Editor/fzminus.svg'
import Bg from '@assets/images/blocks/Editor/bg.svg'
import BgActive from '@assets/images/blocks/Editor/bg-active.svg'
import { convertFromRaw, convertToRaw } from 'draft-js'
import Spinner from 'react-spinkit'
import LoaderChecked from '../../assets/icons/checked.svg'

class RichEditorExample extends React.Component {
  constructor(props) {
    super(props)

    this.state = this.props.preloadedState
      ? {
          ...this.props.preloadedState,
          isLoading: false,
          editorState: this.props.preloadedState.rowContent
            ? EditorState.createWithContent(convertFromRaw(this.props.preloadedState.rowContent))
            : EditorState.createEmpty(),
        }
      : {
          editorState: EditorState.createEmpty(),
          rowContent: null,
          align: 'center',
          bgColor: '#fff',
          color: 'darkGrey',
          isLoading: false,
          showCheckmark: false,
        }
    this.myRef = React.createRef()
    this.blockRef = React.createRef()

    this.focus = () => this.refs.editor.focus()
    this.onChange = editorState => {
      this.setState(
        {
          editorState,
          rowContent: convertToRaw(editorState.getCurrentContent()),
        },
        () => {
          this.props.onStateChange(this.props.id, {
            rowContent: this.state.rowContent,
            align: this.state.align,
            bgColor: this.state.bgColor,
            color: this.state.color,
          })
        }
      )
      this.setState({ isLoading: true })
      setTimeout(() => {
        this.setState({ isLoading: false })
        this.setState({ showCheckmark: true })
        setTimeout(() => {
          this.setState({ showCheckmark: false })
        }, 2000)
      }, 3000)
    }

    this.handleKeyCommand = command => this._handleKeyCommand(command)
    this.onTab = e => this._onTab(e)
    this.toggleBlockType = type => this._toggleBlockType(type)
    this.toggleInlineStyle = style => this._toggleInlineStyle(style)
    this.toggleColor = toggledColor => this._toggleColor(toggledColor)
  }

  _handleKeyCommand(command) {
    const { editorState } = this.state
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return true
    }
    return false
  }

  _onTab(e) {
    const maxDepth = 4
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth))
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType))
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle))
  }

  _toggleColor(toggledColor) {
    const { editorState } = this.state
    const selection = editorState.getSelection()
    this.setState(
      {
        color: toggledColor,
      },
      () => {
        this.props.onStateChange(this.props.id, {
          rowContent: this.state.rowContent,
          align: this.state.align,
          bgColor: this.state.bgColor,
          color: this.state.color,
        })
      }
    )

    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(colorStyleMap).reduce((contentState, color) => {
      return Modifier.removeInlineStyle(contentState, selection, color)
    }, editorState.getCurrentContent())

    let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style')

    const currentStyle = editorState.getCurrentInlineStyle()

    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color)
      }, nextEditorState)
    }

    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor)
    }

    this.onChange(nextEditorState)
  }

  componentDidMount() {
    if (RichUtils.getCurrentBlockType(this.state.editorState) === 'unstyled') {
      this.toggleBlockType('header-three')
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const span = this.blockRef.current.querySelector('span[data-text="true"]')
    if (span) {
      span.style.color = (() => {
        if (this.state.color === 'darkGrey') {
          return '#404753'
        } else if (this.state.color === 'darkBlue') {
          return '#113A7B'
        } else if (this.state.color === 'blue') {
          return '#0053D7'
        } else if (this.state.color === 'lightBlue') {
          return '#2C82FF'
        } else if (this.state.color === 'grey') {
          return '#8CA4C0'
        } else if (this.state.color === 'orange') {
          return '#FF8C00'
        } else if (this.state.color === 'red') {
          return '#DC0000'
        } else {
          return '#404753'
        }
      })()
    }
  }

  render() {
    const { editorState } = this.state
    let editor
    if (this.myRef.current) {
      editor = this.myRef.current.editor
      editor.style.backgroundColor = this.state.bgColor
    }

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor'
    var contentState = editorState.getCurrentContent()
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        className += ' RichEditor-hidePlaceholder'
      }
    }

    return (
      <div
        className="block"
        style={
          this.props.isFactory ? { background: 'transparent', width: 'auto', padding: '0px' } : {}
        }
      >
        <div className="actions-container">
          {this.props.dragRef ? (
            <div ref={this.props.dragRef} className="left-side-actions">
              <img src={Move} alt="move" />
            </div>
          ) : (
            <div />
          )}
          <div className="right-side-actions">
            <div className="sub-actions" />
            <div style={{ marginRight: 60 }}>
              <BlockStyleControls
                editorState={editorState}
                setIsLoading={isLoading => {
                  this.setState({ isLoading })
                }}
                onToggle={this.toggleBlockType}
              />
              <InlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} />
              <Dropdown
                trigger="click"
                overlay={
                  <div
                    style={{
                      display: 'flex',
                      borderRadius: 12,
                      padding: '5px 10px',
                      background: '#fff',
                      boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
                    }}
                    className="colors"
                  >
                    <ColorControls editorState={editorState} onToggle={this.toggleColor} />
                  </div>
                }
              >
                <div style={{ marginRight: 22 }}>
                  {this.state.color === 'darkGrey' ? (
                    <img src={TextColor} alt="" />
                  ) : (
                    <img src={TextColorActive} alt="" />
                  )}
                  <div style={{ height: 20, position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: -5,
                        backgroundColor: (() => {
                          if (this.state.color === 'darkGrey') {
                            return '#404753'
                          } else if (this.state.color === 'darkBlue') {
                            return '#113A7B'
                          } else if (this.state.color === 'blue') {
                            return '#0053D7'
                          } else if (this.state.color === 'lightBlue') {
                            return '#2C82FF'
                          } else if (this.state.color === 'grey') {
                            return '#8CA4C0'
                          } else if (this.state.color === 'orange') {
                            return '#FF8C00'
                          } else if (this.state.color === 'red') {
                            return '#DC0000'
                          } else {
                            return '#404753'
                          }
                        })(),
                        width: 4,
                        height: 4,
                        borderRadius: 2,
                      }}
                    />
                  </div>
                </div>
              </Dropdown>
              <Dropdown
                trigger="click"
                overlay={
                  <>
                    <div
                      style={{
                        display: 'flex',
                        borderRadius: 12,
                        padding: '5px 10px',
                        background: '#fff',
                        boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
                      }}
                      className="colors"
                    >
                      <div
                        onClick={() => {
                          this.setState(
                            {
                              bgColor: '#fff',
                            },
                            () => {
                              this.props.onStateChange(this.props.id, {
                                rowContent: this.state.rowContent,
                                align: this.state.align,
                                bgColor: this.state.bgColor,
                                color: this.state.color,
                              })
                            }
                          )
                          editor.style.backgroundColor = '#fff'
                          this.setState({ isLoading: true })
                          setTimeout(() => {
                            this.setState({ isLoading: false })
                            this.setState({ showCheckmark: true })
                            setTimeout(() => {
                              this.setState({ showCheckmark: false })
                            }, 2000)
                          }, 3000)
                        }}
                        style={{
                          marginRight: 15,
                          height: 14,
                          width: 14,
                          background: '#fff',
                          borderRadius: 10,
                          border: '1px solid #C2CFE0',
                        }}
                      />
                      <div
                        onClick={() => {
                          this.setState(
                            {
                              bgColor: '#F3F5F9',
                            },
                            () => {
                              this.props.onStateChange(this.props.id, {
                                rowContent: this.state.rowContent,
                                align: this.state.align,
                                bgColor: this.state.bgColor,
                                color: this.state.color,
                              })
                            }
                          )
                          editor.style.backgroundColor = '#F3F5F9'
                          this.setState({ isLoading: true })
                          setTimeout(() => {
                            this.setState({ isLoading: false })
                            this.setState({ showCheckmark: true })
                            setTimeout(() => {
                              this.setState({ showCheckmark: false })
                            }, 2000)
                          }, 3000)
                        }}
                        style={{
                          marginRight: 15,
                          height: 14,
                          width: 14,
                          background: '#F3F5F9',
                          borderRadius: 10,
                        }}
                      />
                      <div
                        onClick={() => {
                          this.setState(
                            {
                              bgColor: '#BFCFE2',
                            },
                            () => {
                              this.props.onStateChange(this.props.id, {
                                rowContent: this.state.rowContent,
                                align: this.state.align,
                                bgColor: this.state.bgColor,
                                color: this.state.color,
                              })
                            }
                          )
                          editor.style.backgroundColor = '#BFCFE2'
                          this.setState({ isLoading: true })
                          setTimeout(() => {
                            this.setState({ isLoading: false })
                            this.setState({ showCheckmark: true })
                            setTimeout(() => {
                              this.setState({ showCheckmark: false })
                            }, 2000)
                          }, 3000)
                        }}
                        style={{
                          marginRight: 15,
                          height: 14,
                          width: 14,
                          background: '#BFCFE2',
                          borderRadius: 10,
                        }}
                      />
                      <div
                        onClick={() => {
                          this.setState(
                            {
                              bgColor: '#CCE1FF',
                            },
                            () => {
                              this.props.onStateChange(this.props.id, {
                                rowContent: this.state.rowContent,
                                align: this.state.align,
                                bgColor: this.state.bgColor,
                                color: this.state.color,
                              })
                            }
                          )
                          editor.style.backgroundColor = '#CCE1FF'
                          this.setState({ isLoading: true })
                          setTimeout(() => {
                            this.setState({ isLoading: false })
                            this.setState({ showCheckmark: true })
                            setTimeout(() => {
                              this.setState({ showCheckmark: false })
                            }, 2000)
                          }, 3000)
                        }}
                        style={{
                          marginRight: 15,
                          height: 14,
                          width: 14,
                          background: '#CCE1FF',
                          borderRadius: 10,
                        }}
                      />
                      <div
                        onClick={() => {
                          this.setState(
                            {
                              bgColor: '#B5D3FF',
                            },
                            () => {
                              this.props.onStateChange(this.props.id, {
                                rowContent: this.state.rowContent,
                                align: this.state.align,
                                bgColor: this.state.bgColor,
                                color: this.state.color,
                              })
                            }
                          )
                          editor.style.backgroundColor = '#B5D3FF'
                          this.setState({ isLoading: true })
                          setTimeout(() => {
                            this.setState({ isLoading: false })
                            this.setState({ showCheckmark: true })
                            setTimeout(() => {
                              this.setState({ showCheckmark: false })
                            }, 2000)
                          }, 3000)
                        }}
                        style={{
                          marginRight: 15,
                          height: 14,
                          width: 14,
                          background: '#B5D3FF',
                          borderRadius: 10,
                        }}
                      />
                      <div
                        onClick={() => {
                          this.setState(
                            {
                              bgColor: '#FFE2C5',
                            },
                            () => {
                              this.props.onStateChange(this.props.id, {
                                rowContent: this.state.rowContent,
                                align: this.state.align,
                                bgColor: this.state.bgColor,
                                color: this.state.color,
                              })
                            }
                          )
                          editor.style.backgroundColor = '#FFE2C5'
                          this.setState({ isLoading: true })
                          setTimeout(() => {
                            this.setState({ isLoading: false })
                            this.setState({ showCheckmark: true })
                            setTimeout(() => {
                              this.setState({ showCheckmark: false })
                            }, 2000)
                          }, 3000)
                        }}
                        style={{
                          marginRight: 15,
                          height: 14,
                          width: 14,
                          background: '#FFE2C5',
                          borderRadius: 10,
                        }}
                      />
                      <div
                        onClick={() => {
                          this.setState(
                            {
                              bgColor: '#FFD70B',
                            },
                            () => {
                              this.props.onStateChange(this.props.id, {
                                rowContent: this.state.rowContent,
                                align: this.state.align,
                                bgColor: this.state.bgColor,
                                color: this.state.color,
                              })
                            }
                          )
                          editor.style.backgroundColor = '#FFD70B'
                          this.setState({ isLoading: true })
                          setTimeout(() => {
                            this.setState({ isLoading: false })
                            this.setState({ showCheckmark: true })
                            setTimeout(() => {
                              this.setState({ showCheckmark: false })
                            }, 2000)
                          }, 3000)
                        }}
                        style={{
                          height: 14,
                          width: 14,
                          background: '#FFD70B',
                          borderRadius: 10,
                        }}
                      />
                    </div>
                  </>
                }
              >
                <div style={{ marginRight: 22 }}>
                  {this.state.bgColor === '#fff' ? (
                    <img src={Bg} alt="" />
                  ) : (
                    <img src={BgActive} alt="" />
                  )}
                  <div style={{ height: 20, position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: -5,
                        backgroundColor: this.state.bgColor,
                        width: 5,
                        height: 5,
                        borderRadius: 5,
                        border: '1px solid #F3F5F9',
                      }}
                    />
                  </div>
                </div>
              </Dropdown>
              {this.state.align === 'left' ? (
                <img
                  alt=""
                  style={{ marginRight: 22 }}
                  src={LeftActive}
                  onClick={() => {
                    this.setState(
                      {
                        align: 'left',
                      },
                      () => {
                        this.props.onStateChange(this.props.id, {
                          rowContent: this.state.rowContent,
                          align: this.state.align,
                          bgColor: this.state.bgColor,
                          color: this.state.color,
                        })
                      }
                    )
                    this.setState({ isLoading: true })
                    setTimeout(() => {
                      this.setState({ isLoading: false })
                      this.setState({ showCheckmark: true })
                      setTimeout(() => {
                        this.setState({ showCheckmark: false })
                      }, 2000)
                    }, 3000)
                  }}
                />
              ) : (
                <img
                  style={{ marginRight: 22 }}
                  src={Left}
                  alt=""
                  onClick={() => {
                    this.setState(
                      {
                        align: 'left',
                      },
                      () => {
                        this.props.onStateChange(this.props.id, {
                          rowContent: this.state.rowContent,
                          align: this.state.align,
                          bgColor: this.state.bgColor,
                          color: this.state.color,
                        })
                      }
                    )
                    this.setState({ isLoading: true })
                    setTimeout(() => {
                      this.setState({ isLoading: false })
                      this.setState({ showCheckmark: true })
                      setTimeout(() => {
                        this.setState({ showCheckmark: false })
                      }, 2000)
                    }, 3000)
                  }}
                />
              )}
              {this.state.align === 'center' ? (
                <img
                  alt=""
                  src={CenterActive}
                  onClick={() => {
                    this.setState(
                      {
                        align: 'center',
                      },
                      () => {
                        this.props.onStateChange(this.props.id, {
                          rowContent: this.state.rowContent,
                          align: this.state.align,
                          bgColor: this.state.bgColor,
                          color: this.state.color,
                        })
                      }
                    )
                    this.setState({ isLoading: true })
                    setTimeout(() => {
                      this.setState({ isLoading: false })
                      this.setState({ showCheckmark: true })
                      setTimeout(() => {
                        this.setState({ showCheckmark: false })
                      }, 2000)
                    }, 3000)
                  }}
                />
              ) : (
                <img
                  alt=""
                  src={Center}
                  onClick={() => {
                    this.setState(
                      {
                        align: 'center',
                      },
                      () => {
                        this.props.onStateChange(this.props.id, {
                          rowContent: this.state.rowContent,
                          align: this.state.align,
                          bgColor: this.state.bgColor,
                          color: this.state.color,
                        })
                      }
                    )
                    this.setState({ isLoading: true })
                    setTimeout(() => {
                      this.setState({ isLoading: false })
                      this.setState({ showCheckmark: true })
                      setTimeout(() => {
                        this.setState({ showCheckmark: false })
                      }, 2000)
                    }, 3000)
                  }}
                />
              )}
            </div>
            {this.props.onClickCopy && (
              <img src={Copy} onClick={this.props.onClickCopy} alt="Copy" />
            )}
            {this.props.onClickDelete && (
              <img src={Delete} onClick={this.props.onClickDelete} alt="Delete" />
            )}
          </div>
        </div>
        <div className="content-container" style={{ position: 'relative', width: '100%' }}>
          {this.state.isLoading && (
            <div
              style={{
                position: 'absolute',
                scale: '0.4',
                bottom: '20px',
                right: '22px',
                zIndex: 1000,
              }}
            >
              <Spinner name="line-spin-fade-loader" color="#FF852D" />
            </div>
          )}
          {this.state.showCheckmark && (
            <img
              style={{
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                zIndex: 1000,
              }}
              className="checkmark"
              src={LoaderChecked}
              alt=""
            />
          )}
          <div
            className="RichEditor-root text-editor"
            style={this.props.isFactory ? { width: 'auto' } : {}}
          >
            <div ref={this.blockRef} className={className}>
              <Editor
                blockStyleFn={getBlockStyle}
                customStyleMap={styleMap}
                editorState={editorState}
                handleKeyCommand={this.handleKeyCommand}
                onChange={this.onChange}
                onTab={this.onTab}
                textAlignment={this.state.align}
                ref={this.myRef}
                spellCheck={true}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    bgColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
  darkGrey: {
    color: '#404753',
  },
  darkBlue: {
    color: '#113A7B',
  },
  blue: {
    color: '#0053D7',
  },
  lightBlue: {
    color: '#2C82FF',
  },
  grey: {
    color: '#8CA4C0',
  },
  orange: {
    color: '#FF8C00',
  },
  red: {
    color: '#DC0000',
  },
}

var COLORS = [
  {
    label: (
      <div
        style={{
          height: 14,
          width: 14,
          background: '#404753',
          borderRadius: 10,
        }}
      />
    ),
    style: 'darkGrey',
  },
  {
    label: (
      <div
        style={{
          height: 14,
          width: 14,
          background: '#113A7B',
          borderRadius: 10,
        }}
      />
    ),
    style: 'darkBlue',
  },
  {
    label: (
      <div
        style={{
          height: 14,
          width: 14,
          background: '#0053D7',
          borderRadius: 10,
        }}
      />
    ),
    style: 'blue',
  },
  {
    label: (
      <div
        style={{
          height: 14,
          width: 14,
          background: '#2C82FF',
          borderRadius: 10,
        }}
      />
    ),
    style: 'lightBlue',
  },
  {
    label: (
      <div
        style={{
          height: 14,
          width: 14,
          background: '#8CA4C0',
          borderRadius: 10,
        }}
      />
    ),
    style: 'grey',
  },
  {
    label: (
      <div
        style={{
          height: 14,
          width: 14,
          background: '#FF8C00',
          borderRadius: 10,
        }}
      />
    ),
    style: 'orange',
  },
  {
    label: (
      <div
        style={{
          height: 14,
          width: 14,
          background: '#DC0000',
          borderRadius: 10,
        }}
      />
    ),
    style: 'red',
  },
]

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote'
    default:
      return null
  }
}

class StyleButton extends React.Component {
  constructor() {
    super()
    this.onToggle = e => {
      e.preventDefault()
      this.props.onToggle(this.props.style)
    }
  }

  render() {
    let className = 'RichEditor-styleButton'
    if (this.props.active) {
      className += ' RichEditor-activeButton'
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {(() => {
          if (this.props.active && this.props.style === 'ITALIC') {
            return <img src={TextItalicActive} alt="" />
          } else if (this.props.active && this.props.style === 'BOLD') {
            return <img src={TextBoldActive} alt="" />
          } else {
            return this.props.label
          }
        })()}
      </span>
    )
  }
}

const ColorControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle()
  return (
    <>
      {COLORS.map(type => (
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </>
  )
}

// This object provides the styling information for our custom color
// styles.
const colorStyleMap = {
  red: {
    color: 'rgba(255, 0, 0, 1.0)',
  },
  orange: {
    color: 'rgba(255, 127, 0, 1.0)',
  },
  yellow: {
    color: 'rgba(180, 180, 0, 1.0)',
  },
  green: {
    color: 'rgba(0, 180, 0, 1.0)',
  },
  blue: {
    color: 'rgba(0, 0, 255, 1.0)',
  },
  indigo: {
    color: 'rgba(75, 0, 130, 1.0)',
  },
  violet: {
    color: 'rgba(127, 0, 255, 1.0)',
  },
}

export const BlockStyleControls = props => {
  const [state, setState] = useState(0)

  useEffect(() => {
    if (state === 1) {
      props.onToggle('header-three')
    } else if (state === 2) {
      props.onToggle('header-two')
    } else if (state === 3) {
      props.onToggle('header-one')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, props.onToggle])

  return (
    <div className="RichEditor-controls">
      <img
        alt=""
        style={{ marginRight: 22 }}
        src={Fzplus}
        onClick={e => {
          e.preventDefault()
          if (!state) {
            setState(1)
          }
          setState(prev => (prev < 3 ? prev + 1 : prev))
          props.setIsLoading(true)
          setTimeout(() => {
            props.setIsLoading(false)
          }, 4000)
        }}
      />
      <img
        alt=""
        style={{ marginRight: 22 }}
        src={Fzminus}
        onClick={e => {
          if (!state) {
            setState(1)
          }
          setState(prev => (prev > 1 ? prev - 1 : prev))
          props.setIsLoading(true)
          setTimeout(() => {
            props.setIsLoading(false)
          }, 4000)
        }}
      />
    </div>
  )
}

var INLINE_STYLES = [
  { label: <img src={TextItalic} alt="" />, style: 'ITALIC' },
  // {label: 'Underline', style: 'UNDERLINE'},
  // {label: 'Monospace', style: 'CODE'},
]

const InlineStyleControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle()
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

export default RichEditorExample
