import { Dispatch } from 'redux'
import queryString from 'query-string'

import {
  classroomsRef,
  firebaseTimestamp,
  firestoreRef,
  libraryRef,
  paymentsRef,
  requisitesRef,
  storageRef,
  studentsRef,
  usersRef,
  chatsRef,
  managersRef,
  salesManagersRef,
  authRef,
  recordsRef,
} from '@configs/firebase'

import {
  FIREBASE_CONFIG,
  GOALS,
  INTERESTS,
  IS_DEV_MODE,
  LEVELS,
  LocalStorageConsts,
  STUDENT_RATES,
  TEACHER_RATES,
  TZ_MSK,
} from '@utils/constants'
import firebase from 'firebase/app'
import { PersonalActionsTypes } from '@store/personal/types'
import i18next from 'i18next'
import {
  deleteTeacherAction,
  getTeachersAccountingAction,
  getTeachersAction,
  getManagerStudentsAction,
  getManagerPotentialStudentsAction,
  deleteManagerPotentialStudentAction,
  setStudentPaymentsAction,
  SetIsLoading,
  getManagersAction,
  getSalesManagersAction,
  changeManagerPotentialStudentAction,
  deleteSalesManagerAction,
  getTeacherStatisticsAction,
  setPassedLessonsAction,
  changeTeacherRecordStatusAction,
} from '@store/personal/actions'

import { fetchStudentPayments } from './profileAPI'
import {
  IPassedLesson,
  IRequisites,
  IProfile,
  IPayment,
  PassedLessonStatus,
  IStudentProfile,
  IUserRecords,
} from '@models'
import { getStudentCalendarEvents } from './calendarAPI'
import { logger } from '@utils/ConsoleLogger'
import axios from 'axios'
import { toast } from 'react-toastify'
import { RootState } from '@store/index'
import { isRecord } from 'immutable'
import { id } from 'date-fns/locale'

export const getTeachers = () => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    let teachersSnapshot = await usersRef.get()

    let teachers: IProfile[] = teachersSnapshot.docs.map(user => {
      const levelsOfStudents = user.data().levelsOfStudents
      return {
        ...user.data(),
        name: user.data().lastName + ' ' + user.data().name,
        id: user.id,
        typeOfLang: user.data().typeOfLang ? user.data().typeOfLang : user.data().typeOfSpanish,
        levelsOfStudents: levelsOfStudents?.length > 0 ? levelsOfStudents : [LEVELS.A1],
      } as IProfile
    })

    dispatch(getTeachersAction(teachers))
  }
}
function calculateWorkExperience(createdAt: { seconds: number; nanoseconds: number }): number {
  return new Date().getTime() - new Date(createdAt.seconds * 1000).getTime()
}
export const getTeachersStatistics = () => {
  return async (dispatch: Dispatch<PersonalActionsTypes>, getState: () => RootState) => {
    try {
      const state: RootState = getState()
      if (state.personal.teachersStatistics.length > 0) {
        return
      }
      const teachersSnapshot = await usersRef.get()
      const teachers = await Promise.all(
        teachersSnapshot.docs.map(async docTeacher => {
          const teacher = docTeacher.data()

          const snapPassedLessons = await docTeacher.ref.collection('passedLessons').get()
          const passedLessons = snapPassedLessons.docs.flatMap(passedLesson => {
            return passedLesson.exists
              ? passedLesson.data().lessons.map((item: IPassedLesson) => ({
                  ...item,
                  id: passedLesson.id,
                  r: Number(item.r) === 325 ? 375 : item.r,
                }))
              : []
          })

          const studentIds = snapPassedLessons.docs.map(doc => doc.id)
          const students = await getTeacherStudents(studentIds)

          const experience = teacher.createdAt ? calculateWorkExperience(teacher.createdAt) : 0
          const totalLessons = passedLessons.length
          const averageLessons = studentIds.length
            ? Math.round(totalLessons / studentIds.length)
            : 0

          const activeStudentsCount = students.filter(student => student.balanceCount > 0).length
          const activeStudentPercentage = students.length
            ? Math.round((activeStudentsCount / students.length) * 100)
            : 0

          return {
            ...teacher,
            name: `${teacher.lastName} ${teacher.name}`,
            id: docTeacher.id,
            typeOfLang: teacher.typeOfLang || teacher.typeOfSpanish,
            levelsOfStudents: teacher.levelsOfStudents?.length
              ? teacher.levelsOfStudents
              : [LEVELS.A1],
            experience,
            averageLessons,
            passedLessons: totalLessons,
            activeStudentPercentage,
            allStudents: studentIds.length,
          }
        })
      )

      dispatch(getTeacherStatisticsAction(teachers))
    } catch (error) {
      console.error('Error fetching teacher statistics:', error)
    }
  }
}

export const deleteTeacher = (params: { uid: string; students: string[] }) => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    const classrooms = await classroomsRef.where('uid', '==', params.uid).get()

    await Promise.all(
      classrooms.docs.map(async classroom => {
        let files = await classroomsRef
          .doc(classroom.id)
          .collection('files')
          .get()
        await Promise.all(
          files.docs.map(async file => {
            const fileData = file.data()

            const values = queryString.parse(fileData.url)
            if (values.token) {
              try {
                await storageRef.refFromURL(fileData.url).delete()
              } catch (e) {}
            }

            await libraryRef.doc(`${params.uid}_${fileData.type}`).delete()
            await file.ref.delete()
          })
        )

        await classroomsRef.doc(classroom.id).delete()
      })
    )
    const students = params.students
    await Promise.all(
      students.map(async studentId => {
        await studentsRef
          .doc(studentId)
          .update({ teachers: firebase.firestore.FieldValue.arrayRemove(params.uid) })
        await chatsRef.doc(studentId + params.uid).delete()
      })
    )
    const events = await usersRef
      .doc(params.uid)
      .collection('events')
      .get()
    await Promise.all(
      events.docs.map(async event => {
        await event.ref.delete()
      })
    )

    const passedLessons = await usersRef
      .doc(params.uid)
      .collection('passedLessons')
      .get()
    await Promise.all(
      passedLessons.docs.map(async passedLesson => {
        await passedLesson.ref.delete()
      })
    )
    await usersRef.doc(params.uid).delete()
    await Promise.all(
      params.students.map(async sUid => {
        let studentEvents = await getStudentCalendarEvents(sUid, TZ_MSK)
        studentEvents = studentEvents.filter(e => e.teacherId === params.uid)
        await Promise.all(
          studentEvents.map(async e => {
            await studentsRef
              .doc(sUid)
              .collection('events')
              .doc(e.eventID)
              .delete()
          })
        )
      })
    )

    dispatch(deleteTeacherAction(params.uid))
  }
}

export const fecthPassedLessonsTeacher = async (teacherId: string, range: number | null) => {
  let startedAt = new Date()
  let snapPassedLessons

  if (range) {
    startedAt.setDate(startedAt.getDate() - range)

    snapPassedLessons = await usersRef
      .doc(teacherId)
      .collection('passedLessons')
      .where('timeUpdate', '>=', startedAt)
      .get()
  } else {
    snapPassedLessons = await usersRef
      .doc(teacherId)
      .collection('passedLessons')
      .get()
  }

  let passedLessons: IPassedLesson[] = []
  snapPassedLessons.docs.forEach(passedlessonsItem => {
    if (passedlessonsItem.exists) {
      let newPassedLessons: IPassedLesson[] = passedlessonsItem.data().lessons.map(item => {
        return { ...item, id: passedlessonsItem.id }
      })
      if (range) {
        newPassedLessons = newPassedLessons.filter(
          item => item.d.valueOf() >= firebaseTimestamp.fromDate(startedAt).valueOf()
        )
      }
      passedLessons = [...passedLessons, ...newPassedLessons]
    }
  })
  if (passedLessons.length === 0) {
    if (range === 14) {
      return fecthPassedLessonsTeacher(teacherId, 30)
    } else if (range === 30) {
      return fecthPassedLessonsTeacher(teacherId, 365)
    } else if (range === 365) {
      return fecthPassedLessonsTeacher(teacherId, null)
    } else {
      return passedLessons
    }
  }
  return passedLessons
}

export const fecthPassedLessonsStudent = async (teacherId: string, studentId: string) => {
  let snapPassedLessons
  snapPassedLessons = await usersRef
    .doc(teacherId)
    .collection('passedLessons')
    .doc(studentId)
    .get()
    .then(snapshot => {
      return snapshot.data()?.lessons
    })
  return snapPassedLessons
}

export const getManagerStudents = () => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    let studentsDocs = await studentsRef.where('student', '==', true).get()

    let students = []

    await Promise.all(
      studentsDocs.docs.map(async student => {
        let payments = await fetchStudentPayments(student.id)

        let balanceCount = 0
        let lessonCount = 0

        for (let payment of payments) {
          balanceCount = balanceCount + Number(payment.package)

          if (payment.package === -1) {
            lessonCount += 1
          }
        }

        const interests = changeInterestsKeys(student.data().interests || [])
        const goals = changeGoalsKeys(student.data().goals || [])
        students.push({
          ...student.data(),
          id: student.id,
          interests,
          goals,
          payments,
          rate: student.data().rate || STUDENT_RATES.student,
          passedLessons: student.data().passedLessons || 0,
          lessonCount,
          balanceCount,
          name: student.data().name || '',
          lastName: student.data().lastName || '',
        })
      })
    ).catch(e => {
      logger.error(e)
    })

    dispatch(getManagerStudentsAction(students))
  }
}

export const getManagerPotentialStudents = () => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    let studentsDocs = await studentsRef.where('student', '==', false).get()
    let students = []
    for (let studentDoc of studentsDocs.docs) {
      let studentDocData = studentDoc.data()
      let student = {
        ...studentDocData,
        id: studentDoc.id,
        createdAt: studentDocData.createdAt?.seconds || 0,
        name: studentDocData.name || '',
        lastName: studentDocData.lastName || '',
      }
      students.push(student)
    }
    dispatch(getManagerPotentialStudentsAction(students))
  }
}

export const deleteManagerPotentialStudent = (studentId: string) => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    try {
      const res = await axios.get(
        `${FIREBASE_CONFIG.deleteUserFnLink}?userId=${studentId}&test=${IS_DEV_MODE}`
      )
      if (res.status === 200) {
        await studentsRef.doc(studentId).delete()
        dispatch(deleteManagerPotentialStudentAction(studentId))
      }
    } catch (e) {
      toast('Не удалось удалить студента', {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-message-failed',
      })
    }
  }
}

export const changeManagerPotentialStudent = (studentId: string) => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    dispatch(changeManagerPotentialStudentAction(studentId))
  }
}

export const addStudentBalance = (data: {
  package: number
  studentId: string
  amount: number
  currency: string
  desc: string
}) => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    const payments = await fetchStudentPayments(data.studentId)
    let newPayments = [
      ...payments,
      {
        amount: data.amount,
        date: firebaseTimestamp.now(),
        package: data.package,
        currency: data.currency,
        s: 'manager',
        desc: data.desc,
      },
    ]

    await paymentsRef.doc(data.studentId).set({ data: [...newPayments] })

    dispatch(
      setStudentPaymentsAction({
        studentId: data.studentId,
        payments: newPayments,
      })
    )
  }
}
export const getTeacherPassedLessons = (teacherId: string) => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    let snapPassedLessons = await usersRef
      .doc(teacherId)
      .collection('passedLessons')
      .get()

    let passedLessons: IPassedLesson[] = []
    snapPassedLessons.docs.forEach(passedlessonsItem => {
      if (passedlessonsItem.exists) {
        let newPassedLessons = passedlessonsItem.data().lessons.map((item: IPassedLesson) => {
          return {
            ...item,
            id: passedlessonsItem.id,
            r: Number(item.r) === 325 ? 375 : item.r,
          }
        })

        passedLessons = [...passedLessons, ...newPassedLessons]
      }
    })

    dispatch(setPassedLessonsAction({ teacherId, passedLessons }))
  }
}
export const getTeachersAccounting = () => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    let users = await usersRef.orderBy('lastName', 'asc').get()
    let teachers = []

    await Promise.all(
      users.docs.map(async user => {
        let snapPassedLessons = await user.ref.collection('passedLessons').get()
        let requisites: IRequisites = await getRequisites(user.id)

        let passedLessons: IPassedLesson[] = []
        snapPassedLessons.docs.forEach(passedlessonsItem => {
          if (passedlessonsItem.exists) {
            let newPassedLessons = passedlessonsItem.data().lessons.map((item: IPassedLesson) => {
              return {
                ...item,
                id: passedlessonsItem.id,
                r: Number(item.r) === 325 ? 375 : item.r,
              }
            })

            passedLessons = [...passedLessons, ...newPassedLessons]
          }
        })

        teachers.push({
          ...user.data(),
          id: user.id,
          name: user.data().lastName + ' ' + user.data().name,
          passedLessons,
          requisites,
          rate: user.data().rate || TEACHER_RATES.BEGINNER,
        })
      })
    )

    dispatch(getTeachersAccountingAction(teachers))
    dispatch(SetIsLoading(false))
  }
}

export type UpdatePassedLessonsType = {
  studentId: string
  teacherId: string
  teacherName?: string
  lessonDate: firebase.firestore.Timestamp
  newPassedLesson?: IPassedLesson
  passedLessons?: IPassedLesson[]
  paymentPackage: number | null
  isDelete?: boolean
  d?: Date
  isEditDate?: boolean
  i?: number
  ab?: boolean
}

export const updatePassedLesson = async (updateData: UpdatePassedLessonsType) => {
  try {
    let lessons: IPassedLesson[] = []
    const batch = firestoreRef.batch()

    if (updateData.paymentPackage !== null) {
      let [payments, passedLessonsTeacherByStudent] = await Promise.all([
        fetchStudentPayments(updateData.studentId),
        getPassedLessonsTeacherByStudent(updateData.teacherId, updateData.studentId),
      ])
      lessons = passedLessonsTeacherByStudent
      if (!updateData.isDelete) {
        const existingPayment = payments.find(
          (pay: any) => pay.date.seconds == updateData.lessonDate.seconds
        )
        if (existingPayment) {
          existingPayment.desc = updateData.ab ? 'Lesson.IsCanceled' : ''
          localStorage.removeItem(LocalStorageConsts.lessonData)
        } else {
          payments.push({
            amount: 0,
            date: updateData.lessonDate,
            package: updateData.paymentPackage,
            s: 'manager',
            n: updateData.teacherName || '',
            desc: updateData.ab ? 'Lesson.IsCanceled' : '',
          } as IPayment)
          lessons.push(updateData.newPassedLesson)
        }
      } else {
        payments = payments.filter(
          payment => payment.date && payment.date.valueOf() !== updateData.lessonDate.valueOf()
        )
        lessons = lessons.filter(
          (lesson, i) => lesson.d && lesson.d.valueOf() !== updateData.lessonDate.valueOf()
        )
      }
      batch.set(paymentsRef.doc(updateData.studentId), { data: payments })
      batch.update(studentsRef.doc(updateData.studentId), {
        passedLessons: firebase.firestore.FieldValue.increment(updateData.paymentPackage),
      })
    } else {
      const passedLessonsTeacherByStudent = await getPassedLessonsTeacherByStudent(
        updateData.teacherId,
        updateData.studentId
      )
      if (updateData.isEditDate) {
        lessons = passedLessonsTeacherByStudent.map((lesson, i) => {
          if (lesson.d.seconds) {
            const findLesson = passedLessonsTeacherByStudent.find(les => {
              return i === updateData.i
            })
            return findLesson
              ? { ...findLesson, d: firebase.firestore.Timestamp.fromDate(updateData.d) }
              : lesson
          }
          return lesson
        })
      } else {
        lessons = passedLessonsTeacherByStudent.map(lesson => {
          if (lesson.d) {
            const findLesson = updateData.passedLessons.find(
              l => l.d.valueOf() === lesson.d.valueOf()
            )
            return findLesson ? findLesson : lesson
          }
          return lesson
        })
      }
    }

    batch.set(usersRef.doc(`${updateData.teacherId}/passedLessons/${updateData.studentId}`), {
      lessons,
      timeUpdate: firebaseTimestamp.now(),
    })

    await batch.commit()

    return lessons.map((item: IPassedLesson) => {
      return item.s === PassedLessonStatus.checked
        ? {
            ...item,
            s: PassedLessonStatus.done,
            id: updateData.studentId,
            r: Number(item.r) === 325 ? 375 : item.r,
          }
        : {
            ...item,
            id: updateData.studentId,
            r: Number(item.r) === 325 ? 375 : item.r,
          }
    })
  } catch (e) {
    console.error(e)
  }
}

export const addRequisites = async (teacherId: string, requisites: IRequisites) => {
  await requisitesRef.doc(teacherId).set({ ...requisites })
}

export const getRequisites = async (teacherId: string) => {
  const requisitesSnapshot = await requisitesRef.doc(teacherId).get()
  return requisitesSnapshot.data() as IRequisites
}

export const changeGoalsKeys = (studentGoals: string[]) => {
  const newGoals = studentGoals.map(goal => {
    const find = GOALS.find(item => i18next.t(item.title, { lng: 'ru' }) === goal)
    if (find && find.id) {
      return find.id.toString()
    } else {
      return goal
    }
  })

  return newGoals
}

export const changeInterestsKeys = (studentInterests: string[]) => {
  const newInterests = studentInterests.map(interes => {
    const find = INTERESTS.find(item => i18next.t(item.name, { lng: 'ru' }) === interes)
    if (find && find.id) {
      return find.id.toString()
    } else {
      return interes
    }
  })

  return newInterests
}

export const getPassedLessonsTeacherByStudent = async (
  teacherId: string,
  studentId: string
): Promise<IPassedLesson[]> => {
  const snap = await usersRef
    .doc(teacherId)
    .collection('passedLessons')
    .doc(studentId)
    .get()
  return !snap.exists ? [] : snap.data().lessons
}

export const getTeacherStudents = async (studentIds: string[]) => {
  const students: IStudentProfile[] = []

  const filteredStudentIds = studentIds.filter(Boolean) // Remove falsy values once
  const snapshots = await Promise.all(filteredStudentIds.map(id => studentsRef.doc(id).get()))

  const passedLessonsSnap = await usersRef
    .doc(authRef.currentUser.uid)
    .collection('passedLessons')
    .get()

  const passedLessonsByTeacherMap = passedLessonsSnap.docs.reduce((acc, doc) => {
    acc[doc.id] = doc.exists ? doc.data().lessons.length : 0
    return acc
  }, {} as Record<string, number>)

  await Promise.all(
    snapshots.map(async student => {
      const data = student.data()
      if (!data) return

      const payments = await fetchStudentPayments(student.id)
      const { balanceCount, lessonCount } = payments.reduce(
        (acc, payment) => {
          acc.balanceCount += Number(payment.package)
          if (payment.package === -1) acc.lessonCount += 1
          return acc
        },
        { balanceCount: 0, lessonCount: 0 }
      )

      students.push({
        ...data,
        id: student.id,
        interests: changeInterestsKeys(data.interests || []),
        goals: changeGoalsKeys(data.goals || []),
        payments,
        rate: data.rate || STUDENT_RATES.student,
        passedLessons: data.passedLessons || 0,
        lessonCount,
        balanceCount,
        name: data.name || '',
        lastName: data.lastName || '',
        passedLessonsByTeacher: passedLessonsByTeacherMap[student.id] || 0,
      })
    })
  ).catch(e => logger.error(e))

  const sortedStudents = students.sort((a, b) => {
    if (!a.payments.length && !b.payments.length) return 0
    if (!a.payments.length) return 1
    if (!b.payments.length) return -1
    const lastPaymentA = a.payments.sort(
      (p1, p2) => p2.date?.toDate()?.getTime() - p1.date?.toDate()?.getTime()
    )[0]
    const lastPaymentB = b.payments.sort(
      (p1, p2) => p2.date?.toDate()?.getTime() - p1.date?.toDate()?.getTime()
    )[0]

    return lastPaymentB?.date?.toDate()?.getTime() - lastPaymentA?.date?.toDate()?.getTime()
  })
  return sortedStudents
}

export const getStudentByText = async (search?: string) => {
  let studentsDocs = await studentsRef.where('student', '==', true).get()
  let students = []

  await Promise.all(
    studentsDocs.docs.map(async student => {
      let payments = await fetchStudentPayments(student.id)

      let balanceCount = 0
      let lessonCount = 0

      for (let payment of payments) {
        balanceCount = balanceCount + Number(payment.package)

        if (payment.package === -1) {
          lessonCount += 1
        }
      }
      const interests = changeInterestsKeys(student.data().interests || [])
      const goals = changeGoalsKeys(student.data().goals || [])
      students.push({
        ...student.data(),
        id: student.id,
        interests,
        goals,
        payments,
        rate: student.data().rate || STUDENT_RATES.student,
        passedLessons: student.data().passedLessons || 0,
        lessonCount,
        balanceCount,
        name: student.data().name || '',
        lastName: student.data().lastName || '',
      })
    })
  ).catch(e => {
    logger.error(e)
  })

  return students
}

export const getManagers = () => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    let managersSnapshot = await managersRef.get()

    let managers: IProfile[] = managersSnapshot.docs.map(user => {
      return {
        ...user.data(),
        name: user.data().lastName + ' ' + user.data().name,
        id: user.id,
      } as IProfile
    })

    dispatch(getManagersAction(managers))
  }
}

export const getSalesManagers = () => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    let salesManagersSnapshot = await salesManagersRef.get()

    let salesManagers: IProfile[] = salesManagersSnapshot.docs.map(user => {
      return {
        ...user.data(),
        name: user.data().lastName + ' ' + user.data().name,
        id: user.id,
      } as IProfile
    })

    dispatch(getSalesManagersAction(salesManagers))
  }
}

export const deleteSalesManager = (params: { uid: string }) => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    await salesManagersRef.doc(params.uid).delete()
    dispatch(deleteSalesManagerAction(params.uid))
  }
}

export const changeTeacherRecordStatus = (id: string, status: boolean) => {
  return async (dispatch: Dispatch<PersonalActionsTypes>) => {
    await usersRef.doc(id).update({
      isRecord: !status,
    })

    dispatch(changeTeacherRecordStatusAction(id))
  }
}
