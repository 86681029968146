import { LOGIN_CODES, REGISTER_CODES, ORDER, ROLES } from './constants'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import Moment from 'moment'

import { getBrowserUniqueId } from './helpers'
import { IStudentProfile } from '@models'
import { IFactoryExercise } from '@models/IFactory'
import { browserName, browserVersion } from 'react-device-detect'
import { extendMoment } from 'moment-range'
import { fetchTeacherProfileByUid } from '@servise/profileAPI'
import { toast } from 'react-toastify'
declare const instantChatBot: any

declare global {
  interface Window {
    instantChatBotUidName?: string
  }
}

const moment = extendMoment(Moment as any)
export const generatePromoCode = (length: number): string => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
export const getCounts = () => {
  let counts = []
  for (let i = 2; i <= 64; i++) {
    counts.push({ value: i, label: i })
  }
  return counts
}

export const isCurrentDate = (date: number) => {
  return new Date().getDate() === date
}
export const addDays = (date: Date, days: number) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const getWeekNameByNum = (weekNum: number) => {
  switch (weekNum) {
    case 1:
      return 'MondayShort'
    case 2:
      return 'TuesdayShort'
    case 3:
      return 'WednesdayShort'
    case 4:
      return 'ThursdayShort'
    case 5:
      return 'FridayShort'
    case 6:
      return 'SaturdayShort'
    case 0:
      return 'SundayShort'
    default:
      return 'MondayShort'
  }
}

export const declOfNum = (n: number, text_forms: string[]): string => {
  n = Math.abs(n) % 100
  let n1 = n % 10

  if (n > 10 && n < 20) {
    return text_forms[2]
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1]
  }
  if (n1 === 1) {
    return text_forms[0]
  }
  return text_forms[2]
}

export const formatDateToHumanString = (
  locale: string,
  date: Date | number,
  options: Intl.DateTimeFormatOptions
): string => {
  return new Intl.DateTimeFormat(locale, options).format(date)
}

export const formatDateToTemplate = (date: string | null | Date = null) => {
  if (typeof date === 'string')
    return date
      ? format(new Date(date), 'PP', { locale: ru })
      : format(new Date(), 'PP', { locale: ru })
  if (date instanceof Date)
    return date ? format(date, 'PP', { locale: ru }) : format(new Date(), 'PP', { locale: ru })
}

export const getAvatarNameFromUrl = (avatarUrl: string): string | null => {
  let result = avatarUrl.match(/(2F)(.+\.jpg)/)
  if (result && result.length > 2) return result[2]
  return null
}

export const getErrorMsgFromCode = (code: string) => {
  switch (code) {
    case LOGIN_CODES.password.many_request:
      return 'TooManyRequests'

    case 'data_not_correct':
    case LOGIN_CODES.other.account_deactivated:
      return 'Auth.AccountDeactivated'
    case LOGIN_CODES.other.not_found:
    case LOGIN_CODES.password.wrong:
      return 'PasswordWrong'

    case 'field_must_be_filled':
      return 'FieldMustBeFilled'

    case 'passwords_dont_match':
      return 'Пароли не совпадают!'

    // auth --> common
    case LOGIN_CODES.email.invalid:
    case REGISTER_CODES.email.invalid:
      return 'InvalidEmailFormat'

    // auth --> register
    case REGISTER_CODES.email.already_in_use:
      return 'Такой email уже занят. Попробуйте авторизироваться или используйте другой email для регистрации'

    case REGISTER_CODES.other.not_allowed:
      return 'У вас нет прав доступа в базу данных.'

    case REGISTER_CODES.password.weak_password:
      return 'Пароль недостаточно силен!'

    case REGISTER_CODES.other.student_not_found:
      return 'StudentNotFound'
    case REGISTER_CODES.other.manager_not_found:
      return 'ManagerNotFound'
    case REGISTER_CODES.other.teacher_not_found:
      return 'TeacherNotFound'

    // auth --> login
    case LOGIN_CODES.email.disabled:
      return 'UserIsDisabled'

    default:
      return 'UnknownError'
  }
}

export const getWeeks = d => {
  var firstDay = moment(d).add(-6, 'months')
  var endDay = moment(d).add(6, 'months')

  var monthRange = moment.range(firstDay, endDay)
  var weeksRanges = []
  let increment = 0
  for (let mday of Array.from(monthRange.by('days'))) {
    let weeknumber = mday.isoWeek()
    const weeksFilter = weeksRanges.filter(item => item.weeknumber === weeknumber)

    if (weeksFilter.length === 0) {
      let firstWeekDay = moment(mday.add(-1, 'days'))
        .isoWeek(weeknumber)
        .startOf('isoWeek')
      let lastWeekDay = moment(mday.add(-1, 'days'))
        .isoWeek(weeknumber)
        .endOf('isoWeek')
      let weekRange = moment.range(firstWeekDay, lastWeekDay)
      weeksRanges.push({ weeknumber, weekRange })
      if (increment > 1) {
        weeknumber = weeksRanges[increment - 1].weeknumber

        firstWeekDay = moment(mday.add(-1, 'days'))
          .isoWeek(weeknumber)
          .startOf('isoWeek')
        lastWeekDay = moment(mday.add(-1, 'days'))
          .isoWeek(weeknumber)
          .endOf('isoWeek')

        weekRange = moment.range(firstWeekDay, lastWeekDay)
        weeksRanges[increment - 1] = { weeknumber, weekRange }
      }
      increment++
    }
  }

  const weeks = weeksRanges.map((d, i) => {
    const label = `${d.weekRange.start.format('DD')}-${d.weekRange.end.format('DD')} ${new Date(
      d.weekRange.end
    ).toLocaleString('ru', {
      month: 'short',
    })}`
    return {
      start: d.weekRange.start.format(),
      value: d.weeknumber,
      label,
    }
  })
  return weeks
}

export function formatBytes(x: string) {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let l = 0,
    n = parseInt(x, 10) || 0
  while (n >= 1024 && ++l) {
    n = n / 1024
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]
}

export const stableSort = (array: any, cmp: any) => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index])
  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el: any) => el[0])
}

export const getSorting = (order: ORDER, orderBy: string) => {
  return order === ORDER.DESC
    ? (a: any, b: any) => desc(a, b, orderBy)
    : (a: any, b: any) => -desc(a, b, orderBy)
}

export const getMinutes = (seconds: number) => {
  return Math.floor((3300 - seconds) / 60)
}

export const desc = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export const getMinutesDiff = (startTime: string, timeZone: string) => {
  if (!startTime) return null

  return moment(startTime).diff(
    moment(
      moment(moment.tz(timeZone))
        .format()
        .substring(0, 16)
    ),
    'minutes'
  )
}

export const openSupport = async (e: Event, profile: IStudentProfile | null = null) => {
  if (typeof instantChatBot === 'undefined') return

  e.preventDefault()

  let role = 'CLIENT'

  if (profile) {
    if (profile.tutorId && profile.student) {
      const tutor = await fetchTeacherProfileByUid(profile.tutorId)
      if (tutor) role += ` @${tutor.telegram}`
    } else if (profile.role && profile.role !== ROLES.STUDENT) {
      role = profile.role
    } else if (profile.student) {
      role = ROLES.STUDENT
    }
  }

  window.instantChatBotUidName = profile
    ? `${profile.id} ${role} ${profile.name} ${profile.lastName} ${browserName} ${browserVersion}`
    : `${getBrowserUniqueId()} ${browserName} ${browserVersion}`

  instantChatBot.open()
}

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const formatMinutes = (minutes: number): string =>
  minutes < 0 ? '0:00' : minutes === 60 ? '1:00' : '0:' + ('0' + minutes).slice(-2)
export const parseBirthDate = (birthDate: string) => {
  if (!birthDate) return ''
  const birthDateArray = birthDate.split('-')
  return birthDateArray.length === 3
    ? `${birthDateArray[2]}.${birthDateArray[1]}.${birthDateArray[0]}`
    : birthDate
}

export const isCyrillic = (str: string) => /^[А-Яа-яЁё\s\/-]+$/.test(str)
export const isLatin = (str: string) => /^[A-Za-z\s\/-]+$/.test(str)

export const alphabetRegex = /^([A-Z][a-z]+|[А-ЯЁ][а-яё]+)([-\s\/]([A-Z][a-z]+|[А-ЯЁ][а-яё]+)|\sи\s([A-Z][a-z]+|[А-ЯЁ][а-яё]+))?$/

export const skypeRegex = /^[^\sА-Яа-яЁё]+[^\s]$/
export const telegramRegex = /^(?!\d)[a-zA-Z0-9_]{5,32}$/

export const isBlackFriday =
  moment().format('DD.MM.YYYY') === '21.11.2024' ||
  moment().format('DD.MM.YYYY') === '22.11.2024' ||
  moment().format('DD.MM.YYYY') === '23.11.2024'

export const customFilterStudentOption = (option: any, searchText: string) => {
  const data: IStudentProfile = option.data

  const firstNameLastName = `${data.name ? data.name.trim() : ''} ${
    data.lastName ? data.lastName.trim() : ''
  } ${data.email ? data.email.trim() : ''}`.toLowerCase()
  const lastNamefirstName = `${data.lastName ? data.lastName.trim() : ''} ${
    data.name ? data.name.trim() : ''
  } ${data.email ? data.email.trim() : ''}`.toLowerCase()
  const email = `${data.email ? data.email.trim() : ''}`.toLowerCase()
  const lastNameEmail = `${data.name ? data.name.trim() : ''} ${
    data.email ? data.email.trim() : ''
  }`.toLowerCase()

  const nameEmail = `${data.name ? data.name.trim() : ''} ${
    data.email ? data.email.trim() : ''
  }`.toLowerCase()
  const search = searchText.toLowerCase()
  if (
    firstNameLastName.includes(search) ||
    lastNamefirstName.includes(search) ||
    nameEmail.includes(search) ||
    lastNameEmail.includes(search) ||
    email == search
  ) {
    return true
  } else {
    return false
  }
}

export const filteredExercises = (filters: any, exercises: IFactoryExercise[]) => {
  const checkedLevels = filters.levels.filter(item => item.checked)
  const checkedInterests = filters.interests.filter(item => item.checked)
  const checkedMainGrammatical = filters.mainGrammatical.filter(item => item.checked)
  const checkedCountry = filters.country.filter(item => item.checked)
  return exercises.filter(exercise => {
    let isFilter = true
    if (
      checkedLevels.filter(item => exercise.levels.includes(item.value)).length ===
      checkedLevels.length
    ) {
      isFilter = true
    } else {
      return false
    }

    if (
      checkedInterests.filter(item => exercise.interests.includes(item.value)).length ===
      checkedInterests.length
    ) {
      isFilter = true
    } else {
      return false
    }

    if (
      checkedMainGrammatical.filter(item => exercise.mainGrammatical.includes(item.value))
        .length === checkedMainGrammatical.length
    ) {
      isFilter = true
    } else {
      return false
    }

    let countries: any
    if (typeof exercise.country === 'string') countries = [exercise.country]
    else countries = exercise.country
    if (
      !checkedCountry.length ||
      checkedCountry.find(
        (item: any) => countries?.includes(item.label) || countries?.includes(item.value)
      )
    ) {
      isFilter = true
    } else {
      return false
    }

    return isFilter
  })
}
export const getYouTubePreviewImage = (url: string) => {
  const [a, , b] = url
    .replace(/(>|<)/gi, '')
    .split(
      /^.*(?:(?:youtu\.?be(\.com)?\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/
    )

  if (b !== undefined) {
    return `https://img.youtube.com/vi/${b.split(/[^0-9a-z_-]/i)[0]}/hqdefault.jpg`
  } else {
    return true
  }
}

export const getWords = (words: string) => {
  const wordsString = words.replace(/[ ,.!?\\\-()\n]+/g, ' ')
  const regex = /[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ]+/gu
  const spanishWords = wordsString.match(regex)
  return spanishWords !== null && spanishWords.length > 0 ? spanishWords.join(',') : wordsString
}

export const getRandomInterests = (interests: string[]) => {
  return interests && interests.length > 0 ? interests.join(',') : 'nature'
}

export function cleanInvalidTags(htmlString) {
  const cleanedHtml = htmlString.replace(/<\/?[a-z][a-z0-9]*(\s[^>]*)?>/gi, match => {
    if (match.match(/<\s|<[^a-z]/i) || match.match(/\s>/)) {
      return ''
    }
    return match
  })
  const parser = new DOMParser()
  const parsedDoc = parser.parseFromString(cleanedHtml, 'text/html')
  return parsedDoc.body.innerHTML
}

export const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const showToast = (message: string, isError: boolean = false) => {
  toast(message, {
    autoClose: 2000,
    position: 'bottom-center',
    closeButton: false,
    hideProgressBar: true,
    className: isError ? 'student-teacher-selection-error' : 'student-teacher-selection-success',
  })
}

export const getRandomElement = <T>(arr: T[]): T => {
  const randomIndex = Math.floor(Math.random() * arr.length)
  return arr[randomIndex]
}
export const calculateAge = (birthDate: string) => {
  const parsedDate = moment(birthDate, 'DD.MM.YYYY')
  if (!parsedDate.isValid()) {
    console.error('Invalid birthDate format:', birthDate)
    return null
  }
  return moment().diff(parsedDate, 'years')
}
