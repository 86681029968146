import { CALENDAR_EVENT_TYPES } from '@utils/constants'

export interface IMessage {
  date: number
  type: MessageType
  plannedEvent?: string
  newEvent?: string
  m: string
  id: string
  eventType?: CALENDAR_EVENT_TYPES
  path?: string
}

export enum MessageType {
  text = 'text',
  schedule = 'schedule',
  trialLesson = 'trialLesson',
  date = 'date',
  scheduleBooking = 'scheduleBooking',
  deletedLesson = 'deletedLesson',
  regularLesson = 'regularLesson',
  disapproveLesson = 'disapproveLesson',
}
