import React from 'react'

interface IGoalIcon {
  progress: 'done' | 'current' | 'future'
}

const GoalIcon = ({ progress }: IGoalIcon) => {
  return (
    <>
      {progress === 'done' && (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="13" cy="13" r="13" fill="#4284FF" />
          <path
            d="M8.07485 13.1764L7.95455 13.0579L7.83424 13.1764L6.8797 14.1167L6.75572 14.2388L6.8797 14.3609L10.6979 18.1221L10.8182 18.2406L10.9385 18.1221L19.1203 10.0624L19.2443 9.9403L19.1203 9.81817L18.1658 8.87787L18.0455 8.75937L17.9252 8.87787L10.8182 15.8788L8.07485 13.1764Z"
            fill="white"
            stroke="white"
            strokeWidth="0.342857"
          />
        </svg>
      )}
      {progress === 'current' && (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="13" cy="13" r="12" fill="white" stroke="#9ABDFC" stroke-width="2" />
        </svg>
      )}
      {progress === 'future' && (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="13" cy="13" r="13" fill="#C2CFE0" />
          <path
            d="M13 5L14.7961 10.5279H20.6085L15.9062 13.9443L17.7023 19.4721L13 16.0557L8.29772 19.4721L10.0938 13.9443L5.39155 10.5279H11.2039L13 5Z"
            fill="white"
          />
        </svg>
      )}
    </>
  )
}

export default GoalIcon
