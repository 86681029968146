import {
  PersonalActionsTypes,
  SET_TEACHERS,
  DELETE_TEACHER_ACTION,
  SET_TEACHER_STUDENTS,
  SET_TEACHERS_ACCOUNTING,
  SET_RATE_TEACHER,
  SET_PASSED_LESSONS,
  SET_REQUISITES_TEACHER,
  SET_MANAGER_STUDENTS,
  SET_MANAGER_POSSIBLE_STUDENTS,
  SET_STUDENT_PAYMENTS,
  SET_RATE_STUDENT,
  SET_STUDENT_DATA,
  DELETE_MANAGER_POSSIBLE_STUDENT,
  SET_POSSIBLE_STUDENT_DATA,
  SET_LOADING,
  SET_MANAGERS,
  SET_SALES_MANAGERS,
  SET_TEACHER_STATUS,
  CHANGE_MANAGER_POTENTIAL_STUDENT,
  DELETE_SALES_MANAGER_ACTION,
  SET_TEACHERS_STATISTICS,
  CHANGE_RECORD_STATUS,
} from './types'
import { IPayment, IProfile, IStudentProfile } from '@models/IProfile'
import { IPassedLesson, IRequisites } from '@models/IPersonal'
import { TEACHER_RATES, STUDENT_RATES } from '@utils/constants'

export const deleteTeacherAction = (uid: string): PersonalActionsTypes => {
  return {
    type: DELETE_TEACHER_ACTION,
    payload: uid,
  }
}

export const getTeachersAction = (teachers: IProfile[]): PersonalActionsTypes => {
  return {
    type: SET_TEACHERS,
    payload: teachers,
  }
}
export const getTeacherStatisticsAction = (teachers: IProfile[]): PersonalActionsTypes => {
  return {
    type: SET_TEACHERS_STATISTICS,
    payload: teachers,
  }
}
export const setTeacherStatusAction = (teachers: IProfile[]): PersonalActionsTypes => {
  return {
    type: SET_TEACHER_STATUS,
    payload: teachers,
  }
}

export const getTeachersAccountingAction = (teachers: IProfile[]): PersonalActionsTypes => {
  return {
    type: SET_TEACHERS_ACCOUNTING,
    payload: teachers,
  }
}
export const SetIsLoading = (loading: boolean): PersonalActionsTypes => {
  return {
    type: SET_LOADING,
    payload: loading,
  }
}

export const setTeacherStudentsAction = (data: {
  students: string[]
  id: string
}): PersonalActionsTypes => {
  return {
    type: SET_TEACHER_STUDENTS,
    payload: data,
  }
}

export const setRateTeacherAction = (data: {
  teacherId: string
  rate: TEACHER_RATES
}): PersonalActionsTypes => {
  return {
    type: SET_RATE_TEACHER,
    payload: data,
  }
}

export const setPassedLessonsAction = (data: {
  teacherId: string
  studentId?: string
  passedLessons: IPassedLesson[]
  d?: Date
}): PersonalActionsTypes => {
  return {
    type: SET_PASSED_LESSONS,
    payload: data,
  }
}

export const setRequisitesTeacherAction = (data: {
  teacherId: string
  requisites: IRequisites
}): PersonalActionsTypes => {
  return {
    type: SET_REQUISITES_TEACHER,
    payload: data,
  }
}

export const getManagerStudentsAction = (data: IStudentProfile[]): PersonalActionsTypes => {
  return {
    type: SET_MANAGER_STUDENTS,
    payload: data,
  }
}

export const getManagerPotentialStudentsAction = (
  data: IStudentProfile[]
): PersonalActionsTypes => {
  return {
    type: SET_MANAGER_POSSIBLE_STUDENTS,
    payload: data,
  }
}

export const deleteManagerPotentialStudentAction = (id: string): PersonalActionsTypes => {
  return {
    type: DELETE_MANAGER_POSSIBLE_STUDENT,
    payload: id,
  }
}

export const changeManagerPotentialStudentAction = (id: string): PersonalActionsTypes => {
  return {
    type: CHANGE_MANAGER_POTENTIAL_STUDENT,
    payload: id,
  }
}

export const setStudentPaymentsAction = (data: {
  studentId: string
  payments: IPayment[]
}): PersonalActionsTypes => {
  return {
    type: SET_STUDENT_PAYMENTS,
    payload: data,
  }
}

export const setRateStudentAction = (data: {
  studentId: string
  rate: STUDENT_RATES
}): PersonalActionsTypes => {
  return {
    type: SET_RATE_STUDENT,
    payload: data,
  }
}

export const setStudentDataAction = (data: IStudentProfile): PersonalActionsTypes => {
  return {
    type: SET_STUDENT_DATA,
    payload: data,
  }
}

export const setPotentialStudentDataAction = (data: IStudentProfile): PersonalActionsTypes => {
  return {
    type: SET_POSSIBLE_STUDENT_DATA,
    payload: data,
  }
}

export const getManagersAction = (data: IProfile[]): PersonalActionsTypes => {
  return {
    type: SET_MANAGERS,
    payload: data,
  }
}

export const getSalesManagersAction = (data: IProfile[]): PersonalActionsTypes => {
  return {
    type: SET_SALES_MANAGERS,
    payload: data,
  }
}

export const deleteSalesManagerAction = (uid: string): PersonalActionsTypes => {
  return {
    type: DELETE_SALES_MANAGER_ACTION,
    payload: uid,
  }
}

export const changeTeacherRecordStatusAction = (uid: string): PersonalActionsTypes => {
  return {
    type: CHANGE_RECORD_STATUS,
    payload: uid,
  }
}
