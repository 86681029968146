import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@pages/student/student.styles'

import CloseIconWithCircle from '@components/Icons/closeIconWithCircle'
import sync from '@assets/icons/refresh.svg'

import * as Styled from './individualPlan.styles'
import GoalIcon from '../Icons/GoalIcon'
import { CheckedIcon, UncheckedIcon } from '../Icons'
import { IState } from '@models/IIndividualPlan'

interface IIndividualPlaModal {
  handleClose?: () => void
  handleTeacherClose?: () => void
  handleRegenerate?: () => void
  intermediateGoalText?: string
  mainGoalText?: string
  teacherView?: boolean
  individualPlan: IState
  styles?: React.CSSProperties
}

const IndividualPlanModal = ({
  handleClose,
  mainGoalText,
  intermediateGoalText,
  individualPlan,
  handleRegenerate,
  teacherView,
  handleTeacherClose,
  styles,
}: IIndividualPlaModal) => {
  const { t } = useTranslation()

  return (
    <Styled.IndividualPlanModalWrapper teacherView={teacherView} style={styles}>
      <Styled.IndividualPlanModalContent teacherView={teacherView}>
        {!teacherView && (
          <Styled.GoalBlock>
            <Styled.Title>{t('StudyGoals')}</Styled.Title>

            {intermediateGoalText && (
              <Styled.GoalItem>
                <GoalIcon progress={individualPlan.reachedIntermediateGoal ? 'done' : 'current'} />
                <Styled.GoalText>{intermediateGoalText}</Styled.GoalText>
              </Styled.GoalItem>
            )}

            <Styled.GoalItem>
              <GoalIcon progress={individualPlan.reachedMainGoal ? 'done' : 'future'} />
              <Styled.GoalText> {mainGoalText} </Styled.GoalText>
            </Styled.GoalItem>
          </Styled.GoalBlock>
        )}
        <Styled.PlanBlock>
          <Styled.Title>
            {t('Plan.individualTasks')}
            {!teacherView && (
              <Tooltip
                placement="bottom"
                title={
                  <Styled.TooltipContainer>
                    {t('Plan.RegenerateDescription')}
                  </Styled.TooltipContainer>
                }
              >
                <button onClick={handleRegenerate}>
                  <img src={sync} alt="" />
                </button>
              </Tooltip>
            )}
          </Styled.Title>
          {individualPlan.plan.map(day => (
            <Styled.PlanItem>
              <Styled.DayTitle>
                {t('Plan.Day')} {day.day}
              </Styled.DayTitle>
              {day.tasks.map(task => (
                <Styled.DayTask>
                  {task.isDone ? <CheckedIcon color="#6FCF97" /> : <UncheckedIcon />}
                  {task.description}
                </Styled.DayTask>
              ))}
            </Styled.PlanItem>
          ))}
        </Styled.PlanBlock>

        {!teacherView && (
          <Styled.CloseButton onClick={handleClose}>
            <CloseIconWithCircle />
          </Styled.CloseButton>
        )}

        {teacherView && (
          <Styled.TeacherCloseButton onClick={handleTeacherClose}>
            {t('Close')}
          </Styled.TeacherCloseButton>
        )}
      </Styled.IndividualPlanModalContent>
    </Styled.IndividualPlanModalWrapper>
  )
}

export default IndividualPlanModal
