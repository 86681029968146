import { IStudentProfile } from '@models/IProfile'
import Spinner from 'react-spinkit'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import Step5 from './Steps/Step5'
import Step6 from './Steps/Step6'
import Step7 from './Steps/Step7'
import * as Styled from './worksheet.styles'
import { individualPlansRef, studentsRef } from '@configs/firebase'
import { useTranslation } from 'react-i18next'
import Step8 from './Steps/Step8'
import { createIndividualPlanForStudent } from '@servise/webhookAPI'
import IndividualPlanModal from '@components/Student/IndividualPlan/IndividualPlanModal'
import { IState } from '@models/IIndividualPlan'
interface IStep {
  title: string
  content: JSX.Element
  checked: boolean
}

export default function({
  student: defaulStudent,
  onClose,
}: {
  student: IStudentProfile
  onClose: (data?: IStudentProfile) => void
}) {
  const [student, setStudent] = useState<IStudentProfile>(defaulStudent)
  const [isLoadingPlan, setIsLoadingPlan] = useState(false)
  const [individualPlan, setIndividualPlan] = useState<null | IState>(null)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const { t } = useTranslation()
  const handleChangeStep = (value: number) => {
    if (isLast) {
      setIsLoadingPlan(true)
      saveStudentData()
      return
    }
    setCurrentStep(prev => {
      let step = prev + value
      const deadlineDiffMonths = moment(
        student?.deadline?.seconds ? student.deadline.toDate() : moment()
      ).diff(moment(), 'months')
      let step2 = step === 3 && deadlineDiffMonths <= 4 ? step + value : step

      return step2
    })
  }
  const onChangeData = ({ name, value }: { name: string; value: any }) => {
    setStudent(prev => ({ ...prev, [name]: value }))
  }

  const steps: IStep[] = useMemo(() => {
    return [
      {
        title: 'StudentWorkSheetStep1Title',
        content: <Step1 level={student.level} onChange={onChangeData} />,
        checked: !!student.level,
      },
      {
        title: 'StudentWorkSheetStep2Title',
        content: <Step2 goal={student?.goal} onChange={onChangeData} />,
        checked: !!student?.goal,
      },
      {
        title: 'StudentWorkSheetStep3Title',
        content: <Step3 deadline={student?.deadline} onChange={onChangeData} />,
        checked: !!student.deadline,
      },
      {
        title: `StudentWorkSheetStep4Title`,
        content: (
          <Step4
            goals={
              student.intermediateGoals?.length
                ? student.intermediateGoals
                : [
                    {
                      goal: '',
                      deadline: moment()
                        .add(3, 'months')
                        .format('DD-MM-YYYY'),
                    },
                  ]
            }
            goal={student.goal}
            onChange={onChangeData}
          />
        ),
        checked: !!student?.intermediateGoals?.length,
      },
      {
        title: 'StudentWorkSheetStep5Title',
        content: (
          <Step5 onChange={onChangeData} classFrequency={student.classFrequency || [0, 0]} />
        ),
        checked: student?.classFrequency?.filter(cf => cf > 0).length === 2,
      },
      {
        title: 'StudentWorkSheetStep6Title',
        content: <Step6 attitudeToHomework={student.attitudeToHomework} onChange={onChangeData} />,
        checked: !!student.attitudeToHomework,
      },
      {
        title: 'StudentWorkSheetStep7Title',
        content: <Step7 priorities={student.priorities || {}} onChange={onChangeData} />,
        checked:
          !!student?.priorities?.speaking &&
          !!student.priorities.listening &&
          !!student.priorities.reading &&
          !!student.priorities.writing,
      },
      {
        title: 'StudentWorkSheetStep8Title',
        content: <Step8 interests={student.interests} onChange={onChangeData} />,
        checked: student?.interests?.length > 2,
      },
    ]
  }, [student])

  const saveStudentData = () => {
    delete student?.currentCourse
    studentsRef
      .doc(student.id)
      .update({ ...student })
      .then(async () => {
        await createIndividualPlanForStudent(student.level, student.id, 70)
        setTimeout(async () => {
          const planSnap = await individualPlansRef.doc(student.id).get()
          if (planSnap.exists) {
            const data: IState = planSnap.data() as IState
            setIndividualPlan(data)
          }
          setIsLoadingPlan(false)
        }, 7000)
      })
  }
  const isLast = steps.length === currentStep + 1

  const deadlineDiffMonths = useMemo(() => {
    return moment(student?.deadline?.seconds ? student.deadline.toDate() : moment()).diff(
      moment(),
      'months'
    )
  }, [student])

  return isLoadingPlan ? (
    <Styled.WorkSheetContainer>
      <Styled.GenerationText>Генерируем план занятий...</Styled.GenerationText>
      <div style={{ alignSelf: 'center' }}>
        <Spinner
          style={{
            display: 'flex',
            transform: 'scale(0.6)',
          }}
          name="line-spin-fade-loader"
          fadeIn="none"
          color="orange"
        />
      </div>
    </Styled.WorkSheetContainer>
  ) : (
    <Styled.WorkSheetContainer isLast={steps.length === currentStep + 1}>
      {!individualPlan ? (
        <>
          <Styled.WorkSheetTitlePages>
            {t('Question') + ' '}
            {currentStep >= 4
              ? deadlineDiffMonths <= 4
                ? currentStep
                : currentStep + 1
              : currentStep + 1}
            <span style={{ textTransform: 'lowercase' }}> {t('From')} </span>
            {deadlineDiffMonths > 4 ? steps.length : steps.length - 1}
          </Styled.WorkSheetTitlePages>
          <Styled.WorkSheetTitle>{t(steps[currentStep].title)}</Styled.WorkSheetTitle>
          <Styled.Content>{steps[currentStep].content}</Styled.Content>
          <Styled.PrevNextButton>
            <Styled.Button
              onClick={() => {
                currentStep === 0 ? onClose() : handleChangeStep(-1)
              }}
              style={{ background: '#C2CFE0' }}
            >
              {t('Back')}
            </Styled.Button>
            {steps[currentStep].checked && (
              <Styled.Button onClick={() => handleChangeStep(1)}>
                {isLast ? t('Ready') : t('Next')}
              </Styled.Button>
            )}
          </Styled.PrevNextButton>
        </>
      ) : (
        <>
          <IndividualPlanModal
            individualPlan={individualPlan}
            teacherView
            handleTeacherClose={() => {
              onClose(student)
            }}
          />
        </>
      )}
    </Styled.WorkSheetContainer>
  )
}
