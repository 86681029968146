import React, { useMemo } from 'react'
import * as Styled from '../personal-info.styles'
import { IconButton } from '@material-ui/core'
import EditIcon from '../../../Icons/EditIcon'
import Step4 from '../Worksheet/Steps/Step4'
import { Button, PrevNextButton } from '../Worksheet/worksheet.styles'
import moment from 'moment'
import CheckMarkIcon from '../../../Icons/CheckMark'
import { Calendar } from 'react-date-range'

export default function({
  goals,
  goal,
  editType,
  handleClickEdit,
  onChange,
}: {
  editType?: string
  goal: string
  handleClickEdit: (data: string) => void
  goals?: { goal: string; deadline: string }[]
  onChange: (data: { name: string; value: any }) => void
}) {
  const currentGoal = useMemo(() => {
    return goals[goals.length - 1]
  }, [goals])
  return (
    <>
      <Styled.PersonalInfoItem>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'flex-start',
          }}
        >
          <span
            style={{
              color: '#C2CFE0',
              fontSize: 12,
              fontWeight: 700,
              zIndex: 2,
            }}
          >
            Intermediate
          </span>
          <Styled.PersonalInfoLabel>Goal</Styled.PersonalInfoLabel>
        </div>

        <Styled.PersonalItemCard>{currentGoal.goal}</Styled.PersonalItemCard>
        <div className="hover-block" />
        {editType !== 'intermediateGoals' && (
          <IconButton
            className="edit-btn"
            size="small"
            onClick={() => handleClickEdit('intermediateGoals')}
          >
            <EditIcon />
          </IconButton>
        )}
      </Styled.PersonalInfoItem>
      <Styled.PersonalInfoItem>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span
            style={{
              color: '#C2CFE0',
              fontSize: 12,
              fontWeight: 700,
              zIndex: 2,
            }}
          >
            Intermediate
          </span>
          <Styled.PersonalInfoLabel>Deadline</Styled.PersonalInfoLabel>
        </div>

        <Styled.PersonalItemCard>{currentGoal.deadline}</Styled.PersonalItemCard>
        <div className="hover-block" />
        {editType !== 'intermediateDeadline' && (
          <IconButton
            className="edit-btn"
            size="small"
            onClick={() => handleClickEdit('intermediateDeadline')}
          >
            <EditIcon />
          </IconButton>
        )}
      </Styled.PersonalInfoItem>

      {editType === 'intermediateGoals' && (
        <Styled.EditContainer
          style={{
            top: 40,
            bottom: 0,
            right: 0,
            left: 0,
            background: 'white',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <Styled.PersonalInfoLabel
              style={{
                width: 'auto',
                fontWeight: 700,
                fontSize: 16,
                margin: '40px 30px 10px 30px',
              }}
            >
              What intermediate goal can you achieve in 3 months?
            </Styled.PersonalInfoLabel>
            <Step4 goals={goals} onChange={onChange} goal={goal} />
          </div>

          <PrevNextButton style={{ marginBottom: 20 }}>
            <Button
              style={{ background: '#C2CFE0' }}
              onClick={() => handleClickEdit('intermediateGoals')}
            >
              Cancel
            </Button>
            <Button onClick={() => handleClickEdit('intermediateGoals')}>Save</Button>
          </PrevNextButton>
        </Styled.EditContainer>
      )}

      {editType === 'intermediateDeadline' && (
        <Styled.EditContainer
          style={{
            padding: '16px 20px',
            top: 60,
            right: 16,
            left: 16,
            bottom: 20,
          }}
        >
          <Styled.PersonalInfoLabel
            style={{
              width: 'auto',
              fontWeight: 700,
              fontSize: 16,
              marginBottom: 24,
            }}
          >
            Intermediate Deadline
          </Styled.PersonalInfoLabel>
          <IconButton
            size="small"
            className="edit-btn"
            style={{ display: 'block', top: 10, right: 10 }}
            onClick={() => handleClickEdit('intermediateDeadline')}
          >
            <CheckMarkIcon />
          </IconButton>
          <span
            style={{
              marginBottom: 14,
              color: '#414752',
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            By what date should the intermediate goal be met?
          </span>
          <Calendar
            date={
              currentGoal.deadline
                ? moment(currentGoal.deadline, 'DD-MM-YYYY')
                    .local()
                    .toDate()
                : null
            }
            onChange={date =>
              onChange({
                name: 'intermediateGoals',
                value: [
                  {
                    ...currentGoal,
                    deadline: moment(date)
                      .local()
                      .format('DD-MM-YYYY'),
                  },
                ],
              })
            }
          />
        </Styled.EditContainer>
      )}
    </>
  )
}
