import styled, { css, keyframes } from 'styled-components'

export const PlanWrapper = styled.div`
  display: flex;
  gap: 40px;
  box-shadow: 0px 2px 10px 0px #00000014;
  box-shadow: 0px 6px 18px 0px #0000000f;
  border-radius: 20px;
  padding: 30px 20px;
  color: #414752;
  min-height: 600px;
`

export const PlanColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const PlanHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  line-height: 27.24px;
  & img {
    width: 20px;
    height: 20px;
  }
`
export const TooltipContainer = styled.div`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.14px;
  padding: 6px 15px;
  border-radius: 8px;
  background: rgba(65, 71, 82, 0.85);
  max-width: 300px;
  width: 100%;
`

export const PlanList = styled.ul<{ fade: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 11px;
  ${({ fade }) =>
    fade &&
    css`
      animation: ${fadeIn} 1.5s ease-in-out;
    `}
`

export const ListItem = styled.li`
  border-radius: 20px;
  border: 1px solid #c2cfe0;
  padding: 22px 40px 22px 24px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
`
export const Link = styled.a`
  text-decoration: underline;
  overflow-wrap: break-word;
  white-space: normal;
`

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
`
export const ItemTitle = styled.div`
  font-weight: 700;
  line-height: 21px;
  text-align: left;
`
export const ItemTime = styled.div`
  color: #7e92ac;
  font-weight: 400;
`

export const ShowPlanButton = styled.button`
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  letter-spacing: 0.01em;
  align-self: center;
  padding: 16px 24px;
  border: 1px solid #f3f5f9;
  border-radius: 40px;
  box-shadow: 0px 2px 10px 0px #00000014;
`

export const ProgressBarContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

interface ProgressBarProps {
  progress?: number
}

export const ProgressBar = styled.div<ProgressBarProps>`
  position: relative;
  width: 100%;
  height: 14px;
  width: 200px;
  background: #ebebeb;
  border-radius: 15px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: ${props => `${props.progress}%`};
    height: 100%;
    border-radius: 15px;
    background: ${props =>
      props.progress > 69
        ? 'linear-gradient(90deg, #6FCF97 0%, #29AD60 100%)'
        : props.progress < 30
        ? 'linear-gradient(109.63deg, #FF7051 15.04%, #CE3D1E 88.75%)'
        : 'linear-gradient(270deg, #FF922D 0%, #F9D373 100%)'};
  }
  & svg {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: ${props => `${props.progress}%`};
    z-index: 2;
  }
`

export const ProgressScore = styled.div<{ progress: number }>`
  font-size: 24px;
  font-weight: 800;
  line-height: 36px;
  color: ${props =>
    props.progress > 69 ? '#4DA973' : props.progress < 30 ? '#DB4B2C' : '#FF922D'};
`

export const ScoreList = styled.ul<{ fade: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex-grow: 1;
  ${({ fade }) =>
    fade &&
    css`
      animation: ${fadeIn} 1.5s ease-in-out;
    `}
`
export const ScoreItem = styled.li`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  & span {
    font-weight: 700;
  }
`

// Modal
export const IndividualPlanModalWrapper = styled.div<{ teacherView?: boolean }>`
  position: relative;
  overflow: hidden;
  padding: ${({ teacherView }) => !teacherView && '30px 0 30px 30px'};
`

export const IndividualPlanModalContent = styled.div<{ teacherView?: boolean }>`
  max-height: 100%;
  width: 410px;
  color: #414752;
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-size: 14px;
  background-color: #fff;
  max-height: ${({ teacherView }) => !teacherView && '60vh'};
  overflow-y: auto;
  padding: ${({ teacherView }) => (teacherView ? '0 30px' : 0)};
  margin-bottom: 10px;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollTrack};
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`
export const GoalBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const GoalItem = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  & svg {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
  }
`
export const GoalText = styled.span`
  font-weight: 600;
  line-height: 19.6px;
`

export const Title = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  color: #1b3b77;
  text-align: center;
  & button {
    cursor: pointer;
  }
`
export const PlanBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const PlanItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const DayTitle = styled.span`
  font-size: 14px;
  font-weight: 800;
  line-height: 19.07px;
`
export const DayTask = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  line-height: 19.6px;
  & svg {
    flex-shrink: 0;
  }
`

export const IntermediateBlock = styled.div<{ fade: boolean }>`
  ${({ fade }) =>
    fade &&
    css`
      animation: ${fadeIn} 1.5s ease-in-out;
    `}
  margin-bottom: 60px;
`
export const IntermediateTitle = styled.div`
  color: #c2cfe0;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.34px;
  letter-spacing: 0.01em;
`
export const IntermediateBlockContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
export const ContentText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #414752;
`
export const PlanModalEl = styled.div`
  width: 393px;
  border-radius: 8px;
  z-index: 1;
  text-align: left;
`

export const ButtonsGroup = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 20px;
  transform: translate(0, 50%);
  display: flex;
  gap: 10px;
`

export const GoalButton = styled.button<{ isAgree?: boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 26px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  background: ${({ isAgree }) =>
    isAgree ? 'linear-gradient(270.29deg, #FF922D 18.37%, #FFB800 99.74%)' : '#fff'};
  color: ${({ isAgree }) => !isAgree && '#4284FF'};
  border: ${({ isAgree }) => !isAgree && '2px solid #4284FF'};
`
export const Salut = styled.img`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 300px;
  height: 300px;
`

export const TeacherCloseButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 30px;
  border-radius: 20px;
  width: 94px;
  height: 40px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: center;
  color: #fff;
  background-color: #4284ff;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
