import { APP_CONFIG } from '@utils/constants'
const { appName } = APP_CONFIG

export default [
  { KEY: 'Cancel', Ru: 'Отмена', Eng: 'Cancel', Es: 'Cancelar' },
  { KEY: 'Save', Ru: 'Сохранить', Eng: 'Save', Es: 'Guardar' },
  { KEY: 'Student', Ru: 'Ученик', Eng: 'Student', Es: 'Estudiante' },
  {
    KEY: 'SelectAStudent',
    Ru: 'Выберите ученика',
    Eng: 'Select a Student',
    Es: 'Si necesita recordar los materiales de esta clase, use este botón.',
  },
  { KEY: 'Skype', Ru: 'Skype', Eng: 'Skype', Es: 'Skype' },
  { KEY: 'search', Ru: 'Поиск', Eng: 'Search', Es: 'Buscar' },
  { KEY: 'DontSave', Ru: 'Не сохранять', Eng: "Don't save", Es: 'Cancelar los cambios' },
  { KEY: 'FirstName', Ru: 'Имя', Eng: 'First Name', Es: 'Nombre' },
  { KEY: 'LastName', Ru: 'Фамилия', Eng: 'Last Name', Es: 'Apellido' },
  {
    KEY: 'DateOfBirth',
    Ru: 'Дата рождения',
    Eng: 'Date Of Birth',
    Es: 'fecha de nacimiento',
  },
  { KEY: 'TimeZone', Ru: 'Часовой пояс', Eng: 'Time Zone', Es: 'Huso horario' },
  { KEY: 'Gender', Ru: 'Пол', Eng: 'Gender', Es: 'Sexo' },
  { KEY: 'NotSelected', Ru: 'Не выбран', Eng: 'Not Selected', Es: 'No seleccionado' },
  {
    KEY: 'PhoneNumber',
    Ru: 'Номер телефона',
    Eng: 'Phone Number',
    Es: 'Número de teléfono',
  },
  { KEY: 'Email', Ru: 'Почта', Eng: 'Email', Es: 'Correo electrónico' },
  { KEY: 'Telegram', Ru: 'Телеграм', Eng: 'Telegram', Es: 'Telegram' },
  { KEY: 'Or', Ru: 'Или', Eng: 'Or', Es: 'o' },
  { KEY: 'Location', Ru: 'Город проживания', Eng: 'Location', Es: 'Ciudad' },
  { KEY: 'Clear', Ru: 'Очистить', Eng: 'Clear', Es: 'Borrar' },
  {
    KEY: 'DiscardChanges',
    Ru: 'Отменить изменения',
    Eng: 'Discard changes',
    Es: 'Cancelar los cambios',
  },
  {
    KEY: 'DontSupportEmail',
    Ru:
      'Мы не поддерживаем почты от {{domain}}. Пожалуйста, используйте Gmail, Яндекс или, например, Icloud.',
    Eng:
      'We do not provide service from {{domain}}. Please use Gmail, Yandex or, for example, Icloud.',
    Es: 'No brindamos servicios desde {{dominio}}. Utilice Gmail, Yandex o, por ejemplo, Icloud.',
  },

  {
    KEY: 'EmailIsNotVerified',
    Ru: 'Почта не подтверждена',
    Eng: 'Email is not verified',
    Es: 'Correo electrónico no confirmado',
  },
  {
    KEY: 'ClickToUpdateEmail',
    Ru: 'Нажмите на иконку для оновления почти',
    Eng: 'Click on the icon to update email',
    Es: 'Haga clic en el icono para actualizar el correo electrónico',
  },
  {
    KEY: 'CheckEmail',
    Ru: 'Проверь свою почту для подтверждения нового e-mail',
    Eng: 'Check your e-mail to confirm your new e-mail address',
    Es: 'Compruebe su correo electrónico para confirmar su nueva dirección de correo electrónico',
  },
  { KEY: 'TeacherEmail', Ru: 'E-mail', Eng: 'E-mail', Es: 'Correo electrónico' },
  {
    KEY: 'TeacherEmailIsNotVerified',
    Ru: 'E-mail не подтвержден',
    Eng: 'E-mail is not verified',
    Es: 'Correo electrónico no confirmado',
  },
  { KEY: 'Type', Ru: 'Тип', Eng: 'Type', Es: 'Tipo' },
  { KEY: 'Date', Ru: 'Дата', Eng: 'Date', Es: 'Fecha' },
  { KEY: 'ShowAll', Ru: 'Показать все', Eng: 'Show All', Es: 'Mostrar todo' },
  { KEY: 'Add', Ru: 'Добавить', Eng: 'Add', Es: 'Añadir' },
  { KEY: 'Notebook', Ru: 'Блокнот', Eng: 'Notebook', Es: 'Bloc' },
  { KEY: 'Logout', Ru: 'Выйти', Eng: 'Log out', Es: 'Salir' },
  { KEY: 'Back', Ru: 'Назад', Eng: 'Back', Es: 'Atrás' },
  { KEY: 'Years', Ru: 'лет', Eng: 'Years', Es: 'Años' },
  { KEY: 'Teacher', Ru: 'Преподаватель', Eng: 'Teacher', Es: 'Profesor' },
  { KEY: 'NoClasses', Ru: 'Нет занятий', Eng: 'No classes', Es: 'No hay clases' },
  { KEY: 'TheChange', Ru: 'Изменение', Eng: 'Change', Es: 'Cambio' },
  { KEY: 'Status', Ru: 'Статус', Eng: 'Status', Es: 'Estatus' },
  { KEY: 'Remainder', Ru: 'Остаток', Eng: 'Balance', Es: 'Saldo' },
  { KEY: 'Bonus', Ru: 'Бонус', Eng: 'Bonus', Es: 'Bonus' },
  {
    KEY: 'Buy.Title',
    Ru: 'Пополнить баланс',
    Eng: 'Top up your balance',
    Es: 'Recargar saldo',
  },
  {
    KEY: 'Buy.PaymentHistory',
    Ru: 'История пополнения баланса и занятий',
    Eng: 'Payment history',
    Es: 'Historia de saldo y clases',
  },
  {
    KEY: 'Buy.SecureTextBold',
    Ru: 'Данные защищены.',
    Eng: 'Your data is protected',
    Es: 'Los datos son protegidos',
  },
  {
    KEY: 'Buy.SecureText',
    Ru:
      'Предоставляемая Вами персональная информация является конфиденциальной и не подлежит разглашению. Данные Вашей банковской карты не сохраняются на нашем Web-сервере.',
    Eng:
      'The personal information you provide is confidential and will not be disclosed. Your bank card details are not stored on our Web server.',
    Es:
      'Los datos personales que facilita son confidenciales y no se revelan. Las datos de su tarjeta no se guardan en nuestro servidor web',
  },
  {
    KEY: 'Buy.Description',
    Ru:
      'Здесь можно быстро и удобно оплатить занятия. Выбирай необходимое количество занятий и оплачивай',
    Eng:
      'Here you can easily pay for your classes. Choose how many lessons you need and pay for them from home.',
    Es:
      'Aquí puedes pagar facilmente por las clases. Elija la cantidad de las clases y páguelas sin salir de casa',
  },
  { KEY: 'Classes.AllCourse', Ru: 'Все курсы', Eng: 'All courses', Es: 'Todos los cursos' },
  { KEY: 'Classes.MyClasses', Ru: 'Мои классы', Eng: 'My classes', Es: 'Mis clases' },
  {
    KEY: 'Classes.EmptyPage',
    Ru: `Занятия появятся после начала обучения в школе ${appName}.`,
    Eng: `You will see your classes after the beginning of study at ${appName}.`,
    Es: `Las clases aparecerán después del empezar los estudios en ${appName}`,
  },
  {
    KEY: 'Tests.EmptyPage',
    Ru: 'Тесты появятся после того, как преподаватель назначит их вам',
    Eng: 'The tests will appear after the teacher assigns them to you',
    Es: 'Las pruebas aparecerán después de que el profesor te las asigne',
  },
  {
    KEY: 'Lesson.IsBackHomework',
    Ru: 'Вернуться к домашке',
    Eng: 'Back to homework',
    Es: 'Volver a los deberes',
  },
  {
    KEY: 'Main.NotStudentInfoTitle',
    Ru: 'Изучайте испанский в любое время',
    Eng: 'Learn Spanish anytime',
    Es: 'Aprendan español en cualquier tiempo',
  },
  {
    KEY: 'Main.NotStudentInfoDescription',
    Ru: 'В расписании появятся твои первые занятия',
    Eng: 'Your first lessons will be shown in the schedule',
    Es: 'Las primeras clases estarán en el horario',
  },
  { KEY: 'Main.Progress', Ru: 'Прогресс', Eng: 'Progress', Es: 'Progreso' },
  {
    KEY: 'CurrentCourse',
    Ru: 'Текущий курс',
    Eng: 'Current course',
    Es: 'Curso corriente',
  },
  {
    KEY: 'Main.NotStudentInfoProgressDescription',
    Ru: 'Наши преподаватели помогут достичь тебе поставленной цели',
    Eng: 'Our teachers will help you to achieve the goal',
    Es: 'Nuestros profesores te ayudarán alcanzar tu objetivo',
  },
  { KEY: 'YourLevel', Ru: 'Ваш уровень', Eng: 'Your level', Es: 'Su nivel' },
  {
    KEY: 'CompletedLessons',
    Ru: 'Пройдено занятий',
    Eng: 'Completed lessons',
    Es: 'Clases pasadas',
  },
  {
    KEY: 'CompletedHomework',
    Ru: 'Домашек выполнено',
    Eng: 'Completed homework',
    Es: 'Deberes hechos',
  },
  { KEY: 'NextLevel', Ru: 'Следующий уровень', Eng: 'Next level', Es: 'siguiente nivel' },
  {
    KEY: 'ForNextLevelNeed',
    Ru: 'Для следующего уровня необходимо',
    Eng: 'For the next level you need',
    Es: 'Para siguiente nivel necesitas',
  },
  { KEY: 'TotalClasses', Ru: 'Всего занятий', Eng: 'Total classes', Es: 'Clases en total' },
  {
    KEY: 'LearntWords',
    Ru: 'Слов выучено',
    Eng: 'Learnt words',
    Es: 'Palabras aprendidas',
  },
  {
    KEY: 'IndividualWork',
    Ru: 'Самостоятельной работы',
    Eng: 'individual work',
    Es: 'Trabajos independientes',
  },
  { KEY: 'NewHomework', Ru: 'Новая домашка', Eng: 'New homework', Es: 'Deberes nuevos' },
  { KEY: 'HomeWorkCompleted', Ru: 'Домашка', Eng: 'Homework', Es: 'Deberes' },
  { KEY: 'Execute', Ru: 'Выполнить', Eng: 'Start', Es: 'Empezar' },
  {
    KEY: 'HaveQuestions',
    Ru: 'Возникли вопросы',
    Eng: 'Questions?',
    Es: '¿Algunas preguntas?',
  },
  {
    KEY: 'HomeworkSeeHere',
    Ru: 'Здесь появится домашнее задание, которое задаст преподаватель',
    Eng: 'Here you can see the homework that your teacher assigns',
    Es: 'Aquí encontrararás los deberes que te den el profesores',
  },
  {
    KEY: 'AskToContactYou',
    Ru: 'Попросить связаться',
    Eng: 'Ask to contact you',
    Es: 'Ponerse en contacto',
  },
  {
    KEY: 'OurManagerWillAnswer',
    Ru: 'Наш менеджер ответит на все вопросы и поможет разобраться с проблемой',
    Eng: 'Our manager will answer your questions and help to solve the problem',
    Es: 'Nuestro gerente contestará a todas las preguntas y ayudará resolver problemas',
  },
  { KEY: 'Schedule', Ru: 'Расписание', Eng: 'Schedule', Es: 'Horario' },
  {
    KEY: 'ProgressSeeHere',
    Ru: 'А здесь мы подробно отразим твой прогресс',
    Eng: 'Here you can see your progress',
    Es: 'Aquí puedes ver tu progreso',
  },
  { KEY: 'TheMain', Ru: 'Основное', Eng: 'Main', Es: 'Principal' },
  {
    KEY: 'CurrentLevel',
    Ru: 'Текущий уровень',
    Eng: 'Current level',
    Es: 'Nivel corriente',
  },
  {
    KEY: 'StudyGoals',
    Ru: 'Цели изучения',
    Eng: 'Study goals',
    Es: 'Objetivos de estudio',
  },
  { KEY: 'Interests', Ru: 'Интересы', Eng: 'Interests', Es: 'Intereses' },
  {
    KEY: 'WhatDoLanguageProficiencyLevelsMean',
    Ru: 'Что значат уровни владения языком',
    Eng: 'What Language Proficiency Levels Mean',
    Es: '¿Qué significan los niveles de dominio de un idioma?',
  },
  {
    KEY: 'LevelA1',
    Ru: 'Уровень А1 — первая ступень в изучении испанского языка',
    Eng: 'A1 Level - Beginner',
    Es: 'Nivel A1 - la primera etapa del estudio de la lengua',
  },
  {
    KEY: 'LevelA1Description',
    Ru:
      'Ты войдешь в этот уровень с нулевыми знаниями испанского, однако совсем скоро сможешь участвовать в несложных разговорах: болтать о покупках, заказывать еду в ресторане, рассказывать собеседнику о себе, своих хобби и развлечениях. На этом уровне постигаются азы испанского: алфавит, артикли, глаголы в форме настоящего времени, а также их возвратные формы',
    Eng:
      'You will enter this level with zero knowledge of Spanish, but you will soon be able to participate in simple conversations: chatting about shopping, ordering food in a restaurant, telling people about yourself, your hobbies and entertainment. At this level you will learn the basics of Spanish: the alphabet, the articles, present tense verbs, and their reflexive forms.',
    Es: 'Emiece aprender español desde el principio',
  },
  {
    KEY: 'LevelA2',
    Ru: 'Уровень А2 — элементарный уровень владения испанским',
    Eng: 'A2 level - Elementary',
    Es: 'Nivel A2 - el nivel básico del idioma español',
  },
  {
    KEY: 'LevelA2Description',
    Ru:
      '" На этой ступени сможешь усовершенствовать свои базовые знания, расширить активный вокабуляр. Начнешь понимать наиболее употребляемые фразы носителей испанского. Уровень А2 позволит применять в речи 3 прошедших времени: pretérito perfecto; pretérito indefinido; pretérito imperfecto. Владение настоящим временем глагола и сможете говорить о будущем, используя «futuro». Полученных знаний будет достаточно для несложной беседы с носителем испанского."',
    Eng:
      'At this level you will improve your basic knowledge and expand your active vocabulary. You will finally be able to understand the most commonly used phrases of native Spanish speakers. Level A2 will allow you to use the 3 past tenses: pretérito perfecto; pretérito indefinido; pretérito imperfecto. You will improve your knowledge of the present verb tense and be able to talk about the future using "futuro". You will have enough knowledge to have a simple conversation with a native Spanish speaker',
    Es:
      'En este nivel va a enriquecer los conocimientos básicos y el vocabulario. Por fín enterderá las frases más comunes de los nativos españoles. El nivel A2 le da posibilidad de usar 3 tiempos pasados: Pretérito Perfecto, Pretérito Indefinido, Pretérito Imperfecto. Además dominará bien Presénte de Indicativo y hablarás sobre el futuro.',
  },
  {
    KEY: 'LevelB1',
    Ru: 'Уровень В1 — средний уровень владения испанским',
    Eng: 'B1 level - Intermediate',
    Es: 'Nivel B1 - el nivel intermedio',
  },
  {
    KEY: 'LevelB1Description',
    Ru:
      '" Если вы хотите чувствовать себя уверенно в каждой испаноязычной стране, то вам сюда! Вести беседу в рамках любой ежедневной ситуации, достаточно свободно болтать с носителем и отстаивать свое мнение — всему этому вас научат на уровне В1. Приятный бонус: по окончании уровня вы сможете писать несложные тексты и письма.",',
    Eng:
      "\"If you want to feel confident in every Spanish-speaking country, this is the place for you! You'll learn how to carry on a conversation in any everyday situation, how to talk fluently with a native speaker and how to defend your opinion - all this you'll learn at B1 level. A nice bonus: at the end of the level you will be able to write simple texts and letters,",
    Es:
      'Si quieres estár seguro de sí mismo en cada país hispanohablante es tu opción. Aprenderás como conversar sobre los temas cotidianos, chatear con los nativos y dar su opinión. También podrás escribir los textos simples y las cartas.',
  },
  {
    KEY: 'LevelB2',
    Ru: 'Уровень В2 — продвинутый уровень владения испанским',
    Eng: 'B2 level - Upper intermediate',
    Es: 'B2 - el nivel intermedio alto',
  },
  {
    KEY: 'LevelB2Description',
    Ru:
      '" Этот уровень позволяет ученикам не только свободно общаться с носителями языка, легко применяя все грамматические структуры, но и читать книги в оригинале, смотреть фильмы и сериалы на испанском и всегда быть «в теме» обсуждаемого… Даже если эта тема трудная и абстрактная. Окончив В2 ты больше не будешь испытывать трудности во время путешествия. Совершенствуй испанский в общении с носителями и обязательно посети кинотеатр с премьерой фильма на изучаемом языке.",',
    Eng:
      'This level allows students not only to communicate freely with native speakers, easily applying all grammatical structures, but also to read books in the original, watch films and TV shows in Spanish and always be" in the subject "of the discussion ... Even if this topic is difficult and abstract. After finishing B2 you will no longer experience difficulties when traveling. Improve your Spanish with native speakers and be sure to visit the cinema with the premiere of a film in the target language',
    Es:
      'Este nivel permite a los estudiantes no solo comunicarse libremente con los nativos, aplicando fácilmente todas las estructuras gramaticales, sino también leer libros originales, ver películas y programas de televisión en español y estar siempre "en el tema" de la discusión ... Incluso si este tema es difícil y abstracto. Después de terminar B2, ya no experimentará dificultades al viajar. Mejore tu español con los nativos y asegúrase de visitar el cine con el estreno de una película en español.',
  },
  {
    KEY: 'LevelC1',
    Ru: 'Уровень C1 — высший уровень владения испанским',
    Eng: 'C1 - Advanced',
    Es: 'C1 - el nivel avanzado',
  },
  {
    KEY: 'LevelC1Description',
    Ru:
      '"Чтобы владеть испанским на высшем уровне, достаточно закончить С1. Студентам этого уровня не нужно искать подходящее слово и лезть в переводчик — все уже в их голове. Речь таких учеников богата и насыщена красивыми оборотами, иногда ее сложно отличить от речи носителя. Стань участником делового общения, понимайте сложные абстрактные тексты и создавайте сочинения с четкой структурой и мелкими деталями.",',
    Eng:
      'To speak Spanish at the highest level, it is enough to finish C1. Students of this level don`t need to search for a suitable word in a dictionary - everything is already in their heads. The speech of such students is rich and full of beautiful phrases, sometimes it is difficult to distinguish it from the speech of a native speaker. Become a participant business communication, understand complex abstract texts and create essays with clear structure and fine details',
    Es:
      'Para hablar español al nivel más alto, basta con terminar C1. Los estudiantes de este nivel no necesitan buscar una palabra adecuada en un diccionario, todo ya está en sus cabezas. El discurso de estos estudiantes es rico y lleno de hermosas frases, a veces es difícil distinguirlo del discurso de un hablante nativo. Conviértase en un participante de la comunicación empresarial, comprenda textos abstractos complejos y cree ensayos con una estructura clara y detalles finos',
  },
  {
    KEY: 'LevelC2',
    Ru: 'Уровень С2 — уровень носителя',
    Eng: 'C2 - proficiency (native speaker)',
    Es: 'C2 - el nivel superior',
  },
  {
    KEY: 'LevelC2Description',
    Ru:
      '" На этом уровне студенты почти ничем не отличаются от носителей: они понимают до 100% как устной, так и письменной речи вне зависимости от стиля. Можно выступать на различных мероприятиях и произносить сложную речь без потери основной мысли.",',
    Eng:
      'At this level students speak almost like native speakers: they understand up to 100% of both oral and written speech, regardless of style. You will be able to speak at various events and deliver difficult speech without losing the main idea.',
    Es:
      'En este nivel los estudiantes hablan casi como hablantes nativos: comprenden hasta el 100% del habla tanto oral como escrita, independientemente del estilo. Podrá hablar en varios eventos y pronunciar un discurso difícil sin perder la idea principal.',
  },
  {
    KEY: 'Notes.EmptyPage',
    Ru: `Здесь отображаются записи с занятий. Начните обучение в онлайн-школе ${appName}!`,
    Eng: `Here you can see the recordings from the lessons. Start you own study at ${appName}!`,
    Es: `Aquí puedes ver las grabaciones de las clases. ¡Empiece a estudiar en ${appName}!`,
  },
  {
    KEY: 'Notes.AddedToDictionary',
    Ru: 'Добавлено в словарь',
    Eng: 'Added to dictionary',
    Es: 'Añadido en el diccionarió',
  },
  {
    KEY: 'Notes.AddToDictionary',
    Ru: 'Добавить в словарь',
    Eng: 'Add to dictionary',
    Es: 'Añadir al diccionario',
  },
  {
    KEY: 'Homework.ReportAnIssue',
    Ru: 'Сообщить о проблемe',
    Eng: 'Report a problem',
    Es: 'Informar sobre el problema',
  },
  {
    KEY: 'Homework.ResetAnswers',
    Ru: 'Сбросить ответы',
    Eng: 'Reset the answers',
    Es: 'Borrar respuestas',
  },
  {
    KEY: 'Homework.ExerciseDoesNotWork',
    Ru: 'Не работает упражнение',
    Eng: 'There is a problem',
    Es: 'El ejercicios no funciona',
  },
  {
    KEY: 'Homework.ErrorOnThePage',
    Ru: 'Ошибка на странице',
    Eng: 'Error on the page',
    Es: 'El error en la página',
  },
  {
    KEY: 'Homework.NetworkProblem',
    Ru: 'Проблема со связью',
    Eng: 'Network problem',
    Es: 'Problema de red',
  },
  {
    KEY: 'NextPage',
    Ru: 'Следующая страница',
    Eng: 'Next page',
    Es: 'La siguiente página',
  },
  {
    KEY: 'GoToTheLesson',
    Ru: 'Перейти в урок',
    Eng: 'Go to the lesson',
    Es: 'Ir a la clase',
  },
  {
    KEY: 'BackToTheCabinet',
    Ru: 'Вернуться в кабинет',
    Eng: 'Back to the profile',
    Es: 'Volver al perfíl',
  },
  {
    KEY: 'GoToResults',
    Ru: 'Перейти к результатам',
    Eng: 'Go to results',
    Es: 'Ir a los resultados',
  },
  {
    KEY: 'TakeTestAgain',
    Ru: 'Пройти тест заново',
    Eng: 'Go to results',
    Es: 'Volver a hacer la prueba',
  },
  {
    KEY: 'RetakeTest',
    Ru: 'Ты можешь пройти тест заново, но результаты',
    Eng: 'You can retake the test, but the results',
    Es: 'Puede volver a realizar la prueba, pero no se',
  },
  {
    KEY: 'RetakeTest2',
    Ru: 'текущего прохождения не сохранятся.',
    Eng: 'of the current pass will not be saved.',
    Es: 'guardarán los resultados del pase actual.',
  },
  {
    KEY: 'FinishTest1',
    Ru: 'Тест еще не закончен. Результаты можно',
    Eng: 'The test is not finished yet. The results will be',
    Es: 'La prueba aún no ha terminado. Los resultados estarán',
  },
  {
    KEY: 'FinishTest2',
    Ru: 'будет узнать после полного его прохождения.',
    Eng: 'available after completing it completely.',
    Es: 'disponibles después de completarlo por completo.',
  },
  {
    KEY: 'Homework.ResetAnswersDescription',
    Ru: 'Все выбранные ранее ответы на странице будут удалены и можно снова сделать домашку! :)',
    Eng:
      'All previously selected answers on the page will be deleted and you can do your homework again! :)',
    Es: 'Todas las respuestas elegidas estarán borradas y podrás volver a hacer los deberes',
  },
  {
    KEY: 'Vocabulary.LearnWordsMoreEfficiently',
    Ru: 'Учи слова эффективнее!',
    Eng: 'Learn words more efficiently!',
    Es: '¡Aprende palabras de manera más eficaz!',
  },
  {
    KEY: 'Vocabulary.DownloadApp',
    Ru: 'Скачайте наше приложение и расширение для браузера',
    Eng: 'Download our application and browser extension',
    Es: 'Descarga nuestra app y la extensión para el navegador',
  },
  {
    KEY: 'Vocabulary.HowToAddToTheDictionary',
    Ru: 'Как пополнить словарь?',
    Eng: 'How to add new words to the dictionary?',
    Es: '¿Cómo añadir palabras al diccionario?',
  },
  {
    KEY: 'Vocabulary.DownloadEscuelaApp',
    Ru: `Скачай на телефон приложение ${appName} и повторяй слова в любое время`,
    Eng: `Download  ${appName} application on your smartphone and learn new words at anytime`,
    Es: `Descarga la app  ${appName} en tu teléfono y aprende palabras en cualquier momento`,
  },
  {
    KEY: 'Vocabulary.InstallEscuelaBrowser',
    Ru: `Установи расширение  ${appName} для браузера, чтобы добавлять незнакомые слова с интернета`,
    Eng: `Install  ${appName} Browser extension to add new words from the internet`,
    Es: `Instala la extensión  ${appName} para el navegador, para añadir nuevas palabras de Internet.`,
  },
  {
    KEY: 'Vocabulary.HowToAddWords',
    Ru: 'Как пополнить словарь?',
    Eng: 'How to add the words to the dictionary',
    Es: '¿Cómo añadir palabras al diccionario?',
  },
  { KEY: 'EscuelaBlog', Ru: `Блог ${appName}`, Eng: `Blog ${appName}`, Es: `Blog de ${appName}` },
  {
    KEY: 'Vocabulary.WordsForTheDay',
    Ru: 'Слова за день',
    Eng: "Today's words",
    Es: 'Palabras en un día',
  },
  {
    KEY: 'Vocabulary.WordsOfTheWeek',
    Ru: 'За неделю',
    Eng: 'Words of the week',
    Es: 'En una semana',
  },
  {
    KEY: 'Vocabulary.WordsOfTheMonth',
    Ru: 'За месяц',
    Eng: 'Words of the month',
    Es: 'En un mes',
  },
  {
    KEY: 'Vocabulary.WordsFor3Months',
    Ru: 'За 3-й месяц',
    Eng: 'Words of 3 months',
    Es: 'En 3 meses',
  },
  {
    KEY: 'Vocabulary.AllWords',
    Ru: 'Все слова',
    Eng: 'All words',
    Es: 'Todas las palabras',
  },
  { KEY: 'Help', Ru: 'Помощь', Eng: 'Help', Es: 'Ayuda' },
  {
    KEY: 'DownloadTheApp',
    Ru: 'Скачай приложение',
    Eng: 'Download the app',
    Es: `Descarga la app ${appName} en tu teléfono y aprende palabras en cualquier momento`,
  },
  {
    KEY: 'JoinUs',
    Ru: 'Присоединяйтесь к нам!',
    Eng: 'Join us',
    Es: '¡Únanse a nosotros!',
  },
  {
    KEY: 'FreeTwoLessons',
    Ru: `2 урока в школе ${appName} при первой оплате по промокоду для друга`,
    Eng: '2 Free lessons with the first payment by promo code for your friend',
    Es: `Regalo 2 clases en ${appName} a la hora de pagar con código promocional`,
  },
  {
    KEY: 'LearnForFree',
    Ru: 'Учись бесплатно!',
    Eng: 'Study for free!',
    Es: '¡Estudia gratis!',
  },
  {
    KEY: 'ChangeEventDateModal.Title',
    Ru: 'Хочешь перенести урок?',
    Eng: 'Do you want to reschedule the lesson?',
    Es: '¿Quieres aplazar la clase?',
  },
  {
    KEY: 'ChangeEventDateModal.Desc',
    Ru: 'C тобой свяжется менеджер и уточнит время переноса',
    Eng: 'Our manager will contact you to specify the new time',
    Es: 'Nuestro gerente le escribirá y precisará el tiempo',
  },
  { KEY: 'Reschedule', Ru: 'Перенести', Eng: 'Reschedule', Es: 'Aplazar' },
  { KEY: 'Balance', Ru: 'Баланс', Eng: 'Balance', Es: 'Sueldo' },
  {
    KEY: 'Balance.Get2Lessons',
    Ru: 'Пригласи друга',
    Eng: 'Refer a Friend',
    Es: 'Recibir 2 clases',
  },
  { KEY: 'Balance.TopUp', Ru: 'Пополнить', Eng: 'Top up', Es: 'Recargar saldo' },
  { KEY: 'Balance.Start', Ru: 'Начать!', Eng: 'Start!', Es: '¡A empezar!' },
  { KEY: 'Lesson1', Ru: 'Урок', Eng: 'Lesson', Es: 'Clase' },
  { KEY: 'Lesson2', Ru: 'Урока', Eng: 'Lessons', Es: 'Clases' },
  { KEY: 'Level', Ru: 'Уровень', Eng: 'Level', Es: 'Nivel' },
  {
    KEY: 'LearningObjectives',
    Ru: 'Учебные цели',
    Eng: 'Study goals',
    Es: 'Objetivos de estudio',
  },
  {
    KEY: 'TeacherCardText',
    Ru: 'Связаться с преподавателем через мессенджер',
    Eng: 'You can get in touch with the teacher using the messenger',
    Es: 'Puede estar en contacto a través el mensajero',
  },
  {
    KEY: 'StudentCard.CommentsTitle',
    Ru: 'Комментарии',
    Eng: 'Comments',
    Es: 'Comentarios',
  },
  {
    KEY: 'StudentCard.CommentsPlaceholder',
    Ru:
      'Опишите особенности работы с учеником, результаты пробного занятия и оставляйте комментарии в процессе обучения',
    Eng: 'Describe about students and study process',
    Es:
      'Describir las características de trabajar con el alumno, los resultados de la clase de prueba y dejar comentarios en el proceso de aprendizaje.',
  },
  {
    KEY: 'StudentCard.StudentData',
    Ru: 'Данные об ученике',
    Eng: "Student's info",
    Es: 'Datos del estudiante',
  },
  {
    KEY: 'PromoCode.Checking',
    Ru: 'Секундочку! Проверяем промокод...',
    Eng: 'Checking',
    Es: '¡Un momento! Comprobamos el código promocional ...',
  },
  {
    KEY: 'PromoCode.Success',
    Ru: 'Промокод активирован! Проведите оплату для начисления бонусных занятий',
    Eng: 'Promo code is activated. Top-Up and get a pleasant bonus',
    Es: '¡El código promocional está activado! Realiza el pago para recibir las clases adicionales',
  },
  {
    KEY: 'PromoCode.SuccessAll',
    Ru: 'Промокод подтвержден! На любой миникурс действует скидка {{ discount }}%',
    Eng: 'Promo code is activated. A {{ discount }}% discount is available on any minicourse',
    Es:
      '¡El código promocional está activado! Hay un {{ discount }}%  de descuento en cualquier mini curso',
  },
  {
    KEY: 'PromoCode.SuccessCertain',
    Ru: 'Промокод подтвержден! Курс {{ disCourses }} теперь со скидкой {{ discount }}%',
    Eng:
      'Promo code is activated. The {{ disCourses }} course is now discounted to {{ discount }}%',
    Es:
      '¡El código promocional está activado! Curso {{ disCourses }} ahora con un {{ discount }}% de descuento.',
  },
  {
    KEY: 'PromoCode.SuccessFree',
    Ru: 'Промокод подтвержден! Открыт доступ к курсу {{freeMinicourses}}!',
    Eng: 'Promo code is activated. Access to the {{ freeMinicourses }} course is open',
    Es: 'Ya está abierto el acceso al curso de {{ freeMinicourses }}',
  },
  {
    KEY: 'PromoCode.Fail',
    Ru: 'Неверный промокод. Попробуйте ввести снова',
    Eng: 'Invalid promo code',
    Es: 'El código promocional es incorrecto. Vuelve a introducir',
  },
  {
    KEY: 'PromoCode.Enter',
    Ru: 'Введите промокод',
    Eng: 'Enter promo code',
    Es: 'Introduzca el código promocional',
  },
  {
    KEY: 'PromoCode.PromoText',
    Ru:
      'Если у вас еще нет промокода, то можете отправить его другу \nи получить каждый по 2 занятия в подарок!',
    Eng: 'Share the promo code with your friends and get 2 lessons each as a gift',
    Es: '¡Comparte este código promocional con tu amigo y cada uno recibirá 2 clases más!',
  },
  {
    KEY: 'PromoCode.Get',
    Ru: 'Получить подарок',
    Eng: 'Earn up to 2 lessons',
    Es: 'Recibir el regalo',
  },
  {
    KEY: 'WithNativeSpeaker',
    Ru: 'с носителем',
    Eng: 'with native speaker',
    Es: 'Con el nativo',
  },
  { KEY: 'Class1', Ru: 'занятие', Eng: 'lesson', Es: 'clase' },
  { KEY: 'Class2', Ru: 'занятия', Eng: 'lessons', Es: 'clases' },
  { KEY: 'Class3', Ru: 'занятий', Eng: 'lessons', Es: 'clases' },
  {
    KEY: 'NeedhelpCallUs',
    Ru: 'Нужна помощь? Позвоните нам по номеру',
    Eng: 'Need help? Call us',
    Es: '¿Necesitas ayuda? Llámenos por el número',
  },
  {
    KEY: 'AskManagerContactYou',
    Ru: 'попросите менеджера связаться с вами',
    Eng: 'ask manager to contact you',
    Es: 'pida al gerente llamarle',
  },
  {
    KEY: 'EventSoonTitle',
    Ru: 'Ура-ура! Начинаем занятие!',
    Eng: "Hooray! Let's get this class started!",
    Es: '¡Perfecto! ¡Ya empezamos!',
  },
  {
    KEY: 'EventSoonDescription',
    Ru: 'Преподаватель уже ждёт в классе. Удачи!',
    Eng: 'The teacher is waiting in the class. Good luck!',
    Es: 'El profesor ya te está esperando. ¡Suerte!',
  },
  {
    KEY: 'EventSoonButton',
    Ru: 'Перейти к уроку',
    Eng: 'Open the lesson',
    Es: 'Entrar en la clase',
  },
  {
    KEY: 'NotStudentTitle',
    Ru: 'Изучайте испанский с нами!',
    Eng: 'Learn Spanish with Us!',
    Es: '¡Aprenda español con nosotros!',
  },
  {
    KEY: 'NotStudentDescription',
    Ru: 'Подберем под твои цели программу и преподавателя',
    Eng: "We'll find a teacher and a program to suit your goals",
    Es: 'Escogemos al profesor y el programa según tus objetivos',
  },
  {
    KEY: 'StudentNotPaidFirstTimeTitle',
    Ru: 'Готовы вернуться?',
    Eng: 'Ready to come back?',
    Es: '¿Está listo para volver?',
  },
  {
    KEY: 'StudentNotPaidFirstTimeDesc',
    Ru: 'Поможем продолжить движение к поставленной цели',
    Eng: 'We will help you continue to move towards your goal',
    Es: 'Ayudamos a alcanzar el objetivo',
  },
  { KEY: 'BuyNow', Ru: 'Оплатить', Eng: 'Buy now', Es: 'Pagar' },
  {
    KEY: 'StudentPaidFistTimeTitle',
    Ru: 'На низком старте!',
    Eng: 'About to start!',
    Es: '¡Podemos empezar!',
  },
  {
    KEY: 'StudentPaidFistTimeDescription',
    Ru:
      'Мы уже подбираем преподавателя и совсем скоро назначим урок. Осталось чуть-чуть подождать :)',
    Eng: 'Choosing the best teacher for you. Manager will contact you in 1-2 days',
    Es: 'Estamos escogiendo al mejor profesor. Nuestro gerente llamará muy pronto',
  },
  {
    KEY: 'NextLesson',
    Ru: 'Следующее занятие',
    Eng: 'Next lesson',
    Es: 'La siguiente clase',
  },
  {
    KEY: 'Classes.EmptryPage',
    Ru: 'Создайте для ученика класс или назначьте курс',
    Eng: 'Create a class for the student or assign a course',
    Es: 'Cree la clase para el estudiante y elija el curso',
  },
  { KEY: 'Classes.Classes', Ru: 'Классы', Eng: 'Classes', Es: 'Clases' },
  {
    KEY: 'Classes.GoToLesson',
    Ru: 'Перейти к уроку',
    Eng: 'Open the lesson',
    Es: 'Entrar en la clase',
  },
  {
    KEY: 'Classes.AllClassMaterials',
    Ru: 'Все материалы класса доступны в библиотеке',
    Eng: 'All class materials are available in the library',
    Es: 'Todos los materiales están disponibles en la biblioteca',
  },
  {
    KEY: 'Classes.AllClasses',
    Ru: 'Все классы',
    Eng: 'All classes',
    Es: 'Todos las clases',
  },
  {
    KEY: 'Classes.AvailableCourses',
    Ru: 'Доступные курсы',
    Eng: 'Available courses',
    Es: 'Cursos disponibles',
  },
  {
    KEY: 'Classes.CreateNewClass',
    Ru: 'Создать новый класс',
    Eng: 'Create a new class',
    Es: 'Crear un clase nuevo',
  },
  {
    KEY: 'Classes.CreateNewClassBrief',
    Ru:
      'Добавляйте картинки, аудио, видео, документы, электронный учебник, чтобы сделать урок насыщеннее',
    Eng: 'Add pictures, audio, video, documents, e-textbook to make the lesson more interactive',
    Es:
      'Añada imagenes, audios, vídeos, archivos, libro de texto digital para hacer la clase más intensa',
  },
  {
    KEY: 'Classes.ClasseNameAlreadyExists',
    Ru: 'Такое имя класса уже существует',
    Eng: 'Class title already exists',
    Es: 'La clase con este nombre ya existe',
  },
  {
    KEY: 'Lessons.PastLesson',
    Ru: 'прошедший урок',
    Eng: 'Last lesson',
    Es: 'la clase pasada',
  },
  {
    KEY: 'Lesson.IsCanceled',
    Ru: 'урок отменен из-за отсутствия',
    Eng: 'lesson was canceled due to absence',
    Es: 'la lección fue cancelada debido a la ausencia',
  },
  {
    KEY: 'Lessons.AssignAStudent',
    Ru: 'Назначить ученику',
    Eng: 'Assign this to student',
    Es: 'Fijar al estudiante',
  },
  {
    KEY: 'Lessons.AssignAStudentToCourse',
    Ru: 'Назначить ученика на курс',
    Eng: 'Assign student to the course',
    Es: 'Añadir al esudiante al curso',
  },
  { KEY: 'Lessons.Lessons', Ru: 'Уроков', Eng: 'Lessons', Es: 'Clases' },
  { KEY: 'Course.Lessons', Ru: 'Уроки', Eng: 'Lessons', Es: 'Clases' },
  { KEY: 'Course.Name', Ru: 'Название курса', Eng: 'Сourse name', Es: 'Nombre de curso' },
  {
    KEY: 'Course.Desciption',
    Ru: 'Описание курса',
    Eng: 'Description',
    Es: 'Descripción de curso',
  },
  {
    KEY: 'Toast.GoogleDriveSyncedSuccessfully',
    Ru: 'Google диск успешно синхронизирован',
    Eng: 'Google Drive synced successfully',
    Es: 'Google disco está sincronizado exitosamente',
  },
  {
    KEY: 'Toast.FailedToSyncGoogleDrive',
    Ru: 'Не удалось синхронизировать Google диск',
    Eng: 'Failed to sync Google Drive',
    Es: 'No se ha conseguido sincronizar el Google Drive',
  },
  {
    KEY: 'Toast.FileDeletedSuccessfully',
    Ru: 'Файл успешно удален',
    Eng: 'File deleted successfully!',
    Es: 'Archivo está quitado',
  },
  {
    KEY: 'Toast.FailedToDeleteFile',
    Ru: 'Не удалось удалить файл! :( Попробуйте снова',
    Eng: "The file couldn't be deleted. Please try again",
    Es: 'no se ha logrado eliminar archivo! :( Intente otra vez',
  },
  {
    KEY: 'Toast.FailedToLoadFile',
    Ru: 'Не удалось загрузить файл! :( Попробуйте снова',
    Eng: 'Upload failed. Please try again',
    Es: 'no se ha logrado cargar archivo! :( Intente otra vez',
  },
  {
    KEY: 'Toast.TryToSelectADifferentFile',
    Ru: 'Попробуйте выбрать другой файл:(',
    Eng: 'Please select a vaild file :(',
    Es: 'Intente eligir otro archivo',
  },
  {
    KEY: 'Toast.AddedSuccessfully',
    Ru: '{{type}} - успешно!:)',
    Eng: 'Success! :)',
    Es: '{{type}} - exitosamente',
  },
  {
    KEY: 'Toast.ClassCopiedSuccessfully',
    Ru: 'Класс успешно скопирован',
    Eng: 'Class successfully copied',
    Es: 'La clase está copiada',
  },
  {
    KEY: 'Toast.ClickTheButton',
    Ru: 'Нажмите на кнопку, как будете готовы начать урок',
    Eng: 'Click the button when you are ready to begin the lesson',
    Es: 'Aprete el botón cuando esté listo para empezar la clase',
  },
  {
    KEY: 'Toast.InvalidFileFormat',
    Ru: 'Нажмите на кнопку, как будете готовы начать урок',
    Eng: 'Click the button when you are ready to begin the lesson',
    Es: 'Aprete el botón cuando esté listo para empezar la clase',
  },
  {
    KEY: 'Toast.FailedToCreateClass',
    Ru: 'Не удалось создать класс',
    Eng: 'Unable to create a class',
    Es: 'La clase no está creado.',
  },
  {
    KEY: 'Toast.FailedToDuplicateClass',
    Ru: 'Не удалось дублировать класс',
    Eng: 'Unable to copy the class',
    Es: 'La clase no está copiado.',
  },
  {
    KEY: 'Toast.DataCopied',
    Ru: 'Данные скопированы',
    Eng: 'Data was copied',
    Es: 'Los datos están copiados',
  },
  {
    KEY: 'Toast.ClassSuccessfullyCreated',
    Ru: 'Класс успешно создан',
    Eng: 'Class has been created!',
    Es: 'La clase está creada',
  },
  {
    KEY: 'Toast.ClassSuccessfullyDuplicated',
    Ru: 'Класс успешно дублирован',
    Eng: 'Class has been copied!',
    Es: 'La clase está copiada',
  },
  {
    KEY: 'Toast.PromoCodeCopied',
    Ru: 'Промокод скопирован',
    Eng: 'Promocode was copied',
    Es: 'El código promocional está copiado',
  },
  {
    KEY: 'Toast.GiveNameNewClass',
    Ru: 'Дайте имя новому классу',
    Eng: 'Add title',
    Es: 'Dé el nombre de la clase',
  },
  {
    KEY: 'Toast.BrieflyDescribe',
    Ru: 'Кратко опишите',
    Eng: 'Add description',
    Es: 'Describa en breve',
  },
  {
    KEY: 'LessonHistory.LessonCost',
    Ru: 'Стоимость занятия',
    Eng: 'Pricing',
    Es: 'Precio de la clase',
  },
  {
    KEY: 'ToolTip.WeRecommendUploadPdf',
    Ru: 'Рекомендуем загружать только pdf-файлы, только этот формат отображается на платформе',
    Eng:
      'We recommend uploading only PDF files (only this format can be displayed on the platform)',
    Es:
      'Le recomendamos cargar archivos en formato PDF, solo este formato se soporta en la plataforma',
  },
  {
    KEY: 'ToolTip.OtherFormatsWillDisplay',
    Ru: 'Другие форматы будут отображаться ссылкой и открываться в новом окне',
    Eng: 'Other formats will be displayed as a link and opened in a new window',
    Es: 'Otros formatos estarán representados por un enlace y se abrirá en una ventana nueva',
  },
  {
    KEY: 'Classroom.WriteNotes',
    Ru: 'Пишите заметки вместе с учеником в течение урока',
    Eng: 'Write notes with the student during the lesson',
    Es: 'Apunte con el estudiante durante la clase',
  },
  {
    KEY: 'Classroom.AddPicture',
    Ru: 'Добавить картинку',
    Eng: 'Add image',
    Es: 'Añadir imagen',
  },
  {
    KEY: 'Classroom.AddBoard',
    Ru: 'Добавить доску',
    Eng: 'Add whiteboard',
    Es: 'Añadir pizarra blanca',
  },
  { KEY: 'Classroom.AddVideo', Ru: 'Добавить видео', Eng: 'Add video', Es: 'Añadir vídeo' },
  { KEY: 'Classroom.AddFile', Ru: 'Добавить файл', Eng: 'Add file', Es: 'Añadir archivo' },
  { KEY: 'Classroom.Picture', Ru: 'Картинка', Eng: 'Image', Es: 'Imagen' },
  { KEY: 'Classroom.Document', Ru: 'Документ', Eng: 'Document', Es: 'Documento' },
  { KEY: 'Classroom.Board', Ru: 'Доска', Eng: 'WhiteBoard', Es: 'Pizarra blanca' },
  { KEY: 'Classroom.Audio', Ru: 'Аудио', Eng: 'Audio', Es: 'Audio' },
  { KEY: 'Classroom.Video', Ru: 'Видео', Eng: 'Video', Es: 'Vídeo' },
  {
    KEY: 'LessonNotes',
    Ru: 'Заметки по урокам',
    Eng: 'Lesson notes',
    Es: 'Apuntes de la clase',
  },
  { KEY: 'Homework', Ru: 'Домашнее задание', Eng: 'Homework', Es: 'Deberes' },
  {
    KEY: 'Classroom.EditDescription',
    Ru: 'Редактировать описание',
    Eng: 'Edit description',
    Es: 'Editar la descripción',
  },
  {
    KEY: 'Classroom.StartLesson',
    Ru: 'Начать урок',
    Eng: 'Start lesson',
    Es: 'Empezar la clase',
  },
  {
    KEY: 'Classroom.NoteBookPlaceholdor',
    Ru: 'Пишите заметки вместе с {{user}} в течение урока...',
    Eng: 'Write notes with {{user }} in the lesson',
    Es: 'Apunte junto con {{user}} durante la clase...',
  },
  {
    KEY: 'Classroom.NotesPlaceholder',
    Ru: 'Видны только Вам',
    Eng: 'Only you can see this',
    Es: 'Solo usted puede verlo',
  },
  {
    KEY: 'Classroom.CopyClassLink',
    Ru: 'Копировать ссылку класса',
    Eng: 'Copy Class link',
    Es: 'Copiar el enlace',
  },
  {
    KEY: 'Classroom.DuplicateClass',
    Ru: 'Дублировать класс',
    Eng: 'Duplicate class',
    Es: 'Duplicar la clase',
  },
  {
    KEY: 'Classroom.RemoveClass',
    Ru: 'Удалить класс',
    Eng: 'Remove class',
    Es: 'Eliminar la clase',
  },
  { KEY: 'Classroom.AddLink', Ru: 'Добавить ссылку', Eng: 'Add link', Es: 'Añadir enlace' },
  {
    KEY: 'Classroom.UploadFromComputer',
    Ru: 'Загрузить с компьютера',
    Eng: 'Upload from your computer',
    Es: 'Descargar del ordenador',
  },
  {
    KEY: 'Classroom.SelectFromLibrary',
    Ru: 'Выбрать из библиотеки',
    Eng: 'Select from the library',
    Es: 'Elegir de la biblioteca',
  },
  {
    KEY: 'Classroom.MakeACaptionForTheLesson',
    Ru: 'Сделать заглавной к уроку',
    Eng: 'use this picture as the cover',
    Es: 'Hacer la pagina inicial',
  },
  {
    KEY: 'Classroom.SendTheOutlineToTheStudent',
    Ru: 'Отправить в конспект ученику',
    Eng: "Send notes to the student's book",
    Es: 'Enviar los apuntes al estudiante',
  },
  {
    KEY: 'Classroom.TheNotebookIsCleared',
    Ru: 'Блокнот очистится. Все записи сохранятся в личном кабинете ученика в разделе “Заметки”',
    Eng:
      'The notebook will be cleared. All entries will be saved in the "Notes" section of the student\'s personal profile',
    Es:
      'El bloc de notas estará limpiado. Todos los apuntes se guardarán en la cuenta personal del estudiante en la sección "Apuntes"',
  },
  {
    KEY: 'HomeWork.TeacherEmptyPage',
    Ru:
      'Ученику можно задавать домашки. Для этого нажмите на пункт “Домашнее задание” в дополнительном меню внутри класса',
    Eng:
      'You can assign homework to a student. To do this, click on "Homework" in the additional menu inside the classroom',
    Es:
      'Para dar los deberes al estudiante pulse el botón ¨Deberes¨ en el menú adicional dentro de la clase',
  },
  { KEY: 'HomeWork.Send', Ru: 'Отправить', Eng: 'Send', Es: 'Enviar' },
  { KEY: 'HomeWork.Verify', Ru: 'Проверить', Eng: 'Verify', Es: 'Corregir' },
  {
    KEY: 'HomeWork.PagesCompleted',
    Ru: 'Выполнено страниц',
    Eng: 'Pages completed',
    Es: 'Hecho páginas',
  },
  { KEY: 'HomeWork.Proceed', Ru: 'Приступить', Eng: 'Proceed', Es: 'Empezar' },
  { KEY: 'HomeWork.Continue', Ru: 'Продолжить', Eng: 'Continue', Es: 'Continuar' },
  { KEY: 'HomeWork.Look', Ru: 'Посмотреть', Eng: 'Look', Es: 'Mirar' },
  { KEY: 'HomeWork.Done', Ru: 'выполнено', Eng: 'done', Es: 'Hecho' },
  {
    KEY: 'HomeWork.StartWritingHere',
    Ru: 'Начните писать здесь',
    Eng: 'Start writing here',
    Es: 'Empiece a escribir por aquí',
  },
  { KEY: 'HomeWork.ForChecking', Ru: 'На проверку', Eng: 'Hand it in', Es: 'A comprobar' },
  { KEY: 'HomeWork.Made', Ru: 'Сделано', Eng: 'Done', Es: 'Hecho' },
  {
    KEY: 'HomeWork.EnterJobDescription',
    Ru: 'Введите описание задания',
    Eng: 'Add task description',
    Es: 'Escriba la descripción de la tarea',
  },
  { KEY: 'HomeWork.Exercise', Ru: 'Задание', Eng: 'Exercise', Es: 'Tarea' },
  { KEY: 'HomeWork.Composition', Ru: 'Сочинение', Eng: 'Essay', Es: 'Ensayo' },
  {
    KEY: 'HomeWork.ClickWhenYouCompleteTheTask',
    Ru: 'Нажмите, когда выполните задание',
    Eng: 'Click when you complete the task',
    Es: 'Haga click al terminar la tarea',
  },
  { KEY: 'LEVELS.A1', Ru: 'Элементарный', Eng: 'Elementary', Es: 'Nivel elemental' },
  { KEY: 'LEVELS.A2', Ru: 'Начинающий', Eng: 'Beginner', Es: 'Nivel preintermedio' },
  { KEY: 'LEVELS.B1', Ru: 'Средний', Eng: 'Intermediate', Es: 'Nivel intermedio' },
  { KEY: 'LEVELS.B2', Ru: 'Продвинутый', Eng: 'Advanced', Es: 'Nivel intermedio-superior' },
  { KEY: 'LEVELS.C1', Ru: 'Высший', Eng: 'Proficiency', Es: 'Nivel avanzado' },
  { KEY: 'LEVELS.C2', Ru: 'Носитель', Eng: 'Native', Es: 'Nivel superior' },
  { KEY: 'MondayShort', Ru: 'пн', Eng: 'Mon', Es: 'Lun.' },
  { KEY: 'TuesdayShort', Ru: 'вт', Eng: 'Tue', Es: 'Mar.' },
  { KEY: 'WednesdayShort', Ru: 'ср', Eng: 'Wed', Es: 'Mier.' },
  { KEY: 'ThursdayShort', Ru: 'чт', Eng: 'Thu', Es: 'Juev.' },
  { KEY: 'FridayShort', Ru: 'пт', Eng: 'Fri', Es: 'Vier.' },
  { KEY: 'SaturdayShort', Ru: 'сб', Eng: 'Sat', Es: 'Sab.' },
  { KEY: 'SundayShort', Ru: 'вс', Eng: 'Sun', Es: 'Dom.' },
  { KEY: 'Goals.ToStudy', Ru: 'Для учебы', Eng: 'For studying', Es: 'Para los estudios' },
  {
    KEY: 'Goals.ToTravel',
    Ru: 'Для путешествий',
    Eng: 'For travel',
    Es: 'Para los viajes',
  },
  { KEY: 'Goals.ToWork', Ru: 'Для работы', Eng: 'For work', Es: 'Para el trabajo' },
  {
    KEY: 'Goals.ToMove',
    Ru: 'Для переезда',
    Eng: 'For the relocation',
    Es: 'Para mudarse',
  },
  {
    KEY: 'Goals.ForExam',
    Ru: 'Подготовка к экзамену',
    Eng: 'Exam preparation',
    Es: 'Prepararse para el examen',
  },
  {
    KEY: 'Goals.JustLike',
    Ru: 'Просто нравится язык',
    Eng: 'I just like the language',
    Es: 'Simplemente me gusta el idioma',
  },
  { KEY: 'Interests.Family', Ru: 'Семья', Eng: 'Family', Es: 'Familia' },
  { KEY: 'Interests.Sport', Ru: 'Спорт', Eng: 'Sports', Es: 'Deportes' },
  { KEY: 'Interests.Nature', Ru: 'Природа', Eng: 'Nature', Es: 'Naturaleza' },
  { KEY: 'Interests.Art', Ru: 'Искусство', Eng: 'Art', Es: 'Arte' },
  { KEY: 'Interests.Food', Ru: 'Еда', Eng: 'Food', Es: 'Comida' },
  { KEY: 'Interests.Travel', Ru: 'Путешествия', Eng: 'Travel', Es: 'Viajes' },
  {
    KEY: 'Interests.Movies',
    Ru: 'Кино, сериалы',
    Eng: 'Movies, TV series',
    Es: 'Cine, series',
  },
  { KEY: 'Interests.History', Ru: 'История', Eng: 'History', Es: 'Historia' },
  { KEY: 'Interests.Game', Ru: 'Игры', Eng: 'Games', Es: 'Juegos' },
  { KEY: 'Interests.Music', Ru: 'Музыка', Eng: 'Music', Es: 'Música' },
  { KEY: 'Interests.Photo', Ru: 'Фотография', Eng: 'Photo', Es: 'Fotografía' },
  { KEY: 'Interests.Yoga', Ru: 'Йога, здоровье', Eng: 'Yoga, Health', Es: 'Yoga, salud' },
  { KEY: 'Interests.Literature', Ru: 'Литература', Eng: 'Literature', Es: 'Literatura' },
  { KEY: 'Interests.Technology', Ru: 'Технология', Eng: 'Technology', Es: 'Tecnología' },
  { KEY: 'Interests.Business', Ru: 'Бизнес', Eng: 'Business', Es: 'Negocios' },
  { KEY: 'Interests.Politics', Ru: 'Политика', Eng: 'Politics', Es: 'Política' },
  {
    KEY: 'Interests.Fashion',
    Ru: 'Мода, шопинг',
    Eng: 'Fashion, Shopping',
    Es: 'Moda, compras',
  },
  {
    KEY: 'ShareAdvantages1',
    Ru: '1. Поделись промо - кодом с друзьями',
    Eng: '1. Share promo code with friends',
    Es: '1. Comparta el promo código con sus amigos',
  },
  {
    KEY: 'ShareAdvantages2',
    Ru: '2. При первой оплате друг указывает твой промокод',
    Eng: '2. Your friend gives your promocode at first payment',
    Es: '2. Su amigo muestra su promo código al realizar el primer pago',
  },
  {
    KEY: 'ShareAdvantages3',
    Ru: '3. Оба получаете по 2 урока на счёт в подарок!',
    Eng: '3. You get 2 lessons for free!',
    Es: '3. ¡Ambos reciben 2 clases gratis!',
  },
  {
    KEY: 'PackagesRussianTitle',
    Ru: 'Русскоязычный преподаватель',
    Eng: 'Teacher',
    Es: 'Profesor ruso',
  },
  {
    KEY: 'PackagesRussianDescription',
    Ru:
      'Эффективность индивидуальных занятий выше, чем у групповых. Начни учить испанский индивидуально, если хотите заговорить быстрее.',
    Eng:
      'Individual classes are more effective than group classes. Start learning Spanish individually if you want to speak faster.',
    Es:
      'Las clases individuales son más eficaces que las clases en grupo. Empiece a aprender español de forma individual si quiere hablar más rápido',
  },
  {
    KEY: 'PackagesFamilyTitle',
    Ru: 'Пакет “Семейный”',
    Eng: 'Family Package',
    Es: 'Paquete familiar',
  },
  {
    KEY: 'PackagesFamilyDescription',
    Ru:
      'Пакет «семейный» подходит для пар, друзей, братьев и сестер. Учите испанский вместе и забудьте о поиске собеседника.',
    Eng:
      'The Family Package is great for couples, friends, and siblings. Learn Spanish together and forget about searching for a conversation partner.',
    Es:
      'El paquete familiar es ideal para parejas, amigos y hermanos. Aprendan español juntos y olviden de la búscqueda del compañero de conversación.',
  },
  {
    KEY: 'PackagesIspanicTitle',
    Ru: 'Испаноговорящий преподаватель',
    Eng: 'Spanish-speaking teacher',
    Es: 'Profesor hispanohablante',
  },
  {
    KEY: 'PackagesIspanicDescription',
    Ru:
      'Выбрав занятия с носителем, заговори на испанском с первого урока. Курс испанского с носителем — это стремительный прогресс и быстрые результаты без промедлений.',
    Eng:
      "If you choose a Spanish lessons with a native speaker, you'll start speaking Spanish from the first lesson. A Spanish course with a native speaker means rapid progress and fast results without delay.",
    Es:
      'Si elige las clases de español con un nativo, hablará español desde la primera clase. El curso de español con el nativo es un progreso rápido y resultados inmediatos sin demora',
  },
  { KEY: 'MyClasses', Ru: 'Мои уроки', Eng: 'My lessons', Es: 'Mis clases' },
  {
    KEY: 'AvailableClasses',
    Ru: 'Доступные классы',
    Eng: 'Available classes',
    Es: 'Clases disponibles',
  },
  { KEY: 'BuyLessons', Ru: 'Купить уроки', Eng: 'Buy Lessons', Es: 'Comprar clases' },
  { KEY: 'Dictionary', Ru: 'Словарь', Eng: 'Dictionary', Es: 'Diccionario' },
  { KEY: 'Personal', Ru: 'Личный кабинет', Eng: 'Dashboard', Es: 'Cuenta personal' },
  {
    KEY: 'CourseA1',
    Ru: 'Основной курс для начинающих',
    Eng: 'Beginners Basic Course',
    Es: 'El curso básico para principiantes',
  },
  {
    KEY: 'SearchingResults',
    Ru: 'Результаты поиска',
    Eng: 'Search result',
    Es: 'Resultados de búsqueda',
  },
  {
    KEY: 'EnterWordToTranslate',
    Ru: 'Введите слово для перевода',
    Eng: 'Type a word for translation',
    Es: 'Introduzca la palabra para traducir',
  },
  { KEY: 'Reset', Ru: 'Сбросить', Eng: 'Reset', Es: 'Restablecer' },
  {
    KEY: 'Vocabulary.HowToMemorizeWord',
    Ru: 'Как запоминать иностранные слова?',
    Eng: 'How to memorize foreign words?',
    Es: '¿Cómo memorizar palabras extranjeras?',
  },
  {
    KEY: 'Vocabulary.TranslateAndAddWords',
    Ru: 'Переводи и добавляй слова во время урока на платформе',
    Eng: 'Translate and add words during a lesson on the platform',
    Es: 'Traduce y añade palabras durante la clase en la plataforma',
  },
  { KEY: 'Copy', Ru: 'Скопировать', Eng: 'Copy', Es: 'Copiar' },
  { KEY: 'NoAccess', Ru: 'Нет доступа', Eng: 'No access', Es: 'sin acceso' },
  { KEY: 'MSK', Ru: 'мск', Eng: 'MSK', Es: 'MSK' },
  { KEY: 'In', Ru: 'в', Eng: 'at', Es: 'en' },
  {
    KEY: 'Homework.EmptyPage',
    Ru: `Домашние задания появятся после начала обучения в школе ${appName}`,
    Eng: `Homeworks will appear after you start studying at ${appName}`,
    Es: `Deberes aparecerán después de empezar a estudiar en ${appName}`,
  },
  { KEY: 'Triallesson', Ru: 'Пробный урок', Eng: 'Trial Lesson', Es: 'Clase de prueba' },
  { KEY: 'RecordAutio', Ru: 'Записать аудио', Eng: 'Record audio', Es: 'Grabar audio' },
  { KEY: 'Classroom.AddAudio', Ru: 'Добавить аудио', Eng: 'Add audio', Es: 'Añadir audio' },
  {
    KEY: 'DownloadFile',
    Ru: 'Скачать файл',
    Eng: 'Download file',
    Es: 'Descargar archivo',
  },
  {
    KEY: 'TryDeleteFile',
    Ru: 'Попробуйте удалить файл. И загрузить новый.',
    Eng: 'Try to delete the file and load the new one',
    Es: 'Trate de eliminar el archivo y cargar el otro',
  },
  {
    KEY: 'InvalidFileFormat',
    Ru: 'Неправильный формат файла. Выберите файл с',
    Eng: 'Incorrect file format. Select a file with an image',
    Es: 'Formato de archivo incorrecto. Seleccione el archivo con una imagen',
  },
  { KEY: 'FormatImage', Ru: 'изображением', Eng: 'image', Es: 'imagen' },
  {
    KEY: 'FileIsTooHeavy',
    Ru: "Файл слишком тяжелый :('",
    Eng: 'File is too large',
    Es: 'Archivo es muy pesado',
  },
  {
    KEY: 'SelectFile',
    Ru: 'Выберите файл',
    Eng: 'Select File',
    Es: 'Seleccione un archivo',
  },
  { KEY: 'NoMore', Ru: 'не более', Eng: 'no more than', Es: 'no más que' },
  { KEY: 'MB', Ru: 'мб', Eng: 'mb', Es: 'mb' },
  {
    KEY: 'NoFileSelected',
    Ru: 'Файл не выбран',
    Eng: 'File is not selected',
    Es: 'Archivo no está seleccionado',
  },
  {
    KEY: 'ConvertYourFile',
    Ru: 'Конвертируйте ваш файл',
    Eng: 'Convert your file to PDF',
    Es: 'Convierta su archivo en PDF',
  },
  { KEY: 'InPdfFormat', Ru: 'в pdf формат', Eng: 'in pdf format', Es: 'in pdf format' },
  { KEY: 'WithTeacher', Ru: 'учителем', Eng: 'a teacher', Es: 'un profesor' },
  { KEY: 'WithStudent', Ru: 'учеником', Eng: 'a student', Es: 'un estudiante' },
  {
    KEY: 'SuccessProfileUpdate',
    Ru: 'Профиль успешно сохранен',
    Eng: 'Profile has been updated successfully',
    Es: 'Perfil ha sido actualisado exitosamente',
  },
  {
    KEY: 'FailedProfileUpdate',
    Ru: 'Не удалось обновить профиль',
    Eng: 'Profile update failed',
    Es: 'Error de actualización de perfil',
  },
  {
    KEY: 'NotesSaved',
    Ru: 'Заметки сохранены',
    Eng: 'Notes saved',
    Es: 'Apuntes están guardados',
  },
  {
    KEY: 'FailedNotesSave',
    Ru: 'Не удалось сохранить заметки',
    Eng: 'Failed to save notes',
    Es: 'no se ha logrado guardar apuntes',
  },
  {
    KEY: 'RequestTransferLlessonSent',
    Ru: 'Запрос на перенос занятия отправлен менеджеру',
    Eng: 'Request to reschedule the class time was sent to the manager',
    Es: 'Solicitud para reprogramar la hora de clase fue enviada al manager',
  },
  {
    KEY: 'FailedToSent',
    Ru: 'Не удалось отправить',
    Eng: 'Failed to send',
    Es: 'No se ha logrado enviar',
  },
  {
    KEY: 'HomeworkSubmitted',
    Ru: 'Домашнее задание успешно отправлено на проверку',
    Eng: 'Homework sent successfully for review',
    Es: 'Deberes han sido enviados exitosamente',
  },
  {
    KEY: 'HomeworkSuccessfullySaved',
    Ru: 'Домашнее задание успешно сохранено',
    Eng: 'Homework was successfully saved',
    Es: 'Deberes han sido guardados exitosamente',
  },
  {
    KEY: 'LoginPersonalToAccount',
    Ru: 'Вход в личный кабинет',
    Eng: 'Login to your personal account',
    Es: 'Inicio de sesión en su cuenta personal',
  },
  { KEY: 'Password', Ru: 'Пароль', Eng: 'Password', Es: 'Contraseña' },
  {
    KEY: 'ForgotPassword',
    Ru: 'Забыли пароль',
    Eng: 'Forgot Password',
    Es: 'Ha olvidado la contraseña',
  },
  { KEY: 'IamStudent', Ru: 'Я - студент', Eng: 'I am Student', Es: 'Soy estudiante' },
  { KEY: 'IamManager', Ru: 'Я - менеджер', Eng: 'I am Manager', Es: 'Soy manager' },
  { KEY: 'IamTeacher', Ru: 'Я - учитель', Eng: 'I am Teacher', Es: 'Soy profesor' },
  { KEY: 'Chat', Ru: 'Чат', Eng: 'Chat', Es: 'Chat' },
  {
    KEY: 'MinimizeVideo',
    Ru: 'Уменьшить видео',
    Eng: 'Minimize the video',
    Es: 'Desminuir el vídeo',
  },
  {
    KEY: 'MakeVideoFullScreen',
    Ru: 'На весь экран',
    Eng: 'Make the video full screen',
    Es: 'poner el vídeo en pantalla completa',
  },
  { KEY: 'Unmute', Ru: 'Включить звук', Eng: 'Unmute', Es: 'activar' },
  { KEY: 'Mute', Ru: 'Отключить звук', Eng: 'Mute', Es: 'silenciar' },
  {
    KEY: 'CameraOn',
    Ru: 'Включить камеру',
    Eng: 'Switch the camera on',
    Es: 'Encender la cámara',
  },
  {
    KEY: 'CameraOff',
    Ru: 'Выключить камеру',
    Eng: 'Switch the camera off',
    Es: 'Apagar la cámara',
  },
  {
    KEY: 'TooltipStartLessonButton',
    Ru:
      'При нажатии кнопки у ученика снимается 1 урок.\n                  Если вы нажали кнопку случайно или если урок не\n                   отображается в Истории уроков, пожалуйста свяжитесь\n                   с технической поддержкой.            ',
    Eng:
      'The moment the button is pressed, 1 lesson is taken away\nfrom the student. If you have pressed the button by accident\nor if a lesson does not appear in the Lesson History, please\ncontact Technical Support.',
    Es:
      'Al presionar el botón, se elimina una clase al estudiante. Si Usted ha presionado el botón por error o si la clase no aparece en la historia de las clases, por favor, contacte con el soporte técnico',
  },
  {
    KEY: 'StartLesson',
    Ru: 'Начать урок',
    Eng: 'Start the lesson',
    Es: 'Iniciar la clase',
  },
  {
    KEY: 'TeacherIsNotes',
    Ru: 'Заметки учителя',
    Eng: "Teacher's notes",
    Es: 'Apuntes del profesor',
  },
  {
    KEY: 'WillJoinClass',
    Ru: 'скоро присоединится к уроку',
    Eng: 'will soon join the class',
    Es: 'Pronto se une al clase',
  },
  {
    KEY: 'GiftOfTwoLessons',
    Ru: 'Подарок 2 урока',
    Eng: '2 gift lessons',
    Es: '2 clases regaladas',
  },
  {
    KEY: 'LangInterface',
    Ru: 'Язык интерфейса',
    Eng: 'Interface language',
    Es: 'el idioma de interfaz',
  },
  { KEY: 'Checked', Ru: 'Проверено', Eng: 'Checked', Es: 'corregido' },
  { KEY: 'Sent', Ru: 'Отправлено', Eng: 'Sent', Es: 'enviado' },
  { KEY: 'NotPerformed', Ru: 'Не выполнено', Eng: 'Not done', Es: 'no está hecho' },
  {
    KEY: 'PromlemsWithYourConnection',
    Ru: 'Проблемы с соединением',
    Eng: 'Problems with your connection?',
    Es: 'problemas con la conección',
  },
  {
    KEY: 'CheckYourNet',
    Ru: 'Проверь соединение еще раз и попробуйте с учителем перезагрузить страницу',
    Eng: 'Check your internet connection and try to',
    Es: 'verifique su conección de Internet y trate de',
  },
  {
    KEY: 'ReloadPage',
    Ru: 'Перезагрузить страницу',
    Eng: 'Reload the page.',
    Es: 'volver a cargar la página',
  },
  {
    KEY: 'ConfirmYourBrowser',
    Ru:
      'Убедись, что этот браузер обнолен до последней версии. Мы всегда\n            рекомендуем использовать Google Chrome',
    Eng: 'Confirm your browser is updated to the latest version. We always\nrecommended',
    Es:
      'asegúrese de que su navegador esté actualizado hasta la última versión. Siempre recomendamos usar Google Chrome',
  },
  {
    KEY: 'ForTheBestView',
    Ru: 'для лучшей работы',
    Eng: 'for the best view',
    Es: 'para un funcionamiento mejor',
  },
  {
    KEY: 'ReturnToYourLesson',
    Ru: 'Вернуться к вашему уроку',
    Eng: 'Return to your lesson',
    Es: 'Regresar a su clase',
  },
  { KEY: 'Contacts', Ru: 'Контакты', Eng: 'Contacts', Es: 'Contactos' },
  {
    KEY: 'TrialLesson2',
    Ru: 'пробное занятие',
    Eng: 'trial lesson',
    Es: 'clase de prueba',
  },
  { KEY: 'OnceLesson', Ru: 'разовое занятие', Eng: 'one-time lesson', Es: 'clase única' },
  {
    KEY: 'RegularLesson',
    Ru: 'регулярное занятие',
    Eng: 'regular lesson',
    Es: 'clase regular',
  },
  { KEY: 'Child', Ru: 'Ребёнок', Eng: 'Child', Es: 'Niño' },
  { KEY: 'Couple', Ru: 'Пара', Eng: 'Couple', Es: 'Pareja' },
  {
    KEY: 'SelectTimeZoneTitle',
    Ru: 'Выбери, пожалуйста, часовой пояс',
    Eng: 'Select a time zone',
    Es: 'Seleccione una zona horaria',
  },
  {
    KEY: 'SelectTimeZoneDesc',
    Ru: 'Эта информация нужна для того, чтобы отображать расписание по местному\n        времени',
    Eng: 'This information is needed to display the schedule by local time',
    Es: 'Esta información es necesaria para mostrar el horario por hora local',
  },
  {
    KEY: 'GiveFriendLessons',
    Ru: 'Поделись промокодом с друзьями и получи 2 урока бесплатно',
    Eng: 'Share the promo code with your friends and get 2 lessons for free',
    Es: 'Regala las clases a un amigo y recibe 2 clases como regalo',
  },
  {
    KEY: 'StudentNotFound',
    Ru: 'Студент не найден!',
    Eng: 'Student not found!',
    Es: 'El estudiante no se ha sido encontrado',
  },
  {
    KEY: 'ManagerNotFound',
    Ru: 'Менеджер не найден!',
    Eng: 'Manager not found!',
    Es: 'El gerente no se ha sido encontrado',
  },
  {
    KEY: 'TeacherNotFound',
    Ru: 'Учитель не найден!',
    Eng: 'Teacher not found!',
    Es: 'El profesor no se ha sido encontrado',
  },
  {
    KEY: 'PasswordWrong',
    Ru: 'Неверный логин или пароль.',
    Eng: 'Incorrect login or password.',
    Es: 'No son correctos los datos',
  },
  {
    KEY: 'TooManyRequests',
    Ru: 'Превышено число попыток ввода пароля. Попробуйте войти в аккаунт заново через пару часов',
    Eng:
      'The number of password entry attempts has been exceeded. Try logging in again in a few hours',
    Es:
      'Se ha superado el número de intentos de introducción de contraseña. Intente iniciar sesión nuevamente en un par de horas.',
  },
  {
    KEY: 'FieldMustBeFilled',
    Ru: 'Все поля должны быть заполнены!',
    Eng: 'all required fields must be filled out.',
    Es: 'Todos los cuadros deben estar llenos!',
  },
  {
    KEY: 'UnknownError',
    Ru: 'Неизвестная ошибка, попробуйте позже.',
    Eng: 'Unknown error, try again later.',
    Es: 'Un error desconocido, inténtelo más tarde.',
  },
  {
    KEY: 'UserIsDisabled',
    Ru: 'Пользователь отключен.',
    Eng: 'The user is logged out.',
    Es: 'El usuario está desconectado.',
  },
  {
    KEY: 'InvalidEmailFormat',
    Ru: 'Неверный формат email!',
    Eng: 'Invalid email format!',
    Es: 'El formato de email no es correcto',
  },
  { KEY: 'LoadMore', Ru: 'Загрузить еще', Eng: 'Load more', Es: 'Carga más' },
  {
    KEY: 'ProblemWithConnection3Paragraph',
    Ru: 'Используйте скайп или зум, если возникнут проблемы с видео связью',
    Eng:
      'If you still have any problem with your connection, you can use another communication tool, e.g. Skype or Zoom.',
    Es:
      'If you still have any problem with your connection, you can use another communication tool, e.g. Skype or Zoom.',
  },
  {
    KEY: 'TooltipTurnVideoConnection',
    Ru: 'При нажатии кнопки видео звонок остановится и экран для видео станет меньше',
    Eng:
      'Click if you are calling somewhere else, e.g. Skype or Zoom. \nThe connection will be broken here, the board will get bigger.',
    Es:
      'Click if you are calling somewhere else, e.g. Skype or Zoom. \nThe connection will be broken here, the board will get bigger.',
  },
  {
    KEY: 'MiniCourses.DescriptionTitle',
    Ru: `Практикамос от ${appName}`,
    Eng: `Practicamos by ${appName}`,
    Es: `Practicamos ${appName}`,
  },
  {
    KEY: 'MiniCourses.DescriptionText',
    Ru:
      'Изучай испанский самостоятельно! Выбирай курс и проходи его, когда тебе будет удобно. Интересные темы, полезные упражнения, новые слова — скучно не будет, обещаем :)',
    Eng:
      "Learn Spanish on your own! Choose a course and take it when it suits you. Interesting topics, useful exercises, new words - you won't be bored, we promise :)",
    Es:
      '¡Aprende español por tu cuenta! Elige un curso y hágalo cuando te convenga. Temas interesantes, ejercicios útiles, nuevas palabras: no te aburrirás, te lo prometemos :)',
  },
  { KEY: 'MiniCourses.Free', Ru: 'Бесплатно', Eng: 'Free', Es: 'Gratis' },
  { KEY: 'MiniCourses.Passed', Ru: 'Пройдено', Eng: 'Completed', Es: 'Aprobado' },
  { KEY: 'MiniCourses.Duration', Ru: 'Длительность', Eng: 'Duration', Es: 'Duración' },
  { KEY: 'MiniCourses.Author', Ru: 'Автор', Eng: 'Author', Es: 'Autor' },
  {
    KEY: 'MiniCourses.BenifitsTitle',
    Ru: 'Чему ты научишься',
    Eng: 'What you will learn',
    Es: 'Lo que aprenderás',
  },
  {
    KEY: 'MiniCourses.SourcesTitle',
    Ru: 'Что входит в стоимость',
    Eng: 'What is included',
    Es: 'El precio incluye',
  },
  {
    KEY: 'MiniCourses.CourseProgram',
    Ru: 'Программа курса',
    Eng: 'Course programme',
    Es: 'Programa del curso',
  },
  {
    KEY: 'MiniCourses.BuyModalTitle',
    Ru: 'Ну что, начнем покорять испанский?',
    Eng: "Let's learn Spanish",
    Es: '¿Pues, empecemos a conquistar el español?',
  },
  {
    KEY: 'MiniCourses.BuyModalDescription',
    Ru:
      'Вы можете приобрести курс, чтобы получить доступ ко всем занятиям и прокачать свой испанский :)',
    Eng: 'You can buy a course to access all the lessons and to improve your Spanish :)',
    Es: 'Puedes comprar el curso para acceder a todas las clases y mejorar tu español',
  },
  {
    KEY: 'MiniCourses.BuyButton',
    Ru: 'Купить курс за',
    Eng: 'Buy the course for',
    Es: 'Comprar el curso por',
  },
  { KEY: 'MiniCourses.Currency', Ru: 'руб.', Eng: 'rub.', Es: 'rub.' },
  {
    KEY: 'MiniCourses.PassedCourse',
    Ru: 'Курс пройден',
    Eng: 'Course completed',
    Es: 'Curso aprobado',
  },
  {
    KEY: 'MiniCourses.Practikomos',
    Ru: 'Практикамос',
    Eng: 'Practicamos',
    Es: 'Practicamos',
  },
  { KEY: 'MiniCourses.Hours', Ru: 'ч.', Eng: 'h', Es: 'h' },
  { KEY: 'Login', Ru: 'Вход', Eng: 'Login', Es: 'Iniciar sesión' },
  { KEY: 'Delete', Ru: 'Удалить', Eng: 'Delete', Es: 'Eliminar' },
  { KEY: 'Classes.Delete', Ru: 'Удалить класс', Eng: 'Delete class', Es: 'Eliminar clase' },
  { KEY: 'Tests.Delete', Ru: 'Удалить тест', Eng: 'Delete test', Es: 'Eliminar prueba' },
  {
    KEY: 'Classes.DeleteMessage',
    Ru: 'Этот класс больше не будет отображаться в списке ваших классов.',
    Eng: 'This class will no longer appear in your class list.',
    Es: 'Esta clase ya no aparecerá en tu lista de clases.',
  },
  {
    KEY: 'Tests.DeleteMessage',
    Ru: 'Этот тест больше не будет отображаться в списке ваших тестов.',
    Eng: 'This test will no longer appear in your test list.',
    Es: 'Esta prueba ya no aparecerá en su lista de pruebas.',
  },
  {
    KEY: 'Tests.Time',
    Ru: 'мин',
    Eng: 'mins',
    Es: 'actas',
  },
  {
    KEY: 'Tests.Pass',
    Ru: 'Пройти',
    Eng: 'Pass',
    Es: 'Pase',
  },
  { KEY: 'MiniCourses.CurrencyUSD', Ru: '$', Eng: '$', Es: '$' },
  { KEY: 'MiniCourses.CurrencyEUR', Ru: 'евро', Eng: 'eur.', Es: '€' },
  { KEY: 'MiniCourses.CurrencyRUB', Ru: 'руб', Eng: 'rub.', Es: 'rub.' },
  { KEY: 'MiniCourses.CurrencyKZT', Ru: 'тенге', Eng: 'tenge', Es: 'tenge' },
  { KEY: 'Chat.SearchMessages', Ru: 'Поиск', Eng: 'Search messages', Es: 'Buscar' },
  { KEY: 'Chat.Message', Ru: 'Сообщения', Eng: 'Message', Es: 'Mensajes' },
  { KEY: 'Chat.NotMessage', Ru: 'Сообщений нет', Eng: 'Empty', Es: 'No hay mensajes.' },
  {
    KEY: 'Chat.NotSelected',
    Ru: 'Чат не выбран',
    Eng: 'Chat not selected',
    Es: 'No se ha seleccionado ningún chat',
  },
  { KEY: 'Chat.Yesterday', Ru: 'Вчера', Eng: 'Yesterday', Es: 'Ayer' },
  { KEY: 'Chat.Today', Ru: 'Сегодня', Eng: 'Today', Es: 'Hoy' },
  {
    KEY: 'PageNotFound',
    Ru:
      'Мы не можем открыть эту страницу. Кто-то ее блокирует. Попробуйте VPN или сменить оператора связи',
    Eng: 'We cannot open this page. Someone is blocking her. Try a VPN or change carrier',
    Es:
      'No podemos abrir esta página. Alguien la está bloqueando. Prueba una VPN o cambia de operador',
  },
  {
    KEY: 'ToHomePage',
    Ru: 'Вернуться на главную',
    Eng: 'Go back to the main page',
    Es: 'Regresa a la página principal',
  },
  {
    KEY: 'CourseProgressTooltip',
    Ru: 'Через {{lessonnumber}} уроков',
    Eng: 'After {{lessonnumber}} lessons',
    Es: 'A travez de las {{lessonnumber}} сlases',
  },
  {
    KEY: 'CourseProgress.native',
    Ru: 'Урок с носителем',
    Eng: 'Lesson with native speaker',
    Es: 'La clase con un hablante nativo',
  },
  {
    KEY: 'CourseProgressPassedLessons',
    Ru: 'Пройдено {{passedLessonsCount}} из {{lessonsCount}} уроков',
    Eng: 'Lessons passed {{passedLessonsCount}} of {{lessonsCount}} lessons',
    Es: 'has tenido {{passedLessonsCount}} сlases de {{lessonsCount}}',
  },
  {
    KEY: 'CourseProgress.music',
    Ru: 'Музыкальный урок',
    Eng: 'Musical lesson',
    Es: 'La clase musical',
  },
  {
    KEY: 'CourseProgress.probation',
    Ru: 'Урок с учителем пробного языка',
    Eng: 'Lesson with a teacher from a trial',
    Es: 'la clase con la profe de la clase de prueba',
  },
  {
    KEY: 'CourseProgress.grammar',
    Ru: 'Грамматический урок',
    Eng: 'Grammar lesson',
    Es: 'la clase de gramática',
  },
  {
    KEY: 'Tooltip.StudentEventsTime',
    Ru: 'Не можешь быть на этом уроке? Нажми, чтобы перенести занятие.',
    Eng: "Can't make it? Click to reschedule this lesson.",
    Es: 'No puedes estar en esta clase? Pulsa aquí para posponer la clase.',
  },
  {
    KEY: 'Tooltip.Reschedule',
    Ru:
      'Перенести или отменить занятие можно не позднее, чем за 8 часов до его начала. В месяц можно перенести или отменить не более 4 занятий.',
    Eng:
      'You may reschedule or cancel a class up to 8 hours before it starts. No more than 4 classes can be rescheduled or canceled per month.',
    Es:
      'Posponer o cancelar la clase se puede pero con 8 horas antes de su inicio. No se podrán reprogramar o cancelar más de 4 clases por mes.',
  },
  {
    KEY: 'Tooltip.CourseIsnotAllowed',
    Ru: 'Не торопись, amigo! Сначала нужно пройти этот урок с учителем.',
    Eng: "Don't hurry up, amigo! First, you need to pass this lesson with a teacher.",
    Es: 'No te apresures, amigo. Primero tienes que pasar esta clase con tu profe',
  },
  {
    KEY: 'FirstLoginStudentDescription',
    Ru: 'Наш менеджер скоро позвонит, чтобы подобрать время для первого урока',
    Eng: 'Our manager will call to schedule a time for the first lesson as soon as possible',
    Es: 'Nuestro manager llamará pronto para programar tu primera clase',
  },
  {
    KEY: 'StudentPaidnotFistTimeDescription',
    Ru: 'Как твои успехи в изучении испанского? Не забудь назначить дату следующего урока. :)',
    Eng: "How is your progress in learning Spanish? Don't forget to schedule the next lesson. :)",
    Es:
      '¿Cuál es tu progreso en el aprendizaje del español? No olvides programar la próxima clase:)',
  },
  {
    KEY: 'StudentNotPaidSecondTimeTitle',
    Ru: 'Изучай испанский с нами!',
    Eng: 'Learn Spanish with us!',
    Es: '¡Aprende español con nosotros!',
  },
  {
    KEY: 'StudentNotSecondFirstTimeDesc',
    Ru: 'Подберем под твои цели программу и преподавателя',
    Eng: 'We will find your individual program and teacher for you',
    Es: 'Escogeremos un programa individual y un profe para tus objetivos',
  },
  { KEY: 'UpdateData', Ru: 'Обновить данные', Eng: 'Update data', Es: 'Actualizar datos' },
  {
    KEY: 'StudentDeleteMessage',
    Ru: 'Удалить эту заметку?',
    Eng: 'Do you want to delete this note?',
    Es: 'Eliminar esta nota?',
  },
  {
    KEY: 'StudentDeleteMessageInfo',
    Ru: 'Ты можешь удалить заметки, которые тебе не нужны',
    Eng: "You can delete notes you don't need",
    Es: 'Puedes eliminar las notas inútiles',
  },
  { KEY: 'StudentDeleteMessageActionButton', Ru: 'Удалить', Eng: 'Delete', Es: 'Eliminar' },
  { KEY: 'StudentDeleteMessageCancelButton', Ru: 'Отмена', Eng: 'Cancel', Es: 'Cancelar' },
  {
    KEY: 'UpdateCalender',
    Ru: 'Обновить',
    Eng: 'Update Calendar',
    Es: 'Actualizar calendario',
  },
  {
    KEY: 'ConnectionFailed',
    Ru: 'Пропала связь с интернетом. Проверьте настройки подключения к сети.',
    Eng: 'There is no Internet connection. Check the network connection settings.',
    Es: 'Pérdida de conexión a Internet. Verifique la configuración de su conexión de red.',
  },
  {
    KEY: 'ConnectionErrorHeader',
    Ru: 'Что-то не так с вашим интернетом',
    Eng: 'There seems to be a problem with your Internet connection',
    Es: 'Algo anda mal con tu internet',
  },
  {
    KEY: 'ConnectionErrorBody',
    Ru: 'Чтобы стало лучше, попробуйте:',
    Eng: 'To get a better connection you can try:',
    Es: 'Para mejorar, pruebe:',
  },
  {
    KEY: 'ConnectionErrorStep1',
    Ru: 'закрыть другие вкладки и программы, которые могут “забирать” интернет',
    Eng: 'closing all the extra tabs and programs that may be taking up the Internet',
    Es: 'Cierre otras pestañas y programas que puedan "recoger" Internet',
  },
  {
    KEY: 'ConnectionErrorStep2',
    Ru: 'перейдите ближе к роутеру',
    Eng: 'moving closer to the router',
    Es: 'acercarse al router',
  },
  {
    KEY: 'ConnectionErrorStep3',
    Ru: 'попросите окружающих не “грузить сеть”',
    Eng: 'try asking the people around you not to "load the net"',
    Es: 'Pida a otros que no "carguen la red"',
  },
  {
    KEY: 'ConnectionErrorStep4',
    Ru: 'Обновите страницу',
    Eng: 'refresh the page',
    Es: 'Actualizar la página',
  },
  {
    KEY: 'StudentWorkSheetStep1Title',
    Ru: 'На каком уровне ты знаешь испанский?',
    Eng: 'На каком уровне ты знаешь испанский?',
    Es: '¿Cuál es tu nivel de español?',
  },
  {
    KEY: 'StudentWorkSheetStep2Title',
    Ru: 'Какую цель ты хочешь достичь?',
    Eng: 'Какую цель ты хочешь достичь?',
    Es: '¿Qué objetivo quieres lograr?',
  },
  {
    KEY: 'StudentWorkSheetStep3Title',
    Ru: 'К какому числу ты хочешь выполнить цель?',
    Eng: 'К какому числу ты хочешь выполнить цель?',
    Es: '¿A qué fecha quieres lograr tu objetivo?',
  },
  {
    KEY: 'StudentWorkSheetStep4Title',
    Ru: 'Какую промежуточную цель ты сможешь достигнуть за 3 месяца?',
    Eng: 'Какую промежуточную цель ты сможешь достигнуть за 3 месяца?',
    Es: 'Какую промежуточную цель ты сможешь достигнуть за 3 месяца?',
  },
  {
    KEY: 'StudentWorkSheetStep5Title',
    Ru: 'Сколько часов в неделю ты готов(а) заниматься испанским?',
    Eng: 'Сколько часов в неделю ты готов(а) заниматься испанским?',
    Es: 'Сколько часов в неделю ты готов(а) заниматься испанским?',
  },
  {
    KEY: 'StudentWorkSheetStep6Title',
    Ru: 'А как ты относишься к домашке?',
    Eng: 'А как ты относишься к домашке?',
    Es: 'А как ты относишься к домашке?',
  },
  {
    KEY: 'StudentWorkSheetStep8Title',
    Ru: 'Какие темы тебе интересны? Выбери не менее 3-х, но можно и больше :)',
    Eng: 'Какие темы тебе интересны? Выбери не менее 3-х, но можно и больше :)',
    Es: 'Какие темы тебе интересны? Выбери не менее 3-х, но можно и больше :)',
  },
  { KEY: 'Next', Ru: 'Дальше', Eng: 'Дальше', Es: 'Adelante' },
  { KEY: 'Ready', Ru: 'Готово!', Eng: 'Готово!', Es: 'Hecho!' },
  { KEY: 'Question', Ru: 'Вопрос', Eng: 'Вопрос', Es: 'Pregunta' },
  { KEY: 'From', Ru: 'Из', Eng: 'Из', Es: 'de' },
  {
    KEY: 'StudentWorkSheetStep3Desc',
    Ru:
      'Срок выполнения цели будет в дальнейшем использоваться для составления индивидуального плана студента',
    Eng:
      'Срок выполнения цели будет в дальнейшем использоваться для составления индивидуального плана студента',
    Es:
      'Срок выполнения цели будет в дальнейшем использоваться для составления индивидуального плана студента',
  },
  {
    KEY: 'StudentWorkSheetStep4Placeholder',
    Ru: 'Напиши цель тут. Используй фразу “Через 3 месяца я буду…”',
    Eng: 'Напиши цель тут. Используй фразу “Через 3 месяца я буду…”',
    Es: 'Напиши цель тут. Используй фразу “Через 3 месяца я буду…”',
  },
  { KEY: 'ClassFrequencyFrom', Ru: 'от', Eng: 'от', Es: 'de' },
  { KEY: 'ClassFrequencyTo', Ru: 'до', Eng: 'до', Es: 'a' },
  {
    KEY: 'ClassFrequencyToHoursPerWeek',
    Ru: 'часов в неделю',
    Eng: 'часов в неделю',
    Es: 'horas a la semana',
  },
  {
    KEY: 'ClassFrequencyRecomendation',
    Ru: 'Рекомендуем не делать разницу больше 10 часов, чтобы нагрузка была равномерной :)',
    Eng: 'Рекомендуем не делать разницу больше 10 часов, чтобы нагрузка была равномерной :)',
    Es: 'Recomendamos no hacer la diferencia de más de 10 horas para que la carga sea igual',
  },
  {
    KEY: 'Main.Goals.ToStudy',
    Ru: 'уметь учиться на испанском',
    Eng: 'уметь учиться на испанском',
    Es: 'saber estudiar en español',
  },
  {
    KEY: 'Main.Goals.ToTravel',
    Ru: 'владеть языком для путешествий',
    Eng: 'владеть языком для путешествий',
    Es: 'dominar el idioma para viajar',
  },
  {
    KEY: 'Main.Goals.GetAJob',
    Ru: 'получить работу',
    Eng: 'получить работу',
    Es: 'obtener un trabajo',
  },
  {
    KEY: 'Main.Goals.MoveToSpanishSpeakingCountry',
    Ru: 'переехать в испаноязычную страну',
    Eng: 'переехать в испаноязычную страну',
    Es: 'mudarse a un país de habla hispana',
  },
  {
    KEY: 'Main.Goals.PassTheExam',
    Ru: 'сдать экзамен',
    Eng: 'сдать экзамен',
    Es: 'aprobar el examen',
  },
  {
    KEY: 'Main.Goals.EnjoyALearningLanguage',
    Ru: 'получить удовольствие от изучения языка',
    Eng: 'получить удовольствие от изучения языка',
    Es: 'disfrutar del aprendizaje de idiomas',
  },
  {
    KEY: 'MainGoalTitle',
    Ru: 'Главная цель',
    Eng: 'Главная цель',
    Es: 'Objetivo principal',
  },
  {
    KEY: 'AttitudeToHomework.GreatIDo',
    Ru: 'отлично, буду делать',
    Eng: 'отлично, буду делать',
    Es: 'Genial! Voy a hacerlo',
  },
  {
    KEY: 'AttitudeToHomework.IDontLike',
    Ru: 'фу, не люблю заниматься',
    Eng: 'фу, не люблю заниматься',
    Es: 'Puaj, no me gusta',
  },
  {
    KEY: 'AttitudeToHomework.DoesntMatter',
    Ru: 'без разницы',
    Eng: 'без разницы',
    Es: 'No importa',
  },
  { KEY: 'Selected', Ru: 'Выбрано', Eng: 'Выбрано', Es: 'Elegido' },
  {
    KEY: 'WorksheetDesc',
    Ru:
      'Заполните небольшую анкету и мы автоматически сгенерируем индивидуальный план обучения для ученика. Пока наша система еще учится, поэтому могут быть некоторые неточности, не пугайтесь. :)',
    Eng:
      'Заполните небольшую анкету и мы автоматически сгенерируем индивидуальный план обучения для ученика. Пока наша система ещё учится, поэтому могут быть некоторые неточности, не пугайтесь. :)',
    Es:
      'Rellene un breve cuestionario y generaremos automáticamente un plan de aprendizaje personalizado para el estudiante. Nuestro sistema todavía está aprendiendo, por lo que puede haber algunas imprecisiones, no se preocupe.',
  },
  {
    KEY: 'Gradies.one',
    Ru: 'Кто только перешел на этот уровень',
    Eng: 'has just started studying',
    Es: 'has just started studying',
  },
  {
    KEY: 'Gradies.two',
    Ru: 'Кто знает совсем немного',
    Eng: 'knows something, but not much',
    Es: 'knows something, but not much',
  },
  {
    KEY: 'Gradies.three',
    Ru: 'Кто имеет знания, но есть большие пробелы ',
    Eng: 'has knowledge, but also has meaningful gaps',
    Es: 'has knowledge, but also has meaningful gaps',
  },
  {
    KEY: 'Gradies.four',
    Ru: 'Есть достаточные знания, но есть куда расти',
    Eng: 'has enough knowledge, but there is still work to be done',
    Es: 'has enough knowledge, but there is still work to be done',
  },
  {
    KEY: 'Gradies.five',
    Ru: 'Отличные знания, готов переходить к другому уровню',
    Eng: 'good knowledge, time to learn the next level',
    Es: 'good knowledge, time to learn the next level',
  },
  { KEY: 'Interests.Desktops', Ru: 'Настолки', Eng: 'Desktops', Es: 'Juegos de mesa' },
  { KEY: 'Interests.Psychology', Ru: 'Психология', Eng: 'Psychology', Es: 'Psicología' },
  { KEY: 'Interests.Astrology', Ru: 'Астрология', Eng: 'Astrology', Es: 'Astrología' },
  { KEY: 'Interests.TikTok', Ru: 'ТикТок', Eng: 'TikTok', Es: 'TikTok' },
  { KEY: 'Interests.Children', Ru: 'Дети', Eng: 'Children', Es: 'Niños' },
  { KEY: 'Interests.Blogging', Ru: 'Блогинг', Eng: 'Blogging', Es: 'Blogs / Blogging' },
  { KEY: 'LEVELS.A0', Ru: 'Нулевой', Eng: 'Start', Es: 'А0' },
  { KEY: 'Interests.Dancing', Ru: 'Танцы', Eng: 'Dancing', Es: 'Danza' },
  {
    KEY: 'Interests.Desc',
    Ru:
      'На основе отмеченных интересов, учитель подбирает темы для разговоров во время урока. Также, это ключевой параметр для подбора собеседника для <a href="{{networkingLink}}" target="_blank">нетворкинга</a>.',
    Eng:
      'Based on the noted interests, the teacher selects topics for conversation during the lesson. Also, this is a key parameter for selecting an interlocutor for <a href="{{networkingLink}}" target="_blank">networking</a>.',
    Es:
      'Con base en los intereses anotados, el maestro selecciona temas de conversación durante la clase. Además, este es un parámetro clave para seleccionar un interlocutor para la creación de <a href="{{networkingLink}}" target="_blank">redes</a>.',
  },
  { KEY: 'Interests.Knitting', Ru: 'Вязание', Eng: 'Knitting', Es: 'Tejido de punto' },
  {
    KEY: 'InvalidEmailAddress',
    Ru: 'Не правильный e-mail адрес',
    Eng: 'Incorrect e-mail',
    Es: 'Email incorrecto',
  },
  {
    KEY: 'LanguageInTranslate',
    Ru: 'Язык перевода в словаре',
    Eng: 'Language in dictionary translation',
    Es: 'Idioma en la traducción del diccionario',
  },
  {
    KEY: 'StartLessonWithoutIntroductory',
    Ru: 'Начни заниматься по программе без вводного урока.',
    Eng: 'Get started with the program without an introductory lesson.',
    Es: 'Comience con el programa sin una clase de prueba',
  },
  {
    KEY: 'GetGiftWithPurchasePackage',
    Ru: 'Получи урок в подарок при покупке любого пакета!',
    Eng: 'Get a lesson as a gift with the purchase of any package!',
    Es: '¡Llévate una clase de regalo con la compra de cualquier paquete!',
  },
  { KEY: 'BuyPackage', Ru: 'Купить пакет', Eng: 'Buy package', Es: 'Comprar paquete' },
  {
    KEY: 'SendMessage',
    Ru: 'Отправить сообщение',
    Eng: 'send a message',
    Es: 'send a message',
  },
  { KEY: 'WatchVideo', Ru: 'Посмотреть видео', Eng: 'Watch video', Es: 'Ver video' },
  {
    KEY: 'WriteConvenientForStudy',
    Ru:
      'Напиши, в какое время тебе удобнее заниматься и есть ли какие-то предпочтения по выбору преподавателя:',
    Eng:
      'Write what time is more convenient for you to study and if there are any teacher preferences:',
    Es: 'Escribe a qué hora te conviene más estudiar y si hay alguna preferencias del profesor:',
  },
  {
    KEY: 'ExtraLessonAdded',
    Ru: 'Дополнительный урок был начислен',
    Eng: 'Extra lesson added',
    Es: 'Clase extra añadida',
  },
  {
    KEY: 'FindTeacherMessage',
    Ru:
      'Скоро мы подберем для тебя преподавателя и назначим дату первого занятия. А пока ты можешь посмотреть видео о работе с платформой',
    Eng:
      'We will soon find a teacher for you and set a date for the first session. In the meantime, you can watch a video about working with the platform',
    Es:
      'Pronto encontraremos un profesor para ti. y fije una fecha para la primera sesión. Mientras tanto, puede ver un video sobre cómo trabajar con la plataforma',
  },
  {
    KEY: 'LearnMoreWithPlatform',
    Ru: 'Узнай больше о работе с платформой',
    Eng: 'Learn more about working with the platform',
    Es: 'Más información sobre cómo trabajar con la plataforma',
  },
  {
    KEY: 'HeadingAfterAnswer',
    Ru: 'Спасибо за ответ!',
    Eng: 'Thanks for the answer!',
    Es: '¡Gracias por la respuesta!',
  },
  {
    KEY: 'HeadingAfterPurchase',
    Ru: 'Спасибо за покупку!',
    Eng: 'Thank you for your purchase!',
    Es: '¡Gracias por su compra!',
  },
  {
    KEY: 'YouTubeVideoLink',
    Ru: 'https://youtu.be/7o0uwDmEfJo',
    Eng: 'https://youtu.be/7o0uwDmEfJo',
    Es: 'https://youtu.be/7o0uwDmEfJo',
  },
  { KEY: 'Authorization', Ru: 'Авторизация', Eng: 'Authorization', Es: 'Autorización' },
  {
    KEY: 'checkSpamFolder',
    Ru: 'Если письмо не пришло, проверьте папку “Спам”.',
    Eng: "If you didn't receive the email, check your Spam folder.",
    Es: 'Si no recibiste el correo electrónico, revisa tu carpeta de correo no deseado.',
  },
  {
    KEY: 'sendLoginPassword',
    Ru: 'Мы выслали пароль для входа на ваш e-mail.',
    Eng: 'We have sent the login password to your e-mail.',
    Es: 'Hemos enviado la contraseña de inicio de sesión a su correo electrónico.',
  },
  {
    KEY: 'haveAnAccount',
    Ru: 'У меня уже есть учетная запись',
    Eng: 'I already have an account',
    Es: 'ya tengo una cuenta',
  },
  {
    KEY: 'privacyAndPolicy',
    Ru: 'политикой конфиденциальности',
    Eng: 'privacy policy',
    Es: 'política de privacidad',
  },
  {
    KEY: 'privacyAndPolicyNotice',
    Ru: 'Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь c',
    Eng: 'By clicking on the button, you consent to the processing of personal data and agree with',
    Es:
      'Al hacer clic en el botón, usted acepta el procesamiento de datos personales y de acuerdo con',
  },
  { KEY: 'registerBtn', Ru: 'Регистрация', Eng: 'Registration', Es: 'Registro' },
  {
    KEY: 'registerNewUser',
    Ru: 'Регистрация нового ученика',
    Eng: 'Registering a new student',
    Es: 'Registro de un nuevo estudiante',
  },
  {
    KEY: 'LoadAllStudents',
    Ru: 'Загрузить всех',
    Eng: 'Download all',
    Es: 'Descargar todo',
  },
  {
    KEY: 'OurPrivacy',
    Ru: 'Политика конфиденциальности',
    Eng: 'Our Privacy',
    Es: 'Nuestra política de privacidad',
  },
  {
    KEY: 'CreatedAccount',
    Ru: 'Учетная запись создана!',
    Eng: 'Account created!',
    Es: '¡Cuenta creada!',
  },
  { KEY: 'Leadership.table', Ru: 'Турнирная таблица', Eng: 'Leadership', Es: 'Torneo' },
  { KEY: 'Leadership.Position', Ru: 'Место', Eng: 'Rank', Es: 'Lugar' },
  { KEY: 'Leadership.Name', Ru: 'Имя', Eng: 'Name', Es: 'Nombre' },
  {
    KEY: 'Leadership.PassedLesson',
    Ru: "Пройдено уроков за месяц'",
    Eng: 'Passed lessons per month',
    Es: 'Clases aprobadas por mes',
  },
  {
    KEY: 'TableWillBeUpdated',
    Ru: 'Таблица обновится через: {{hours}} ч {{minutes}} мин',
    Eng: 'The table will be updated in: {{hours}} h {{minutes}} min',
    Es: 'La tabla se actualizará en: {{hours}} h {{minutes}} min',
  },
  {
    KEY: 'Congratulations',
    Ru: 'поздравления!',
    Eng: 'Congratulations!',
    Es: '¡Felicidades!',
  },
  {
    KEY: 'NumberOfDays.CompetitionsTable',
    Ru: '{{day}} дня подряд!',
    Eng: '{{day}} days in a row!',
    Es: '¡{{day}} días seguidos!',
  },
  { KEY: 'Skip', Ru: 'Пропустить!', Eng: 'Skip', Es: 'Saltar' },
  { KEY: 'Next', Ru: 'Дальше!', Eng: 'Next', Es: 'Próximo' },
  {
    KEY: 'material.heading',
    Ru: 'Учебные материалы!',
    Eng: 'Educational materials',
    Es: 'Materiales educativos',
  },
  {
    KEY: 'audioAndVideo.heading',
    Ru: 'Вкл./выкл. микрофона и камеры',
    Eng: 'On off. microphone and camera',
    Es: 'Encendido apagado. micrófono y cámara',
  },
  { KEY: 'dictionary.heading', Ru: 'Словарь', Eng: 'Dictionary', Es: 'Diccionario' },
  { KEY: 'note.heading', Ru: 'Блокнот', Eng: 'Notebook', Es: 'Computadora portátil' },
  {
    KEY: 'material.details',
    Ru: 'Здесь находятся интерактивные материалы урока.',
    Eng: 'Here are interactive lesson materials.',
    Es: 'Aquí hay materiales de lecciones interactivos.',
  },
  {
    KEY: 'audioAndVideo.details',
    Ru:
      'Советуем держать микрофон и камеру включенными, чтобы учитель тебя мог слышать и видеть :)',
    Eng:
      'We advise you to keep the microphone and camera turned on so that the teacher can hear and see you :)',
    Es:
      'Le recomendamos que mantenga el micrófono y la cámara encendidos para que el profesor pueda escucharlo y verlo :)',
  },
  {
    KEY: 'dictionary.details',
    Ru: 'Переводи слова и фразы во время урока.',
    Eng: 'Translate words and phrases during the lesson.',
    Es: 'Traduce palabras y frases durante la clase.',
  },
  {
    KEY: 'note.details',
    Ru: 'Для заметок во время урока. Вы можете заполнять его с учителем одновременно.',
    Eng: 'For notes during the lesson. You can complete it with the teacher at the same time.',
    Es: 'Para notas durante la clase. Puedes completarlo con el profesor al mismo tiempo.',
  },
  {
    KEY: 'userNotInTop10',
    Ru: 'Чем больше ты пройдешь уроков, тем лучше будет прогресс!',
    Eng: 'The more lessons you complete, the better your progress will be!',
    Es: '¡Cuantas más lecciones completes, mejor será tu progreso!',
  },
  { KEY: 'Main.Statistics', Ru: 'Статистика', Eng: 'Statistics', Es: 'Estadísticas' },
  {
    KEY: 'HomeWork.MinimumWord',
    Ru: 'Минимальное количество слов:',
    Eng: 'Minimum number of words:',
    Es: 'Número mínimo de palabras:',
  },
  {
    KEY: 'HomeWork.Pronunciation',
    Ru: 'Задание на произношение',
    Eng: 'Pronunciation task',
    Es: 'Tarea de pronunciacion',
  },
  { KEY: 'totalPaid', Ru: 'Оплачено всего:', Eng: 'Total Paid:', Es: 'Total pagado:' },
  {
    KEY: 'numberOfPayments',
    Ru: 'Количество оплат:',
    Eng: 'Number of payments:',
    Es: 'Numero de pagos:',
  },
  {
    KEY: 'averageCostOfLesson',
    Ru: 'Средняя стоимость урока:',
    Eng: 'Average cost of a lesson:',
    Es: 'Costo promedio de una clase:',
  },
  {
    KEY: 'HeadingAfterPurchase',
    Ru: 'Спасибо за покупку!',
    Eng: 'Thank you for your purchase!',
    Es: 'Gracias por su compra!',
  },
  { KEY: 'couresStatus.Published', Ru: 'опубликовано', Eng: 'Published', Es: 'Publicada' },
  {
    KEY: 'couresStatus.Changes',
    Ru: 'есть изменения',
    Eng: 'There are changes',
    Es: 'hay cambios',
  },
  {
    KEY: 'couresStatus.Moderation',
    Ru: 'на модерации',
    Eng: 'Moderation',
    Es: 'Moderación',
  },
  { KEY: 'couresStatus.Diverted', Ru: 'отклонено', Eng: 'Rejected', Es: 'rechazada' },
  {
    KEY: 'couresStatus.ChangesRejected',
    Ru: 'изменения отклонены',
    Eng: 'Changes Rejected',
    Es: 'cambios rechazados',
  },
  {
    KEY: 'EmptyMiniCourseText',
    Ru:
      'Здесь вы можете создавать мини-курсы для самостоятельного изучения учащимися. И получать за это деньги. Подробности уточняйте у менеджера :)',
    Eng:
      'Here you can create mini-courses for students to take on their own. And get paid for it. Check with your manager for details :)',
    Es:
      'Aquí puede crear mini-cursos para que los estudiantes tomen por su cuenta. Y que te paguen por ello. Consulte con su gerente para obtener más detalles :)',
  },
  {
    KEY: 'Telegram.SendText',
    Ru: 'Отправить в телеграм',
    Eng: 'Send to telegram',
    Es: 'enviar a telegrama',
  },
  {
    KEY: 'PromoCode.WorkTitle',
    Ru: 'Как это работает?',
    Eng: 'How it works?',
    Es: '¿Cómo funciona?',
  },
  {
    KEY: 'PromoCode.WorkStep1',
    Ru: 'Скопируй промокод и отправь его другу.',
    Eng: 'Copy the promo code and send it to a friend.',
    Es: 'Copie el código promocional y envíelo a una amiga.',
  },
  {
    KEY: 'PromoCode.WorkStep2',
    Ru: 'Друг при первой оплате уроков укажет твой промокод.',
    Eng: 'A friend at the first payment for lessons will indicate your promotional code.',
    Es: 'Un amigo en el primer pago de las clases le indicará su código promocional.',
  },
  {
    KEY: 'PromoCode.WorkStep3',
    Ru: 'Оба получаете 2 урока в подарок!',
    Eng: 'Both get 2 lessons for free!',
    Es: '¡Ambos obtienen 2 clases gratis!',
  },
  {
    KEY: 'Lesson.IsNotStarted',
    Ru: 'урок еще не начался',
    Eng: 'lesson is not started yet',
    Es: 'la clase aún no ha comenzado',
  },
  {
    KEY: 'Lesson.AccessSetting',
    Ru: 'настройка доступа к урокам',
    Eng: ' access setting for lessons',
    Es: 'configuración de acceso a las clases',
  },
  {
    KEY: 'Lesson.AccessSettingSubTittle',
    Ru: 'испанский для начинающих',
    Eng: 'spanish for beginners',
    Es: 'Español para principiantes',
  },
  {
    KEY: 'HomeWork.ArtificialIntelligence',
    Ru: 'Искусственный интеллект',
    Eng: 'Artificial intelligence',
    Es: 'Inteligencia artificial',
  },
  {
    KEY: 'CheckedAI',
    Ru: 'автопроверка от ИИ',
    Eng: 'AI auto-check',
    Es: 'Comprobación automática de IA',
  },
  {
    KEY: 'HomeworkCheckedByAI',
    Ru: 'Домашнее задание проверяется автоматической проверкой AI',
    Eng: 'Homework is checked by AI auto-check',
    Es: 'La tarea se verifica mediante la verificación automática de AI',
  },
  {
    KEY: 'Schedule.Cancellation',
    Ru: 'Отмена занятия',
    Eng: 'Cancellation',
    Es: 'Cancellation',
  },
  { KEY: 'Schedule.LessonCanceled', Ru: 'Занятие  ', Eng: 'Lesson ', Es: 'La clase ' },
  {
    KEY: 'Schedule.ConfirmText',
    Ru: 'Вы действительно хотите отменить занятие  ',
    Eng: 'Are you sure you want to cancel class ',
    Es: '¿Está seguro de que desea cancelar la clase el ',
  },
  {
    KEY: 'Schedule.NotCancel',
    Ru: 'Нет, не нужно',
    Eng: 'No no need',
    Es: 'No hay necesidad',
  },
  { KEY: 'Schedule.Good', Ru: 'Хорошо', Eng: 'Fine', Es: 'Bien' },
  {
    KEY: 'Schedule.ConfirmCancel',
    Ru: 'Да, отменить',
    Eng: 'Yes, cancel',
    Es: 'Sí, cancelar',
  },
  {
    KEY: 'Schedule.Reschedule',
    Ru: ' Перенос занятия',
    Eng: 'Lesson rescheduling',
    Es: 'Reprogramación de clases',
  },
  {
    KEY: 'Schedule.Rescheduled',
    Ru:
      '       Новое время занятия {{date}} в {{time}} мск. Мы сообщим вам, когда\n          учитель подтвердит перенос.',
    Eng:
      'New class time {{date}} at {{time}} Moscow time. We will notify you when\n           the teacher will confirm the transfer.',
    Es:
      'Hora de nueva clase {{date}} a las {{time}} hora de Moscú. Te avisaremos cuando\n           el profesor confirmará la transferencia.',
  },
  {
    KEY: 'Schedule.Title',
    Ru: '  Выберите новое время занятия',
    Eng: 'Choose a new class time',
    Es: 'Elige un nuevo horario de clase',
  },
  {
    KEY: 'Schedule.Cancel',
    Ru: 'Отменить урок',
    Eng: 'Cancel lesson',
    Es: 'Cancelar clase',
  },
  {
    KEY: 'Schedule.LimitDescr',
    Ru:
      'В месяц можно отменять не более 4 уроков. Если тебе не подходит это время урока, ты можешь его перенести на другой день.',
    Eng:
      'You can cancel no more than 4 lessons per month. If this lesson time does not suit you, you can reschedule it to another day.',
    Es:
      'No puedes cancelar más de 4 lecciones por mes. Si este horario de lección no te conviene, puedes reprogramarlo para otro día.',
  },
  {
    KEY: 'Schedule.LimitReschedule',
    Ru: 'Переносить одно и то же занятие можно только один раз',
    Eng: 'You can reschedule the same lesson only once',
    Es: 'Puedes reprogramar la misma lección solo una vez',
  },
  {
    KEY: 'Schedule.HideWindow',
    Ru: 'Закрыть окно',
    Eng: 'Close a window',
    Es: 'Cerrar una ventana',
  },
  { KEY: 'Schedule.Choose', Ru: ' Выбрать', Eng: 'Choose', Es: 'Elegir' },
  { KEY: 'Schedule.InMSK', Ru: '(по мск)', Eng: '(MSK)', Es: '(MSK)' },
  { KEY: 'Schedule.RescheduleOk', Ru: 'Понятно', Eng: "It's clear", Es: 'Está vacío' },
  {
    KEY: 'Homework.CheckAILabel',
    Ru: 'Автопроверка от ИИ готова и находится в разделе “Домашние задания”',
    Eng: 'Auto-check from AI is ready and is in the “Homework” section',
    Es: 'La verificación automática de AI está lista y se encuentra en la sección "Tarea"',
  },
  {
    KEY: 'Classroom.StudentSendNotes',
    Ru: 'Отправить себе в заметки',
    Eng: 'Send to your notes',
    Es: 'Enviar a tus notas',
  },
  {
    KEY: 'Classroom.StudentNotebookIsCleared',
    Ru: 'Все записи сохранятся у тебя в разделе "Заметки". Блокнот очистится. ',
    Eng: 'All entries will be saved in your "Notes" section. Notepad will be cleared.',
    Es: 'Todas las entradas se guardarán en la sección "Notas". El bloc de notas se borrará.',
  },
  {
    KEY: 'GoToTheLessonTooltip',
    Ru: 'Если тебе нужно вспомнить материалы с этого урока - воспользуйся этой кнопкой.',
    Eng: 'If you need to remember the materials from this lesson - use this button.',
    Es: 'Si necesita recordar los materiales de esta clase, use este botón.',
  },
  {
    KEY: 'ChatMessageCalendarNewEvent',
    Ru: ' Новое время урока:  {{ date }}  в {{time }} по мск',
    Eng: 'New lesson time: {{ date }} at {{time }} Moscow time',
    Es: 'Hora de la nueva clase: {{ date }} a las {{time }} hora de Moscú',
  },
  {
    KEY: 'ChatMessageCalendarRescheduleEvent',
    Ru: 'запланированное на {{ date }} по мск.',
    Eng: 'scheduled for {{ date }} Moscow time.',
    Es: 'programado para el {{ date }} hora de Moscú.',
  },
  {
    KEY: 'ChatMessageCalendarWantsReschedule',
    Ru: 'хочет перенести',
    Eng: 'wants to reschedule',
    Es: 'quiere reprogramar',
  },
  { KEY: 'ChatMessageCalendarRescheduled', Ru: 'перенес', Eng: 'moved', Es: 'movido' },
  { KEY: 'ChatMessageCalendarCancelled', Ru: 'отменил', Eng: 'canceled', Es: 'cancelado' },
  { KEY: 'CoursesRoute.Title', Ru: 'Конструктор занятий', Eng: 'Activity Builder' },
  { KEY: 'CoursesRoute.Tab1', Ru: 'Курсы', Eng: 'Courses' },
  { KEY: 'CoursesRoute.Tab2', Ru: 'Мини-курсы', Eng: 'Mini courses' },
  {
    KEY: 'CoursesRoute.CreateNewCourseTitle',
    Ru: 'Создать новый курс',
    Eng: 'Create a new course',
  },
  { KEY: 'CourseModal.Title', Ru: 'Ваш новый курс', Eng: 'Your new course' },
  { KEY: 'CourseModal.MediaTitle', Ru: 'Изображение курса', Eng: 'Course image' },
  { KEY: 'CourseModal.InputFileTitle', Ru: 'Загрузите изображение', Eng: 'Upload an image' },
  { KEY: 'CourseModal.InputFileSize', Ru: 'не более 5 Мб', Eng: 'no more than 5 MB' },
  { KEY: 'CourseModal.InputPlaceholderName', Ru: 'Название курса', Eng: 'Course name' },
  {
    KEY: 'CourseModal.InputPlaceholderDescription',
    Ru: 'Описание курса',
    Eng: 'Course description',
  },
  {
    KEY: 'CourseModal.CheckboxMiniTitle',
    Ru: 'Мини-курс Практикамос',
    Eng: 'Mini-course Practicamos',
  },
  { KEY: 'CourseModal.ActionCreate', Ru: 'Создать курс', Eng: 'Create course' },
  { KEY: 'CourseWarningModalDeleteAction.Title', Ru: 'Удалить курс?', Eng: 'Delete course?' },
  {
    KEY: 'CourseWarningModalDeleteAction.Description',
    Ru: 'Курс и все файлы удалятся из конструктора',
    Eng: 'The course and all files will be removed from the constructor',
  },
  { KEY: 'CourseWarningModalDeleteAction.ActionDelete', Ru: 'Удалить', Eng: 'Delete' },
  { KEY: 'CourseWarningModalDeleteAction.ActionCancelDelete', Ru: 'Отмена', Eng: 'Cancel' },
  {
    KEY: 'CourseWarningModalPublishAction.Title',
    Ru: 'Опубликовать курс?',
    Eng: 'Post a course?',
  },
  {
    KEY: 'CourseWarningModalPublishAction.Description',
    Ru: 'Курс станет доступен всем учителям на платформе',
    Eng: 'The course will be available to all teachers on the platform',
  },
  {
    KEY: 'CourseWarningModalPublishAction.ActionPublish',
    Ru: 'Опубликовать',
    Eng: 'Published',
  },
  { KEY: 'CourseWarningModalPublishAction.ActionCancelPublish', Ru: 'Отмена', Eng: 'Cancel' },
  { KEY: 'ActionCard.LessonsTitle', Ru: 'Уроков:', Eng: 'Lessons:' },
  {
    KEY: 'ActionCard.SignTestTitle',
    Ru: 'Назначить ученику',
    Eng: 'Assign to a student',
    Es: 'Asignar a un estudiante',
  },
  { KEY: 'ActionCard.DeleteCourseTitle', Ru: 'Удалить курс', Eng: 'Delete course' },
  { KEY: 'ActionCard.DuplicateCourseTitle', Ru: 'Дублировать курс', Eng: 'Duplicate course' },
  { KEY: 'ActionCard.PublishCourseTitle', Ru: 'Опубликовать курс', Eng: 'Publish Course' },
  {
    KEY: 'ActionCard.TransformToMiniCourseTitle',
    Ru: 'Сделать мини-курсом',
    Eng: 'Make it a mini course',
  },
  { KEY: 'ActionCard.Moderation', Ru: 'Отправить на модерацию', Eng: 'Send to moderation' },
  { KEY: 'LessonsRoute.Title', Ru: 'Вернуться в кабинет', Eng: 'Back to office' },
  { KEY: 'LessonsRoute.Tab1', Ru: 'Уроки', Eng: 'Lessons' },
  { KEY: 'LessonsRoute.Tab2', Ru: 'Настройки', Eng: 'Settings' },
  {
    KEY: 'LessonsRoute.CreateNewLessonTitle',
    Ru: 'Создать новый урок',
    Eng: 'Create a new lesson',
  },
  { KEY: 'LessonModal.Title', Ru: 'Ваш новый урок', Eng: 'Your new lesson' },
  { KEY: 'LessonModal.MediaTitle', Ru: 'Изображение урока', Eng: 'Lesson image' },
  { KEY: 'LessonModal.InputFileTitle', Ru: 'Загрузите изображение', Eng: 'Upload an image' },
  { KEY: 'LessonModal.InputFileSize', Ru: 'не более 5 Мб', Eng: 'no more than 5 MB' },
  { KEY: 'LessonModal.InputPlaceholderName', Ru: 'Название урока', Eng: 'Lesson name' },
  {
    KEY: 'LessonModal.InputPlaceholderDescription',
    Ru: 'Описание урока',
    Eng: 'Lesson description',
  },
  { KEY: 'LessonModal.ActionCreate', Ru: 'Создать урок', Eng: 'Create lesson' },
  { KEY: 'LessonModal.ActionCancelCreation', Ru: 'Отмена', Eng: 'Cancel' },
  {
    KEY: 'MiniCourseSettings.TooltipTitle',
    Ru: 'Добавить раздел к описанию курса',
    Eng: 'Add a section to the course description',
  },
  {
    KEY: 'MiniCourseSettings.DescriptionTitle',
    Ru: 'Описание курса',
    Eng: 'Course description',
  },
  {
    KEY: 'MiniCourseSettings.DescriptionText',
    Ru: 'Расскажите, о чем данный курс, какие темы раскрываются',
    Eng: 'Tell us what this course is about, what topics are covered',
  },
  {
    KEY: 'MiniCourseSettings.DescriptionPlaceholder',
    Ru: 'Опишите курс',
    Eng: 'Describe the course',
  },
  {
    KEY: 'MiniCourseSettings.BenefitsTitle',
    Ru: 'Чему вы научитесь',
    Eng: 'What will you learn',
  },
  {
    KEY: 'MiniCourseSettings.BenefitsText',
    Ru: 'Какие темы, правила изучит ученик на курсе',
    Eng: 'What topics, rules will the student study in the course',
  },
  {
    KEY: 'MiniCourseSettings.BenefitsTextBottom',
    Ru: '(пункты расставляются автоматически по нажатию на Enter)',
    Eng: '(points are placed automatically by pressing Enter)',
  },
  {
    KEY: 'MiniCourseSettings.BenefitsPlaceholder',
    Ru: 'Польза от курса',
    Eng: 'Benefits of the course',
  },
  {
    KEY: 'MiniCourseSettings.SourcesTitle',
    Ru: 'Что входит в стоимость',
    Eng: 'What is included in the price',
  },
  {
    KEY: 'MiniCourseSettings.SourcesText',
    Ru: 'Что входит в курс: упражнения, видеозаписи, сертификат об окончании и т.п.',
    Eng: 'What is included in the course: exercises, videos, certificate of completion, etc.',
  },
  { KEY: 'MiniCourseSettings.SourcesPlaceholder', Ru: 'Состав курса', Eng: 'Course content' },
  {
    KEY: 'MiniCourseSettings.InfoTitle',
    Ru: 'Общая информация о курсе',
    Eng: 'General information about the course',
  },
  {
    KEY: 'MiniCourseSettings.InfoLevelPlaceholder',
    Ru: 'Уровень знания языка',
    Eng: 'Language level',
  },
  {
    KEY: 'MiniCourseSettings.InfoDurationPlaceholder',
    Ru: 'Длительность курса',
    Eng: 'Course duration',
  },
  {
    KEY: 'MiniCourseSettings.InfoAuthorPlaceholder',
    Ru: 'Автор курса',
    Eng: 'Course author',
  },
  { KEY: 'MiniCourseSettings.InfoPricePlaceholder', Ru: 'Цена курса', Eng: 'Course price' },
  {
    KEY: 'MiniCourseSettings.LessonsTitle',
    Ru: 'Выберите занятия, которые ученик может пройти бесплатно',
    Eng: 'Select the classes that the student can take for free',
  },
  {
    KEY: 'MiniCourseSettings.TagsTitle',
    Ru: 'Выберите тэги, подходящие по теме курса',
    Eng: 'Choose tags that match the topic of the course',
  },
  { KEY: 'MiniCourseSettings.TagsSecondTitle1', Ru: 'Интересы:', Eng: 'Interests:' },
  { KEY: 'MiniCourseSettings.TagsSecondTitle2', Ru: 'Цели:', Eng: 'Goals:' },
  { KEY: 'MiniCourseSettings.SaveButton', Ru: 'Сохранить', Eng: 'Save' },
  { KEY: 'LessonWarningModalDeleteAction.Title', Ru: 'Удалить урок?', Eng: 'Delete lesson?' },
  {
    KEY: 'LessonWarningModalDeleteAction.Description',
    Ru: 'Урок и все файлы удалятся из конструктора',
    Eng: 'The lesson and all files will be deleted from the constructor',
  },
  { KEY: 'LessonWarningModalDeleteAction.ActionDelete', Ru: 'Удалить', Eng: 'Delete' },
  { KEY: 'LessonWarningModalDeleteAction.ActionCancelDelete', Ru: 'Отмена', Eng: 'Cancel' },
  { KEY: 'ActionCard.DeleteLessonTitle', Ru: 'Удалить класс', Eng: 'Delete class' },
  { KEY: 'ActionCard.DuplicateLessonTitle', Ru: 'Дублировать класс', Eng: 'Duplicate class' },
  {
    KEY: 'PossibleStudentDeleteAction.Title',
    Ru: 'Удалить ученика из списка?',
    Eng: 'Remove a student from the list?',
  },
  {
    KEY: 'MiniCourses.DescriptionTitle',
    Ru: `Практикамос от ${appName}`,
    Eng: `Practicamos by ${appName}`,
    Es: `Practicamos by ${appName}`,
  },
  {
    KEY: 'GetICalendarFeed',
    Ru: 'Импорт календаря',
    Eng: 'Calendar import',
    Es: 'Importación de calendari',
  },
  {
    KEY: 'Vocabulary.DownloadExcelFile',
    Ru: 'Скачать слова в Excel',
    Eng: 'Download Words in Excel',
    Es: 'Descargar palabras en Excel',
  },
  {
    KEY: 'AccessQualityLesson',
    Ru: 'Оценить качество урока',
    Eng: 'Assess the quality of the lesson',
    Es: 'Evaluar la calidad de la lección',
  },
  {
    KEY: 'AddedByManager',
    Ru: 'Добавлен менеджером',
    Eng: 'Added by manager',
    Es: 'Añadido por el administrador',
  },
  {
    KEY: 'Payment.Title',
    Ru: 'Изменить дату выплаты',
    Eng: 'Change the payment date',
    Es: 'Change the payment date',
  },
  { KEY: 'Payment.Confirm', Ru: 'Подтвердить', Eng: 'Confirm', Es: 'Confirm' },
  { KEY: 'Payment.Cancel', Ru: 'Отмена', Eng: 'Cancel', Es: 'Cancel' },
  { KEY: 'Homework.WillNotDo', Ru: 'Не буду делать', Eng: "I won't do", Es: 'No lo haré' },
  {
    KEY: 'Homework.MadeEsewhere',
    Ru: 'Сделано в другом месте',
    Eng: 'Made elsewhere',
    Es: 'Hecho en otro lugar',
  },
  {
    KEY: 'Homework.DeleteTitle',
    Ru: 'Удалить это задание?',
    Eng: 'Delete this homework?',
    Es: '¿Eliminar este trabajo?',
  },
  {
    KEY: 'Homework.DeleteDesc',
    Ru: 'Ты можешь удалить выполненное домашнее задание, если оно больше тебе не нужно.',
    Eng: 'You can delete completed homework if you no longer need it.',
    Es: 'Puede eliminar la tarea completada si ya no la necesita.',
  },
  {
    KEY: 'Homework.OriginalEssay',
    Ru: 'Текст сочинения',
    Eng: 'Essay text',
    Es: 'Texto de ensayo',
  },
  {
    KEY: 'Homework.CheckedEssay',
    Ru: 'Проверенное сочинение',
    Eng: 'Checked Essay',
    Es: 'Ensayo comprobado',
  },
  {
    KEY: 'Homework.CheckedEssayWithNotes',
    Ru: 'Проверенное сочинение с пометками',
    Eng: 'Checked essay with notes',
    Es: 'Ensayo revisado con notas',
  },
  {
    KEY: 'Homework.QuickCheck',
    Ru: 'Быстрая проверка',
    Eng: 'Quick check',
    Es: 'Comprobación rápida',
  },
  {
    KEY: 'Homework.UnderReview',
    Ru: 'на проверке',
    Eng: 'under review',
    Es: 'bajo revisión',
  },
  {
    KEY: 'Homework.RemoveFailed',
    Ru: 'Не удалось удалить домашнее задание',
    Eng: 'Failed to delete homework',
    Es: 'Error al eliminar la tarea',
  },
  {
    KEY: 'AddedByManager',
    Ru: 'Добавлен менеджером',
    Eng: 'Added by manager',
    Es: 'Añadido por el administrador',
  },
  {
    KEY: 'Homework.TaskMarkedAsCompleted',
    Ru: 'Задание отмечено как выполненное',
    Eng: 'The task is marked as completed',
    Es: 'La tarea se marca como completada',
  },
  {
    KEY: 'Homework.RemoveSuccess',
    Ru: 'Домашнее задание успешно удалено',
    Eng: 'Homework successfully deleted',
    Es: 'Tarea eliminada con éxito',
  },
  {
    KEY: 'CoursesRoute.Title',
    Ru: 'Конструктор занятий',
    Eng: 'Activity Builder',
  },
  { KEY: 'CoursesRoute.Tab1', Ru: 'Курсы', Eng: 'Courses' },
  { KEY: 'CoursesRoute.Tab2', Ru: 'Мини-курсы', Eng: 'Mini courses' },
  { KEY: 'CoursesRoute.Tab3', Ru: 'Тесты', Eng: 'Tests' },
  {
    KEY: 'Tests.CreateNewTest',
    Ru: 'Создать тест',
    Eng: 'Create a test',
  },
  {
    KEY: 'ActionCard.DuplicateCourseTitle',
    Ru: 'Дублировать курс',
    Eng: 'Duplicate course',
  },
  {
    KEY: 'ActionCard.PublishCourseTitle',
    Ru: 'Опубликовать курс',
    Eng: 'Publish Course',
  },
  {
    KEY: 'ActionCard.TransformToMiniCourseTitle',
    Ru: 'Сделать мини-курсом',
    Eng: 'Make it a mini course',
  },
  { KEY: 'ActionCard.Moderation', Ru: 'на модерации', Eng: 'Moderation' },
  {
    KEY: 'Tests',
    Ru: 'Тесты',
    Eng: 'Tests',
    Es: 'Pruebas',
  },
  {
    KEY: 'ActionCard.CopyTestTitle',
    Ru: 'Копировать ссылку теста',
    Eng: 'Copy test link',
  },
  {
    KEY: 'ActionCard.EditTestTitle',
    Ru: 'Редактировать описание',
    Eng: 'Edit description',
  },
  {
    KEY: 'ActionCard.DuplicateTestTitle',
    Ru: 'Дублировать тест',
    Eng: 'Duplicate test',
  },
  {
    KEY: 'ActionCard.DeleteTestTitle',
    Ru: 'Удалить тест',
    Eng: 'Delete test',
  },
  {
    KEY: 'TestModal.Title',
    Ru: 'Ваш новый тест',
    Eng: 'Your new test',
  },
  {
    KEY: 'TestModal.TimeTitle',
    Ru: 'Время его прохождения (мин)',
    Eng: 'Time to complete (min)',
  },
  {
    KEY: 'TestModal.InputPlaceholderDescription',
    Ru: 'Описание теста',
    Eng: 'Test description',
  },
  {
    KEY: 'TestModal.InputPlaceholderName',
    Ru: 'Название теста',
    Eng: 'Test name',
  },
  {
    KEY: 'TestModal.ActionCreate',
    Ru: 'Создать тест',
    Eng: 'Create test',
  },
  {
    KEY: 'TestModal.ActionCancelCreation',
    Ru: 'Отмена',
    Eng: 'Cancel',
  },
  {
    KEY: 'TestModal.ActionEdit',
    Ru: 'Редактировать тест',
    Eng: 'Edit test',
  },
  {
    KEY: 'LessonsRoute.Title',
    Ru: 'Вернуться в кабинет',
    Eng: 'Back to office',
  },
  {
    KEY: 'Homework.AnotherError',
    Ru: 'Другая проблема',
    Eng: 'Another Problem',
    Es: 'Otro problema',
  },
  {
    KEY: 'ThanksForFeeback',
    Ru: 'Спасибо за обратную связь!',
    Eng: 'Thanks for the feedback!',
    Es: '¡Gracias por la respuesta!',
  },
  {
    KEY: 'AutoTeacher.Step1.Title',
    Ru: 'Как ты хочешь заниматься?',
    Eng: 'How do you want to practice?',
    Es: '¿Cómo quieres practicar?',
  },
  {
    KEY: 'AutoTeacher.Step1.Desc',
    Ru:
      '   Если тебе важно заговорить на испанском быстро или тебе не хочется углубляться в\n        грамматические темы, выбирай многопреподавательский состав.',
    Eng:
      'If it is important for you to speak Spanish quickly or you do not want to delve into\n         grammar topics, choose multi-teaching staff.',
    Es:
      'Si es importante para ti hablar español rápido o no quieres profundizar en\n         temas de gramática, elige profesorado pluripersonal.',
  },
  {
    KEY: 'AutoTeacher.Step1.Newbee',
    Ru: 'А для начинающих у нас есть специальный курс разговорного тренинга и курс A1. ',
    Eng: 'And for beginners we have a special conversational training course and an A1 course. ',
    Es:
      'Y para principiantes tenemos un curso especial de formación conversacional y un curso A1. ',
  },
  {
    KEY: 'GetMoreDetails',
    Ru: 'Узнать подробнее',
    Eng: 'Get more details',
    Es: 'Obtenga más detalles',
  },
  {
    KEY: 'TrainingForBeginners',
    Ru: 'Обучение для начинающих',
    Eng: 'Training for Beginners',
    Es: 'Entrenamiento para principiantes',
  },
  {
    KEY: 'SpeakingTraining',
    Ru: 'Разговорный тренинг',
    Eng: 'Speaking training',
    Es: 'Entrenamiento de habla',
  },

  {
    KEY: 'SpeakingTraining.Desc1',
    Ru:
      'Курс заточен на развитие разговорного навыка, поэтому мы рекомендуем его изучать с 2-3 и более преподавателями. На каждую тему отводится неделя. Одни и те же вопросы темы вы обсуждаете с разными преподавателями. Это уникальная разработка поможет не создать языковой барьер.',
    Eng:
      'The course is designed to develop speaking skills, so we recommend studying it with 2-3 or more teachers. A week is allocated for each topic. You discuss the same topics on the topic with different teachers. This unique development will help not create a language barrier.',
    Es:
      'El curso está diseñado para desarrollar habilidades orales, por lo que recomendamos estudiarlo con 2-3 o más profesores. Se asigna una semana para cada tema. Discutes los mismos temas sobre el tema con diferentes profesores. Este desarrollo único ayudará a no crear una barrera del idioma.',
  },
  {
    KEY: 'SpeakingTraining.Desc2',
    Ru:
      'Таким образом вы повторите тему, типичные речевые клише несколько раз, услышите разные акценты и хорошо потренируете сразу ответы в трех временах. Это поможет вам максимально быстро «разговориться».',
    Eng:
      'This way you will repeat the topic, typical speech clichés several times, hear different accents and get good practice in answering in three tenses at once. This will help you “conversate” as quickly as possible.',
    Es:
      'De esta manera no solo repetirás el tema y los clichés típicos del habla varias veces, sino que también escucharás diferentes acentos y obtendrás una buena práctica triple. Esto te ayudará a “conversar” lo más rápido posible.',
  },
  {
    KEY: 'SpeakingTraining.Desc3',
    Ru:
      'Мы также рекомендуем брать по одному уроку из интерактивного курса в неделю с другим преподавателем, чтобы отработка разговорных навыков была более грамматически верной и понятной вам.',
    Eng:
      'We also recommend taking one lesson from the online course per week with a different teacher, so that your speaking skills will be more grammatically correct and understandable to you.',
    Es:
      'También recomendamos tomar una lección del curso en línea por semana con un profesor diferente, para que tus habilidades orales sean más gramaticalmente correctas y comprensibles para ti.',
  },
  {
    KEY: 'InteractiveCourse',
    Ru: 'Интерактивный курс',
    Eng: 'Interactive course',
    Es: 'Curso interactivo',
  },
  {
    KEY: 'CourseA1.Desc',
    Ru:
      'Чтобы хорошо ориентироваться в языке и разобраться с главными грамматическими темами, как, например, число и род существительных и прилагательных, артикли, числительные, спряжения глаголов мы рекомендуем заниматься по этому курсу. Разговорная практика в нем тоже занимает важную роль, это не простое изучение грамматики.',
    Eng:
      'In order to have a good understanding of the language and understand the main grammatical topics, such as the number and gender of nouns and adjectives, articles, numerals, and verb conjugations, we recommend taking this course. Conversation practice also plays an important role in it; this is not a simple study of grammar.',
    Es:
      'Para tener un buen conocimiento del idioma y comprender los principales temas gramaticales, como el número y género de sustantivos y adjetivos, artículos, numerales y conjugaciones verbales, recomendamos realizar este curso. La práctica de la conversación también juega un papel importante en ello; Este no es un simple estudio de gramática.',
  },
  {
    KEY: 'HelpViaTutor',
    Ru: 'Если у вас остались вопросы вы всегда можете их задать своему куратору',
    Eng: 'If you have any questions, you can always ask your tutor',
    Es: 'Si tienes alguna duda, siempre puedes preguntarle a tu tutor.',
  },

  {
    KEY: 'AutoTeacher.Step1.Title2',
    Ru: 'Количество преподавателей',
    Eng: 'Number of teachers',
    Es: 'Número de profesores',
  },
  { KEY: 'Continue', Ru: 'Продолжить', Eng: 'Continue', Es: 'Continuar' },
  {
    KEY: 'AutoTeacher.Step1.OptionTitle',
    Ru: 'как обычно, один преподаватель',
    Eng: 'as usual, one teacher',
    Es: 'como de costumbre, un maestro',
  },
  {
    KEY: 'AutoTeacher.Step1.Item1',
    Ru: 'привычная система',
    Eng: 'familiar system',
    Es: 'sistema familiar',
  },
  {
    KEY: 'AutoTeacher.Step1.Item2',
    Ru: 'для тех, кто хочет заниматься по своему учебнику',
    Eng: 'for those who want to study according to their textbook',
    Es: 'para aquellos que quieren estudiar de acuerdo con su libro de texto',
  },
  {
    KEY: 'AutoTeacher.Step1.Item3',
    Ru: 'если нет потребности в разговорном навыке',
    Eng: 'if there is no need for conversational skill',
    Es: 'si no hay necesidad de habilidad conversacional',
  },
  {
    KEY: 'AutoTeacher.Step1.OptionTitle2',
    Ru: 'с разными преподавателями',
    Eng: 'with different teachers',
    Es: 'con diferentes profesores',
  },
  {
    KEY: 'AutoTeacher.Step1.Item4',
    Ru: 'разная разговорная практика',
    Eng: 'different speaking practice',
    Es: 'práctica de habla diferente',
  },
  {
    KEY: 'AutoTeacher.Step1.Item5',
    Ru: 'больше уроков на неделе ',
    Eng: 'more lessons per week',
    Es: 'más lecciones por semana',
  },
  {
    KEY: 'AutoTeacher.Step1.Item6',
    Ru: 'эффективность таких уроков выше',
    Eng: 'the effectiveness of such lessons is higher',
    Es: 'la efectividad de tales lecciones es mayor',
  },
  {
    KEY: 'AutoTeacher.Step1.Item7',
    Ru: 'нет эффекта “языкового барьера"',
    Eng: 'no language barrier effect',
    Es: 'sin efecto de barrera del idioma',
  },
  {
    KEY: 'AutoTeacher.Step1.Item8',
    Ru: 'идеально для тех, кто хочет выйти на разговорный за 3-4 месяца',
    Eng: 'ideal for those who want to reach conversational in 3-4 months',
    Es: 'ideal para aquellos que quieren llegar a la conversación en 3-4 meses',
  },
  {
    KEY: 'AutoTeacher.Step2.Title',
    Ru: 'Выбор времени занятий',
    Eng: 'Choice of class time',
    Es: 'Elección del horario de clase',
  },
  {
    KEY: 'AutoTeacher.Step2.Desc',
    Ru:
      ' Выбери время, в которое ты сможешь заниматься. Лучше выбрать сразу несколько окон, чтобы мы\n        смогли подобрать больше преподавателей.',
    Eng:
      'Choose a time when you can practice. It is better to select several windows at once so that we\n         were able to recruit more teachers.',
    Es:
      'Elige un momento en el que puedas practicar. Es mejor seleccionar varias ventanas a la vez para que podamos\n         fueron capaces de contratar a más profesores. ',
  },
  {
    KEY: 'AutoTeacher.Step2.Desc2',
    Ru: '  Не хочу искать',
    Eng: "I don't want to search",
    Es: 'no quiero buscar',
  },
  {
    KEY: 'AutoTeacher.Step2.Desc2.1',
    Ru: 'подберите мне преподавателя.',
    Eng: 'find me a teacher.',
    Es: 'búscame un maestro.',
  },
  {
    KEY: 'AutoTeacher.Step2.Desc3',
    Ru: 'Поиск длится от 1 до 3 дней.',
    Eng: 'The search lasts from 1 to 3 days.',
    Es: 'La búsqueda dura de 1 a 3 días.',
  },
  {
    KEY: 'AutoTeacher.Step3.Title',
    Ru: 'Отметь свои предпочтения',
    Eng: 'Mark your preference',
    Es: 'Marca tu preferencia',
  },
  {
    KEY: 'AutoTeacher.Step3.Desc',
    Ru:
      ' Можешь пропустить этот шаг, если эти критерии для тебя неважны. Дальше ты сможешь изменить\n        эти критерии, если будет необходимость.',
    Eng:
      'You can skip this step if these criteria are not important to you. Then you can change\n         these criteria, if necessary.',
    Es:
      'Puede omitir este paso si estos criterios no son importantes para usted. Entonces puedes cambiar estos criterios, si es necesario.',
  },
  {
    KEY: 'AutoTeacher.Step3.GenderTitle',
    Ru: 'Пол преподавателя',
    Eng: 'Instructor gender',
    Es: 'sexo del instructor',
  },
  {
    KEY: 'AutoTeacher.Step3.AgeTitle',
    Ru: 'Возраст преподавателя',
    Eng: 'Instructor age',
    Es: 'Edad del instructor',
  },
  {
    KEY: 'Age',
    Ru: 'Возраст',
    Eng: 'Age',
    Es: 'Edad',
  },
  {
    KEY: 'AutoTeacher.Step3.CountryTitle',
    Ru: 'Страна проживания',
    Eng: 'Country of Residence',
    Es: 'País de residencia',
  },
  {
    KEY: 'AutoTeacher.Step3.LangVariant',
    Ru: 'Вариант испанского',
    Eng: 'Spanish variant',
    Es: 'variante española',
  },
  {
    KEY: 'AutoTeacher.TeachersResultTitle',
    Ru: 'Поиск преподавателей',
    Eng: 'Search for teachers',
    Es: 'Поиск преподавателей',
  },
  {
    KEY: 'AutoTeacher.ChangeTime',
    Ru: 'Изменить время',
    Eng: 'change time',
    Es: 'cambio de hora',
  },
  {
    KEY: 'AutoTeacher.ConfirmSchedule',
    Ru: 'Подтвердить расписание',
    Eng: 'Confirm Schedule',
    Es: 'Confirmar horario',
  },
  {
    KEY: 'AutoTeacher.SchduleFooterLabel',
    Ru:
      'Тебе нужно выбрать от 3-х до 7 преподавателей. Один преподаватель на каждый урок в неделю.',
    Eng: 'You need to choose from 3 to 7 teachers. One teacher per lesson per week.',
    Es: 'Tienes que elegir de 3 a 7 profesores. Un profesor por lección por semana.',
  },
  { KEY: 'Close', Ru: 'Закрыть', Eng: 'Close', Es: 'Cerca' },
  {
    KEY: 'StudentPaidFistTimeDesc2',
    Ru: 'Выбери преподавателя себе по душе и занимайся с удовольствием',
    Eng: 'Choose a teacher to your liking and study with pleasure',
    Es: 'Elige un profesor a tu gusto y estudia con gusto',
  },
  {
    KEY: 'StudentPaidFistTimeBtnText2',
    Ru: 'Найти преподавателя',
    Eng: 'Find a teacher',
    Es: 'encontrar un maestro',
  },
  {
    KEY: 'DeleteActivity',
    Ru: 'Удалить занятие',
    Eng: 'Delete activity',
    Es: 'Eliminar actividad',
  },
  {
    KEY: 'Nextlesson',
    Ru: 'следующее занятие',
    Eng: 'next lesson',
    Es: 'siguiente lección',
  },
  {
    KEY: 'MultipleTeacherBtnTooltip',
    Ru:
      'Многопреподавательский метод обучения предполагает занятия с несколькими\n              преподавателями, каждый из которых имеет свои особенности в преподавании и позволяет\n              получить более разносторонние знания языка.',
    Eng:
      'The multi-teacher method of teaching involves classes with several\n               teachers, each of which has its own characteristics in teaching and allows\n               get more versatile knowledge of the language.',
    Es:
      'El método de enseñanza con varios profesores implica clases con varios\n               profesores, cada uno de los cuales tiene sus propias características en la enseñanza y permite\n               conseguir un conocimiento más polivalente del idioma.',
  },
  {
    KEY: 'MultipleTeacherLabel',
    Ru: 'Многопреподавательский режим',
    Eng: 'Multi-teaching mode',
    Es: 'Personal pluridocente',
  },
  {
    KEY: 'AutoTeacherBannerText',
    Ru: 'Не можешь найти подходящего преподавателя? Свяжись с нами – мы поможем.',
    Eng: "Can't find the right teacher? Contact us - we will help.",
    Es: '¿No encuentras al profesor adecuado? Póngase en contacto con nosotros - le ayudaremos.',
  },
  {
    KEY: 'AutoTeacherBannerBtnText',
    Ru: 'Супер, давайте!',
    Eng: "Great, let's go!",
    Es: '¡Genial, vamos!',
  },
  {
    KEY: 'TakenTimeTooltip',
    Ru: 'В это время у тебя урок \nс другим преподавателем',
    Eng: 'At this time you have a lesson\nwith another teacher',
    Es: 'En este momento tienes una lección.\ncon otro maestro',
  },
  { KEY: 'Gender.F', Ru: 'женский', Eng: 'feminine', Es: 'femenino' },
  { KEY: 'Gender.M', Ru: 'мужской', Eng: 'male', Es: 'masculino' },
  { KEY: 'Gender.ANY', Ru: 'любой', Eng: 'any', Es: 'cualquier' },
  { KEY: 'Country.Hispanic', Ru: 'испаноязычная', Eng: 'Hispanic', Es: 'Hispano' },
  { KEY: 'Country.ANY', Ru: 'любая', Eng: 'any', Es: 'cualquier' },
  {
    KEY: 'LangVariant.1',
    Ru: 'Испанский Испании (Castellano)',
    Eng: 'Spanish of Spain (Castellano)',
    Es: 'Español de España (Castellano)',
  },
  {
    KEY: 'LangVariant.2',
    Ru: 'Латиноамериканский испанский',
    Eng: 'Latin American Spanish',
    Es: 'español latinoamericano',
  },
  { KEY: 'LangVariant.ANY', Ru: 'любой', Eng: 'any', Es: 'cualquier' },
  {
    KEY: 'InviteFriendsModal.Title',
    Ru: 'Учи испанский вместе с друзьями!',
    Eng: 'Learn Spanish with your friends!',
    Es: '¡Aprende español con amigos!',
  },
  {
    KEY: 'InviteFriendsModal.Text',
    Ru: 'Пригласи друга на занятия и получи 2 урока в подарок',
    Eng: 'Invite a friend to the lesson and get 2 lessons as a gift.',
    Es: 'Invita a un amigo a la clase y llévate 2 clases de regalo.',
  },
  {
    KEY: 'Invite',
    Ru: 'Пригласить друга',
    Eng: 'Invite a friend',
    Es: 'Invitar a un amigo',
  },
  { KEY: 'CourseModal.ActionCancelCreation', Ru: 'Отмена', Eng: 'Cancel' },
  {
    KEY: 'AutoTeacher.SchduleFooterLabelWithBalance',
    Ru:
      '\nНо пока на твоем балансе {{balanceCount}} урока, поэтому сейчас ты можешь выбрать не более {{balanceCount}} преподавателей.',
    Eng:
      'But for now, you have {{balanceCount}} lessons on your balance, so now you can choose no more than {{balanceCount}} teachers.',
    Es:
      'Pero por ahora, tiene {{balanceCount}} lecciones en su saldo, por lo que ahora puede elegir no más de {{balanceCount}} profesores.',
  },
  {
    KEY: 'AutoTeacher.TooltipLessonTakenAnotherTeacher',
    Ru: 'В это время у тебя урок с другим преподавателем',
    Eng: 'At this time you have a lesson with another teacher',
    Es: 'En este momento tienes una lección con otro profesor.',
  },
  {
    KEY: 'AutoTeacher.TooltipLessonTakenRemoveLesson',
    Ru: 'Если ты хочешь заниматься \nс другим преподавателем, отмени занятия с {{teacher}}.',
    Eng: 'If you want to practice\nwith another teacher, cancel classes with {{teacher}}.',
    Es: 'Si quieres practicar\ncon otro profesor, cancelar las clases con {{teacher}}..',
  },
  {
    KEY: 'AutoTeacher.TooltipLessonTakenMaxOfBalance',
    Ru:
      'У тебя выбрано максимальное количество занятий в неделю, с учетом твоего баланса уроков \nЗанятий на балансе: {{balanceCount}}',
    Eng:
      'You have selected the maximum number of lessons per week, taking into account your balance of lessons\nLessons on balance: {{balanceCount}}',
    Es:
      'Ha seleccionado el número máximo de lecciones por semana, teniendo en cuenta su saldo de lecciones\nLecciones sobre el equilibrio: {{balanceCount}}',
  },
  { KEY: 'AboutMe', Ru: 'О себе', Eng: 'About Me', Es: 'Acerca de mí' },
  {
    KEY: 'AutoTeacher.TeachesYears',
    Ru: 'Преподает {{years}}',
    Eng: 'Teaches {{years}}',
    Es: 'Enseña {{year}}',
  },
  {
    KEY: 'SuperAbilities',
    Ru: 'Суперспособности',
    Eng: 'Super abilities',
    Es: 'Súper habilidades',
  },
  {
    KEY: 'AutoTeacherCloseModalTitle',
    Ru: 'Сохранить выбранных преподавателей',
    Eng: 'Save selected teachers',
    Es: 'Guardar profesores seleccionados',
  },
  {
    KEY: 'AutoTeacherCloseModalTitleSecond',
    Ru: 'Ты точно хочешь выйти с экрана выбора преподавателей',
    Eng: 'Are you sure you want to exit the instructor selection screen?',
    Es: '¿Está seguro de que desea salir de la pantalla de selección de instructor?',
  },
  {
    KEY: 'ScheduleIsInConfirmationMode',
    Ru: 'Расписание находится в режиме подтверждения',
    Eng: 'Schedule is in confirmation mode',
    Es: 'El horario está en modo de confirmación',
  },
  {
    KEY: 'NotificationTitle.FirstLesson',
    Ru: 'Ураа! Первое занятие!',
    Eng: 'Yay! First lesson!',
    Es: '¡Bien! ¡Primera lección!',
  },
  {
    KEY: 'NotificationTitle.OneLesson',
    Ru: '¡Hola! На балансе остался всего 1 урок',
    Eng: '¡Hola! Only 1 lesson left on the balance sheet!',
    Es: '¡Hola! Sólo queda 1 lección en el balance!',
  },
  {
    KEY: 'NotificationTitle.RemindLesson',
    Ru: 'Приходи на занятия вовремя',
    Eng: 'Come to class on time',
    Es: 'Llegar puntual a clase',
  },
  {
    KEY: 'NotificationText.FirstLesson',
    Ru:
      'Когда преподаватель начнет занятие, наверху, в баннере с быком, появится кнопка перехода на урок. Удачи! ',
    Eng:
      'When the teacher starts the lesson, there will be a button at the top, in the bull banner, to go to the lesson. Good luck!',
    Es:
      'Cuando el profesor comience la lección, habrá un botón en la parte superior, en el banner del toro, para ir a la lección. Buena suerte.',
  },
  {
    KEY: 'NotificationTip.FirstLesson',
    Ru: 'В случае проблемы пиши куратору',
    Eng: 'If you have a problem, write to your tutor.',
    Es: 'Si tienes algún problema, escribe a tu tutor.',
  },
  {
    KEY: 'NotificationText.OneLesson',
    Ru: 'Не забудь купить новый пакет уроков, если хочешь продолжить занятия.',
    Eng: "Don't forget to buy a new package of lessons if you want to continue classes.",
    Es: 'No olvides comprar un nuevo paquete de lecciones si quieres continuar con las clases.',
  },
  {
    KEY: 'NotificationText.RemindLesson',
    Ru: 'При опоздании на урок более чем на 15 минут урок будет отменен',
    Eng: 'If you are more than 15 minutes late to class, the lesson will be canceled',
    Es: 'Si llega más de 15 minutos tarde a clase, la lección se cancelará',
  },
  {
    KEY: 'AutoTeacher.LessonWithNative',
    Ru: 'урок с носителем',
    Eng: 'lesson with a native',
    Es: 'lección con un nativo',
  },
  {
    KEY: 'Links.PlayStoreToolTip',
    Ru: 'Откройте Google Play',
    Eng: 'Open Google Play',
    Es: 'Abre Google Play',
  },
  {
    KEY: 'Links.AppStoreToolTip',
    Ru: 'Открыть App Store',
    Eng: 'Open App Store',
    Es: 'Abrir tienda de aplicaciones',
  },
  {
    KEY: 'Links.ExtensionStoreToolTip',
    Ru: 'Перейти в магазин расширений',
    Eng: 'Go to extension store',
    Es: 'Ir a la tienda de extensiones',
  },
  {
    KEY: 'Links.TelegramToolTip',
    Ru: 'Напиши нам в Telegram',
    Eng: 'Message us on Telegram',
    Es: 'Envíanos un mensaje en Telegram',
  },
  {
    KEY: 'Links.WhatsappToolTip',
    Ru: 'Смотри нас в WhatsApp',
    Eng: 'Watch us on whatsapp',
    Es: 'Míranos en whatsapp',
  },
  {
    KEY: 'Test.OneAnswer',
    Ru: 'Один вариант ответа',
    Eng: 'One answer',
    Es: 'Una respuesta',
  },
  {
    KEY: 'Test.MultiAnswer',
    Ru: 'Несколько вариантов ответа',
    Eng: 'Multiple answer options',
    Es: 'Varias opciones de respuesta',
  },
  {
    KEY: 'AutoTeacher.WaitingComfirmed',
    Ru: 'Ждем подтверждения от преподавателя',
    Eng: 'We are waiting for confirmation from the teacher',
    Es: 'Estamos esperando la confirmación del profesor.',
  },
  {
    KEY: 'ChooseTeacher',
    Ru: 'Выбрать преподавателя',
    Eng: 'Choose a teacher',
    Es: 'Elige un profesor',
  },
  {
    KEY: 'TrialLessonHasStarted',
    Ru: 'Пробный урок с {{ user }} начался.',
    Eng: 'Trial lesson with {{ user}} has started.',
    Es: 'Ha comenzado la lección de prueba con {{ user }}.',
  },
  {
    KEY: 'RegularLessonHasStarted',
    Ru: 'Урок с {{ user }} начался.',
    Eng: 'Lesson with {{ user }} has started.',
    Es: 'Ha comenzado la regular lección con {{ user }}.',
  },
  {
    KEY: 'ChatFllowThisLink',
    Ru: 'Перейди по ссылке',
    Eng: 'Follow this link',
    Es: 'Siga este enlace',
  },
  {
    KEY: 'ChatToGetToClass',
    Ru: 'чтобы попасть на урок.',
    Eng: 'to get to class.',
    Es: 'para llegar a clase.',
  },
  {
    KEY: 'StudentPaidSecondTimeBtnText2 ',
    Ru: 'Выбрать дату',
    Eng: 'Select date',
    Es: 'Seleccione fecha',
  },
  {
    KEY: 'StudentPaidSecondTimeDesc2',
    Ru: 'Не забудь назначить дату следующего урока :)',
    Eng: "Don't forget to set a date for the next lesson :)",
    Es: 'No olvides fijar una fecha para la próxima lección :)',
  },
  {
    KEY: 'Toast.AutoTeacherSelection',
    Ru: 'Хорошо, все сделаем! :)',
    Eng: "Okay, let's do it! :)",
    Es: 'Bien, ¡hagámoslo! :)',
  },
  { KEY: 'Interests.Animals', Ru: 'Животные', Eng: 'Animals', Es: 'Animales' },
  { KEY: 'Interests.House', Ru: 'Дом', Eng: 'House', Es: 'Casa' },
  { KEY: 'Interests.Culture', Ru: 'Культура', Eng: 'Culture', Es: 'Cultura' },
  {
    KEY: 'Interests.Entertainment',
    Ru: 'Развлечение',
    Eng: 'Entertainment',
    Es: 'Entretenimiento',
  },
  { KEY: 'Interests.Philosophy', Ru: 'Философия', Eng: 'Philosophy', Es: 'Filosofía' },
  { KEY: 'Interests.General', Ru: 'Общее', Eng: 'General ', Es: 'General ' },
  { KEY: 'Priorities.important', Ru: 'важно', Eng: 'important', Es: 'importante' },
  {
    KEY: 'Priorities.notImportant',
    Ru: 'не важно',
    Eng: 'not important',
    Es: 'no importa',
  },
  { KEY: 'Priorities.neutral', Ru: 'нейтрально', Eng: 'neutral', Es: 'neutral' },
  { KEY: 'Priorities.speaking', Ru: 'Говорение', Eng: 'Speaking', Es: 'Discurso' },
  { KEY: 'Priorities.reading', Ru: 'Чтение', Eng: 'Reading', Es: 'Lectura' },
  { KEY: 'Priorities.listening', Ru: 'Аудирование', Eng: 'Listening', Es: 'Escuchand' },
  { KEY: 'Priorities.writing', Ru: 'Письмо', Eng: 'Writing', Es: 'Escribiendo' },
  {
    KEY: 'StudentWorkSheetStep7Title',
    Ru: 'Что для тебя в приоритете?',
    Eng: 'What is your priority?',
    Es: '¿Cuál es tu prioridad?',
  },
  {
    KEY: 'GetICalendarFeed.Description',
    Ru:
      'Чтобы увидеть расписание в своем любимом календаре, вы можете добавить эту ссылку в Google Calendar или Apple iCal.',
    Eng:
      'To see the schedule in your favorite calendar, you can add this link to Google Calendar or Apple iCal.',
    Es:
      'Para ver la programación en su calendario favorito, puede agregar este enlace a Google Calendar o Apple iCal.',
  },
  { KEY: 'SMS.Title', Ru: 'Отправить СМС:', Eng: 'Send SMS:', Es: 'Enviar SMS:' },
  {
    KEY: 'SMS.Description',
    Ru: `У вас начался урок в ${appName}. Сообщите преподавателю, если опаздываете.`,
    Eng: `Your lesson has started at ${appName}. Tell your teacher if you are late.`,
    Es: `Tu lección ha comenzado en la ${appName}. Dile a tu maestro si llegas tarde.`,
  },
  {
    KEY: 'Lession.Passed',
    Ru: 'Пройденные уроки',
    Eng: 'Lessons passed',
    Es: 'Lecciones pasadas',
  },
  {
    KEY: 'AutoTeacher.AllTeachers',
    Ru: 'Все преподаватели',
    Eng: 'All teachers',
    Es: 'Todos los profesores',
  },
  {
    KEY: 'AutoTeacher.TooltipAllTeachers',
    Ru: 'Пропустить настройку фильтра и перейти к просмотру всех преподавателей',
    Eng: 'Skip the filter setting and go to view all teachers',
    Es: 'Omita la configuración del filtro y vaya a ver todos los maestrosh',
  },
  { KEY: 'AutoTeacher.Accent', Ru: 'Акцент', Eng: 'Accent', Es: 'Acento' },
  {
    KEY: 'Schedule.LessonRequestConfirmText',
    Ru: 'Вы действительно хотите отменить запрос на занятие  ',
    Eng: 'Are you sure you want to cancel your class request ',
    Es: '¿Estás seguro de que deseas cancelar tu solicitud de clase ',
  },
  {
    KEY: 'Schedule.LessonRequestCanceled',
    Ru: 'Запрос на занятие ',
    Eng: 'Lesson Request',
    Es: 'Solicitud de lección ',
  },
  {
    KEY: 'Schedule.DateTime',
    Ru: ' {{ date }}  в {{time }} мск',
    Eng: '{{ date }} at {{time }} Moscow time',
    Es: ' {{ date }} a las {{time }} hora de Moscú',
  },
  {
    KEY: 'Schedule.Canceled',
    Ru: ' отменено',
    Eng: ' has been canceled',
    Es: ' ha sido cancelada',
  },
  {
    KEY: 'Feedback.Heading',
    Ru: 'Как вы оцениваете обучение?',
    Eng: 'How do you rate learning?',
    Es: '¿Cómo califica el aprendizaje?',
  },
  {
    KEY: 'Feedback.FitsMyPurpose',
    Ru: 'Соответствует моей цели',
    Eng: 'Fits my purpose',
    Es: 'Se ajusta a mi propósito',
  },
  {
    KEY: 'Feedback.InterestingToDo',
    Ru: 'Интересно заниматься',
    Eng: 'Interesting to do',
    Es: 'interesante para hacer',
  },
  {
    KEY: 'Feedback.Send',
    Ru: 'Отправить',
    Eng: 'Send',
    Es: 'Enviar',
  },
  {
    KEY: 'Feedback.SendLater',
    Ru: 'Оценить позже',
    Eng: 'Rate later',
    Es: 'Calificar después',
  },
  {
    KEY: 'Feedback.HeadingAfterSend',
    Ru: 'Спасибо за обратную связь!',
    Eng: 'Thanks for your feedback!',
    Es: '¡Gracias por tus comentarios!',
  },
  {
    KEY: 'Homework.UseThisWords',
    Ru: 'Используй следующие слова в сочинении',
    Eng: 'Use the following words in your essay',
    Es: 'Usa las siguientes palabras en su ensayo',
  },
  {
    KEY: 'Homework.CountWords',
    Ru: 'Количество слов',
    Eng: 'Word count',
    Es: 'El recuento de palabras',
  },
  {
    KEY: 'Schedule.BookingMessageText',
    Ru:
      'Ученик, {{student}}, предлагает провести {{type}}, {{date}} по мск. Пожалуйста, подтвердите запрос в ближайшее время. Бронирование автоматически отменится, если не будет подтверждения от преподавателя за 8 часов до начала урока.',
    Eng:
      'The student, {{student}}, proposes to spend {{type}}, {{date}} Moscow time. Please confirm your request as soon as possible. The reservation will be automatically canceled if there is no confirmation from the teacher 8 hours before the start of the lesson.',
    Es:
      'El estudiante, {{student}}, propone pasar el {{type}}, {{date}} hora de Moscú. Por favor confirme su solicitud lo antes posible. La reserva quedará automáticamente cancelada si no hay confirmación por parte del profesor 8 horas antes del inicio de la lección.',
  },
  {
    KEY: 'Schedule.BookingTitle',
    Ru: 'Бронирование урока',
    Eng: 'Book a lesson',
    Es: 'Reserva una lección',
  },
  {
    KEY: 'UserNotFound',
    Ru: 'Пользователь не найден!',
    Eng: 'User not found!',
    Es: '¡No se encuentra el usuario!',
  },
  {
    KEY: 'Homework.MadeEsewhereTooltip',
    Ru: 'Например, письменно в тетради',
    Eng: 'For example, writing in a notebook',
    Es: 'Por ejemplo, escribir en un cuaderno.',
  },
  {
    KEY: 'Schedule.AutoDeleteLessonText',
    Ru:
      'Автоматическое удаление заявки на урок {{date}}. Преподаватель не подтвердил урок за 8 часов до начала',
    Eng:
      'Automatic deletion of application for lesson {{date}}. The teacher did not confirm the lesson 8 hours before the start',
    Es:
      'Eliminación automática de solicitud de lección {{date}}. El profesor no confirmó la lección 8 horas antes del inicio.',
  },
  {
    KEY: 'CancelLessonNotifyText',
    Ru:
      'Если вдруг у тебя возникла неотложная ситуация, из-за которой ты вынужден отменить или перенести урок менее чем за 8 часов до его начала, учти, что урок всё равно будет вычтен с баланса.',
    Eng:
      'If you suddenly have an emergency that forces you to cancel or reschedule a lesson less than 8 hours before it starts, please note that the lesson will still be deducted from your balance.',
    Es:
      'Si de repente tienes una emergencia que te obliga a cancelar o reprogramar una lección menos de 8 horas antes de que comience, ten en cuenta que la lección aún se deducirá de tu saldo.',
  },
  {
    KEY: 'CancelLessonNotifyTitle',
    Ru: 'С баланса был списан урок',
    Eng: 'The lesson was written off the balance',
    Es: 'La lección fue cancelada del saldo.',
  },
  {
    KEY: '2AttemptsHintOpen',
    Ru: 'Осталось 2 попытки до открытия подсказки',
    Eng: '2 attempts left before the hint opens',
    Es: 'Quedan 2 intentos antes de que se abra la pista.',
  },
  { KEY: 'HomeworkHide', Ru: 'Домашние задания', Eng: 'Homework', Es: 'Deberes' },
  {
    KEY: 'Homework.Hide',
    Ru: 'Счетчик новых домашек',
    Eng: 'New home counter',
    Es: 'Nuevo contador de visitas',
  },
  {
    KEY: 'Homework.OptionHide',
    Ru: 'Скрывать',
    Eng: 'Hide',
    Es: 'Ocultar',
  },
  {
    KEY: 'Homework.OptionShow',
    Ru: 'Показывать',
    Eng: 'Show',
    Es: 'Mostrar',
  },
  {
    KEY: '1AttemptsHintOpen',
    Ru: 'Осталась 1 попытка до открытия подсказки',
    Eng: '1 attempt left before the hint opens',
    Es: 'Queda 1 intento antes de que se abra la pista.',
  },
  {
    KEY: 'Open',
    Ru: 'Открыть',
    Eng: 'Open',
    Es: 'Abierto',
  },
  {
    KEY: 'ShowMore',
    Ru: 'Показать больше',
    Eng: 'Show more',
    Es: 'Mostrar más',
  },
  {
    KEY: 'Vocabulary.AddAllWords',
    Ru: 'Добавить все слова',
    Eng: 'Add all words',
    Es: 'Añadir todas las palabras',
  },
  {
    KEY: 'Vocabulary.CancelAddition',
    Ru: 'Отменить добавление',
    Eng: 'Cancel addition',
    Es: 'Cancelar adición',
  },
  {
    KEY: 'Vocabulary.AllWordsAdded',
    Ru: 'Все слова добавлены',
    Eng: 'All words added',
    Es: 'Todas las palabras agregadas',
  },
  {
    KEY: 'Buy.CheckmarkTheOffer',

    Ru:
      'Оплачивая покупку, я соглашаюсь с <a href="{{offerLink}}" target="_blank">оффертой</a> и  <a href="{{policyLink}}" target="_blank" >политикой использования данных</a>.  <a href="{{contactsLink}}" target="_blank">Наши контакты</a>.',
    Eng:
      'By paying for the purchase, I agree to the  and  <a href="{{offerLink}}" target="_blank">offer</a> and <a href="{{policyLink}}" target="_blank" >data usage policy</a>. <a href="{{contactsLink}}" target="_blank">Our contacts</a>.',
    Es:
      'Al pagar la compra acepto la  y  <a href="{{offerLink}}" target="_blank">oferta</a> y <a href="{{policyLink}}" target="_blank" >política de uso de datos</a>. <a href="{{contactsLink}}" target="_blank">Nuestros Contactos</a>.',
  },
  {
    KEY: 'Buy.RubCheckmarkTheOffer',

    Ru:
      'Нажимая "Выбрать/купить/начать", даю <a href="{{rubAgreementLink}}" target="_blank">согласие</a> на обработку персональных данных в соответствии с <a href="{{rubPolicyLink}}" target="_blank" >Политикой обработки персональных данных</a> , а также соглашаюсь с условиями <a href="{{rubOfferLink}}" target="_blank">Оферты на заключение договора</a>.',
    Eng:
      'By clicking "Select/Buy/Start", I <a href="{{rubAgreementLink}}" target="_blank">agree</a> to the processing of personal data in accordance with the <a href="{{rubPolicyLink}}" target="_blank" >Personal Data Processing Policy</a>, and also agree to the terms of the <a href="{{rubOfferLink}}" target="_blank">Offer to conclude an agreement</a>.',
    Es:
      'Al hacer clic en "Seleccionar/Comprar/Iniciar", doy <a href="{{rubAgreementLink}}" target="_blank">consentimiento</a> para el procesamiento de datos personales de acuerdo con <a href=" {{rubPolicyLink} }" target="_blank" >Política de procesamiento de datos personales</a>, y también acepta los términos de una <a href="{{rubOfferLink}}" target="_blank">Oferta para celebrar un contrato</a>.',
  },
  {
    KEY: 'LoginAgain',
    Ru: 'Войдите снова',
    Eng: 'Login again',
    Es: 'Mostrar más',
  },
  {
    KEY: 'LoginAgainBeforeChangeEmail',
    Ru: 'Нужно заново залогиниться через текущую почту, чтобы сменить на новую.',
    Eng: 'You need to log in again using your current email to change to a new one.',
    Es:
      'Debes iniciar sesión nuevamente usando tu correo electrónico actual para cambiar a uno nuevo.',
  },
  {
    KEY: 'HowVoiceNotes',
    Ru: 'Как озвучивать заметки',
    Eng: 'How to voice notes',
    Es: 'Cómo expresar notas',
  },
  {
    KEY: 'Audiotraining',
    Ru: 'Аудио тренировки',
    Eng: 'Audio training',
    Es: 'entrenamiento de audio',
  },
  {
    KEY: 'AudioNotesHintTitle',
    Ru: 'Для чего озвучивать заметки',
    Eng: 'Why voice notes?',
    Es: '¿Por qué notas de voz?',
  },
  {
    KEY: 'AudioNotesHintDescr',
    Ru:
      'Создавай индивидуальные аудио упражнения. Озвучь фразу на двух языках и слушай когда появится время. В таком режиме ты сможешь совершать аудио тренировки на пробежке или идя на работу, просто слушая заметки через мобильное приложение.',
    Eng:
      'Create customized audio exercises. Voice the phrase in two languages ​​and listen when you have time. In this mode, you can perform audio workouts while jogging or going to work, simply listening to notes through the mobile application.',
    Es:
      'Crea ejercicios de audio personalizados. Expresa la frase en dos idiomas y escúchala cuando tengas tiempo. En este modo, puedes realizar entrenamientos de audio mientras haces jogging o vas a trabajar, simplemente escuchando notas a través de la aplicación móvil.',
  },
  {
    KEY: 'AudioNotesHintTitleLink',
    Ru: 'Объясняем про заметки тут',
    Eng: 'We explain about notes here',
    Es: 'Te explicamos sobre las notas aquí.',
  },
  {
    KEY: 'AudioNotesHint.Step1',
    Ru:
      'Сначала проговори фразу на испанском, затем сделай паузу, и после этого скажи перевод фразы. ',
    Eng:
      'First, say the phrase in Spanish, then pause, and then say the translation of the phrase.',
    Es:
      'Primero, diga la frase en español, luego haga una pausa y luego diga la traducción de la frase.',
  },
  {
    KEY: 'AudioNotesHint.Step2',
    Ru:
      'После того, как весь список фраз и слов был записан, начни озвучивать все в обратном порядке. Сначала фраза на русском, затем пауза и после нее фраза на испанском.',
    Eng:
      'After the entire list of phrases and words has been written down, start voicing everything in reverse order. First a phrase in English, then a pause and after that a phrase in Spanish.',
    Es:
      'Una vez escrita la lista completa de frases y palabras, comience a expresar todo en orden inverso. Primero una frase en ruso, luego una pausa y después una frase en español.',
  },
  {
    KEY: 'CopyAll',
    Ru: 'Скопировать все',
    Eng: 'Copy all',
    Es: 'Copie todo',
  },
  {
    KEY: 'CopyAll',
    Ru: 'Скопировать все',
    Eng: 'Copy all',
    Es: 'Copie todo',
  },

  { KEY: 'Privacy.Policy', Ru: 'Политика', Eng: 'Policy', Es: 'Política' },
  { KEY: 'Privacy.Offer', Ru: 'Оферта', Eng: 'Offer', Es: 'Oferta' },
  {
    KEY: 'CreatedByAI',
    Ru: 'Создано ИИ',
    Eng: 'Created by AI',
    Es: 'Creado por IA',
  },
  {
    KEY: 'GenerateAIStory',
    Ru: 'Сгенерировать историю',
    Eng: 'Generate story',
    Es: 'Generar historia',
  },
  {
    KEY: 'GenerateAIStoryAgain',
    Ru: 'Сгенерировать другую историю',
    Eng: 'Generate another story',
    Es: 'Generar otra historia',
  },
  {
    KEY: 'Generation',
    Ru: 'Генерация...',
    Eng: 'Generation...',
    Es: 'Generación...',
  },
  {
    KEY: 'StoryLike',
    Ru: 'Что понравилось в истории? Напиши об этом тут',
    Eng: 'Did you not like something? Write about it here',
    Es: '¿No te gustó algo? Escribe sobre esto aquí',
  },
  {
    KEY: 'StoryDontLike',
    Ru: 'Что-то не понравилось? Напиши об этом тут',
    Eng: 'What did you like about the story? Write about it here',
    Es: '¿Qué te gustó de la historia? Escribe sobre esto aquí',
  },
  {
    KEY: 'WillGenerateViaAI',
    Ru: 'Текст будет сгенерирован с помощью ИИ',
    Eng: 'The text will be generated using AI',
    Es: 'El texto se generará utilizando IA',
  },
  {
    KEY: 'RecordNote',
    Ru: 'Записать аудио для заметки',
    Eng: 'Record audio for a note',
    Es: 'Grabar audio para una nota',
  },
  {
    KEY: 'MicAccess',
    Ru: 'Доступ к микрофону',
    Eng: 'Microphone access',
    Es: 'Acceso al micrófono',
  },
  {
    KEY: 'AudioPresentation',
    Ru: 'Аудио презентация',
    Eng: 'Audio presentation',
    Es: 'presentación de audio',
  },
  {
    KEY: 'MicAccess.Step1',
    Ru: 'Чтобы мы смогли записать ваш голос, разрешите доступ к микрофону в вашем бразуере.',
    Eng: 'To allow us to record your voice, please allow microphone access in your browser.',
    Es: 'Para permitirnos grabar su voz, permita el acceso al micrófono en su navegador.',
  },
  {
    KEY: 'AudioNote',
    Ru: 'Аудио запись',
    Eng: 'Audio note',
    Es: 'Nota de audio',
  },
  {
    KEY: 'AudioExist',
    Ru: 'К этой заметке уже создана аудио запись.Если записать новую, то старая будет удалена.',
    Eng:
      'An audio recording has already been created for this note. If you record a new one, the old one will be deleted.',
    Es:
      'Ya se ha creado una grabación de audio para esta nota. Si graba uno nuevo, el antiguo se eliminará.',
  },
  {
    KEY: 'CheckMic',
    Ru: 'Проверьте микрофон и нажмите на кнопку “Начать запись”',
    Eng: 'Check the microphone and click on the “Start Recording” button”',
    Es: 'Verifique el micrófono y haga clic en el botón “Iniciar grabación”',
  },
  {
    KEY: 'StartRecord',
    Ru: 'Начать запись',
    Eng: 'Start recording',
    Es: 'Empezar a grabar',
  },
  {
    KEY: 'AudiorecordError',
    Ru: 'Не удалось записать. Попробуйте снова',
    Eng: 'Failed to record. Try again',
    Es: 'No se pudo grabar. Intentar otra vez',
  },
  {
    KEY: 'ThisEmailAlreadyUse',
    Ru: 'Этот email уже используется',
    Eng: 'This email is already in use',
    Es: 'Este correo electrónico ya está en uso',
  },
  {
    KEY: 'AITranslationButton.Idle',
    Ru: 'Перевести текст',
    Eng: 'Translate a text',
    Es: 'Traducir un texto',
  },
  {
    KEY: 'AITranslationButton.Proccess',
    Ru: 'Перевод текста',
    Eng: 'Translation text',
    Es: 'Texto de traducción',
  },
  {
    KEY: 'AITranslationButton.Done',
    Ru: 'Переведено',
    Eng: 'Translated',
    Es: 'Traducido',
  },
  {
    KEY: 'Networking.Title',
    Ru: 'Поболтаем по-испански?',
    Eng: 'Shall we chat in Spanish?',
    Es: '¿Charlamos en español?',
  },
  {
    KEY: 'Networking.Description',
    Ru:
      'Практикуй язык весело и бесплатно. Подключайся к <a href="{{networkingLink}}" target="_blank">нетворкингу</a> на испанском. \n\n Мы подбираем для вас другого ученика с похожими интересами и уровнем. А вы уже сможете договориться об онлайн-встрече, чтобы практиковать разговорный навык. Вопросы для <a href="{{networkingLink}}" target="_blank">нетворкинга</a> публикуем в тг.\n\n Присоединяйтесь – будет круто! :)',
    Eng:
      'Practice your language in a fun and free way. Join <a href="{{networkingLink}}" target="_blank">networking</a> events in Spanish. \n\n We select for you another student with similar interests and level. And you can already arrange an online meeting to practice your speaking skills. We publish questions for <a href="{{networkingLink}}" target="_blank">networking</a> in tg.\n\n Join us – it will be cool! :)',
    Es:
      'Practica tu idioma de una forma divertida y gratuita. Únete a eventos de <a href="{{networkingLink}}" target="_blank">networking</a> en español.\n\n Seleccionamos para ti otro estudiante con intereses y nivel similar. Y ya puedes concertar una reunión online para practicar tus habilidades orales. Publicamos preguntas para <a href="{{networkingLink}}" target="_blank">networking</a> en tg.\n\n Únase a nosotros, ¡será genial! :)',
  },
  {
    KEY: 'Networking.Button',
    Ru: 'Я с вами!',
    Eng: `I'm with you!`,
    Es: '¡Estoy contigo!',
  },
  {
    KEY: 'AIAssistant',
    Ru: 'ИИ ассистент',
    Eng: 'AI assistant',
    Es: 'Asistente de IA',
  },
  {
    KEY: 'AIAssistant.Step1',
    Ru: 'Есть вопросы по домашнему заданию?',
    Eng: 'Have questions about homework?',
    Es: '¿Tienes preguntas sobre la tarea?',
  },
  {
    KEY: 'AIAssistant.Step2',
    Ru: 'Спроси ИИ, он постарается помочь.',
    Eng: 'Ask the AI assistant, it will try to help.',
    Es: 'Pregunta al asistente de IA, intentará ayudar.',
  },
  {
    KEY: 'AIAssistant.Example1',
    Ru: 'Почему у слова vivimos такое окончание?',
    Eng: 'Why does the word vivimos have this ending?',
    Es: '¿Por qué la palabra vivimos tiene esta terminación?',
  },
  {
    KEY: 'AIAssistant.Example2',
    Ru: 'В чем разница между Gusta и Gustan?',
    Eng: `What's difference between Gusta and Gustan?`,
    Es: '¿Cuál es la diferencia entre Gusta y Gustan?',
  },
  {
    KEY: 'AIAssistant.Example3',
    Ru: 'Разница между ser и estar?',
    Eng: `Difference between ser and estar?`,
    Es: '¿Diferencia entre ser y estar?',
  },
  {
    KEY: 'AIAssistant.Example4',
    Ru: 'Как правильно использовать предлоги “por” и “para”?',
    Eng: `How to use the prepositions “por” and “para” correctly?`,
    Es: '¿Cómo utilizar correctamente las preposiciones “por” y “para”?',
  },
  {
    KEY: 'AIAssistant.Example5',
    Ru: 'Основные правила использования сослагательного наклонения (subjuntivo)?',
    Eng: `Basic rules for using the subjunctive mood (subjuntivo)?`,
    Es: '¿Reglas básicas para usar el modo subjuntivo (subjuntivo)?',
  },
  {
    KEY: 'AIAssistant.Example6',
    Ru: 'Как правильно использовать “pretérito perfecto” и “pretérito imperfecto”?',
    Eng: `How to use “pretérito perfecto” and “pretérito imperfecto” correctly?`,
    Es: '¿Cómo utilizar correctamente “pretérito perfecto” y “pretérito imperfecto”?',
  },
  {
    KEY: 'AIAssistant.Generation',
    Ru: 'Размышляю над ответом',
    Eng: `I'm thinking about the answer`,
    Es: 'Estoy pensando en la respuesta',
  },
  {
    KEY: 'AIAssistant.Placeholder',
    Ru: 'Напиши свой вопрос тут',
    Eng: `Write your question here`,
    Es: 'Escribe tu pregunta aquí',
  },
  {
    KEY: 'AIAssistant.Error',
    Ru: 'Ошибка в запросе. Попробуйте очистить контекст чата',
    Eng: 'Error in request. Try clearing context of chat',
    Es: 'Error en la solicitud. Intenta borrar el contexto del chat.',
  },
  {
    KEY: 'Homework.QuickCheckTooltip',
    Ru: 'Сначала отправим на проверку ИИ',
    Eng: 'First of all, I will send a check',
    Es: 'Primero que nada, enviaré un cheque',
  },

  {
    KEY: 'DescribeTheProblem',
    Ru: 'Пожалуйста, опишите проблему',
    Eng: 'Please describe the problem',
    Es: 'Por favor describe el problema',
  },
  {
    KEY: 'Message',
    Ru: 'Текст сообщения',
    Eng: 'Message',
    Es: 'Mensaje',
  },

  {
    KEY: 'AutoTeacher.TimezoneTooltip',
    Ru: 'Часовой пояс можно изменить в настройках пользователя',
    Eng: 'The time zone can be changed in user settings',
    Es: 'La zona horaria se puede cambiar en la configuración del usuario.',
  },
  {
    KEY: 'Schedule.LessonCannotMaximimum4Lessons',
    Ru: 'Этот урок нельзя отменить, потому что в месяц можно отменить максимум 4 урока.',
    Eng: 'This lesson cannot be canceled because a maximum of 4 lessons can be canceled per month.',
    Es:
      'Esta lección no se puede cancelar porque se pueden cancelar un máximo de 4 lecciones por mes.',
  },
  {
    KEY: 'Schedule.LessonCannotMaximimum4LessonsTooltip',
    Ru:
      'В месяц можно отменять не более 4 уроков. Если тебе не подходит это время урока, ты можешь его перенести на другой день.',
    Eng:
      'You can cancel no more than 4 lessons per month. If this lesson time does not suit you, you can reschedule it to another day.',
    Es:
      'No puedes cancelar más de 4 lecciones por mes. Si este horario de lección no te conviene, puedes reprogramarlo para otro día.',
  },
  {
    KEY: 'AutoTeacher.ExitFromScreen',
    Ru: 'Ты точно хочешь выйти с экрана выбора преподавателей?',
    Eng: 'Ty tochno want to show vybora prepodavateley s screen?',
    Es: '¿Ty tochno quiere mostrar la pantalla de vybora prepodavateley?',
  },
  {
    KEY: 'AutoTeacher.ScheduleSentForConfirmationTitle',
    Ru: 'Расписание отправлено на подтверждение',
    Eng: 'Schedule sent for confirmation',
    Es: 'Horario enviado para confirmación',
  },
  {
    KEY: 'AutoTeacher.ScheduleSentForConfirmationText',
    Ru:
      'Учителю нужно время, чтобы подтвердить урок. Ты можешь написать куратору, чтобы ускорить процесс подтверждения.\n \n  Если урок не будет подтвержден, то он отменится за 8 часов до его начала.',
    Eng:
      'The teacher needs time to confirm the lesson. You can write to the curator to speed up the confirmation process.\n \n  If the lesson is not confirmed, it will be canceled 8 hours before it starts.',
    Es:
      'Uchitelyu urgente vremya, chtoby podtverdit urok. Ty mojesh napisat curadoru, chtoby uskorit proceso podtverjdeniya.\n \n  Si la lección no se confirma, se cancelará 8 horas antes de su inicio.',
  },
  {
    KEY: 'AutoTeacher.ChooseAll',
    Ru: 'выбрать все',
    Eng: 'choose all',
    Es: 'elegir todos',
  },
  {
    KEY: 'RestorePassword',
    Ru: 'Восстановить пароль',
    Eng: 'Restore password',
    Es: 'Restaurar contraseña',
  },
  {
    KEY: 'SendMessageSuccess',
    Ru: 'Cообщение успешно отправлено!!!',
    Eng: 'Message sent successfully!!!',
    Es: '¡¡¡Mensaje enviado con éxito!!!',
  },
  {
    KEY: 'ReloadForLogin',
    Ru: 'Если кнопка не стала активной, перезагрузите страницу',
    Eng: 'If the button does not become active, reload the page',
    Es: 'Si el botón no se activa, recarga la página.',
  },
  {
    KEY: 'Manager.Description.Topup',
    Ru: 'Пополнение баланса',
    Eng: 'Top up balance',
    Es: 'Recargar saldo',
  },
  {
    KEY: 'JoinConference',
    Ru: 'Войти в конференцию',
    Eng: 'Join the conference',
    Es: 'Únase a la conferencia',
  },
  {
    KEY: 'Classroom.AddFactory',
    Ru: 'Добавить упражнение из фабрики',
    Eng: 'Add an exercise from the factory',
    Es: 'Añadir ejercicio de fábrica',
  },
  {
    KEY: 'Classroom.AddAIBlock',
    Ru: 'Добавить блок ИИ',
    Eng: 'Add an AI block',
    Es: 'Añadir bloque AI',
  },

  {
    KEY: 'OfferBuy.Title',
    Ru: 'Пожалуйста, ознакомьтесь с важной информацией перед покупкой',
    Eng: 'Please read important information before purchasing',
    Es: 'Lea la información importante antes de comprar.',
  },
  {
    KEY: 'OfferBuy.AgreeCheckbox',
    Ru:
      'Я соглашаюсь с условиями оферты и подтверждаю, что ознакомился с важной информацией об использовании уроков, включая срок их действия и условия возврата.',
    Eng:
      'I agree to the terms of the offer and confirm that I have read the important information about the use of lessons, including their validity period and return conditions.',
    Es:
      'Acepto los términos de la oferta y confirmo que he leído la información importante sobre el uso de las lecciones, incluido su período de validez y condiciones de devolución.',
  },
  {
    KEY: 'OfferBuy.Rules',
    Ru: `Условия использования уроков\n\n 1. У каждого пакета есть свой срок использования: для пакета на 4 урока 60 дней, для 8 уроков - 90 дней, для 16 уроков - 150 дней, для 32 уроков - 180 дней. Если не пройти занятия в указанный срок, то уроки сгорят.\n\n 2. Дисциплина в изучении языков - это главный залог успеха. Если не успели пройти уроки в срок, то его можно продлить, просто купив новый пакет.`,
    Eng: `Terms of use of lessons\n\n 1. Each package has its own period of use: for a package for 4 lessons 60 days, for 8 lessons - 90 days, for 16 lessons - 150 days, for 32 lessons - 180 days. If you do not complete the classes within the specified period, the lessons will be lost.\n\n 2. Discipline in learning languages ​​is the main key to success. If you didn’t manage to complete the lessons on time, you can extend it by simply purchasing a new package.`,
    Es: `Condiciones de uso de las lecciones.\n\n1. Cada paquete tiene su propio período de uso: para un paquete de 4 lecciones - 60 días, para 8 lecciones - 90 días, para 16 lecciones - 150 días, para 32 lecciones - 180 días. Si no completa las clases dentro del período especificado, las lecciones se perderán.\n\n 2. La disciplina en el aprendizaje de idiomas es la principal clave del éxito. Si no lograste completar las lecciones a tiempo, puedes extenderlas simplemente comprando un nuevo paquete.`,
  },
  {
    KEY: 'ShowExamples',
    Ru: 'Показать варианты употребления',
    Eng: 'Show usage options',
    Es: 'Mostrar opciones de uso',
  },
  {
    KEY: 'NotifyChromeTitle',
    Ru: 'Используйте Google Chrome',
    Eng: 'Use Google Chrome',
    Es: 'Utilice Google Chrome',
  },
  {
    KEY: 'NotifyChromeDesc',
    Ru: 'Все функции платформы работают корректно в браузере Google Chrome.',
    Eng: 'All platform functions work correctly in the Google Chrome browser.',
    Es: 'Estas funciones de plataformas funcionan correctamente en el navegador Google Chrome.  ',
  },
  {
    KEY: 'RestorePassword.SentPasswordText',
    Ru:
      'Мы отправили вам пароль на вашу почту, пожалуйста, проверьте папку спам. В случае, проблем напишите куратору или в тех. поддержку.',
    Eng:
      'We have sent you a password to your email, please check your spam folder. In case of problems, write to the curator or tech. support.',
    Es:
      'Te hemos enviado una contraseña a tu correo electrónico, por favor revisa tu carpeta de spam. En caso de problemas, escribir al curador o al técnico. apoyo.',
  },
  {
    KEY: 'Plan.TodayTasks',
    Ru: 'Задания на сегодня',
    Eng: 'Tasks for today',
    Es: 'Tareas para hoy',
  },
  {
    KEY: 'Plan.DailyProgress',
    Ru: 'Индикатор активности',
    Eng: 'Activity indicator',
    Es: 'Indicador de actividad',
  },
  {
    KEY: 'Plan.Button',
    Ru: 'Посмотреть план обучения',
    Eng: 'View training plan',
    Es: 'Ver plan de entrenamiento',
  },
  {
    KEY: 'Plan.NewDay',
    Ru: ' новый день',
    Eng: ' new day',
    Es: ' nuevo dia',
  },
  {
    KEY: 'Plan.NewWord',
    Ru: ' новыe слова в словаре',
    Eng: ' new words in the dictionary',
    Es: ' nuevas palabras en el diccionario',
  },
  {
    KEY: 'Plan.HomeWork',
    Ru: ' выполненная домашка',
    Eng: ' completed homework',
    Es: ' tarea cumplida',
  },
  {
    KEY: 'Plan.CompletedDailyTask',
    Ru: ' выполненные задания на сегодня',
    Eng: ' completed tasks for today',
    Es: ' tareas completadas para hoy',
  },
  {
    KEY: 'Plan.LearnedWord',
    Ru: ' выученные слова',
    Eng: ' learned words',
    Es: ' palabras aprendidas',
  },
  {
    KEY: 'Plan.Network',
    Ru: ' нетворкинг',
    Eng: ' networking',
    Es: ' networking',
  },
  {
    KEY: 'Plan.individualTasks',
    Ru: 'План самостоятельных занятий',
    Eng: 'Self-study plan',
    Es: 'Plan de autoestudio',
  },
  {
    KEY: 'Plan.IntermediateGoal',
    Ru: 'Промежуточная',
    Eng: 'Intermediate',
    Es: 'Intermedio',
  },
  {
    KEY: 'Plan.MainGoal',
    Ru: 'Основная',
    Eng: 'Main',
    Es: 'Principal',
  },
  {
    KEY: 'Plan.RegenerateDescription',
    Ru:
      'При перегенерации у вас изменится распорядок заданий и текущий страйк заданий. Индикатор активности останется на том же уровне',
    Eng:
      'When regenerating, your task order and current task strike will change. Activity indicator will remain at the same level',
    Es:
      'Al regenerarse, su orden de tareas y la huelga actual de tareas cambiarán. Indicador de actividad se mantendrá al mismo nivel',
  },
  {
    KEY: 'Plan.Day',
    Ru: ' День',
    Eng: 'Day',
    Es: 'Dia',
  },
  {
    KEY: 'Plan.FewDay',
    Ru: 'Ваш 30-дневный план подходит к концу. Сгенерировать новый?',
    Eng: 'Your 30-day plan is coming to an end. Generate a new one?',
    Es: 'Tu plan de 30 días está llegando a su fin. ¿Generar uno nuevo?',
  },
  {
    KEY: 'Plan.ChangedLevel',
    Ru: 'У вас сменился уровень. Перерегенерировать задания с учетом вашего уровня?',
    Eng: 'Your level has changed. Regenerate tasks based on your level?',
    Es: 'Tu nivel ha cambiado. ¿Regenerar tareas según tu nivel?',
  },
  {
    KEY: 'Plan.GetRefreshedPlan',
    Ru: 'Получить обновленный план',
    Eng: 'Get an updated plan',
    Es: 'Obtenga un plan actualizado',
  },
  {
    KEY: 'Plan.GeneratePlan',
    Ru: 'Сгенерировать план',
    Eng: 'Generate a plan',
    Es: 'Generar plan',
  },
  {
    KEY: 'Plan.NotExist',
    Ru: 'У вас еще нет созданного индивидуального учебного плана',
    Eng: `You don't have an individual curriculum created yet`,
    Es: 'Todavía no tienes un plan de estudios personalizado creado',
  },
  {
    KEY: 'Plan.ScoreDescr',
    Ru:
      'Выполняйте ежедневные задания, чтобы получать очки согласно приведенной ниже таблице. Ваша цель — поддерживать свой прогресс на высоком уровне! Обучение языкам требует регулярного внимания, даже если это небольшие усилия каждый день. Мы рекомендуем стремиться к максимальному выполнению ежедневных заданий, чтобы добиться лучших результатов.',
    Eng: `Complete daily tasks to earn points according to the table below. Your goal is to maintain your progress at a high level! Learning languages ​​requires regular attention, even if it's a small effort every day. We recommend that you strive to complete daily tasks as much as possible to achieve better results.`,
    Es:
      'Complete tareas diarias para ganar puntos de acuerdo con la siguiente tabla. ¡Tu objetivo es mantener tu progreso a un alto nivel! Aprender idiomas requiere una atención regular, aunque sea un pequeño esfuerzo cada día. Le recomendamos que se esfuerce por completar las tareas diarias tanto como sea posible para lograr mejores resultados.',
  },
  {
    KEY: 'Homework.New.Tab',
    Ru: 'Новые',
    Eng: 'New',
    Es: 'Nuevos',
  },
  {
    KEY: 'Homework.Completed.Tab',
    Ru: 'Выполненные',
    Eng: 'Completed',
    Es: 'Ejecutados',
  },
  {
    KEY: 'Auth.AccountDeactivated',
    Ru: 'Аккаунт отключен, обратитесь к менеджеру',
    Eng: 'Account disabled, contact manager',
    Es: 'Cuenta inhabilitada, contacta al administrador',
  },
  {
    KEY: 'Plan.ButtonDescr',
    Ru:
      'План будет создан по вашим целям и интересам на ближайшие 30 дней. На каждый день будут задания по испанскому языку. Мы рекомендуем поддерживать индикатор активности всегда выше 70!',
    Eng:
      'A plan will be created based on your goals and interests for the next 30 days. There will be Spanish assignments for each day. We recommend keeping your activity indicator above 70 at all times!',
    Es:
      'Se creará un plan basado en tus objetivos e intereses para los próximos 30 días. Habrá tareas de español para cada día. ¡Recomendamos mantener su indicador de actividad por encima de 70 en todo momento!',
  },
  {
    KEY: 'AutoTeacher.NotFound',
    Ru: 'Ой, не получилось найти. Попробуйте еще раз',
    Eng: `Oh, I couldn't find it. try again`,
    Es: 'Ah, no pude encontrarlo. intentar otra vez',
  },
  {
    KEY: 'Schedule.TeacherCancelledLessonTitle',
    Ru: 'Урок отменен преподавателем',
    Eng: 'Lesson canceled by teacher',
    Es: 'Lección cancelada por el profesor',
  },
  {
    KEY: 'Schedule.TeacherCancelledLessonText',
    Ru: 'Преподаватель отменил урок {{date}}. Пожалуйста, выберите другое время.',
    Eng: 'The teacher canceled the lesson on {{date}}. Please choose another time.',
    Es: 'El profesor canceló la lección el {{date}}. Por favor, elija otro horario.',
  },
]
