import React from 'react'
import * as Styled from './filter-teachers.styles'
import { RadioGroup } from '@material-ui/core'
import CustomMaterialRadioButton from '@components/Checkbox/CustomMaterialRadioButton'
import { COUNTRIES, FILTER_AGES, GENDERS } from '..'
import { useTranslation } from 'react-i18next'
import { APP_CONFIG } from '@utils/constants'
const Step3Component = ({
  gender,
  country,
  age,
  langVariant,
  onChange,
}: {
  gender: string
  country: string
  age: number
  langVariant: string
  onChange: (data: { name: any; value: string }) => void
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Styled.Title>{t('AutoTeacher.Step3.Title')}</Styled.Title>
      <Styled.Desc>{t('AutoTeacher.Step3.Desc')}</Styled.Desc>
      <Styled.SecondTitle>{t('AutoTeacher.Step3.GenderTitle')}</Styled.SecondTitle>
      <RadioGroup
        aria-label="gender"
        name="gender"
        value={gender}
        onChange={e => onChange({ name: 'gender', value: e.target.value })}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {GENDERS.map(gender => (
          <Styled.RadionButtonItem key={gender.value}>
            <CustomMaterialRadioButton label={t(gender.label)} name={gender.value} />
          </Styled.RadionButtonItem>
        ))}
      </RadioGroup>

      <Styled.SecondTitle>{t('AutoTeacher.Step3.AgeTitle')}</Styled.SecondTitle>
      <RadioGroup
        aria-label="age"
        name="age"
        value={age}
        onChange={e => onChange({ name: 'age', value: e.target.value })}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {FILTER_AGES.map(age => (
          <Styled.RadionButtonItem key={age.value}>
            <CustomMaterialRadioButton label={t(age.label)} name={age.value} />
          </Styled.RadionButtonItem>
        ))}
      </RadioGroup>

      {/* <Styled.SecondTitle>{t('AutoTeacher.Step3.CountryTitle')}</Styled.SecondTitle>
      <RadioGroup
        aria-label="country"
        name="country"
        value={country}
        onChange={e => onChange({ name: 'country', value: e.target.value })}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {COUNTRIES.map(country => (
          <Styled.RadionButtonItem>
            <CustomMaterialRadioButton label={t(country.label)} name={country.value} />
          </Styled.RadionButtonItem>
        ))}
      </RadioGroup> */}

      <Styled.SecondTitle>{t('AutoTeacher.Step3.LangVariant')}</Styled.SecondTitle>
      <RadioGroup
        aria-label="langVariant"
        name="langVariant"
        value={langVariant}
        onChange={e => onChange({ name: 'langVariant', value: e.target.value })}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {APP_CONFIG.typeOfLangs?.items?.map(variant => (
          <Styled.RadionButtonItem key={variant.value}>
            <CustomMaterialRadioButton label={t(variant.label)} name={variant.value} />
          </Styled.RadionButtonItem>
        ))}
        <Styled.RadionButtonItem key={'ANY'}>
          <CustomMaterialRadioButton label={t('LangVariant.ANY')} name={'ANY'} />
        </Styled.RadionButtonItem>
      </RadioGroup>
    </>
  )
}

export default Step3Component
