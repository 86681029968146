import React, { useMemo, useState } from 'react'
import * as Styled from './reschedule-lesson.styles'
import { Calendar } from 'react-date-range'
import moment from 'moment-timezone'
import Button from '@components/TeacherButton'
import { IBusinessHours, IProfile, IStudentProfile } from '@models/IProfile'
import { useEffect } from 'react'
import { addEvent, deleteEvent, getTeacherCalendarEvents, updateEvent } from '@servise/calendarAPI'
import { CALENDAR_EVENT_TYPES, getRateLesson, getTeacherRatesInfo } from '@utils/constants'
import { IEvent } from '@models/ICalendar'
import { AppDispatch, RootState } from '@store'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  fetchTeacherProfileByUid,
  getStudentProfile,
  updateStudentCountOfCancel,
} from '@servise/profileAPI'
import { IMessage, MessageType } from '@models/IMessage'
import { firebaseTimestamp } from '@configs/firebase'
import { chatAPI } from '@servise/chatAPI'
import { sendCalendarNotify } from '@servise/webhookAPI'
import { ToastContent, toast } from 'react-toastify'
import CancelLessonNotify from './CancelLessonNotify'
import {
  UpdatePassedLessonsType,
  getPassedLessonsTeacherByStudent,
  updatePassedLesson,
} from '@servise/personalAPI'
import { PassedLessonStatus } from '@models/IPersonal'
import { calendarUtil } from '@utils/calendarUtil'
import { Tooltip, TooltipContainer } from '@pages/student/student.styles'

export default function({ onClose, event: propsEvent }: { onClose: () => void; event: IEvent }) {
  const [selectedDate, setSelectedDate] = useState(null)
  const [isCancel, setIsCancel] = useState(propsEvent?.isConfirm)
  const [isCanceled, setIsCanceled] = useState(false)
  const [isReschedule, setIsReschedule] = useState(false)
  const [disabledDates, setDisabledDates] = useState([])
  const [hourses, setHourses] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedHours, setSelectedHours] = useState(null)

  const [teacherCalendarEvents, setTeacherCalendarEvents] = useState([])
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const [teacher, setTeacher] = useState<null | IProfile>(null)
  const [event, setEvent] = useState<null | IEvent>(propsEvent)
  const [eventIsConfirm, setEventIsConfirm] = useState<null | boolean>(propsEvent?.isConfirm)
  const profile = useSelector((state: RootState) => state.profile.profile as IStudentProfile)
  const events = useSelector((state: RootState) => state.calendar.events)
  const checkEvent = (events: IEvent[], businessHours: IBusinessHours[], date: string) => {
    if (!events.length) return true
    let disabledDay = 0
    for (const item of businessHours) {
      const find = events.find(event => {
        return (
          new Date(event.rrule.dtstart).getTime() <
            new Date(date + ' ' + item.startTime).getTime() ||
          new Date(event.rrule.dtstart).getTime() > new Date(date + ' ' + item.endTime).getTime()
        )
      })

      if (find) {
        disabledDay++
      }
    }

    if (businessHours.length === disabledDay) return false
    return true
  }

  const isPossibleRechedule = useMemo(() => {
    return !events.some(ev => ev.prevEventId === event.eventID)
  }, [event, events])

  useEffect(() => {
    if (profile?.timeZone) {
      moment.tz.setDefault(profile.timeZone)
    }
  }, [profile])

  const handleRescheduleEvent = async () => {
    setLoading(true)
    const hours = moment(event.rrule.dtstart).diff(moment(), 'hours')
    if (hours < 8) {
      const passedLessons = await getPassedLessonsTeacherByStudent(teacher.id, event.studentId)
      const rateInfo = getTeacherRatesInfo(teacher.rate, passedLessons.length, teacher.rates)
      const rateLesson = getRateLesson(profile.rate, rateInfo.rate)

      const newPassedLesson = {
        s: PassedLessonStatus.active,
        n: profile.lastName + ' ' + profile.name,
        d: firebaseTimestamp.now(),
        r: rateLesson.toString(),
        cr: rateInfo.currency,
      }

      const updatePassedLessonData: UpdatePassedLessonsType = {
        studentId: event.studentId,
        teacherId: event.teacherId,
        teacherName: teacher.lastName + ' ' + teacher.name,
        newPassedLesson,
        lessonDate: newPassedLesson.d,
        paymentPackage: -1,
      }

      await updatePassedLesson(updatePassedLessonData)
      dispatch(getStudentProfile(event.studentId))
      toast((<CancelLessonNotify />) as ToastContent, {
        position: 'bottom-right',
        closeButton: true,
        autoClose: false,
        hideProgressBar: true,
        className: 'cancel-lesson-notify',
      })
    }
    //  else {
    //   const month = moment().month()
    //   dispatch(updateStudentCountOfCancel(profile.id, month))
    // }

    const dtstart = moment(moment(selectedDate).format('YYYY-MM-DD') + ' ' + selectedHours).format()
    const byweekday = [
      moment(selectedDate)
        .locale('en')
        .format('dd'),
    ]
    const plannedDate = calendarUtil.convertToMSK(event.rrule.dtstart)
    let rescheduleEvent: IEvent = {
      rrule: {
        freq: 2,
        byweekday,
        dtstart,
        count: 1,
      },
      studentId: event.studentId,
      prevEventId: event.eventID,
      teacherId: event.teacherId,
      type: event.type,
      reschedule: true,
      title: event.title,
      plannedDate: plannedDate.valueOf(),
    }

    const data: { id: string; message: IMessage } = {
      id: `${event.studentId}${event.teacherId}`,
      message: {
        id: event.studentId,
        m: 'student',
        plannedEvent: calendarUtil.convertToMSK(plannedDate.format()).format(),
        newEvent: calendarUtil.convertToMSK(rescheduleEvent.rrule.dtstart).format(),
        date: firebaseTimestamp.now().toMillis(),
        type: MessageType.schedule,
        eventType: event.type,
      },
    }

    await chatAPI.addUnreadMessage({
      userId: event.studentId,
      isTeacher: true,
      addUserId: event.teacherId,
    })

    await chatAPI.addUnreadMessage({
      userId: event.teacherId,
      isTeacher: false,
      addUserId: event.studentId,
    })

    await chatAPI.sendMessage(data)

    sendCalendarNotify({
      studentEmail: profile.email,
      teacherEmail: teacher.email,
      studentId: event.studentId,
      teacherId: event.teacherId,
      studentName: `${profile.name} ${profile.lastName}`,
      teacherName: `${teacher.name} ${teacher.lastName}`,
      date: calendarUtil.convertToMSK(rescheduleEvent.rrule.dtstart).format('DD.MM.YYYY'),
      time: calendarUtil.convertToMSK(rescheduleEvent.rrule.dtstart).format('HH:mm'),
      type: 'Change',
      p: event.type === CALENDAR_EVENT_TYPES.ONCE ? 'once' : 'regular',
      locale: profile.lang,
      teacher_chat_id: teacher?.notifications?.cid,
      student_chat_id: profile?.notifications?.cid,
      telegram: teacher?.telegram,
      ...(profile?.isTgOn && { studentCid: profile.notifications?.cid }),
    })
    dispatch(addEvent(rescheduleEvent)).then(() => {
      setLoading(false)
      setIsReschedule(true)
    })
  }

  const handleCancelLesson = async () => {
    setLoading(true)

    const hours = moment(event.rrule.dtstart).diff(moment(), 'hours')
    if (hours < 8) {
      const passedLessons = await getPassedLessonsTeacherByStudent(teacher.id, event.studentId)
      const rateInfo = getTeacherRatesInfo(teacher.rate, passedLessons.length, teacher.rates)
      const rateLesson = getRateLesson(profile.rate, rateInfo.rate)

      const newPassedLesson = {
        s: PassedLessonStatus.active,
        n: profile.lastName + ' ' + profile.name,
        d: firebaseTimestamp.now(),
        r: rateLesson.toString(),
        cr: rateInfo.currency,
      }

      const updatePassedLessonData: UpdatePassedLessonsType = {
        studentId: event.studentId,
        teacherId: event.teacherId,
        teacherName: teacher.lastName + ' ' + teacher.name,
        newPassedLesson,
        lessonDate: newPassedLesson.d,
        paymentPackage: -1,
      }

      await updatePassedLesson(updatePassedLessonData)
      dispatch(getStudentProfile(event.studentId))
      toast((<CancelLessonNotify />) as ToastContent, {
        position: 'bottom-right',
        closeButton: true,
        autoClose: false,
        hideProgressBar: true,
        className: 'cancel-lesson-notify',
      })
    } else {
      const month = moment().month()
      dispatch(updateStudentCountOfCancel(profile.id, month))
    }
    const newEvent = events.find(item => item.eventID === event.eventID)
    sendCalendarNotify({
      studentEmail: profile.email,
      teacherEmail: teacher.email,
      studentId: event.studentId,
      teacherId: event.teacherId,
      studentName: `${profile.name} ${profile.lastName}`,
      teacherName: `${teacher.name} ${teacher.lastName}`,
      date: calendarUtil.convertToMSK(event.rrule.dtstart).format('DD.MM.YYYY'),
      time: calendarUtil.convertToMSK(event.rrule.dtstart).format('HH:mm'),
      type: 'Cancel',
      p: event.type === CALENDAR_EVENT_TYPES.ONCE ? 'once' : 'regular',
      locale: profile.lang,
      teacher_chat_id: teacher?.notifications?.cid,
      student_chat_id: profile?.notifications?.cid,
      telegram: teacher?.telegram,
      ...(profile?.isTgOn && { studentCid: profile.notifications?.cid }),
    })
    if (newEvent.rrule?.count > 1) {
      const date = moment(event.rrule.dtstart).format()
      dispatch(
        updateEvent(
          {
            ...newEvent,
          },
          null,
          date
        )
      ).then(() => {
        setLoading(false)
        setIsCanceled(true)
      })
    } else {
      dispatch(deleteEvent({ event, isTeacher: false, isSendChat: true })).then(() => {
        setLoading(false)
        setIsCanceled(true)
      })
    }
  }

  useEffect(() => {
    if (event?.teacherId) {
      fetchTeacherProfileByUid(event.teacherId).then(teacher => {
        setTeacher({ ...teacher, id: event.teacherId })
      })
    }
  }, [event])
  useEffect(() => {
    if (teacher?.id) {
      getTeacherCalendarEvents(teacher?.id, profile.timeZone).then(events => {
        const allEvents = calendarUtil.getAllEvents(events, profile.timeZone, false)

        setTeacherCalendarEvents(allEvents)
        let dates = []

        for (var i = 0; i < 30; i++) {
          const date = moment().add(i, 'days')
          const weekday = date.weekday() + 1 === 7 ? 0 : date.weekday() + 1
          const filterBusinessHours = teacher.businessHours.filter(item =>
            item.daysOfWeek.includes(weekday)
          )

          const filterEvents = allEvents.filter(
            event => moment(event.rrule.dtstart).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
          )
          const isCheckEvent = checkEvent(
            filterEvents,
            filterBusinessHours,
            date.format('YYYY-MM-DD')
          )

          if (filterBusinessHours.length > 0 && isCheckEvent) {
          } else {
            dates.push(new Date(date.format('YYYY-MM-DD')))
          }
          if (i !== 0) {
            dates.push(
              new Date(
                moment()
                  .add(-i, 'days')
                  .format('YYYY-MM-DD')
              )
            )
          }
        }

        setDisabledDates(dates)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacher?.id, profile])

  const handleSelect = (newDate: any) => {
    const date = moment(newDate)
    const weekday = date.weekday() + 1 === 7 ? 0 : date.weekday() + 1
    const businessHours = teacher.businessHours.filter(item => item.daysOfWeek.includes(weekday))
    const newHourses = []
    const filterEvents = teacherCalendarEvents.filter(
      event => moment(event.rrule.dtstart).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
    )

    businessHours.forEach((hours, index) => {
      const find = filterEvents.find(
        event =>
          new Date(event.rrule.dtstart).getTime() <
            new Date(date.format('YYYY-MM-DD') + ' ' + hours.startTime).getTime() ||
          new Date(event.rrule.dtstart).getTime() >
            new Date(date.format('YYYY-MM-DD') + ' ' + hours.endTime).getTime()
      )

      if (find || !filterEvents.length) {
        newHourses.push(hours.startTime)
        if (
          businessHours.length - 1 > index &&
          Number(hours.startTime.substring(0, 2)) + 1 ===
            Number(businessHours[index + 1].startTime.substring(0, 2))
        ) {
          newHourses.push(hours.startTime.substring(0, 2) + ':30')
        }
      }
    })

    setHourses(newHourses)
    setSelectedDate(newDate)
  }

  if (isCancel) {
    return (
      <Styled.RescheduleLessonContainer>
        <Styled.RescheduleLessonHeader>{t('Schedule.Cancellation')}</Styled.RescheduleLessonHeader>
        <Styled.Divider />

        <Styled.CancelLessonText style={{ margin: isCanceled ? '0px 25px' : '0px 75px' }}>
          {isCanceled ? (
            eventIsConfirm ? (
              <>
                {t('Schedule.LessonRequestCanceled')}
                <span>
                  {t('Schedule.DateTime', {
                    date: moment(event.rrule.dtstart).format('DD MMMM'),
                    time: moment(event.rrule.dtstart).format('HH:mm'),
                  })}
                </span>
                {t('Schedule.Canceled')}.
              </>
            ) : (
              <>
                {t('Schedule.LessonCanceled')}
                <span>
                  {t('Schedule.DateTime', {
                    date: moment(event.rrule.dtstart).format('DD MMMM'),
                    time: moment(event.rrule.dtstart).format('HH:mm'),
                  })}
                </span>
                {t('Schedule.Canceled')}.
              </>
            )
          ) : eventIsConfirm ? (
            <>
              {t('Schedule.LessonRequestConfirmText')}
              <span>
                {t('Schedule.DateTime', {
                  date: moment(event.rrule.dtstart).format('DD MMMM'),
                  time: moment(event.rrule.dtstart).format('HH:mm'),
                })}
              </span>
              ?
            </>
          ) : (
            <>
              {t('Schedule.ConfirmText')}
              <span>
                {t('Schedule.DateTime', {
                  date: moment(event.rrule.dtstart).format('DD MMMM'),
                  time: moment(event.rrule.dtstart).format('HH:mm'),
                })}
              </span>
              ?
            </>
          )}
        </Styled.CancelLessonText>

        <Styled.Footer style={{ margin: isCanceled ? '40px 0px 50px 0px' : '30px 0px' }}>
          <Button
            whiteTheme
            bgWhite
            children={isCanceled ? t('Schedule.Good') : t('Schedule.ConfirmCancel')}
            type="button"
            loading={loading}
            onClick={() => {
              !isCanceled ? handleCancelLesson() : onClose()
            }}
          />

          {!isCanceled && (
            <Styled.Button cancelBtn onClick={onClose}>
              {t('Schedule.NotCancel')}
            </Styled.Button>
          )}
        </Styled.Footer>
      </Styled.RescheduleLessonContainer>
    )
  }

  if (isReschedule) {
    const dtstart = moment(moment(selectedDate).format('YYYY-MM-DD') + ' ' + selectedHours).format()
    return (
      <Styled.RescheduleLessonContainer>
        <Styled.RescheduleLessonHeader>{t('Schedule.Reschedule')}</Styled.RescheduleLessonHeader>
        <Styled.Divider />

        <Styled.CancelLessonText>
          {t('Schedule.Rescheduled', {
            date: moment(dtstart).format('DD MMMM'),
            time: moment(dtstart).format('HH:mm'),
          })}
        </Styled.CancelLessonText>

        <Styled.Footer>
          <Button
            whiteTheme
            bgWhite
            children={t('Schedule.RescheduleOk')}
            type="button"
            onClick={onClose}
          />
          <Styled.Button cancelBtn>{t('Cancel')}</Styled.Button>
        </Styled.Footer>
      </Styled.RescheduleLessonContainer>
    )
  }

  return (
    <Styled.RescheduleLessonContainer>
      <Styled.RescheduleLessonHeader>{t('Schedule.Title')}</Styled.RescheduleLessonHeader>
      <Styled.Divider />
      <Styled.MainContainer>
        <Styled.LeftContainer>
          <Calendar
            disabledDates={disabledDates}
            maxDate={
              new Date(
                moment()
                  .add(14, 'days')
                  .format()
              )
            }
            date={selectedDate}
            onChange={handleSelect}
          />
          <Styled.Footer>
            <Tooltip
              placement="right-start"
              title={<TooltipContainer>{t('Schedule.LimitDescr')}</TooltipContainer>}
            >
              <Styled.CancelButton
                onClick={() => setIsCancel(true)}
                disabled={profile.countOfCancel?.c >= 4 || false}
              >
                {t('Schedule.Cancel')}
              </Styled.CancelButton>
            </Tooltip>

            <Styled.Button onClick={onClose}>{t('Schedule.HideWindow')}</Styled.Button>
          </Styled.Footer>
        </Styled.LeftContainer>
        {selectedDate && (
          <Styled.RightContainer>
            <Styled.SelectedDateTimeLabel>
              {new Date(selectedDate).toDateString()}
            </Styled.SelectedDateTimeLabel>

            <Styled.HoursContainer>
              {hourses.map((hours, index) => {
                if (hours === selectedHours)
                  return (
                    <Styled.SelectedItem key={hours + index}>
                      <Styled.SelectedHours>{hours}</Styled.SelectedHours>
                      <Tooltip
                        title={<TooltipContainer>{t('Schedule.LimitReschedule')}</TooltipContainer>}
                        placement="right-start"
                      >
                        <Styled.CheckTimeButton
                          onClick={handleRescheduleEvent}
                          disabled={loading || !isPossibleRechedule}
                        >
                          {t('Schedule.Choose')}
                        </Styled.CheckTimeButton>
                      </Tooltip>
                    </Styled.SelectedItem>
                  )
                return (
                  <Styled.HoursItem key={hours + index} onClick={() => setSelectedHours(hours)}>
                    {hours}
                  </Styled.HoursItem>
                )
              })}
            </Styled.HoursContainer>
          </Styled.RightContainer>
        )}
      </Styled.MainContainer>
    </Styled.RescheduleLessonContainer>
  )
}
